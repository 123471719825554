import { track } from "instrumentation/tracking/track";

export const trackClickPreviewNotifications = (fields) => track("track_click_preview_notifications", fields);

export const trackClickAddGuestNotificationSet = (fields) => track("track_click_add_guest_notification_set", fields);

export const trackClickPostCheckoutInstructions = (fields) => track("track_click_post_checkout_instructions", fields);

export const trackClickEditNotificationSet = (fields) => track("track_click_edit_notification_set", fields);

export const trackClickSaveNotificationSet = (fields) => track("track_click_save_notification_set", fields);
