export const Fields = {
  ConfigName: "config_name",
  Description: "description",
  ShowCreateOrder: "show_create_order",
  ShowKDSView: "show_kds_view",
  ShowLocationsOverview: "show_locations_overview",
  ShowMenuEditor: "show_menu_editor",
  ShowOrderControl: "show_order_control",
  ShowStationsOverview: "show_stations_overview",
  ShowTipReport: "show_tip_report",
  UseStripeChipReader: "use_stripe_chip_reader",
};
