import React from "react";
import axios from "axios";

// Components
import { Container, Row, Col, Button, OverlayTrigger, Tooltip, ButtonGroup } from "react-bootstrap";
import FullfillmentTable from "components/owner-app/tables/fulfillment-table/FulfillmentTable";
import { Link } from "react-router-dom";
import CreateNewFulfillmentNoticesModal from "components/owner-app/modals/create-fulfillment-notice-modal/CreateFulfillmentNoticesModal";
import FulfillmentNoticeCheckerModal from "components/owner-app/modals/fulfillment-notice-checker-modal/FulfillmentNoticeCheckerModal";
import { toast } from "react-toastify";
import EditFulfillmentNoticesModal from "components/owner-app/modals/edit-fulfillment-notice-modal/EditFulfillmentNoticeModal";
import {
  trackClickAddGuestNotificationSet,
  trackClickPreviewNotifications,
} from "instrumentation/tracking/page-tracking-events";

class FulfillmentNoticeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCustomer: null,
      isMobile: false,
      showModal: false,
      selected: [],
      fulfillmentNotices: [],
      stations: [],
      locations: [],
      userInfo: null,
      showCheckerModal: false,
      customerStationsFulfillmentMethods: [],
      fulfillmentMethods: [],
      statusNames: {},
      fulfillmentMethodPrettyNames: {},
      showEditModal: false,
      editFulfillmentNoticeId: null,
    };
  }

  componentDidMount() {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) {
      return;
    }

    this.getNotices();
    this.getStations();
    this.getLocations();
    this.getFulfillmentMethods();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getNotices();
      this.getStations();
      this.getLocations();
      this.getFulfillmentMethods();
    }

    if (this.state.fulfillmentNotices !== prevState.fulfillmentNotices) {
      this.sortNotices(this.state.fulfillmentNotices);
    }
  }

  resetToDefault = async () => {
    // note: currently the only error status possibly returned by the endpoints is 403 forbidden, so I don't
    // bother to case on other errors. If more sophisticated error behavior is developed, the catch block should
    // be changed
    const { selectedCustomer } = this.props;

    if (
      window.confirm(
        "Are you sure you wish to reset? This action is not reversible. This will reset ALL notification settings for this customer. It will erase all customized settings you have saved."
      )
    ) {
      try {
        await axios.post("/owner/resetFulfillmentNoticesToDefault", {
          customer_id: selectedCustomer.customer_id,
        });
        await this.getNotices();
        toast.success("Successfully reset all notification settings.");
      } catch (error) {
        console.error(error.response.data.message);
        toast.error("There was an error resetting the notification settings.");
      }
    }
  };

  getFulfillmentMethods = async () => {
    const { selectedCustomer } = this.props;
    try {
      let res = await axios.get("/api/getCustomerFulfillmentMethods", {
        params: { customer_id: selectedCustomer?.customer_id },
      });

      // add in checkout complete, refunded for all fulfillment methods
      Object.keys(res.data.fulfillment_methods).forEach((method) => {
        res.data.fulfillment_methods[method].unshift("checkout_complete");
        res.data.fulfillment_methods[method].push("refunded");
      });
      // add in an 'All' fulfillment method
      res.data.fulfillment_methods.all = ["checkout_complete", "refunded"];
      res.data.customer_fulfillment_methods.push("all");

      this.setState({
        customerStationsFulfillmentMethods: res.data.customer_fulfillment_methods,
        fulfillmentMethods: res.data.fulfillment_methods,
        statusNames: res.data.status_pretty_names,
      });
    } catch (error) {
      toast.error("There was an error getting the notification settings. Redirecting to login page.");
      console.error(error.response.data.message);
      this.props.history.push("/login");
    }
  };

  getNotices = async () => {
    const { selectedCustomer } = this.props;
    let endpoint = "/owner/fulfillmentNotices";
    try {
      const res = await axios.get(endpoint, {
        params: { customer_id: selectedCustomer?.customer_id },
      });
      this.setState({
        fulfillmentNotices: this.sortNotices(res.data.fulfillmentNotices),
        defaultEmails: res.data.defaultEmails,
        defaultTexts: res.data.defaultTexts,
      });
    } catch (error) {
      toast.error("There was an error getting the notification settings. Redirecting to login page.");
      this.props.history.push("/login");
    }
  };

  getStations = async () => {
    let endpoint = "/owner/stations";
    try {
      const res = await axios.get(endpoint);
      this.setState({
        stations: res.data.stations,
      });
    } catch (error) {
      toast.error("There was an error getting the notification settings. Redirecting to login page.");
      console.error(error.response.data.message);
      this.props.history.push("/login");
    }
  };

  getLocations = async () => {
    let endpoint = "/owner/locations";
    try {
      const res = await axios.get(endpoint);
      this.setState({
        locations: res.data.locations,
      });
    } catch (error) {
      toast.error("There was an error getting the notification settings. Redirecting to login page.");
      console.error(error);
      this.props.history.push("/login");
    }
  };

  deleteFulfillmentNotices = async (notice_ids) => {
    const { selectedCustomer } = this.props;
    const { fulfillmentNotices } = this.state;

    const payload = {
      customer_id: selectedCustomer.customer_id,
      notice_ids: notice_ids,
    };

    if (fulfillmentNotices.length <= 1) {
      toast.warn("Cannot delete this Notification Set, you must have at least one Guest Notification Set.");
      return;
    }

    try {
      await axios.post("/owner/deleteFulfillmentNotices", payload);

      let updatedNoticeList = fulfillmentNotices.filter((d) => !notice_ids.includes(d.id));

      this.setState({
        fulfillmentNotices: updatedNoticeList,
      });
    } catch (error) {
      console.error(error.response.data.message);
      toast.error("Couldn't delete the guest notification set: " + error.response.data.message);
    }
  };

  createNewFulfillmentNotice = async (notice) => {
    const { fulfillmentNotices } = this.state;
    const { selectedCustomer } = this.props;

    // make sure advanced settings are consistent
    if (notice.configuredFor === "All Stations") {
      notice.selectedLocations = [];
    } else if (notice.configuredFor === "All Locations") {
      notice.selectedStations = [];
    }

    // make sure the name doesn't exist already
    const fulfillmentNoticeNames = fulfillmentNotices.map((fulfillmentNotice) => fulfillmentNotice.name);
    if (fulfillmentNoticeNames.includes(notice.name)) {
      toast.warn("A Guest Notification Set already exists with that name. Please use a different name.");
      return;
    }

    // preprocess the stations and locations list to only include the IDs
    notice.stations = notice.selectedStations;
    notice.locations = notice.selectedLocations;

    const payload = {
      customer_id: selectedCustomer.customer_id,
      notice_params: {
        ...notice,
        texts_to_patron: [],
        emails_to_patron: [],
        integrations: [],
        post_checkout_instructions: [],
      },
    };

    try {
      const res = await axios.post("/owner/createNewFulfillmentNotice", payload);

      this.getNotices();

      this.setState({
        showModal: false,
      });

      // Redirect to the new fulfillment notice page
      const fulfillmentNoticeId = res.data.id;
      window.location.href = "/fulfillment-notices/" + fulfillmentNoticeId;
    } catch (error) {
      toast.error("There was an error creating a notification set: " + error.response.data.message);
      console.error(error.response.data.message);
    }
  };

  editFulfillmentNotice = async (notice) => {
    const { editFulfillmentNoticeId } = this.state;
    const { selectedCustomer } = this.props;

    // make sure advanced settings are consistent
    if (notice.configuredFor === "All Stations") {
      notice.selectedLocations = [];
    } else if (notice.configuredFor === "All Locations") {
      notice.selectedStations = [];
    }

    // preprocess the stations and locations list to only include the IDs
    notice.stations = notice.selectedStations;
    notice.locations = notice.selectedLocations;

    const payload = {
      customer_id: selectedCustomer.customer_id,
      notice_params: notice,
      fulfillment_notice_id: editFulfillmentNoticeId,
    };

    try {
      await axios.post("/owner/editFulfillmentNotice", payload);

      this.getNotices();

      this.setState({
        showEditModal: false,
        editFulfillmentNoticeId: null,
      });
    } catch (error) {
      toast.error("There was an error creating a notification set. Please refresh the page and try again.");
      console.error(error.response.data.message);
    }
  };

  sortNotices = (list) => {
    list.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      } else if (a.name === "Default Notification Set") {
        return -1;
      } else if (b.name === "Default Notification Set") {
        return 1;
      } else {
        return a.name < b.name ? -1 : 1;
      }
    });
    return list;
  };

  render() {
    const { selectedCustomer, userInfo, isSelectedCustomerFlaggedForDovetail } = this.props;
    return (
      <div>
        <Container>
          <div className={"breadcrumbs-row margin-bottom-4"}>
            <span>
              <Link to={"/"} className={"breadcrumb-back"}>
                &#60; Back
              </Link>
            </span>
            <span className={"breadcrumbs"}>
              <span>
                <Link to={"/"} className={"breadcrumb-link"}>
                  Owner Portal
                </Link>
              </span>
              <span className={"breadcrumb-link"}>&#62;</span>
              <span className={"color-blue-5"}>Guest Notification Sets</span>
            </span>
          </div>
          <div className={"margin-bottom-3"}>
            <h2>
              Manage Guest Notification Sets For{" "}
              <span className={"color-blue-5"}>{selectedCustomer?.customer_name}</span>
            </h2>
          </div>
          <div className={"margin-bottom-3"}>
            <p>
              A Guest Notification Set is the sequence of emails/texts that the system will send to guests after
              ordering (e.g. notifying them that the order is ready to pick up). Click below to edit these text messages
              and emails.
            </p>
            <p>
              <i>
                Note: For advanced use cases, you can create an additional Notification Set that will be used for orders
                placed at a specific Location Code, or for orders fulfilled by a specific Prep Station.
              </i>
            </p>
          </div>
          <div className={"margin-bottom-3"}>
            <Button
              as={ButtonGroup}
              size={"lg"}
              className={"reset-to-defaults-btn margin-right-2"}
              onClick={this.resetToDefault}
            >
              Reset all notifications to default
            </Button>
          </div>
          <OverlayTrigger
            placement={"right"}
            overlay={<Tooltip>View the email/text of a specific configuration</Tooltip>}
          >
            <Button
              size={"lg"}
              variant={"outline-primary"}
              className={"margin-bottom-4"}
              onClick={() => {
                trackClickPreviewNotifications();
                this.setState({ showCheckerModal: true });
              }}
            >
              Preview fulfillment notification
            </Button>
          </OverlayTrigger>

          <Row>
            <Col sm={12}>
              <FullfillmentTable
                userInfo={userInfo}
                title={"Guest Notification Sets"}
                columns={
                  userInfo?.role === "admin"
                    ? [
                        { label: "Name", name: "name" },
                        {
                          label: "Location of order",
                          name: "locations",
                          innerName: "locationName",
                        },
                        {
                          label: "Prep Station fulfilling order",
                          name: "stations",
                          innerName: "station_name",
                        },
                        {
                          label: "",
                          name: "edit",
                        },
                      ]
                    : [
                        { label: "Name", name: "name" },
                        {
                          label: "Locations Affected",
                          name: "locations",
                          innerName: "locationName",
                        },
                        {
                          label: "Stations Affected",
                          name: "stations",
                          innerName: "station_name",
                        },
                      ]
                }
                data={this.state.fulfillmentNotices}
                selected={this.state.selected}
                handleDelete={(selectedRows) => {
                  const selectedFMN = selectedRows.map((r) => r.id);
                  this.deleteFulfillmentNotices(selectedFMN);
                }}
                handleSelectRow={(data) => {
                  this.setState({ selected: [data] });
                }}
                addRow={() => {
                  trackClickAddGuestNotificationSet();
                  this.setState({
                    showModal: true,
                  });
                }}
                editNotificationSet={(fulfillmentNoticeId) => {
                  this.setState({
                    showEditModal: true,
                    editFulfillmentNoticeId: fulfillmentNoticeId,
                  });
                }}
                isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
              />
            </Col>
          </Row>
        </Container>
        {/*{this.editStationsLocationsModal()}*/}
        <EditFulfillmentNoticesModal
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({
              showEditModal: false,
              editFulfillmentNoticeId: null,
            })
          }
          selectedFulfillmentNotice={this.state.fulfillmentNotices.find(
            (fulfillmentNotice) => fulfillmentNotice.id === this.state.editFulfillmentNoticeId
          )}
          stations={this.state.stations}
          locations={this.state.locations}
          saveCallback={(payload) => {
            this.editFulfillmentNotice(payload);
          }}
          isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
        />
        <CreateNewFulfillmentNoticesModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          stations={this.state.stations}
          locations={this.state.locations}
          saveCallback={(payload) => {
            this.createNewFulfillmentNotice(payload);
          }}
          isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
        />
        <FulfillmentNoticeCheckerModal
          show={this.state.showCheckerModal}
          onHide={() => this.setState({ showCheckerModal: false })}
          stations={this.state.stations}
          locations={this.state.locations}
          customerStationsFulfillmentMethods={this.state.customerStationsFulfillmentMethods}
          fulfillmentMethods={this.state.fulfillmentMethods}
          fulfillmentNotices={this.state.fulfillmentNotices}
          statusNames={this.state.statusNames}
          fulfillmentMethodPrettyNames={this.state.fulfillmentMethodPrettyNames}
          saveCallback={(payload) => {
            this.createNewFulfillmentNotice(payload);
          }}
          isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
        />
      </div>
    );
  }
}

export default FulfillmentNoticeList;
