import * as React from "react";
import { Modal } from "@doordash/component-modal";
import { ModalProps } from "../constants";
import { Text } from "@doordash/design-language";
import Toggle from "@doordash/component-toggle";
import { Select, TextField } from "@doordash/component-fields";

export const AddOrEditMenuItemModal: React.FC<ModalProps> = ({
  primaryActionProps,
  secondaryActionProps,
  setOpenModal,
  fields,
  updateForm,
  updateFormField,
  onClose,
}) => (
  <Modal
    title={"Edit Menu Item"}
    // title={`${fields.real_property ? 'Edit' : 'New'} Menu Item`}
    primaryAction={primaryActionProps}
    secondaryAction={secondaryActionProps}
    onClose={onClose}
    isFullScreenOnMobile
  >
    <Text styles={Text.Styles.Title2}>1. Select Item Type:</Text>
    <Toggle
      type={Toggle.Types.Radio}
      label="Drink (e.g., Jack Daniels, Margarita)"
      onChange={() => updateFormField("item_type", "drink")}
      isSelected={fields.item_type === "drink"}
    />
    <Toggle
      type={Toggle.Types.Radio}
      label="Food (e.g., Pizza, salad)"
      onChange={() => updateFormField("item_type", "food")}
      isSelected={fields.item_type === "food"}
    />
    <Text styles={Text.Styles.Title2}>2. Presentation To Customer:</Text>
    <Select
      label={"Include on Menu(s)"}
      size={Select.Sizes.Medium}
      onChange={(value: string) => updateFormField("include_on_menus", value)}
      options={[{ name: "bella italia catering menu", value: "bella_italia_catering_menu" }]}
    />
    <Select
      label={"Display in Menu Heading"}
      size={Select.Sizes.Medium}
      onChange={(value: string) => updateFormField("display_in_menu_heading", value)}
      options={[
        { name: "Half Trays and Full Trays", value: "Half Trays and Full Trays" },
        { name: "Individually Packed Salads", value: "Individually Packed Salads" },
        { name: "Individual Packaged Sandwiches", value: "Individual Packaged Sandwiches" },
        { name: "Pastas", value: "Pastas" },
        { name: "Pizza Packages", value: "Pizza Packages" },
      ]}
    />
    <TextField
      label={"Item Display Name"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("item_display_name", value)}
      value={fields.item_display_name}
    />
    <Toggle
      label="Most Loved"
      onChange={(value: boolean) => updateFormField("most_loved", value)}
      isSelected={fields.most_loved}
    />
    <TextField
      label={"Description"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("description", value)}
      value={fields.description}
    />
    <TextField
      label={"Price"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("price", value)}
      value={fields.price}
    />
    <TextField
      label={"Tax Percent"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("tax_percent", value)}
      value={fields.tax_percent}
    />
    <Toggle
      label="Allow Modifiers"
      onChange={(value: boolean) => updateFormField("allow_modifiers", value)}
      isSelected={fields.allow_modifiers}
    />
    <Text styles={Text.Styles.Title2}>Select Modifiers:</Text>
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>SIDE CHOICE</Text>
    French fries Side salad
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>INDIVIDUAL DRINK CHOICE</Text>
    Bottle of water San Pellegrino sparkling water Cola Diet cola Lemon lime
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>PROTEIN ADDITION</Text>
    Add chicken ($3.00) Add grilled chicken (half) ($20.00) Add grilled chicken (full) ($40.00)
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>SIZE CHOICE</Text>
    Half (serves 8-10) ($64.00) Full (serves 16-20) ($125.00) Half (serves 8-10) ($85.00) Full (serves 16-20) ($170.00)
    Half (serves 8-10) ($65.00) Full (serves 16-20) ($130.00) Half (serves 8-10) ($75.00) Full (serves 16-20) ($150.00)
    Half (serves 8-10) ($95.00) Full (serves 16-20) ($190.00) Half (serves 8-10) ($70.00) Full (serves 16-20) ($145.00)
    Half (serves 8-10) ($50.00) Full (serves 16-20) ($100.00) Half (serves 8-10) ($60.00) Full (serves 16-20) ($110.00)
    Half (serves 8-10) ($80.00) Full (serves 16-20) ($160.00)
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>PROTEIN CHOICE</Text>
    Beef Chicken
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>CUSTOMIZE</Text>
    No honey mustard and BBQ sauce No Parmigiano cheese No Bread and butter No bread and butter
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>PREPERATION CHOICE</Text>
    Meatballs Sausage
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>SALAD CHOICE</Text>
    Italian Caesar
    <Text styles={Text.Styles.Title2}>3. Reporting:</Text>
    <TextField
      label={"Name for reports"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("name_for_reports", value)}
      value={fields.name_for_reports}
    />
    <Select
      label={"Report Category"}
      size={Select.Sizes.Medium}
      onChange={(value: string) => updateFormField("report_category", value)}
      options={[
        { name: "Food", value: "Food" },
        { name: "Beer", value: "Beer" },
        { name: "Liquor", value: "Liquor" },
        { name: "Other", value: "Other" },
      ]}
    />
    <TextField
      label={"Sku (optional)"}
      autoComplete={"off"}
      onChange={(value: string) => updateFormField("sku", value)}
      value={fields.sku}
    />
    <Text styles={Text.Styles.Title2}>4. Fulfillment: *</Text>
    More Info. Enabled Name displayed on tablet Mixed Hoagie Tray
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>INVENTORY MANAGEMENT</Text>
    <Toggle
      label="In Stock"
      type={Toggle.Types.Radio}
      onChange={() => updateFormField("inventory_management", "in_stock")}
      isSelected={fields.inventory_management === "in_stock"}
    />
    <Toggle
      label="Out of Stock"
      type={Toggle.Types.Radio}
      onChange={() => updateFormField("inventory_management", "out_of_stock")}
      isSelected={fields.inventory_management === "out_of_stock"}
    />
    <Toggle
      label="Quantity"
      type={Toggle.Types.Radio}
      onChange={() => updateFormField("inventory_management", "quantity")}
      isSelected={fields.inventory_management === "quantity"}
    />
    <Toggle
      label="Uses the same inventory as item..."
      onChange={(value: boolean) => updateFormField("uses_the_same_inventory_as_item", value)}
      isSelected={fields.uses_the_same_inventory_as_item}
    />
    <Text styles={Text.Styles.TagMediumLabel}>WHICH STATION FULFILLS THIS ITEM?</Text>
    Bella Italia Catering Station
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>ADD MENU TAGS:</Text>
    Spicy Vegan Alcohol Vegetarian Gluten free Skips ahead in line by: 0 minutes
    <br />
    <Text styles={Text.Styles.TagMediumLabel}>SPECIAL INSTRUCTIONS:</Text>
    Guests may type instructions Prompt to Guests: Add item image (250kb max): Show image on front page of menuShow
    image on menu item page
  </Modal>
);
