import { useState } from "react";

import { Link } from "react-router-dom";

import { isLinkFlagged, getDovetailButtonDisabled, getDovetailRedirectLink, getDovetailClassnames } from "./dovetail";

import { trackClickDashboardItem } from "instrumentation/tracking/page-tracking-events/DashboardPageTracking";

import BBOT_SERVER from "Config";

import ToolTip from "../../components/dovetail/ToolTip";

const DashboardLink = ({
  link,
  index,
  redirectUrlAppendix,
  isSuperAdmin,
  isCurrentOwnerFlaggedForDovetail: isOwnerFlagged,
  selectedCustomer,
}) => {
  const [isToolTipVisible, setIsToolTipVisible] = useState(false);
  const isButtonDisabledAndOwnerFlagged = getDovetailButtonDisabled(link.name) && isOwnerFlagged;
  const isLinkAndOwnerFlagged = isLinkFlagged(link.name) && isOwnerFlagged;

  const onLinkClick = (event, link) => {
    const newRedirectURL = getDovetailRedirectLink(link.name, isOwnerFlagged, selectedCustomer?.dd_store_id);

    if (isButtonDisabledAndOwnerFlagged) {
      setIsToolTipVisible(!isToolTipVisible);
    }
    if (newRedirectURL) {
      window.open(newRedirectURL, "_blank");
    }
    if (newRedirectURL || isButtonDisabledAndOwnerFlagged) {
      // Prevent the to/href from getting activated and do not track
      event.preventDefault();
      return null;
    }

    return trackClickDashboardItem({ destination_link: link.link });
  };

  // Use this to differentiate between react/angular links for routing
  const CustomLink = ({ children }) => {
    const linkProps = {
      id: link.id,
      disabled: isButtonDisabledAndOwnerFlagged,
      onClick: (e) => onLinkClick(e, link),
      onMouseLeave: () => setIsToolTipVisible(false),
    };

    const href = link.isAdminPortalLink
      ? BBOT_SERVER + link.link
      : redirectUrlAppendix + link.link + "?from=owner_react_app";

    return link.reactLink ? (
      <Link to={link.link} {...linkProps} className={`react-link ${isSuperAdmin ? "super-admin-link" : ""}`}>
        {children}
      </Link>
    ) : (
      <a href={href} {...linkProps} className={isSuperAdmin ? "super-admin-link" : ""}>
        {children}
      </a>
    );
  };

  return (
    <div key={"dashboard-link-" + index} className="dashboard-link">
      <CustomLink>
        <div className={`dashboard-link-container`}>
          <span
            className={`material-icons-outlined link-icon ${getDovetailClassnames(
              link.name,
              isOwnerFlagged,
              isSuperAdmin
            )}`}
          >
            {link.googleIcon}
          </span>
          <span className={`dashboard-link-text ${getDovetailClassnames(link.name, isOwnerFlagged, isSuperAdmin)}`}>
            {link.name}
          </span>
          {isLinkAndOwnerFlagged ? (
            <ToolTip
              link={link}
              isToolTipVisible={isToolTipVisible}
              setIsToolTipVisible={setIsToolTipVisible}
              isOwnerFlagged={isOwnerFlagged}
            />
          ) : null}
        </div>
      </CustomLink>
    </div>
  );
};

export default DashboardLink;
