import axios from "axios";
import { Formik, Form as FormikForm } from "formik";
import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ENV } from "../../../../Config";
import { generalErrorAlert } from "../../../../util/Utils";
import { CheckboxInput } from "../../../global/form";

class ZapierIntegrationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectUrl:
        ENV === "prod"
          ? "https://zapier.com/app/dashboard"
          : "https://zapier.com/developer/public-invite/138525/f8783254f21a9a3aec714727797eed5c/",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.show && !prevProps.show) || prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getZapierConnectionStatus();
    }
  }

  getZapierConnectionStatus = async () => {
    const { selectedCustomer } = this.props;
    try {
      let res = await axios.get("/owner/getZapierConnectionStatus?customer_id=" + selectedCustomer.customer_id);
      this.setState({
        customerConnected: res.data.connected,
      });
      if (this.state.customerConnected) {
        this.setState({
          orderNew: res.data.settings.order_new,
          orderUpdate: res.data.settings.order_update,
          orderRefund: res.data.settings.order_refund,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Encountered an unknown error while attempting to access your Zapier integration settings");
    }
  };
  set_zapier_webhook_notification_settings = async (values) => {
    const { selectedCustomer, onClose, onSave } = this.props;
    try {
      let res = await axios.post("/owner/setZapierWebhookSettings", {
        customer_id: selectedCustomer.customer_id,
        integration_settings: { ...values },
      });
      this.setState({
        orderNew: res.data.settings.webhook_notification_level.order_new,
        orderUpdate: res.data.settings.webhook_notification_level.order_update,
        orderRefund: res.data.settings.webhook_notification_level.order_refund,
      });

      toast.success("Successfully updated your Zapier webhook preferences");
      onSave();
      onClose();
    } catch (error) {
      console.log(error);
      toast.error("Encountered an unknown error while attempting to access your Zapier integration settings");
    }
  };

  editModal() {
    const { show, onClose } = this.props;
    const { orderNew, orderUpdate, orderRefund } = this.state;
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Integrate With Zapier</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            order_new: orderNew,
            order_update: orderUpdate,
            order_refund: orderRefund,
          }}
          onSubmit={(values) => this.set_zapier_webhook_notification_settings(values)}
        >
          {({ values, errors, touched, handleSubmit, isValidating, setFieldValue }) => (
            <Form className={"styled-form"} onSubmit={handleSubmit}>
              <Modal.Body>
                <div>
                  <div>
                    <CheckboxInput label={"New orders"} name={"order_new"} id={"order_new"} />
                    <CheckboxInput label={"Status changes"} name={"order_update"} id={"order_update"} />
                    <CheckboxInput label={"Refunds"} name={"order_refund"} id={"order_refund"} />
                  </div>
                </div>
                <a href="https://zapier.com/app/zaps" target="_blank" rel="noopener noreferrer">
                  Manage your zaps
                </a>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                  className={"margin-right-1"}
                >
                  Close
                </Button>
                <Button size={"sm"} type={"submit"}>
                  Save Settings
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
  connectModal() {
    const { show, onClose } = this.props;
    const { redirectUrl } = this.state;
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
      >
        <Formik enableReinitialize initialValues={{}} onSubmit={this.onSubmit}>
          {({ values, errors, touched, isValidating, setFieldValue }) => (
            <Form className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Integrate With Zapier</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <div>Connect with TOP on Zapier's App Store</div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={() => {
                    onClose();
                  }}
                  className={"margin-right-1"}
                >
                  Close
                </Button>
                <Button
                  size={"sm"}
                  type={"submit"}
                  onClick={() => {
                    window.open(redirectUrl, "_blank");
                  }}
                  className={"margin-right-1"}
                >
                  Go to Zapier App Store
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
  disconnectModal() {
    const { show, onClose } = this.props;
    return (
      <Modal
        show={show}
        onHide={() => {
          onClose();
        }}
        className={"zapier-modal"}
      >
        <Formik initialValues={{}}>
          <FormikForm className={"styled-form"}>
            <Modal.Header closeButton>
              <Modal.Title>Disconnect Zapier</Modal.Title>
            </Modal.Header>
            <Modal.Body>Click disconnect to revoke Zapier's access to your data.</Modal.Body>
            <Modal.Footer>
              <Button
                size={"sm"}
                variant={"light"}
                onClick={() => {
                  onClose();
                }}
                className={"margin-right-1"}
              >
                Cancel
              </Button>
              <Button
                size={"sm"}
                type={"submit"}
                onClick={() => {
                  this.revokeZapierAccessToken();
                }}
              >
                Disconnect
              </Button>
            </Modal.Footer>
          </FormikForm>
        </Formik>
      </Modal>
    );
  }
  revokeZapierAccessToken = async () => {
    const { onSave, onClose, selectedCustomer } = this.props;
    try {
      await axios.get("/owner/revokeZapier?customer_id=" + selectedCustomer.customer_id);
      toast.success("Successfully revoked Zapier read access to your data");
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(
        error,
        "Encountered an unknown error while attempting to revoke Zapier's read access to your data."
      );
    }
  };

  render() {
    const { disconnectMode } = this.props;
    const { customerConnected } = this.state;
    if (disconnectMode) {
      return this.disconnectModal();
    } else {
      if (customerConnected) {
        return this.editModal();
      } else {
        return this.connectModal();
      }
    }
  }
}

export default ZapierIntegrationModal;
