import { notification } from "antd";
import Text from "antd/es/typography/Text";
import axios from "axios";
import "components/owner-app/modals/printer-configuration/NetworkConfigModals.scss";
import PropTypes from "prop-types";
import { useState } from "react";
import styled from "styled-components";
import { BbotModal, Form, FormattedCheckboxGroupInput, Row } from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const EditHandheldsOnNetworkModal = ({
  showAddHandheldConfigModal,
  network,
  selectableConfigs,
  visibleNetworkConfigs,
  selectedCustomer,
  onSaveCallback,
  onHideCallback,
}) => {
  const [editConfigsOnNetworkForm] = Form.useForm();
  const [isSavingNetwork, setIsSavingNetwork] = useState(false);
  const [, setFormValues] = useState({});

  const editHandheldConfigs = async (values) => {
    try {
      setIsSavingNetwork(true);
      const payload = {
        network_id: network.id,
        customer_id: selectedCustomer.customer_id,
        device_ids_to_add: values.handheldsToAdd,
        device_ids_to_remove: selectableConfigs
          .filter((config) => !values.handheldsToAdd.includes(config.id))
          .map((config) => config.id),
      };
      let res = await axios.post("/printer-setup/edit-configs-on-network", payload);
      notification.success({ message: res.data.success });
      setIsSavingNetwork(false);
      onSaveCallback();
      onHideCallback();
    } catch (error) {
      setIsSavingNetwork(false);
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  return (
    <BbotModal
      title={"Select Terminal Printing Devices"}
      visible={showAddHandheldConfigModal}
      onCancel={() => onHideCallback()}
      onOk={editConfigsOnNetworkForm.submit}
      okText={"Submit"}
      okButtonProps={{
        loading: isSavingNetwork,
      }}
    >
      <Form
        form={editConfigsOnNetworkForm}
        name="handheld-configs-on-network-form"
        onFinish={editHandheldConfigs}
        layout={"vertical"}
        validateMessages={{
          // eslint-disable-next-line no-template-curly-in-string
          required: "${label} is required",
        }}
        initialValues={{
          handheldsToAdd: selectableConfigs
            .filter((config) => visibleNetworkConfigs.includes(config))
            .map((config) => config.id),
        }}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <div>
          <InfoRow gutter={12}>
            <Text>
              Select the terminals that are always connected to the same physical network (the same network router) as
              the printers. Bbot's web servers will use them to communicate with the printers. Do not select terminals
              that might be on a different network, such as a cellular hotspot. Note: staff can always hit the button to
              print tickets on any Terminal, regardless of whether it's checked here. This setting is just about your
              network setup, not staff workflows. Ask your IT expert if you're unsure.
            </Text>
          </InfoRow>
          <Row gutter={12}>
            {selectableConfigs.length ? (
              <FormattedCheckboxGroupInput
                id={"network-handheld-config-selector"}
                label={"Devices"}
                columns={1}
                options={selectableConfigs?.map((config) => ({
                  label: config.config_name,
                  value: config.id,
                }))}
                name="handheldsToAdd"
              />
            ) : (
              <StyledWarningText>
                All Devices are currently already assigned to a Network, and each Device can only be added to one
                Network at a time. If you want to add a specific Device to this Network, please remove it from the other
                Network it is on.
              </StyledWarningText>
            )}
          </Row>
        </div>
      </Form>
    </BbotModal>
  );
};

const InfoRow = styled(Row)`
  margin-bottom: 20px;
`;

const StyledWarningText = styled(Text)`
  font-weight: bold;
`;

EditHandheldsOnNetworkModal.propTypes = {
  onHideCallback: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object.isRequired,
};
export default EditHandheldsOnNetworkModal;
