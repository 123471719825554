import axios from "axios";
import { DYNAMIC_DELIVERY_INTEGRATIONS } from "Constants";
import {
  trackClickAddServiceCharge,
  trackClickBulkDeleteServiceCharge,
  trackClickEditServiceCharge,
} from "instrumentation/tracking/page-tracking-events";
import AddServiceChargeModal from "pages/service-charges/AddServiceChargeModal";
import DeleteServiceChargeModal from "pages/service-charges/DeleteServiceChargeModal";
import EditServiceCharges from "pages/service-charges/EditServiceCharges";
import { useEffect, useState } from "react";
import { BbotTable } from "top-component-library";
import OPContainer from "top-component-library/OPContainer";
import { fulfillmentKeysToPrettyNames, generalErrorAlert } from "util/Utils";

/*
Service Charges top-level page
 */
const ServiceCharges = (props) => {
  // Boolean to represent whether service charges are mid-load
  const [loadingServiceCharges, setLoadingServiceCharges] = useState(false);
  const [savingNewCharge, setSavingNewCharge] = useState(false);
  // States to hold customer data
  const [serviceCharges, setServiceCharges] = useState([]);
  const [locations, setLocations] = useState([]);
  const [fulfillmentMethods, setFulfillmentMethods] = useState({});
  const [ownerProperties, setOwnerProperties] = useState({});
  // State to hold currently selected charges
  const [selectedServiceChargeKeys, setSelectedServiceChargeKeys] = useState([]);
  // State for whether user is in edit mode
  const [editMode, setEditMode] = useState(false);
  // State for whether modals are visible
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  // Currently selected customer
  const selectedCustomer = props.selectedCustomer;

  // Check if this customer has integrations that support dynamic delivery
  const supportsDynamicDelivery = selectedCustomer
    ? DYNAMIC_DELIVERY_INTEGRATIONS.some((integration) =>
        Object.keys(selectedCustomer.integrations).includes(integration)
      )
    : false;

  // Re-initialize page when customer is switched
  useEffect(() => {
    hideEdit();
    getServiceCharges();
    getLocations();
    getOwnerProperties();
    setSelectedServiceChargeKeys([]);
    setIsAddVisible(false);
    setIsDeleteVisible(false);
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Function to handle showing delete modal
  const showDeleteModal = (selectedRowKeys) => {
    trackClickBulkDeleteServiceCharge();
    setSelectedServiceChargeKeys(selectedRowKeys);
    setIsDeleteVisible(true);
  };

  // Explicitly state some page values
  const id = "service-charges";
  const name = "Service Charges";
  const url = "/service-charges";

  // Format a % + $ fee for display in the table
  const formatFee = (feeFraction, feeCents, matchesDelivery) => {
    if (matchesDelivery) return "Delivery cost";
    else if (feeCents && feeFraction) return feeFraction * 100 + "% + $" + (feeCents / 100).toFixed(2);
    else if (feeCents) return "$" + (feeCents / 100).toFixed(2);
    else if (feeFraction) return feeFraction * 100 + "%";
    else return "No fee";
  };

  // Format service charges to be passed to table
  const formattedServiceCharges = serviceCharges.map((fee) => {
    return {
      key: fee.id,
      name: fee.name_for_owner,
      fee: formatFee(fee.fraction_of_sale, fee.fixed_cents, fee.automatically_matches_delivery_cost),
      fulfillment_methods: fulfillmentKeysToPrettyNames(fee.fulfillment_methods, fulfillmentMethods)
        .join(", ")
        .replace("_", " "),
      locations: fee.location_ids.length,
      tax: fee.uses_default_tax
        ? "Default (" + (fee.tax_fraction * 100).toFixed(3) + "%)"
        : (fee.tax_fraction * 100).toFixed(3) + "%",
    };
  });

  // Description that appears at the top of the page
  const description = (
    <span>
      Set the fees that will be added to the guest's check. Sales tax will be collected for these fees.{" "}
      <a href="https://central.bbot.menu/article/669-taxable-fees">More Info.</a>
    </span>
  );
  const columns = ["Name", "Fee", "Fulfillment Methods", "Locations", "Tax"];

  // Function called when user clicks edit
  const editFunction = (selectedRows) => {
    trackClickEditServiceCharge();
    setSelectedServiceChargeKeys(selectedRows);
    setEditMode(true);
  };

  // Retrieve service charges from the server
  const getServiceCharges = async () => {
    if (!selectedCustomer) return;
    setLoadingServiceCharges(true);
    try {
      const res = await axios.get("api/getAllOrderFeeConfigs", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      // If user is not an admin filter to charges visible to owners
      const visibleCharges =
        props.userInfo.role === "admin"
          ? res.data.order_fee_configs
          : res.data.order_fee_configs?.filter((charge) => {
              return charge.visible_to_owner === true;
            });
      setServiceCharges(visibleCharges);
    } catch (error) {
      generalErrorAlert(
        error,
        "There was an error getting the service charge settings. Redirecting to dashboard.",
        true
      );
      console.error(error);
    }
    setLoadingServiceCharges(false);
  };

  // Get locations and fulfillment methods from server
  const getLocations = async () => {
    if (!selectedCustomer) return;
    try {
      const res = await axios.get("api/ownerlocations", {
        params: {
          customer_id: selectedCustomer.customer_id,
          include_vendees: true,
        },
      });
      setLocations(res.data.locations);
      setFulfillmentMethods(res.data.fulfillment_methods);
    } catch (error) {
      generalErrorAlert(error, "There was an error getting the location codes.", true);
      console.error(error);
    }
  };

  // Get customer properties from the server
  const getOwnerProperties = async () => {
    if (!selectedCustomer) return;
    try {
      const res = await axios.get("api/getOwnerProperties", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      setOwnerProperties(res.data.owner_properties);
    } catch (error) {
      generalErrorAlert(error, "There was an error getting the customer settings.", true);
      console.error(error);
    }
  };

  // Hide the edit mode and return to list view
  const hideEdit = () => {
    setEditMode(false);
  };

  // Render
  return (
    <OPContainer
      title={name}
      url={url}
      description={description}
      selectedCustomer={props.selectedCustomer}
      refreshOnCurrentPageClick={true}
      userInfo={props.userInfo}
    >
      {/* Modal to add a new service charge*/}
      <AddServiceChargeModal
        id={id + "-addModal"}
        locations={locations}
        fulfillmentMethods={fulfillmentMethods}
        ownerProperties={ownerProperties}
        isVisible={isAddVisible}
        hideModalFunction={() => setIsAddVisible(false)}
        selectedCustomer={selectedCustomer}
        onSubmitCallback={getServiceCharges}
        userInfo={props.userInfo}
        onSaving={(isSaving) => setSavingNewCharge(isSaving)}
        supportsDynamicDelivery={supportsDynamicDelivery}
      />
      {/* Modal to confirm deleting service charges */}
      <DeleteServiceChargeModal
        id={id + "-deleteModal"}
        isVisible={isDeleteVisible}
        hideModalFunction={() => setIsDeleteVisible(false)}
        selectedCustomer={selectedCustomer}
        onSubmitCallback={getServiceCharges}
        serviceCharges={serviceCharges}
        selectedServiceChargeKeys={selectedServiceChargeKeys}
      />
      {/* If edit mode is enabled show the edit screen, otherwise show the service charges table */}
      {editMode ? (
        <EditServiceCharges
          hideEditFunction={hideEdit}
          serviceCharges={serviceCharges}
          selectedCustomer={props.selectedCustomer}
          selectedServiceChargeKeys={selectedServiceChargeKeys}
          userInfo={props.userInfo}
          locations={locations}
          fulfillmentMethods={fulfillmentMethods}
          onSubmitCallback={getServiceCharges}
          ownerProperties={ownerProperties}
          supportsDynamicDelivery={supportsDynamicDelivery}
        />
      ) : (
        <BbotTable
          id="service-charges-table"
          columns={columns}
          data={formattedServiceCharges}
          title={name}
          allowBulkDeletion={true}
          allowBulkEdit={true}
          onBulkEdit={editFunction}
          onBulkDelete={showDeleteModal}
          onAdd={() => {
            trackClickAddServiceCharge();
            setIsAddVisible(true);
          }}
          loading={loadingServiceCharges || savingNewCharge}
        />
      )}
    </OPContainer>
  );
};

export default ServiceCharges;
