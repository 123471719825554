/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useToast } from "@doordash/component-toast";
import axios from "axios";
import {
  containsMenuHeadingId,
  containsMenuItemId,
  elementTypes,
  extractAllMenuItemsFromMenus,
  getMenusAndItems,
  getMenuHeadingsAndCustomerUpsells,
  findUpsells,
  updateUpsells,
  updateSelected,
  setSelectedElementAndCurrentlySelectedUpsells,
  removeDashesFromUUIDS,
} from "./utils";
import UpsellsTable from "./UpsellsTable";

const Upsells = (props) => {
  const [menus, setMenus] = useState([]);
  const [menuHeadings, setMenuHeadings] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [currentlySelectedUpsells, setCurrentlySelectedUpsells] = useState([]);
  const [orderedUpsells, setOrderedUpsells] = useState([]);
  const [loading, setLoading] = useState(true);
  const cartLevelUpsellsSelected = selectedElement === "";
  const noElementSelected = selectedElement === null;
  const menuItemIsSelected = menus.length !== 0 && containsMenuItemId(menus, selectedElement);
  const menuHeadingIsSelected = menuHeadings.length !== 0 && containsMenuHeadingId(menuHeadings, selectedElement);
  const textToDisplay = menuHeadingIsSelected ? "Menu Heading" : menuItemIsSelected ? "Item" : "Customer's Cart";
  const { menuItem, menuHeading, customer } = elementTypes;
  const { displayToast } = useToast();

  useEffect(() => getMenusAndItems(axios, setMenuItems, setMenus), []);

  useEffect(() => setTimeout(() => setLoading(false), 7000), [setLoading]);

  useEffect(() => {
    getMenuHeadingsAndCustomerUpsells(menus, menuHeadings, axios, setMenuHeadings);
    setCurrentlySelectedUpsells(extractAllMenuItemsFromMenus(menus));
  }, [menus]);

  useEffect(() => {
    let upsells = [];
    if ("" === selectedElement && !!props.selectedCustomer) {
      upsells = props.selectedCustomer.upsells;
    } else if (menuHeadingIsSelected && !!menuHeadings.find((heading) => heading.id === selectedElement)) {
      upsells = menuHeadings.find((heading) => heading.id === selectedElement).upsells;
    } else if (menuItemIsSelected && !!currentlySelectedUpsells.find((item) => item.id === selectedElement)) {
      upsells = findUpsells(selectedElement, menus);
    }
    if (upsells && upsells.length !== 0) {
      setOrderedUpsells(
        upsells.map((upsell) => ({
          id: upsell,
          name: currentlySelectedUpsells.find((item) => item.id === upsell)?.name ?? "",
          selected: true,
        }))
      );

      setCurrentlySelectedUpsells(updateSelected(upsells, currentlySelectedUpsells));
    }
  }, [selectedElement]);

  const packageUpsells = (elementType, params) =>
    updateUpsells(displayToast, axios, menuHeadings, menus, selectedElement, elementType, params);

  const saveUpsells = menuHeadingIsSelected
    ? () =>
        packageUpsells(menuHeading, {
          id: selectedElement,
          heading_type: menuHeadings.find((heading) => heading.id === selectedElement).heading_type,
          upsells: removeDashesFromUUIDS(orderedUpsells),
        })
    : menuItemIsSelected
    ? () =>
        packageUpsells(menuItem, {
          menuItemId: selectedElement,
          upsells: removeDashesFromUUIDS(orderedUpsells),
        })
    : () =>
        packageUpsells(customer, {
          customer_id: props.selectedCustomer.customer_id,
          upsells: removeDashesFromUUIDS(orderedUpsells),
        });

  const setUpsells = (id) =>
    setSelectedElementAndCurrentlySelectedUpsells(
      id,
      setSelectedElement,
      setCurrentlySelectedUpsells,
      menus,
      setOrderedUpsells
    );

  return (
    <UpsellsTable
      cartLevelUpsellsSelected={cartLevelUpsellsSelected}
      currentlySelectedUpsells={currentlySelectedUpsells}
      loading={loading}
      menuHeadings={menuHeadings}
      menuItems={menuItems}
      menus={menus}
      noElementSelected={noElementSelected}
      orderedUpsells={orderedUpsells}
      saveUpsells={saveUpsells}
      selectedElement={selectedElement}
      setCurrentlySelectedUpsells={setCurrentlySelectedUpsells}
      setOrderedUpsells={setOrderedUpsells}
      setUpsells={setUpsells}
      textToDisplay={textToDisplay}
    />
  );
};

export default Upsells;
