import styled from "styled-components";
import { DatePicker, Form } from "top-component-library";

const DateTimePickerInput = ({ id, showTime = false, label = "", name = "", required = true, ...props }) => {
  return (
    <Form.Item
      id={id}
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: "This is a required field",
        },
      ]}
    >
      <StyledDatePicker showTime={showTime} {...props} />
    </Form.Item>
  );
};

const StyledDatePicker = styled(DatePicker)`
  &&&.ant-picker {
    padding: 8px 14px;
    border-radius: 8px;
    width: 100%;
  }
`;

export default DateTimePickerInput;
