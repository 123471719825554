import ConnectStripeWidget from "components/customer-journey/widgets/ConnectStripeWidget";
import PaymentWidget from "components/customer-journey/widgets/PaymentWidget";
import { useEffect, useState } from "react";

import {
  BbotAlert,
  BbotButton,
  BbotContainer,
  BbotTabs,
  BbotTag,
  Breadcrumbs,
  Card,
  CheckboxInput,
  Col,
  Form,
  notification,
  Radio,
  Row,
  SelectInput,
  Tabs,
} from "top-component-library";

import { getDoveTailAlertTextWithRedirect } from "../../pages/owner-app-dashboard/dovetail";

import axios from "axios";
import { DateTime } from "luxon";
import styled from "styled-components";
import { timeISOToUserReadableDate } from "util/Utils";
import "./ConnectPayment.scss";

const ConnectPayment = (props) => {
  const [stripeAccountSettings, setStripeAccountSettings] = useState(null);
  const [bills, setBills] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [formValues, setFormValues] = useState({});

  const { selectedCustomer, userInfo, allowedCustomersById, isSelectedCustomerFlaggedForDovetail } = props;

  const [stripePayoutForm] = Form.useForm();

  const userIsAdmin = userInfo?.role === "admin";

  /**
   * Reset the stripe account info and bills/subscriptions on customer change
   */
  useEffect(() => {
    if (selectedCustomer) {
      getStripeAccountInfo();
      getBillsAndSubscriptions();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const initialValues = {
      one_payout_per_day: stripeAccountSettings?.one_payout_per_day,
      instant_payouts: stripeAccountSettings?.instant_payouts,
      payout_period: stripeAccountSettings?.payout_period || "day",
      payout_day: stripeAccountSettings?.payout_day || 0,
      combine_customers_payouts: stripeAccountSettings?.combine_customers_payouts,
      managing_customer_id: stripeAccountSettings?.managing_customer.customer_id,
    };
    stripePayoutForm.setFieldsValue(initialValues);
    setFormValues(initialValues);
  }, [stripePayoutForm, stripeAccountSettings]);

  const getStripeAccountInfo = async () => {
    try {
      const res = await axios.get("/owner/getStripeAccountInfo", {
        params: { customerId: selectedCustomer.customer_id },
      });
      setStripeAccountSettings(res.data.stripe_account_settings);
    } catch (err) {
      console.error("/owner/getStripeAccountInfo failed with error", err);
      notification.error({
        message: "Trouble retrieving Stripe account settings. Please refresh and try again.",
      });
    }
  };

  const getBillsAndSubscriptions = async () => {
    try {
      const res = await axios.get("/owner/ownerListBillsAndSubscriptions", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      setBills(res.data.bills);
      setSubscriptions(res.data.subscriptions);
    } catch (err) {
      console.error("/owner/ownerListBillsAndSubscriptions failed with error", err);
    }
  };

  const setStripeAccountInfo = async (values) => {
    const payload = {
      customerId: selectedCustomer.customer_id,
      propertiesToEdit: values,
    };
    try {
      await axios.post("/owner/setStripeAccountInfo", payload);
      await getStripeAccountInfo();
      notification.success({
        message: "Successfully updated Stripe Account Settings.",
      });
    } catch (err) {
      console.error("/owner/setStripeAccountInfo failed with error", err);
      notification.error({
        message: "Trouble setting Stripe account settings. Please refresh and try again.",
      });
    }
  };

  const payoutSettings = () => {
    const customerIsNotManaging =
      stripeAccountSettings?.managing_customer.customer_id !== selectedCustomer?.customer_id;
    const userIsBbotAdmin = userInfo?.teams?.includes("bbot");

    return (
      <>
        <div className={"margin-bottom-4"}>
          <ConnectStripeWidget
            customerId={selectedCustomer?.customer_id}
            userInfo={userInfo}
            // setNavigationTab={setNavigationTab}
          />
        </div>

        <Card className={"padding-4"}>
          <div className={"margin-bottom-2"}>
            <p>
              Edit your Stripe Account payout settings. This allows you to control how often and in what form you want
              your payouts from Stripe to be.
            </p>
          </div>

          <Form
            form={stripePayoutForm}
            name={"stripe-payout-form"}
            layout={"vertical"}
            onValuesChange={(_, values) => setFormValues(values)}
            onFinish={setStripeAccountInfo}
          >
            <Form.Item required name={"payout_period"} label={"How often will ACH payouts be sent to my bank?"}>
              <Radio.Group disabled={customerIsNotManaging}>
                <Radio value={"day"}>Every Business Day</Radio>
                <Radio value={"week"}>Once Per Week</Radio>
              </Radio.Group>
            </Form.Item>

            {formValues.payout_period === "week" && (
              <SelectInput
                label={"Payout Day"}
                id={"payout-day"}
                name={"payout_day"}
                options={[
                  { label: "Sunday", value: 0 },
                  { label: "Monday", value: 1 },
                  { label: "Tuesday", value: 2 },
                  { label: "Wednesday", value: 3 },
                  { label: "Thursday", value: 4 },
                  { label: "Friday", value: 5 },
                  { label: "Saturday", value: 6 },
                ]}
                required={true}
                disabled={customerIsNotManaging}
              />
            )}

            <div className={"margin-bottom-2"}>
              <Form.Item
                label={"How will a day's card charges be grouped into ACH payouts to my bank?"}
                name={"one_payout_per_day"}
                id={"one-payout-per-day"}
                required
              >
                <Radio.Group disabled={customerIsNotManaging}>
                  <Radio value={true}>Collect all card charges for 1 business day into 1 payout</Radio>

                  <Radio value={false}>
                    Send card charges as soon as available. Charges early in the day (before roughly 5pm) will be sent 1
                    day sooner, together with charges from late in the prior day.
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            {stripeAccountSettings?.customers?.length > 1 && (
              <Form.Item
                label={"If multiple restaurants use this Stripe account, how should ACH payouts be sent to my bank?"}
                name={"combine_customers_payouts"}
                id={"combine-customers-payouts"}
                required
              >
                <Radio.Group disabled={customerIsNotManaging}>
                  <Radio value={true}>One payout covering all restaurants</Radio>
                  <Radio value={false}>A separate payout for each restaurant</Radio>
                </Radio.Group>
              </Form.Item>
            )}

            {stripeAccountSettings?.customers?.length > 1 && userIsBbotAdmin && (
              <SelectInput
                label={"Set Managing Customer for Stripe Account"}
                name={"managing_customer_id"}
                id={"managing-customer"}
                options={stripeAccountSettings.customers.map((customer) => ({
                  label: customer.customer_name,
                  value: customer.customer_id,
                }))}
                disabled={customerIsNotManaging}
              />
            )}

            {userIsBbotAdmin && (
              <CheckboxInput
                label={
                  "Pay out each day's sales immediately, from Bbot's reserves, without waiting for card charges to clear. Requires CFO approval."
                }
                name={"instant_payouts"}
                id={"instant-payouts"}
                disabled={customerIsNotManaging}
              />
            )}

            {customerIsNotManaging && (
              <div className={"form-descriptor"}>
                Your Stripe payout settings can only be edited from the{" "}
                {stripeAccountSettings?.managing_customer?.customer_name} account. (That's because your restaurants
                share the same Stripe account.)
              </div>
            )}

            <BbotButton type={"primary"} htmlType={"submit"} disabled={customerIsNotManaging}>
              Submit
            </BbotButton>
          </Form>
        </Card>
      </>
    );
  };

  /**
   * Checks whether or not the given subscription is still active. A subscription is still active if the end date is in the future.
   * @param subscription
   * @returns {boolean}
   */
  const subscriptionActive = (subscription) => {
    return DateTime.now() < DateTime.fromISO(subscription.end_date);
  };

  const activeBillsAndSubscriptions = () => {
    return (
      <Row gutter={12}>
        <Col sm={12}>
          <BillSubscriptionListCard title={"Bills"}>
            {bills
              .sort((billA, billB) =>
                DateTime.fromISO(billA.time_to_execute) < DateTime.fromISO(billB.time_to_execute) ? 1 : -1
              )
              .map((bill) => (
                <div className={"d-flex justify-content-space-between padding-bottom-1 margin-bottom-1 bill"}>
                  <span>
                    <span>
                      <div className={"margin-right-2"}>
                        {timeISOToUserReadableDate(bill.time_succeeded || bill.time_to_execute)}
                      </div>
                      <div className={"margin-right-1 text-small-detail"}>{bill.name_for_customer}</div>
                    </span>
                  </span>

                  <span>
                    <span className={"margin-right-2"}>
                      {bill.time_succeeded ? <BbotTag>paid</BbotTag> : <BbotTag>pending</BbotTag>}
                    </span>
                    ${bill.posttax_cents / 100}
                  </span>
                </div>
              ))}
            {!bills.length && (
              <div className={"d-flex justify-content-center align-items-center text-small-detail"}>
                <div className={"margin-y-4"}>This account doesn't have any bills yet.</div>
              </div>
            )}
          </BillSubscriptionListCard>
        </Col>
        <Col sm={12}>
          <BillSubscriptionListCard title={"Subscriptions"}>
            {subscriptions.map((subscription) => (
              <div className={"d-flex justify-content-space-between padding-bottom-1 margin-bottom-1 subscription"}>
                <span>
                  <div className={"margin-right-2"}>{subscription.name_for_customer}</div>
                  <div className={"text-small-detail"}>{subscription.billing_period + "ly"}</div>
                </span>
                <span>
                  {subscriptionActive(subscription) && <BbotTag className={"margin-right-2"}>active</BbotTag>}$
                  {subscription.posttax_cents / 100}
                </span>
              </div>
            ))}
            {!subscriptions.length && (
              <div className={"d-flex justify-content-center align-items-center text-small-detail"}>
                <div className={"margin-y-4"}>This account doesn't have any subscriptions yet.</div>
              </div>
            )}
          </BillSubscriptionListCard>
        </Col>
      </Row>
    );
  };

  return (
    <BbotContainer>
      <Breadcrumbs name={"Payouts And Billing"} link={"/payouts-and-billing"} />

      <div className="margin-bottom-2">
        <h2>
          Manage Payouts and Billing For <span className="color-primary__dark">{selectedCustomer?.customer_name}</span>
        </h2>
      </div>
      <div className="margin-bottom-2">
        <p>
          Here, you can save your payment details by uploading a credit card or connecting a bank account. <br />
          Please note that in order to add a payment method, you must first enter a valid bill-payer email address. This
          is where we will send bills and invoices.
        </p>
      </div>

      <BbotTabs className={"margin-y-4"}>
        <Tabs.TabPane tab={"Billing Information"} key={"billing-information"}>
          <PaymentWidget
            customerId={selectedCustomer?.customer_id}
            customersById={allowedCustomersById}
            userIsAdmin={userIsAdmin}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Active Bills"} key={"active-bills"}>
          {activeBillsAndSubscriptions()}
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Stripe Payout Settings"} key={"payout-settings"}>
          {/* If user is flagged for dovetail, then do not allow payout settings to be configurable */}
          {!isSelectedCustomerFlaggedForDovetail ? (
            payoutSettings()
          ) : (
            <BbotAlert
              className="margin-bottom-1"
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getDoveTailAlertTextWithRedirect(
                      "Payouts and Billing",
                      isSelectedCustomerFlaggedForDovetail,
                      selectedCustomer?.dd_store_id
                    ),
                  }}
                />
              }
              type="info"
              showIcon
            />
          )}
        </Tabs.TabPane>
      </BbotTabs>
    </BbotContainer>
  );
};

export default ConnectPayment;

const BillSubscriptionListCard = styled(Card)`
  height: 100%;
`;
