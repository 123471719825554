import React, { useState, useEffect } from "react";

import {
  getIntegrationSettings,
  connectIntegration,
  disconnectIntegration,
} from "util/pos-integrations/IntegrationConnectModalUtils";

import * as Yup from "yup";
import { Modal, Form, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Formik } from "formik";
import SubmitCancelButtonsWithLoader from "components/global/form/SubmitCancelButtonsWithLoader";
import { SearchSelectInput, TextInput } from "../../../global/form";
import PromptYesNoPOSIntegrationModal from "components/global/modals/pos-integrations/PromptYesNoPOSIntegrationModal";

const LoyaltyIntegrationModal = (props) => {
  const [loyaltySettings, setLoyaltySettings] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const { selectedCustomer, disconnectMode, show, onClose, onSave, integration, integrationPrettyName } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getLoyaltySettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {}, [loyaltySettings]);

  const getLoyaltySettings = async () => {
    const getSettingsUrl = "/api/loyalty/get-loyalty-integration-connection-details";
    setLoadingData(true);
    await getIntegrationSettings(getSettingsUrl, integrationPrettyName, selectedCustomer, saveLoyaltySettings);
    setLoadingData(false);
  };

  const saveLoyaltySettings = async (newLoyaltySettings) => {
    setLoyaltySettings(newLoyaltySettings);
  };

  const connectLoyaltyIntegration = async (values) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      integration: integration,
      program_name: values.programName,
      sign_up_modal_title: values.signupModalTitle,
      sign_up_modal_top_text: values.signupModalTopText,
      sign_up_modal_bottom_text: values.signupModalBottomText,
      sign_up_url: values.signUpUrl,
      sign_in_url: values.signInUrl,
      selected_pos_promo_by_vendor: values.selectedPosPromoByVendor,
      program_benefits_list: values.programBenefitsList,
      store_id: values.storeID,
      merchant_id: values.merchantID,
      card_template_code: values.cardTemplateCode,
      reward_name: values.rewardName,
      icon_url: values.iconUrl,
      banner_color: values.bannerColor,
    };
    await connectIntegration(
      "/api/loyalty/connect-loyalty-integration",
      payload,
      integrationPrettyName,
      setLoadingData,
      onConnectSuccess
    );
  };

  const onConnectSuccess = async () => {
    await getLoyaltySettings();
    onSave();
    onClose();
  };

  const disconnectLoyaltyIntegration = async () => {
    await disconnectIntegration(
      "/api/loyalty/disconnect-loyalty-integration",
      integrationPrettyName,
      selectedCustomer,
      false,
      setLoadingData,
      onDisconnectSuccess,
      integration
    );
  };

  const onDisconnectSuccess = async () => {
    await getLoyaltySettings();
    onClose();
    onSave();
  };

  const disconnectModal = () => {
    return (
      <PromptYesNoPOSIntegrationModal
        onHide={onClose}
        show={show}
        onSubmit={disconnectLoyaltyIntegration}
        onClose={onClose}
        integrationSourceName={integrationPrettyName}
        warningText={`Are you sure you would like to disconnect ${integrationPrettyName}?`}
        submitText={"Disconnect"}
        title={`Disconnect ${integrationPrettyName}`}
        cancelText={"Cancel"}
      />
    );
  };

  const submitEditSettings = async (values) => {
    await connectLoyaltyIntegration(values);
    await getLoyaltySettings();
  };

  const updateProgramBenefits = (setFieldValue, values, benefitToRemove = null) => {
    console.log(benefitToRemove);
    if (benefitToRemove) {
      setFieldValue(
        "programBenefitsList",
        values.programBenefitsList.filter((benefit) => benefit !== benefitToRemove)
      );
    } else {
      values.programBenefitsList.push(values.programBenefit);
      setFieldValue("programBenefitsList", values.programBenefitsList);
    }

    setFieldValue("programBenefit", "");
  };

  const editSettingsModal = () => {
    let initialValues = {
      programName: loyaltySettings.program_name ?? "",
      signupModalTitle: loyaltySettings.sign_up_modal_title ?? "",
      signupModalTopText: loyaltySettings.sign_up_modal_top_text ?? "",
      signupModalBottomText: loyaltySettings.sign_up_modal_bottom_text ?? "",
      signUpUrl: loyaltySettings.sign_up_url ?? "",
      signInUrl: loyaltySettings.sign_in_url ?? "",
      selectedPosPromoByVendor: loyaltySettings.connected_promos_by_vendor_pk ?? {},
      connectablePromosByVendor: loyaltySettings.connectable_promos_by_vendor ?? {},
      posByVendorPk: loyaltySettings.pos_by_vendor_pk ?? {},
      connectedPromosByVendorPk: loyaltySettings.connected_promos_by_vendor_pk ?? {},
      vendorNamesByPk: loyaltySettings.vendor_names_by_pk ?? {},
      programBenefitsList: loyaltySettings.program_benefits_list ?? [],
      storeID: loyaltySettings.external_store_id ?? "",
      merchantID: loyaltySettings.external_merchant_id ?? "",
      cardTemplateCode: loyaltySettings.card_template_code ?? "",
      rewardName: loyaltySettings.reward_name ?? "",
      bannerColor: loyaltySettings.banner_color ?? "",
      iconUrl: loyaltySettings.icon_url ?? "",
    };

    let validationSchema = Yup.object({});

    return (
      <Modal show={show} onHide={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            submitEditSettings(values);
          }}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Connect {integrationPrettyName}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                <Form.Row>
                  <TextInput label={"Program Name"} name={"programName"} id={"programName"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Reward Name"} name={"rewardName"} id={"rewardName"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Merchant ID" name="merchantID" id="merchantID" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Store ID" name="storeID" id="storeID" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Card Template Code" name="cardTemplateCode" id="cardTemplateCode" />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Signup Modal Title"} name={"signupModalTitle"} id={"signupModalTitle"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Signup Modal Top Text"} name={"signupModalTopText"} id={"signupModalTopText"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Banner Color"} name={"bannerColor"} id={"bannerColor"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Icon URL"} name={"iconUrl"} id={"iconUrl"} />
                </Form.Row>
                <Form.Row>
                  <TextInput
                    label={"Signup Modal Bottom Text"}
                    name={"signupModalBottomText"}
                    id={"signupModalBottomText"}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Sign Up Url"} name={"signUpUrl"} id={"signUpUrl"} />
                </Form.Row>
                <Form.Row>
                  <TextInput label={"Sign In URL"} name={"signInUrl"} id={"signInUrl"} />
                </Form.Row>
                {values?.connectablePromosByVendor != null ? (
                  Object.keys(values.connectablePromosByVendor).map((vendorId) => (
                    <Form.Row>
                      <SearchSelectInput
                        key={vendorId}
                        isSearchable
                        id={`select-pos-promo-${vendorId}`}
                        name={`selectedPosPromoByVendor.${vendorId}`}
                        label={[
                          `${values.vendorNamesByPk[vendorId]} ${values.posByVendorPk[vendorId]} Promotion`,
                          "  ",
                          <OverlayTrigger
                            key={`overlay-${vendorId}-loyalty-discount-selection`}
                            placement={"right"}
                            overlay={
                              <Tooltip id={`tooltip-${vendorId}-loyalty-discount-selection`}>
                                At {values.vendorNamesByPk[vendorId]}, which {values.posByVendorPk[vendorId]} Promotion
                                should be used to represent loyalty discounts? (Must be an "open" discount in the POS,
                                not a fixed amount.)
                              </Tooltip>
                            }
                          >
                            <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                          </OverlayTrigger>,
                        ]}
                        defaultValue={values.connectablePromosByVendor[vendorId]
                          .map((p) => {
                            return { label: p.name, value: p.external_id };
                          })
                          .find((p) => p.value === values.connectedPromosByVendorPk[vendorId])}
                        options={values.connectablePromosByVendor[vendorId].map((p) => {
                          return { label: p.name, value: p.external_id };
                        })}
                        onChange={(value) => {
                          setFieldValue(`selectedPosPromosByVendor.${vendorId}`, value);
                        }}
                      />
                    </Form.Row>
                  ))
                ) : (
                  <></>
                )}
                {values.programBenefitsList && values.programBenefitsList.length > 0 && (
                  <>
                    {values.programBenefitsList.map((benefit, i) => (
                      <Form.Row key={i}>
                        <TextInput
                          id={"programBenefit" + i}
                          name={"programBenefit" + i}
                          label="Benefit"
                          disabled={true}
                          required={false}
                          placeholder={benefit}
                        />

                        <Button
                          htmlType={"submit"}
                          style={{ marginTop: "35px", marginBottom: "30px" }}
                          size={"sm"}
                          id={"programBenefit" + i}
                          name={"programBenefit" + i}
                          onClick={() => updateProgramBenefits(setFieldValue, values, benefit)}
                        >
                          Remove
                        </Button>
                      </Form.Row>
                    ))}
                  </>
                )}
                <Form.Row>
                  <TextInput
                    id={"program-benefit"}
                    data-test-id={"program-benefit"}
                    name={"programBenefit"}
                    label={"Add Benefit"}
                  />
                  <Button
                    htmlType={"submit"}
                    data-test-id={"add-program-benefit"}
                    onClick={() => updateProgramBenefits(setFieldValue, values)}
                    style={{ marginTop: "35px", marginBottom: "30px" }}
                    size={"sm"}
                  >
                    Enter
                  </Button>
                </Form.Row>
              </Modal.Body>
              <Modal.Footer>
                <SubmitCancelButtonsWithLoader loadingData={loadingData} errors={errors} onCancel={onClose} />
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  return editSettingsModal();
};

export default LoyaltyIntegrationModal;
