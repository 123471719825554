import axios from "axios";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { Component } from "react";
import { Form as BtpForm, Button, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Loader } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { generalErrorAlert } from "../../../../util/Utils";
import { CheckboxInput, TextInput } from "../../../global/form";

class UpserveIntegrationModal extends Component {
  state = {
    upserveUsername: null,
    upservePassword: null,
    injectOrders: false,
    archiveExistingItems: false,
    locationOnOrder: false,
    showOrderNumber: false,
    showFulfillmentMethod: false,
    showCheckoutInfo: false,
    autoAcceptOrder: false,
    deleteMenuItems: false,
    loadingData: false,
    hasLoaded: false,
    ticketNameTemplateString: null,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.show && !prevProps.show && !this.state.hasLoaded) ||
      (this.state.hasLoaded && prevProps.selectedCustomer !== this.props.selectedCustomer)
    ) {
      this.getUpserveOptions();
    }
  }

  onHide = () => {
    const { onHide } = this.props;
    this.setState(
      {
        deleteMenuItems: false,
      },
      onHide
    );
  };

  onSubmit = async (values) => {
    const { selectedCustomer, onSave } = this.props;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        upserve_api_username: values.upserveUsername,
        upserve_api_password: values.upservePassword,
        inject_orders_into_upserve: values.injectOrders,
        archive_existing_items: values.archiveExistingItems,
        display_location_on_order: values.locationOnOrder,
        display_order_number: values.showOrderNumber,
        display_fulfillment_method: values.showFulfillmentMethod,
        display_checkout_info: values.showCheckoutInfo,
        auto_accept_order: values.autoAcceptOrder,
        ticket_name_template_string: values.ticketNameTemplateString,
      };
      this.setState({ loadingData: true });
      await axios.post("/admin/connectUpserve", payload);
      await this.getUpserveOptions();
      toast.success("Successfully connected Upserve");
      this.onHide();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error connecting Upserve.");
    } finally {
      this.setState({ loadingData: false });
    }
  };

  getUpserveOptions = async () => {
    try {
      const res = await axios.get("/admin/getAllUpserveConfigOptions");
      this.setState({
        upserveUsername: res.data.upserve_api_username,
        upservePassword: res.data.upserve_api_password,
        injectOrders: res.data.inject_orders,
        locationOnOrder: res.data.display_location_on_order,
        showOrderNumber: res.data.display_order_number,
        showFulfillmentMethod: res.data.display_fulfillment_method,
        showCheckoutInfo: res.data.display_checkout_info,
        autoAcceptOrder: res.data.auto_accept_order,
        ticketNameTemplateString: res.data.ticket_name_template_string,
      });
      this.setState({ hasLoaded: true });
    } catch (error) {
      generalErrorAlert(error, "Error getting Upserve options.");
    }
  };

  disconnect = async () => {
    const { selectedCustomer, onSave } = this.props;
    const { deleteMenuItems } = this.state;

    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        delete_menu_items: deleteMenuItems,
      };
      await axios.post("/admin/disconnectUpserve", payload);
      toast.success("Successfully disconnected from Upserve");
      await this.getUpserveOptions();
      this.onHide();
      onSave();
    } catch (error) {
      generalErrorAlert(error, "Error disconnecting Upserve.");
    }
  };

  disconnectOptions = () => (
    <div>
      <div className={"margin-bottom-1"}>
        <p>If you would also like to delete menu items that came from Upserve, please check the checkbox below.</p>
      </div>
      <BtpForm.Group className={"margin-bottom-1 "}>
        <BtpForm.Check
          className={"check-box"}
          label={"Delete existing Upserve menu items"}
          type={"checkbox"}
          onChange={(event) => {
            this.setState({ deleteMenuItems: event.target.checked });
          }}
        />
      </BtpForm.Group>
    </div>
  );

  disconnectModal = () => {
    const { show } = this.props;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Disconnect From Upserve</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.disconnectOptions()}</Modal.Body>
        <Modal.Footer className={"space-between"}>
          <Button size={"sm"} variant={"light"} onClick={this.onHide} className={"margin-right-1"}>
            Cancel
          </Button>
          <Button size={"sm"} variant={"danger"} onClick={this.disconnect}>
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render() {
    const { show, disconnectMode } = this.props;
    const {
      upserveUsername,
      upservePassword,
      locationOnOrder,
      showOrderNumber,
      ticketNameTemplateString,
      showFulfillmentMethod,
      showCheckoutInfo,
      autoAcceptOrder,
      injectOrders,
      archiveExistingItems,
    } = this.state;
    return disconnectMode ? (
      this.disconnectModal()
    ) : (
      <Modal show={show} onHide={this.onHide}>
        <Formik
          enableReinitialize
          initialValues={{
            upserveUsername: upserveUsername || "",
            upservePassword: upservePassword || "",
            locationOnOrder: locationOnOrder || false,
            showOrderNumber: showOrderNumber || false,
            showFulfillmentMethod: showFulfillmentMethod || false,
            showCheckoutInfo: showCheckoutInfo || false,
            autoAcceptOrder: autoAcceptOrder || false,
            injectOrders: injectOrders || false,
            archiveExistingItems: archiveExistingItems || false,
            ticketNameTemplateString: ticketNameTemplateString ?? "",
          }}
          onSubmit={this.onSubmit}
        >
          {({ values, errors, touched, isValidating, setFieldValue }) => (
            <Form className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>Integrate With Upserve</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {!upserveUsername && (
                  <div className={"margin-bottom-2"}>
                    <p>Enter the Username and Password found in the Upserve Integration Partners page.</p>
                    <p>
                      To find more about connecting Upserve, check out{" "}
                      <a
                        href={"https://help.upserve.com/s/article/Set-Up-An-API-Connection-with-an-Integration-Partner"}
                      >
                        this article
                      </a>
                    </p>
                  </div>
                )}
                <Row>
                  {!upserveUsername && (
                    <TextInput label={"Upserve API Username"} name={"upserveUsername"} id={"upserve-username"} />
                  )}
                </Row>
                <Row>
                  {!upservePassword && (
                    <TextInput label={"Upserve API Password"} name={"upservePassword"} id={"upserve-password"} />
                  )}
                </Row>
                {!upservePassword && (
                  <div className={"margin-top-2 margin-bottom-2"}>
                    If you would like to delete all existing menu items prior to connecting Upserve. Check below.
                  </div>
                )}
                {!upservePassword && (
                  <Row>
                    <CheckboxInput
                      sm={12}
                      label={"Delete existing menu items"}
                      name={"archiveExistingItems"}
                      id={"archive_existing_items"}
                    />
                  </Row>
                )}
                <div className={"margin-top-2 margin-bottom-2"}>Settings for forwarding TOP orders to Upserve:</div>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Inject orders into Upserve"}
                    name={"injectOrders"}
                    id={"inject-orders"}
                  />
                </Row>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Display TOP location info on Upserve ticket"}
                    name={"locationOnOrder"}
                    id={"display_location_on_order"}
                    disabled={!values.injectOrders}
                  />
                </Row>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Display TOP order number on Upserve ticket"}
                    name={"showOrderNumber"}
                    id={"show_order_number"}
                    disabled={!values.injectOrders}
                  />
                </Row>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Display TOP fulfillment method on Upserve ticket"}
                    name={"showFulfillmentMethod"}
                    id={"show_fulfillment_method"}
                    disabled={!values.injectOrders}
                  />
                </Row>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Display TOP checkout info on Upserve ticket"}
                    name={"showCheckoutInfo"}
                    id={"show_checkout_info"}
                    disabled={!values.injectOrders}
                  />
                </Row>
                <Row>
                  <CheckboxInput
                    sm={12}
                    label={"Automatically mark new orders as Accepted on TOP KDS"}
                    name={"autoAcceptOrder"}
                    id={"auto_accept_order"}
                    disabled={!values.injectOrders}
                  />
                </Row>
                <Row>
                  <TextInput
                    name={"ticketNameTemplateString"}
                    id={"ticket-name-template-string"}
                    label={[
                      "Ticket Name Field Format",
                      "  ",
                      <OverlayTrigger
                        key={"placement"}
                        placement={"right"}
                        overlay={
                          <Tooltip id={"tooltip-select-days-and-times"}>
                            <strong>
                              If you include a keyword below, it will be automatically replaced with the relevant info.
                            </strong>
                            <br />
                            $ORDER_NUMBER - Order number
                            <br />
                            $LOCATION - Location's name
                            <br />
                            $PATRON_NAME - Patron's name
                            <br />
                            $ROOM - Room number
                            <br />
                          </Tooltip>
                        }
                      >
                        <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                      </OverlayTrigger>,
                    ]}
                  />
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size={"sm"}
                  variant={"light"}
                  onClick={this.onHide}
                  className={"margin-right-1"}
                  disabled={this.state.loadingData}
                >
                  Cancel
                </Button>
                {this.state.loadingData ? (
                  <Button size={"sm"} type={"submit"} disabled={true}>
                    <Loader props />
                  </Button>
                ) : (
                  <Button size={"sm"} type={"submit"}>
                    {this.state.upserveUsername ? "Save" : "Connect"}
                  </Button>
                )}
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

UpserveIntegrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
  disconnectMode: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default UpserveIntegrationModal;
