import { Form } from "antd";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import AddressInput from "top-component-library/form-inputs/AddressInput";
import TextInput from "top-component-library/form-inputs/TextInput";
import { extractBbotAddressFields } from "util/GooglePlacesUtils";
/*
Component with 3 inputs treated as one:
- A text field for recipient
- An address field with google autocomplete
- A text field for unit/suite
 */
const RestaurantAddressInput = ({
  id,
  name,
  onChange = () => void 0,
  initialValue,
  label,
  required,
  form,
  ...props
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      [name + "Recipient"]: initialValue?.recipient,
      [name + "Line2"]: initialValue?.line2,
    });
    setValue(initialValue);
  }, [initialValue, name]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatAddressObject = (recipient, google_place, line2) => {
    if (!google_place || Object.keys(google_place).length === 0) {
      const hasAddressInfo = recipient || line2;
      return hasAddressInfo ? { recipient, line2, google_place: null } : null;
    }
    const res = extractBbotAddressFields(google_place);
    res.recipient = recipient;
    res.google_place = google_place;
    res.line2 = line2;
    return res;
  };

  return (
    <Form.Item id={id} name={name} {...props}>
      <strong>{label}</strong>
      <TextInput
        id={id + "-recipient"}
        name={name + "Recipient"}
        placeholder="Venue name"
        required={required}
        label="Recipient"
        onChange={(e) => {
          const formattedAddress = formatAddressObject(
            e.target.value,
            value?.google_place,
            form.getFieldValue(name + "Line2")
          );
          setValue(formattedAddress);
          form.setFieldsValue({ [name]: formattedAddress });
          onChange(formattedAddress);
        }}
      />
      <AddressInput
        id={id + "-formatted"}
        name={name + "Formatted"}
        required={required}
        label="Street Address"
        placeholder="Address"
        initialValue={initialValue}
        onAddressObjectChange={(addressObject) => {
          const formattedAddressObject = formatAddressObject(
            form.getFieldValue(name + "Recipient"),
            addressObject,
            form.getFieldValue(name + "Line2")
          );
          setValue(formattedAddressObject);
          form.setFieldsValue({ [name]: null }); // setFieldsValue is a merge so this is necessary to change it to replace
          form.setFieldsValue({
            [name]: formattedAddressObject,
            [name + "Formatted"]: addressObject?.formatted_address,
          });
          onChange(formattedAddressObject);
        }}
      />
      <TextInput
        label="Unit/Suite"
        name={name + "Line2"}
        placeholder="Unit/suite"
        required={false}
        id={id + "-line2"}
        onChange={(e) => {
          const formattedAddressObject = formatAddressObject(
            form.getFieldValue(name + "Recipient"),
            value?.google_place,
            e.target.value
          );
          setValue(formattedAddressObject);
          form.setFieldsValue({ [name]: formattedAddressObject });
          onChange(formattedAddressObject);
        }}
      />
    </Form.Item>
  );
};

export default RestaurantAddressInput;

RestaurantAddressInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func,
  initialValue: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
};
