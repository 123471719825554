import { hasOwnProperty } from "luxon/src/impl/util";
import { DEFAULT_LABELS } from "pages/locations/CheckoutTab";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Alert, BbotButton, BbotModal, Form, Input } from "top-component-library";
import CheckboxInput from "top-component-library/form-inputs/CheckboxInput";
import SelectInput from "top-component-library/form-inputs/SelectInput";
import TextInput from "top-component-library/form-inputs/TextInput";
import { capitalizeString } from "util/Utils";

const AddEditRequiredCheckoutInfo = (props) => {
  const [customChoices, setCustomChoices] = useState([]);
  const [defaultsForCustom, setDefaultsForCustom] = useState([]);
  const [, setFormValues] = useState({});
  const {
    show,
    onHideCallback,
    onSubmitCallback,
    requiredCheckoutInfo,
    editMode,
    removeRCI,
    existingRCIKeys,
    selectedLocationIds,
    fulfillmentMethod,
    possibleFulfillmentMethods,
    newRCIKey,
    isDisabled,
  } = props;

  const [rciForm] = Form.useForm();

  useEffect(() => {
    if (requiredCheckoutInfo?.choices) {
      setCustomChoices(requiredCheckoutInfo.choices);

      if (requiredCheckoutInfo?.value && Array.isArray(requiredCheckoutInfo.value)) {
        const defaultsForCustom = requiredCheckoutInfo.choices.map((choice) =>
          requiredCheckoutInfo.value.includes(choice.label)
        );
        setDefaultsForCustom(defaultsForCustom);
      } else if (typeof requiredCheckoutInfo?.value === "string") {
        const indexOfDefault = requiredCheckoutInfo.choices.findIndex(
          (choice) => choice.label === requiredCheckoutInfo?.value
        );
        if (indexOfDefault >= 0) {
          setDefaultsForCustom(requiredCheckoutInfo.choices.map((choice, index) => index === indexOfDefault));
        }
      }
    }

    if (!editMode && show) {
      // if new checkbox/radio field, add 1 choice
      if (newRCIKey === "custom_checkboxes" || newRCIKey === "custom_radio_buttons") {
        setCustomChoices([{ label: "Choice 1" }]);
        setDefaultsForCustom([]);
      } else {
        setCustomChoices([]);
        setDefaultsForCustom([]);
      }
    }
  }, [requiredCheckoutInfo, editMode, newRCIKey, show]);

  const onHide = () => {
    setCustomChoices([]);
    setDefaultsForCustom([]);
    onHideCallback();
  };

  const rciTypeKeys = Object.keys(DEFAULT_LABELS).map((key) => key);

  const getDefaultCheckoutLabel = (key) => {
    if (hasOwnProperty(DEFAULT_LABELS, key)) {
      return DEFAULT_LABELS[key].checkoutLabel;
    }
    return "";
  };

  const getDefaultKdsLabel = (key) => {
    if (hasOwnProperty(DEFAULT_LABELS, key)) {
      return DEFAULT_LABELS[key].kdsLabel;
    }
    return "";
  };

  const requiredCheckoutInfoInitialVals = {
    checkoutLabel: requiredCheckoutInfo?.name_for_patron ?? getDefaultCheckoutLabel(newRCIKey),
    kdsLabel: requiredCheckoutInfo?.name_for_bartender ?? getDefaultKdsLabel(newRCIKey),
    remember: isDisabled ? true : requiredCheckoutInfo?.remember ?? false,
    required: isDisabled ? true : requiredCheckoutInfo?.required ?? false,
    showOnKds: requiredCheckoutInfo?.show_on_kds ?? true, // Note that we are setting this defaulted to true for new fields!!
    showToGuests: true,
    showOnTicket: requiredCheckoutInfo?.show_on_ticket ?? false,
    showOnHandheld: requiredCheckoutInfo?.show_on_handheld ?? false,
    requiredOnHandheld: requiredCheckoutInfo?.required_on_handheld ?? false,
    fulfillmentMethod: requiredCheckoutInfo?.method ?? "all",
  };

  useEffect(() => {
    rciForm.setFieldsValue(requiredCheckoutInfoInitialVals);
    setFormValues(requiredCheckoutInfoInitialVals);
  }, [requiredCheckoutInfo, newRCIKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const customFieldsEditor = () => {
    const isRadioField = newRCIKey === "custom_radio_buttons" || requiredCheckoutInfo?.type === "radio";
    return (
      <div className={"card padding-2"}>
        <div>
          <p className={"text-detail-small"}>
            Click on each label to edit it.
            <br />
            Use the checkboxes on the left to control if each choice is initially checked or unchecked.
          </p>
        </div>

        {customChoices?.map((choice, index) => (
          <>
            <div className={"margin-bottom-1 d-flex align-items-center"} key={index}>
              <input
                type="checkbox"
                checked={defaultsForCustom[index] || false}
                onChange={(e) => {
                  e.persist();
                  if (isRadioField && e.target.checked) {
                    setDefaultsForCustom(
                      customChoices.map((defaultForCustom, originalIndex) => originalIndex === index)
                    );
                  } else {
                    setDefaultsForCustom((defaultsForCustom) => {
                      let newDefaults = [...defaultsForCustom];
                      newDefaults[index] = e.target.checked;
                      return newDefaults;
                    });
                  }
                }}
              />

              <Input
                style={{ border: "None" }}
                type={"text"}
                data-test-id={props.testId}
                className={"text-input"}
                value={choice.label}
                onChange={(e) => {
                  e.persist();
                  setCustomChoices((customChoices) => {
                    let newChoices = [...customChoices];
                    newChoices[index].label = e.target.value;
                    return newChoices;
                  });
                }}
              />

              <span
                style={{
                  border: "1px solid var(--color-error__light)",
                  width: "26px",
                  color: "red",
                  textAlign: "center",
                  marginRight: "8px",
                }}
              >
                <i
                  className={"zmdi zmdi-delete clickable-icon"}
                  onClick={() => {
                    if (customChoices.length > 1) {
                      setCustomChoices((customChoices) => {
                        let newChoices = [...customChoices];
                        newChoices = [...newChoices.slice(0, index), ...newChoices.slice(index + 1)];
                        return newChoices;
                      });
                      if (isRadioField) {
                        setDefaultsForCustom(customChoices.map((choice) => false));
                      } else {
                        defaultsForCustom.splice(index, 1);
                      }
                    } else {
                      toast.error("You must have at least one choice.");
                    }
                  }}
                />
              </span>
            </div>
            {index < customChoices.length - 1 && <hr style={{ marginTop: "4px", marginBottom: "4px" }} />}
          </>
        ))}
        <BbotButton
          type={"primary"}
          className={"add-item"}
          onClick={() =>
            setCustomChoices((customChoices) => [
              ...customChoices,
              {
                label: "Choice " + (customChoices.length + 1),
              },
            ])
          }
        >
          Add choice
        </BbotButton>
      </div>
    );
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onHide}
      title={
        editMode && rciTypeKeys.includes(requiredCheckoutInfo?.key)
          ? "Edit " + capitalizeString(requiredCheckoutInfo.key.replaceAll("_", " ")) + " Field"
          : "Edit Required Checkout Info"
      }
      okText={"Save"}
      okButtonProps={{
        htmlType: "submit",
        form: "required-checkout-info",
      }}
    >
      <Form
        name={"required-checkout-info"}
        form={rciForm}
        layout={"vertical"}
        initialValues={requiredCheckoutInfoInitialVals}
        onFinish={(values) => {
          const isCustomField = requiredCheckoutInfo?.custom || newRCIKey?.includes("custom");
          const newKey = isCustomField
            ? values.kdsLabel.toLowerCase().replaceAll(" ", "_")
            : requiredCheckoutInfo?.key || newRCIKey;

          if (
            isCustomField &&
            ((!editMode && existingRCIKeys.includes(newKey)) ||
              (editMode && requiredCheckoutInfo?.key !== newKey && existingRCIKeys.includes(newKey)))
          ) {
            // if adding new field and label already exists, or editing and another field has the same key
            toast.info("Labels must be unique. Please change the KDS/Ticket Label.");
            return;
          }
          onSubmitCallback({
            ...values,
            key: requiredCheckoutInfo?.key || newRCIKey,
            choices: customChoices,
            defaultsForCustom: defaultsForCustom,
            custom: isCustomField, // new custom field or editing custom field
          });
          setCustomChoices([]);
          setDefaultsForCustom([]);
        }}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <TextInput required name={"checkoutLabel"} id={"checkout-label"} label={"Checkout Label"} />
        <TextInput required name={"kdsLabel"} id={"kds-label"} label={"KDS/Kitchen Label"} />

        {selectedLocationIds.length > 0 && fulfillmentMethod === "patron_choice" && (
          <SelectInput
            name={"fulfillmentMethod"}
            id={"fulfillmentMethod"}
            label={"Fulfillment Method"}
            options={[
              { value: "all", label: "All" },
              ...possibleFulfillmentMethods?.map((fulfillmentMethod) => ({
                value: fulfillmentMethod.fulfillment_method,
                label: fulfillmentMethod.button_name,
              })),
            ]}
          />
        )}

        {(newRCIKey === "custom_checkboxes" ||
          newRCIKey === "custom_radio_buttons" ||
          requiredCheckoutInfo?.type === "radio" ||
          requiredCheckoutInfo?.type === "checkbox") &&
          newRCIKey !== "marketing_opt_in" &&
          requiredCheckoutInfo?.key !== "marketing_opt_in" && (
            <div className={"margin-bottom-4"}>
              <div className={"margin-bottom-1"}>
                {`Edit Custom ${
                  newRCIKey === "custom_radio_buttons" || requiredCheckoutInfo?.type === "radio" ? "Radio" : "Checkbox"
                } Choices`}
              </div>
              {customFieldsEditor()}
            </div>
          )}

        <h5 className={"margin-bottom-1"}>Online Checkout by Guests</h5>
        <CheckboxInput
          label={"Require this field to be filled in"}
          name={"required"}
          id={"required"}
          disabled={newRCIKey === "marketing_opt_in" || isDisabled}
        />
        <CheckboxInput
          label={"Remember guest input"}
          name={"remember"}
          id={"remember"}
          disabled={newRCIKey === "marketing_opt_in" || isDisabled}
        />
        <h5 className={"margin-bottom-1"}>Checkout On TOP Terminal (3.0+)</h5>
        <CheckboxInput
          label={"Show this field"}
          name={"showOnHandheld"}
          id={"showOnHandheld"}
          disabled={rciForm.getFieldValue("requiredOnHandheld")}
        />
        {rciForm.getFieldValue("showOnHandheld") && (
          <CheckboxInput
            label={"Make this field required for handhelds"}
            name={"requiredOnHandheld"}
            id={"requiredOnHandheld"}
            disabled={newRCIKey === "marketing_opt_in"}
          />
        )}

        <h5 className={"margin-bottom-1"}>Display to Staff</h5>
        <CheckboxInput label={"Show on printed tickets"} name={"showOnTicket"} id={"showOnTicket"} />

        <CheckboxInput label={"Show on TOP Terminal"} name={"showOnKds"} id={"showOnKds"} />
      </Form>

      {editMode && (
        <BbotButton
          danger
          onClick={() => removeRCI(requiredCheckoutInfo.key)}
          disabled={isDisabled}
          data-test-id={"remove-field-button"}
        >
          Remove This Field
        </BbotButton>
      )}

      {isDisabled && (
        <Alert
          data-test-id={"required-field-alert"}
          className={"margin-top-4"}
          type={"info"}
          message={
            rciTypeKeys.includes(requiredCheckoutInfo?.key)
              ? `${capitalizeString(
                  requiredCheckoutInfo.key.replaceAll("_", " ")
                )} is a required field when tabs are enabled.`
              : "This is a required field when tabs are enabled."
          }
          description={""}
          showIcon={"true"}
        />
      )}
    </BbotModal>
  );
};

export default AddEditRequiredCheckoutInfo;
