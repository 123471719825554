import axios from "axios";
import { getWidgetData, updateWidgetData } from "components/customer-journey/widgets/Widget";
import { BBOT_DJANGO_SERVER } from "Config";
import React from "react";
import { Alert, Button, Form } from "react-bootstrap";

class ChooseHostnameWidget extends React.Component {
  constructor(props) {
    super(props);
    this.customer_id = props.journey.customer_id;
    this.journey_id = props.journey.id;
    this.task_id = props.task.id;
    this.widget = props.widget;
    this.state = {
      use_custom_domain: false,
      custom_domain: "",
      error_message: "",
      widget_data: {},
    };
  }

  componentDidMount() {
    getWidgetData(this.widget.id).then((res) => {
      const msg = res.data;
      this.setState({
        use_custom_domain: msg.use_custom_domain ? msg.use_custom_domain : false,
        custom_domain: msg.custom_domain ? msg.custom_domain : "",
      });
    });
  }

  editCustomerHostnames = async (event) => {
    event.preventDefault();
    this.setState({ error_message: "" });
    try {
      await axios.post(BBOT_DJANGO_SERVER + "/admin/editCustomerHostnames", {
        customer_id: this.customer_id,
        editFirstHostname: this.state.use_custom_domain ? this.state.custom_domain : "bbot.menu",
        hostnamesToDelete: this.state.use_custom_domain ? "bbot.menu" : "",
      });
      updateWidgetData({
        widgetId: this.widget.id,
        widgetData: {
          use_custom_domain: this.state.use_custom_domain,
          custom_domain: this.state.custom_domain,
        },
      });

      await this.props.updateTaskStatus("completed", { widgetId: this.props.widget.id });
    } catch (error) {
      console.log(error);
      const res = error.response;
      if (res && res.data && res.data.errorCode) {
        this.setState({ error_message: res.data.errorCode });
      }
    }
  };

  handleCustomDomainChange = (e) => {
    this.setState({ use_custom_domain: e.target.value === "true" });
  };

  render() {
    return (
      <div>
        <Form onSubmit={(event) => this.editCustomerHostnames(event)}>
          <h4>Choose A Hostname</h4>
          <p className="text-muted" style={{ marginBottom: "1em" }}>
            The hostname is a website domain where your ordering page will be hosted at. For example, if you have an
            ordering page called Test Pickup Menu and use the default bbot.menu hostname, your ordering page will be at
            bbot.menu/testpickupmenu.
          </p>
          <Form.Group controlId="choose-hostname">
            <h6>What hostname do you want to use for your menu?</h6>
            <div>
              <div>
                <input
                  type="radio"
                  checked={!this.state.use_custom_domain}
                  name="use_custom_domain"
                  onChange={() => this.setState({ use_custom_domain: false })}
                />
                <span> We'll have DoorDash buy a domain for us </span>
              </div>
              <div>
                <input
                  type="radio"
                  checked={this.state.use_custom_domain}
                  name="use_custom_domain"
                  onChange={() => this.setState({ use_custom_domain: true })}
                />
                <span> We'll use our own domain </span>
              </div>
            </div>
          </Form.Group>
          {this.state.use_custom_domain && (
            <Form.Group>
              <Form.Control
                required
                type="text"
                placeholder="Preferred hostname (e.g. yourrestaurantdomain.com)"
                value={this.state.custom_domain}
                onChange={(e) =>
                  this.setState({
                    custom_domain: e.target.value,
                    error_message: "",
                  })
                }
              />
            </Form.Group>
          )}
          {this.state.error_message && <Alert variant="danger">{this.state.error_message}</Alert>}
          <Button type="submit" size="sm">
            Save
          </Button>
        </Form>
      </div>
    );
  }
}

export default ChooseHostnameWidget;
