import axios from "axios";

import { BbotButton, BbotModal, Col, notification, Row } from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const DeleteCredentialModal = (props) => {
  const { selectedCustomer, selectedCredential, onClose, show } = props;

  const deletePartnerCredential = async () => {
    const payload = {
      credentialId: selectedCredential.id,
      customerId: selectedCustomer?.customer_id,
    };

    try {
      await axios.post("/owner/deletePartnerCredential", payload);
      notification.success({
        message: "Successfully deleted credential.",
      });
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble deleting credential.", true);
    }
  };

  return (
    <BbotModal
      title={"Delete Credential"}
      visible={show}
      onCancel={onClose}
      closable
      footer={[
        <BbotButton
          key={1}
          type={"default"}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </BbotButton>,
        <BbotButton
          key={2}
          type={"primary"}
          danger
          onClick={() => {
            deletePartnerCredential();
          }}
        >
          Delete
        </BbotButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          <div className={"margin-bottom-2"}>
            You are attempting to delete a set of partner credentials. Please press "Delete" to go through with this
            action.
          </div>
        </Col>
      </Row>
    </BbotModal>
  );
};

export default DeleteCredentialModal;
