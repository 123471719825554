import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { VerifyMicroDepositModal } from "components/customer-journey/forms/BankForm";
import CardSetupForm from "components/customer-journey/forms/CardSetupForm";
import { useEffect, useState } from "react";
import "styles/customer-journey/PaymentWidget.scss";
import PlaidLink from "../BankAccountPlaidLink";

import { PLAID_SUPPORTED_COUNTRIES } from "Constants";

import Button, { IconButton } from "@doordash/component-button";
import { Select as DDSelect } from "@doordash/component-fields";
import Loading from "@doordash/component-loading";
import { SingleSelectGroup } from "@doordash/component-select-group";
import { useToast } from "@doordash/component-toast";
import { Colors, Icon, Inset, StackChildren, Text } from "@doordash/design-language";
import styled from "styled-components";
import { Alert } from "../../../top-component-library";
import { generalErrorAlert } from "../../../util/Utils";

import BillingMethod from "../BillingMethod";
import PlaidManualBankForm from "../forms/PlaidManualBankForm";
import BillPayerEmail from "./BillPayerEmail";

const PAYMENT_TYPE = {
  CREDIT_CARD: "credit_card",
  BANK_ACCOUNT: "bank_account",
};

const PaymentWidget = ({ task, widget, customersById, customerId, updateTaskStatus }) => {
  // State
  // Stripe
  const [intentClientSecret, setIntentClientSecret] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);
  const [verificationPaymentMethodId, setVerificationPaymentMethodId] = useState(null);

  const [isSavingCard, setIsSavingCard] = useState(false);
  const [billpayer, setBillpayer] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);

  // MODALS
  const [showVerifyPaymentModal, setShowVerifyPaymentModal] = useState(false);

  const [selectedCountryCode, setSelectedCountryCode] = useState("US");
  const [showAddBillingMethod, setShowAddBillingMethod] = useState(false);
  const [showManualBankAccountForm, setShowManualBankAccountForm] = useState(false);
  const [selectedPaymentType, setSelectedPaymentType] = useState(PAYMENT_TYPE.CREDIT_CARD);

  const [isLoadingPaymentInfo, setIsLoadingPaymentInfo] = useState(false);
  const [isLoadingBillingMethods, setIsLoadingBillingMethods] = useState(false);

  const { displayToast } = useToast();

  useEffect(() => {
    const fetchPaymentData = async () => {
      if (!customerId) return;

      setIsLoadingPaymentInfo(true);

      const stripePublishableKey = await getDataForPaymentTask();
      const stripePromise = loadStripe(stripePublishableKey);
      setStripePromise(stripePromise);

      setIsLoadingPaymentInfo(false);
    };
    fetchPaymentData();
  }, [customerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowAddBillingMethod(paymentMethods.length <= 0);
  }, [paymentMethods]);

  const annotatePaymentMethods = (allPaymentMethods, unverifiedPaymentMethods, primaryPaymentMethodId) => {
    const unverifiedPaymentMethodIds = unverifiedPaymentMethods.map(
      (unverifiedPaymentMethod) => unverifiedPaymentMethod.id
    );
    allPaymentMethods.forEach((paymentMethod) => {
      paymentMethod.verified = !unverifiedPaymentMethodIds.includes(paymentMethod.id);
    });
    allPaymentMethods.sort((paymentMethod1, paymentMethod2) => {
      if (paymentMethod1.id === primaryPaymentMethodId) return -1;
      if (paymentMethod2.id === primaryPaymentMethodId) return 1;
      if (paymentMethod1.verified && !paymentMethod2.verified) return -1;
      if (!paymentMethod1.verified && paymentMethod2.verified) return 1;
      return 0;
    });
    return allPaymentMethods;
  };

  const getDataForPaymentTask = async (resetIntent = false) => {
    try {
      const res = await axios.get("/api/journey/getDataForPaymentTask", {
        params: { customer_id: customerId },
      });

      // const msg = res.data;
      const billpayer = res.data.billpayer;

      setIntentClientSecret(res.data.intent_client_secret);
      setBillpayer(billpayer);
      if (!resetIntent) {
        setPaymentMethods(
          annotatePaymentMethods(
            billpayer ? billpayer.list_sources : [],
            billpayer ? billpayer.list_unverified_bank_accounts : [],
            billpayer?.preferred_charge_source_id
          )
        );
      }
      return res.data.stripe_publishable_key;
    } catch (error) {
      generalErrorAlert(error, "Could not get account's billing information.", true);
    }
  };

  const onSavePaymentMethodSuccess = async () => {
    setIsLoadingBillingMethods(true);
    await getDataForPaymentTask();
    setShowAddBillingMethod(false);
    setSelectedPaymentType(PAYMENT_TYPE.CREDIT_CARD);
    setIsLoadingBillingMethods(false);
  };

  const selectPaymentMethod = async (payment_method_id) => {
    try {
      await axios.post("/api/setPreferredChargeSource", {
        customer_id: customerId,
        preferred_charge_source_id: payment_method_id,
      });
      await getDataForPaymentTask();
    } catch (error) {
      generalErrorAlert(error, "Could not update billing information.", true);
    }
  };

  const deletePaymentMethod = async (payment_method_id) => {
    try {
      await axios.post("/api/deletePaymentMethod", {
        customer_id: customerId,
        payment_method_id: payment_method_id,
      });
      await getDataForPaymentTask();
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmitBillpayerEmail = async (email) => {
    try {
      await axios.post("/api/createUpdateBillPayer", {
        customer_id: customerId,
        email_address: email,
      });

      await getDataForPaymentTask();
    } catch (error) {
      generalErrorAlert(error, "Could not update bill-payer information.", true);
    }
  };

  const detachBillpayer = async () => {
    try {
      const payload = {
        customer_id: customerId,
      };
      await axios.post("/api/detachBillpayer", payload);
      await getDataForPaymentTask();
      displayToast({
        text: "Successfully disconnected billpayer. You may now edit your billing information.",
        insetFromEdge: Inset.Sizes.Medium,
      });
    } catch (error) {
      generalErrorAlert(error, "Could not update billing information.", true);
      console.error(error);
    }
  };

  const managingCustomerName =
    billpayer && billpayer.managing_customer ? customersById[billpayer.managing_customer]?.name_for_owner : "";

  const connectManualBankAccount = (
    <ManualAccountContainer>
      <StackChildren>
        <div>
          <ManualAccountTitleContainer>
            <Text styles={Text.Styles.Body1Emphasis}>Connect your Account Manually</Text>
            <IconButton
              iconType={showManualBankAccountForm ? Icon.Types.ChevronUp : Icon.Types.ChevronDown}
              accessibilityLabel="manual-bank-account"
              onClick={() => setShowManualBankAccountForm((prevShow) => !prevShow)}
            />
          </ManualAccountTitleContainer>
          <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
            Having trouble connecting with Plaid? Connect your bank account manually instead.
          </Text>
        </div>

        {showManualBankAccountForm && (
          <Elements stripe={stripePromise}>
            <PlaidManualBankForm
              customerId={customerId}
              billpayerExists={!!billpayer}
              onSuccessCallback={() => {
                if (updateTaskStatus && task) {
                  updateTaskStatus("completed", { widgetId: widget.id });
                }
                onSavePaymentMethodSuccess();
              }}
            />
          </Elements>
        )}
      </StackChildren>
    </ManualAccountContainer>
  );

  const creditCardForm = (
    <StackChildren>
      <Elements stripe={stripePromise}>
        <CardSetupForm
          getDataForPaymentTask={getDataForPaymentTask}
          intentClientSecrent={intentClientSecret}
          customerId={customerId}
          task_id={task?.id}
          submitting={isSavingCard}
          setSubmitting={setIsSavingCard}
          billpayerExists={!!billpayer}
          onCancel={() => setShowAddBillingMethod(false)}
          onSuccessCallback={async () => {
            if (updateTaskStatus && task) {
              updateTaskStatus("completed", { widgetId: widget.id });
            }
            await onSavePaymentMethodSuccess();
          }}
        />
      </Elements>
    </StackChildren>
  );

  const connectBankAccount = (
    <StackChildren size={StackChildren.Sizes.Large}>
      <InputContainer>
        <DDSelect
          label="Country"
          value={selectedCountryCode}
          onChange={(countryCode) => setSelectedCountryCode(countryCode)}
          options={Object.entries(PLAID_SUPPORTED_COUNTRIES).map(([countryCode, countryName]) => ({
            name: countryName,
            value: countryCode,
          }))}
        />
      </InputContainer>
      <StackChildren size={StackChildren.Sizes.XxSmall}>
        <Text styles={Text.Styles.Body1Emphasis}>Connect with Plaid</Text>
        <Text styles={Text.Styles.Body2} color={Colors.TextSecondary}>
          Plaid is an easy and instant way to connect your bank account. You will need your banking credentials to
          complete this step.
        </Text>
      </StackChildren>
      <PlaidLink
        billpayerExists={!!billpayer}
        customerId={customerId}
        customerCountryCode={selectedCountryCode}
        resetAddPaymentForm={onSavePaymentMethodSuccess}
        onSuccessCallback={async () => {
          if (updateTaskStatus && task) {
            updateTaskStatus("completed", { widgetId: widget.id });
          }
          await onSavePaymentMethodSuccess();
        }}
      />
      {selectedCountryCode === "US" && connectManualBankAccount}
    </StackChildren>
  );

  const getBillingMethodsContent = () => {
    if (isLoadingBillingMethods) {
      return (
        <div className="margin-y-6">
          <Loading />
        </div>
      );
    }

    return (
      <div className={paymentMethods.length && "margin-bottom-2"}>
        {!!paymentMethods.length && (
          <div className="margin-bottom-1">
            <Text styles={Text.Styles.FieldMediumLabel}>Active Billing Methods</Text>
          </div>
        )}
        <StackChildren size={StackChildren.Sizes.Large}>
          {paymentMethods.map((paymentMethod, index) => {
            const isPrimary = paymentMethod.id === billpayer?.preferred_charge_source_id;

            return (
              <BillingMethod
                key={paymentMethod.id}
                isPrimary={isPrimary}
                paymentMethodName={paymentMethod.name}
                isVerified={paymentMethod.verified}
                removePaymentMethod={async () => deletePaymentMethod(paymentMethod.id)}
                selectPaymentMethodAsPrimary={async () => selectPaymentMethod(paymentMethod.id)}
                card={paymentMethod.card}
                onClickVerify={() => {
                  setShowVerifyPaymentModal(true);
                  setVerificationPaymentMethodId(paymentMethod.id);
                }}
              />
            );
          })}

          {!showAddBillingMethod && (
            <Button isInline onClick={() => setShowAddBillingMethod(true)} leadingIcon={Icon.Types.Add}>
              Add Billing Method
            </Button>
          )}
        </StackChildren>
      </div>
    );
  };

  const billingMethodContent = (
    <div className="margin-bottom-4">
      <StackChildren size={StackChildren.Sizes.Large}>
        <BillPayerEmail savedValue={billpayer?.email_address} onSave={onSubmitBillpayerEmail} />
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          {getBillingMethodsContent()}
          {showAddBillingMethod && (
            <>
              <Text styles={Text.Styles.FieldMediumLabel}>Billing Method</Text>

              <SingleSelectGroup
                accessibilityLabel="billing-method-selector"
                defaultSelectedIndex={0}
                options={["Credit Card", "Bank Account"]}
                onChange={(option) => {
                  setSelectedPaymentType(option === 0 ? PAYMENT_TYPE.CREDIT_CARD : PAYMENT_TYPE.BANK_ACCOUNT);
                }}
              >
                <Button>
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon type={Icon.Types.CardLine} className="margin-right-1" color={Icon.Colors.CurrentColor} />
                    <div>Credit Card</div>
                  </div>
                </Button>
                <Button>
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon type={Icon.Types.MoneyBank} className="margin-right-1" color={Icon.Colors.CurrentColor} />
                    <div>Bank Account</div>
                  </div>
                </Button>
              </SingleSelectGroup>
              <div className="padding-top-2">
                {selectedPaymentType === PAYMENT_TYPE.CREDIT_CARD ? creditCardForm : connectBankAccount}
              </div>
            </>
          )}
        </StackChildren>
      </StackChildren>
    </div>
  );

  const getBillingInfoContent = () => {
    if (isLoadingPaymentInfo) {
      return (
        <div className="margin-y-6">
          <Loading />
        </div>
      );
    }

    if (billpayer && billpayer?.managing_customer !== customerId) {
      return (
        <div className={"margin-bottom-2"}>
          <Alert
            variant={"primary"}
            message={
              <div>
                This customer's billing is controlled by {managingCustomerName}. If you want this customer to control
                its own billing info, please click{" "}
                <Button type={Button.Types.Link} onClick={detachBillpayer} isInline>
                  here.
                </Button>
              </div>
            }
          />
        </div>
      );
    }

    return billingMethodContent;
  };

  return (
    <div>
      {getBillingInfoContent()}

      {showVerifyPaymentModal && (
        <VerifyMicroDepositModal
          payment_method_id={verificationPaymentMethodId}
          customerId={customerId}
          onCloseCallback={() => {
            setShowVerifyPaymentModal(false);
            setVerificationPaymentMethodId(null);
          }}
          getDataForPaymentTask={getDataForPaymentTask}
          onSuccessCallback={async () => {
            setVerificationPaymentMethodId(null);
            setShowVerifyPaymentModal(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentWidget;

const ManualAccountContainer = styled.div`
  padding: 24px 0;
`;

const ManualAccountTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InputContainer = styled.div`
  width: 50%;
`;
