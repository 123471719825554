import styled from "styled-components";
import { InputNumber } from "top-component-library";

const BbotInputNumber = ({ ...props }) => {
  return <InputNumberStyled {...props} />;
};

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
  padding: 4px 0;
  border-radius: 8px;

  &&&.ant-input-number-group-wrapper {
    padding: 0px 0;
  }

  .ant-input-number-group-addon {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export default BbotInputNumber;
