import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import DismissibleButton from "components/owner-app/dismissable-button/DismissibleButton";
import AddEditDateRange from "components/owner-app/modals/location-editor/AddEditDateRange";
import AddEditRequiredCheckoutInfo from "components/owner-app/modals/location-editor/AddEditRequiredCheckoutInfo";
import Scheduler from "components/owner-app/scheduler/Scheduler";
import BbotToggle from "top-component-library/BbotToggle";

import { Select } from "@doordash/component-fields";
import BulkEditableSetting from "components/owner-app/BulkEditableSetting";
import { MAX_32_BIT_INT } from "Constants";
import { DateTime } from "luxon";
import styled from "styled-components";
import { BbotButton, BbotInput, BbotSelect, BbotTable, Card, Checkbox } from "top-component-library";
import NumberInput from "top-component-library/form-inputs/NumberInput";
import { capitalizeString, splitSnakeCase } from "util/Utils";
import {
  DescriptionContainer,
  SettingsContainer,
  TitleRow,
  ToggleContainer,
} from "../../styles/SettingsContainerStyles";

const REQUIRED_CHECKOUT_INFO_TYPES = {
  email: "email",
  address: "address",
  room_number: "text",
  name: "text",
  last_name: "text",
  marketing_opt_in: "checkbox",
  phone_number: "phone_number",
  number: "number",
};

// AddEditRequiredCheckoutInfo.js uses these
export const DEFAULT_LABELS = {
  address: {
    checkoutLabel: "Address",
    kdsLabel: "Address",
  },
  room_number: {
    checkoutLabel: "Enter Room Number",
    kdsLabel: "Room Number",
  },
  name: {
    checkoutLabel: "Full Name",
    kdsLabel: "Name",
  },
  last_name: {
    checkoutLabel: "Enter your Last Name (must match room reservation)",
    kdsLabel: "Last Name",
  },
  email: {
    checkoutLabel: "E-mail",
    kdsLabel: "E-mail",
  },
  phone_number: {
    checkoutLabel: "Phone Number",
    kdsLabel: "Phone",
  },
  marketing_opt_in: {
    checkoutLabel: "I'd like to receive special offers.",
    kdsLabel: "Marketing Opt In",
  },
  number: {
    checkoutLabel: "Custom Number Field",
    kdsLabel: "Custom Number Field",
    fieldTypeLabel: "Number Field",
  },
  custom_text: {
    checkoutLabel: "Custom Text Field",
    kdsLabel: "Custom Text Field",
    fieldTypeLabel: "Text Field",
  },
  custom_checkboxes: {
    checkoutLabel: "Custom Field",
    kdsLabel: "Custom Field",
    fieldTypeLabel: "Checkbox Field",
  },
  custom_radio_buttons: {
    checkoutLabel: "Custom Field",
    kdsLabel: "Custom Field",
    fieldTypeLabel: "Radio Field",
  },
};

const rciTypeKeys = Object.keys(DEFAULT_LABELS);

const CheckoutTab = (props) => {
  const [showAddEditRCIModal, setShowAddEditRCIModal] = useState(false);
  const [showAddEditDateRangeModal, setShowAddEditDateRangeModal] = useState(false);
  const [selectedRCI, setSelectedRCI] = useState(null);
  const [editRCIMode, setEditRCIMode] = useState(false);
  const [requiredCheckoutInfo, setRequiredCheckoutInfo] = useState({});

  //edge case use for bulk editing to prevent overwriting by og allow tabs by tabs on by default

  // select date range state (whitelist/blacklist)
  const [selectedDateRangeName, setSelectedDateRangeName] = useState(null);
  const [editDateRangeMode, setEditDateRangeMode] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(null);

  const [settingsState, setSettingsState] = useState({
    forcedTipEnabled: false,
    forcedTipFraction: 0,
    forcedTipName: "",
    allowTabs: false,
    tabsEnabledByDefault: false,
    minPretaxPurchaseEnabled: false,
    minPretaxPurchaseCents: 0,
    maxPretaxPurchaseEnabled: false,
    maxPretaxPurchaseCents: 0,
    minCartTotalEnabled: false,
    minCartTotalCents: 0,
    promoCodeLabel: "",
    checkoutPIIDisclaimerText: "",
    activeWhiteListDates: [],
    activeBlackListDates: [],
    visibleWhiteListDates: [],
    visibleBlackListDates: [],
  });
  const [activeWhiteListEnabled, setActiveWhiteListEnabled] = useState(false);
  const [activeBlackListEnabled, setActiveBlackListEnabled] = useState(false);
  const [visibleWhiteListEnabled, setVisibleWhiteListEnabled] = useState(false);
  const [visibleBlackListEnabled, setVisibleBlackListEnabled] = useState(false);
  const [newRCIKey, setNewRCIKey] = useState(null);

  const {
    updateLocationSettings,
    updatedLocationSettings,
    currentLocationSettings,
    updateCustomMenuProperties,
    removeChanges,
    removeCustomMenuChanges,
    bulkEditMode,
    defaultLocationSettings,
    selectedLocationIds,
    formInstance,
    clearWarning,
    userIsBbotAdmin,
  } = props;

  useEffect(() => {
    setSettingsState((settingsState) => ({
      ...settingsState,
      isForcedTipFractionBulkEditVisible: !props.bulkEditMode,
      isMinPretaxPurchaseBulkEditVisible: !props.bulkEditMode,
      isMaxPretaxPurchaseBulkEditVisible: !props.bulkEditMode,
      isMinCartTotalBulkEditVisible: !props.bulkEditMode,
    }));
  }, [props.bulkEditMode]);

  useEffect(() => {
    if (!props.defaultLocationSettings) return;

    const { defaultLocationSettings } = props;

    // set the required checkout info
    setRequiredCheckoutInfo({
      ...defaultLocationSettings.required_checkout_info,
    });

    // set the settings state
    setSettingsState({
      ...settingsState,
      forcedTipEnabled: defaultLocationSettings.forced_tip_fraction !== null,
      forcedTipFraction:
        defaultLocationSettings.forced_tip_fraction !== null ? defaultLocationSettings.forced_tip_fraction * 100 : 0,
      forcedTipName: defaultLocationSettings.forced_tip_name,
      patronTabAuthStrategy: defaultLocationSettings.patron_tab_auth_strategy,
      patronTabAutoCloseDelayMinutes: defaultLocationSettings.patron_tab_auto_close_delay_minutes ?? 180,
      allowTabs: defaultLocationSettings.allow_consumer_tabs,
      tabsEnabledByDefault: defaultLocationSettings.tabs_on_by_default,
      minPretaxPurchaseCents: defaultLocationSettings.minimum_pretax_cents ?? 0,
      maxPretaxPurchaseCents: defaultLocationSettings.maximum_paid_order_cents ?? 0,
      minCartTotalCents: defaultLocationSettings.minimum_paid_order_cents ?? 1,
      promoCodeLabel: defaultLocationSettings.custom_menu_properties?.promo_code_text ?? "",
      checkoutPIIDisclaimerText: defaultLocationSettings.checkout_pii_disclaimer_text,
      activeWhiteListDates: defaultLocationSettings.active_schedule_white_list_dates ?? [],
      activeBlackListDates: defaultLocationSettings.active_schedule_black_list_dates ?? [],
      visibleWhiteListDates: defaultLocationSettings.visible_schedule_white_list_dates ?? [],
      visibleBlackListDates: defaultLocationSettings.visible_schedule_black_list_dates ?? [],
    });

    // set the default enable/disabled state for active whitelists/blacklists
    if (defaultLocationSettings.active_schedule_white_list_dates?.length) {
      setActiveWhiteListEnabled(true);
    } else if (defaultLocationSettings.active_schedule_black_list_dates?.length) {
      setActiveBlackListEnabled(true);
    }

    // set the default enable/disabled state for visible whitelists/blacklists
    if (defaultLocationSettings.visible_schedule_white_list_dates?.length) {
      setVisibleWhiteListEnabled(true);
    } else if (defaultLocationSettings.visible_schedule_black_list_dates?.length) {
      setVisibleBlackListEnabled(true);
    }
  }, [props.defaultLocationSettings, props.bulkEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputOnChange = (e) => {
    setSettingsState({
      ...settingsState,
      [e.target.name]: e.target.value,
    });
  };

  const removeRCI = (key) => {
    delete requiredCheckoutInfo[key];
    setShowAddEditRCIModal(false);
    setRequiredCheckoutInfo(requiredCheckoutInfo);
    setSelectedRCI(null);
    const updatedRequiredCheckoutInfo = { ...requiredCheckoutInfo };
    updateLocationSettings({
      required_checkout_info: updatedRequiredCheckoutInfo,
    });
  };

  const rciFieldDisabled = (locationProperty, currentRCIKey, rciKeysToDisable) => {
    if (rciKeysToDisable.includes(currentRCIKey)) {
      return locationProperty;
    } else {
      return false;
    }
  };

  const changeRCIDisplayOrder = (dragAndDropResult) => {
    if (!dragAndDropResult || !dragAndDropResult.destination) return;

    const draggedRCI = {
      ...requiredCheckoutInfo[dragAndDropResult.draggableId],
      display_order: dragAndDropResult.destination.index,
    };

    // turn required checkout info into an array
    let newRCI = Object.values(requiredCheckoutInfo)
      .filter((rci) => rci.key !== dragAndDropResult.draggableId)
      .sort((rci1, rci2) => (rci1.display_order > rci2.display_order ? 1 : -1));

    newRCI.splice(dragAndDropResult.destination.index, 0, draggedRCI);
    newRCI.forEach((rci, index) => {
      rci.display_order = index;
    });

    // remake the object from the array, with updated display_order indexes
    const rciKeys = newRCI.map((rci) => [rci.key, rci]);
    const newRequiredCheckoutInfo = Object.fromEntries(rciKeys);

    setRequiredCheckoutInfo(newRequiredCheckoutInfo);
    updateLocationSettings({ required_checkout_info: newRequiredCheckoutInfo });
  };

  const saveRequiredCheckoutInfo = (values) => {
    let key = editRCIMode ? selectedRCI.key : values.key;
    if (values.custom) {
      key = values.kdsLabel.toLowerCase().replaceAll(" ", "_");
    }

    let newRequiredCheckoutInfo = {
      ...requiredCheckoutInfo,
      [key]: {
        ...selectedRCI,
        key: key,
        name_for_bartender: values.kdsLabel,
        name_for_patron: values.checkoutLabel,
        required: values.required || false,
        show_to_guests: values.showToGuests,
        show_on_ticket: values.showOnTicket || false,
        show_on_kds: values.showOnKds || false,
        show_on_handheld: values.showOnHandheld || false,
        required_on_handheld: values.requiredOnHandheld || false,
        remember: values.remember || false,
      },
    };

    // if patron_choice, attach method to the required checkout field
    if (
      selectedLocationIds.length === 1 &&
      defaultLocationSettings.fulfillment_method === "patron_choice" &&
      values.fulfillmentMethod !== "all"
    ) {
      newRequiredCheckoutInfo[key].method = values.fulfillmentMethod;
    } else if (values.fulfillmentMethod === "all") {
      delete newRequiredCheckoutInfo[key].method;
    }

    // if adding a new field, include these additional properties
    if (!editRCIMode) {
      newRequiredCheckoutInfo[key].key = key;
      newRequiredCheckoutInfo[key].type = REQUIRED_CHECKOUT_INFO_TYPES[values.key];
      newRequiredCheckoutInfo[key].display_order = Object.keys(requiredCheckoutInfo).length;
    } else if (values.custom && selectedRCI.key !== key) {
      // if editing a custom field and the computed key is different, delete the old key (selectedRci.key)
      delete newRequiredCheckoutInfo[selectedRCI.key];
    }

    // Marketing opt in special case
    if (!editRCIMode && values.key === "marketing_opt_in") {
      // if adding marketing opt in
      newRequiredCheckoutInfo.marketing_opt_in.default_choice = true;
      newRequiredCheckoutInfo.marketing_opt_in.dependant_on_fields = ["email"];
    }

    // if adding a new custom checkbox or radio field, save custom=true, then update the key and the type.
    if (values.custom && !editRCIMode) {
      newRequiredCheckoutInfo[key].custom = true;
      newRequiredCheckoutInfo[key].type = "text";

      if (values.key === "custom_checkboxes") {
        newRequiredCheckoutInfo[key].type = "checkbox";
      } else if (values.key === "custom_radio_buttons") {
        newRequiredCheckoutInfo[key].type = "radio";
      }
    }

    // if adding/editing a custom checkbox or radio field, save the choices
    if (
      values.custom &&
      (newRequiredCheckoutInfo[key].type === "checkbox" || newRequiredCheckoutInfo[key].type === "radio")
    ) {
      newRequiredCheckoutInfo[key].choices = values.choices;

      // if adding/editing custom checkbox, calculate default value for it.
      if (newRequiredCheckoutInfo[key].type === "checkbox") {
        newRequiredCheckoutInfo[key].value = values.defaultsForCustom
          .map((defaultForCustom, index) => (defaultForCustom ? values.choices[index]?.label : null))
          .filter((defaultForCustom) => defaultForCustom !== null);
      } else if (newRequiredCheckoutInfo[key].type === "radio") {
        const indexOfDefault = values.defaultsForCustom.indexOf(true); // find the index of the default value
        newRequiredCheckoutInfo[key].value = indexOfDefault >= 0 ? values.choices[indexOfDefault]?.label : "";
      }
    }

    // save everything, update the state variables
    setRequiredCheckoutInfo(newRequiredCheckoutInfo);
    setSelectedRCI(null);
    setShowAddEditRCIModal(false);

    updateLocationSettings({ required_checkout_info: newRequiredCheckoutInfo });
  };

  const saveSchedule = (userInput, timeRangesByDay, scheduleType = "active_schedule") => {
    const militaryTimeRanges = timeRangesByDay;

    const payload = {
      sunday: {
        customer_input: userInput[0],
        military_times: militaryTimeRanges[0],
      },
      monday: {
        customer_input: userInput[1],
        military_times: militaryTimeRanges[1],
      },
      tuesday: {
        customer_input: userInput[2],
        military_times: militaryTimeRanges[2],
      },
      wednesday: {
        customer_input: userInput[3],
        military_times: militaryTimeRanges[3],
      },
      thursday: {
        customer_input: userInput[4],
        military_times: militaryTimeRanges[4],
      },
      friday: {
        customer_input: userInput[5],
        military_times: militaryTimeRanges[5],
      },
      saturday: {
        customer_input: userInput[6],
        military_times: militaryTimeRanges[6],
      },
    };

    updateLocationSettings({
      [scheduleType]: payload,
    });
  };

  const droppableTableBody = (props) => {
    return (
      <DragDropContext onDragEnd={changeRCIDisplayOrder}>
        <Droppable droppableId={"droppable"}>
          {(provided, _) => <tbody ref={provided.innerRef} {...props} {...provided.droppableProps} />}
        </Droppable>
      </DragDropContext>
    );
  };

  const draggableBodyRow = ({ rci, index, ...props }) => {
    return (
      <Draggable key={props["data-row-key"]} draggableId={props["data-row-key"]} index={index}>
        {(provided) => (
          <tr ref={provided.innerRef} {...props} {...provided.draggableProps} {...provided.dragHandleProps} />
        )}
      </Draggable>
    );
  };

  const guestCheckoutInfoTable = () => {
    const checkoutInfoTableColumns = [
      {
        title: "Field Label",
        key: "field_label",
        dataIndex: "checkout_info_name",
        render: (checkoutInfo) => (
          <div>
            <div>{checkoutInfo.name_for_patron}</div>
            <div>
              <p className={"supporting-text"}>{checkoutInfo.name_for_bartender}</p>
            </div>
          </div>
        ),
      },

      {
        title: "Shown/Required",
        key: "shown-or-required",
        dataIndex: "shown_or_required",
        render: (checkoutInfo) => (
          <div>
            {checkoutInfo.show_on_kds && (
              <span className={"supporting-text margin-right-4"}>
                <i className={"zmdi zmdi-check margin-right-1"} />
                Shown on KDS
              </span>
            )}
            {checkoutInfo.show_on_ticket && (
              <span className={"supporting-text margin-right-4"}>
                <i className={"zmdi zmdi-check margin-right-1"} />
                Shown on Ticket
              </span>
            )}
            {checkoutInfo.required && (
              <span className={"supporting-text margin-right-4"}>
                <i className={"zmdi zmdi-check margin-right-1"} />
                Required
              </span>
            )}
          </div>
        ),
      },
      { title: "Field Type", key: "field-type", dataIndex: "field_type" },
    ];

    const checkoutInfoTableData = Object.values(requiredCheckoutInfo)
      .sort((rci1, rci2) => (rci1.display_order > rci2.display_order ? 1 : -1))
      .map((rci, _) => ({
        checkout_info_name: {
          name_for_patron: rci.name_for_patron,
          name_for_bartender: rci.name_for_bartender,
        },
        shown_or_required: {
          show_on_kds: rci.show_on_kds,
          show_on_ticket: rci.show_on_ticket,
          required: rci.required,
        },
        field_type: capitalizeString(splitSnakeCase(rci.type)),
        rciInfo: rci,
      }));

    const availableKeys = rciTypeKeys.filter((rciType) => !Object.keys(requiredCheckoutInfo).includes(rciType));

    return (
      <div>
        <BbotTable
          id={"guest-checkout-info-id"}
          manuallyFormattedColumns={checkoutInfoTableColumns}
          data={checkoutInfoTableData}
          rowKey={(checkoutInfo) => checkoutInfo.rciInfo.key}
          components={{
            body: {
              wrapper: (val) => droppableTableBody({ ...val }),
              row: (val) => draggableBodyRow({ ...val }),
            },
          }}
          manualOnRow={(checkoutInfo, index) => ({
            rci: checkoutInfo.rciInfo,
            index: index,
            onClick: () => {
              setSelectedRCI(checkoutInfo.rciInfo);
              setShowAddEditRCIModal(true);
              setEditRCIMode(true);
            },
          })}
        />
        <StyledButton>
          <span className={"margin-right-1"}>Add Additional Field</span>
          <NewFieldSelect
            onSelect={(newRCIKey) => {
              setShowAddEditRCIModal(true);
              setEditRCIMode(false);
              setNewRCIKey(newRCIKey);
            }}
            options={availableKeys.map((key) => ({
              label: DEFAULT_LABELS[key].fieldTypeLabel || capitalizeString(splitSnakeCase(key)),
              value: key,
            }))}
          />
        </StyledButton>
      </div>
    );
  };

  const bulkEditButtonText = "Edit For Selected Locations";

  return (
    <div>
      <div className={"margin-bottom-6"}>
        {/*REQUIRED CHECKOUT INFO TABLE*/}
        <div className={"margin-bottom-2"}>
          <h4>Guest Checkout Information</h4>
          <p>
            To edit a field, click anywhere in its row. <br />
            To reorder the fields, drag/drop each row in the table into the desired position. <br />
            Learn more about required checkout information{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://central.bbot.menu/article/434-required-checkout-information"}
            >
              here
            </a>
            .
            <br />
            Note: we recommend only using this option when necessary. Requiring too much info makes checkout cumbersome
            for guests, which harms sales.
          </p>
        </div>

        {guestCheckoutInfoTable()}
      </div>

      <div className={"margin-bottom-6"}>
        <div className={"margin-bottom-2"}>
          <h4>Miscellaneous Checkout Settings</h4>
        </div>

        <Card className={"settings-section-card"}>
          <div className={"margin-bottom-2"}>
            <h5 className={"card-subheader"}>Tip and Tax</h5>
            <hr />

            <BulkEditableSetting
              title={"Forced Tip"}
              description={
                "Would you like enforce a specific tip amount for guests to pay at this location(s)? This is often used for larger tables where an enforced gratuity exists for large parties."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateLocationSettings({
                  forced_tip_name: settingsState.forcedTipName,
                  forced_tip_fraction: settingsState.forcedTipFraction,
                });
              }}
              onBulkEditCancel={() => {
                removeChanges(["forced_tip_name", "forced_tip_fraction"]);
              }}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.forced_tip_fraction !== null,
                onClick: (e) => {
                  formInstance.setFieldsValue({ forcedTipFraction: 0 });
                  setSettingsState({
                    ...settingsState,
                    forcedTipName: "",
                    forcedTipFraction: e.SWITCH_STATE.enabled ? 0 : null,
                  });
                  updateLocationSettings({
                    forced_tip_name: "",
                    forced_tip_fraction: e.SWITCH_STATE.enabled ? 0 : null,
                  });
                },
              }}
            >
              <div className={"margin-bottom-2"}>
                <div className="supporting-text margin-bottom-1">Forced Tip Name</div>
                <BbotInput
                  placeholder={"Gratuity Included, etc..."}
                  value={settingsState.forcedTipName || ""}
                  name={"forcedTipName"}
                  onChange={(e) => {
                    inputOnChange(e);
                    updateLocationSettings({
                      forced_tip_name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className={"margin-bottom-2"}>
                <div className="supporting-text margin-bottom-1">Forced Tip Percentage</div>
                <NumberInput
                  id={"forced-tip-fraction"}
                  addonAfter={"%"}
                  controls={false}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: "This cannot be negative.",
                    },
                  ]}
                  value={settingsState.forcedTipFraction * 100}
                  name={"forcedTipFraction"}
                  onChange={(newValue) => {
                    setSettingsState({
                      ...settingsState,
                      forcedTipFraction: newValue * 0.01,
                    });
                    updateLocationSettings({
                      forced_tip_fraction: newValue * 0.01,
                    });
                  }}
                />
              </div>
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Minimum Pretax Purchase"}
              description={
                "When enabled this will prevent guests from checking out at this location(s) if their cart subtotal is below the amount specified."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateLocationSettings({
                  minimum_pretax_cents: settingsState.minPretaxPurchaseCents,
                });
              }}
              onBulkEditCancel={() => {
                removeChanges(["minimum_pretax_cents"]);
              }}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.minimum_pretax_cents > 0,
                onClick: (e) => {
                  formInstance.setFieldsValue({ minPretaxPurchaseCents: 0 });
                  setSettingsState({ ...settingsState, minPretaxPurchaseCents: 0 });
                  updateLocationSettings({ minimum_pretax_cents: 0 });
                },
              }}
            >
              <NumberInput
                id={"min-pretax-purchase-cents"}
                addonBefore={"$"}
                controls={false}
                rules={[
                  {
                    type: "number",
                    min: 0,
                    message: "This cannot be negative.",
                  },
                  {
                    type: "number",
                    max: MAX_32_BIT_INT * 0.01,
                    message: `Please choose an amount less than $${MAX_32_BIT_INT * 0.01}`,
                  },
                ]}
                value={settingsState.minPretaxPurchaseCents * 0.01}
                name={"minPretaxPurchaseCents"}
                onChange={(newValue) => {
                  setSettingsState({
                    ...settingsState,
                    minPretaxPurchaseCents: newValue * 100,
                  });
                  updateLocationSettings({
                    minimum_pretax_cents: newValue * 100,
                  });
                }}
              />
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Maximum Pretax Purchase"}
              description={
                "When enabled this will prevent guests from checking out at this location(s) if their cart subtotal is above the amount specified."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateLocationSettings({
                  maximum_paid_order_cents: settingsState.maxPretaxPurchaseCents,
                });
              }}
              onBulkEditCancel={() => {
                removeChanges(["maximum_paid_order_cents"]);
              }}
              toggleConfig={{
                toggleShouldBlockContent: true,
                defaultEnabled: defaultLocationSettings.maximum_paid_order_cents < MAX_32_BIT_INT,
                onClick: (e) => {
                  formInstance.setFieldsValue({ maxPretaxPurchaseCents: MAX_32_BIT_INT * 0.01 });
                  setSettingsState({ ...settingsState, maxPretaxPurchaseCents: MAX_32_BIT_INT * 0.01 });
                  updateLocationSettings({ maximum_paid_order_cents: MAX_32_BIT_INT });
                },
              }}
            >
              <NumberInput
                id={"max-pretax-purchase-cents"}
                addonBefore={"$"}
                controls={false}
                rules={[
                  {
                    type: "number",
                    min: 0.01,
                    message: "This must be positive.",
                  },
                  {
                    type: "number",
                    max: MAX_32_BIT_INT * 0.01,
                    message: `Please choose an amount less than $${MAX_32_BIT_INT * 0.01}`,
                  },
                ]}
                value={settingsState.maxPretaxPurchaseCents * 0.01}
                name={"maxPretaxPurchaseCents"}
                onChange={(newValue) => {
                  setSettingsState({
                    ...settingsState,
                    maxPretaxPurchaseCents: newValue * 100,
                  });
                  updateLocationSettings({
                    maximum_paid_order_cents: newValue * 100,
                  });
                }}
              />
            </BulkEditableSetting>

            <BulkEditableSetting
              title={"Minimum Cart Total"}
              description={
                "When enabled this will prevent guests from checking out at this location(s) if their cart cart total (everything but delivery fees + discounts) is below the amount specified."
              }
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateLocationSettings({
                  minimum_paid_order_cents: defaultLocationSettings.minimum_paid_order_cents,
                });
              }}
              onBulkEditCancel={() => {
                removeChanges(["minimum_paid_order_cents"]);
                formInstance.setFieldsValue({
                  minCartTotalCents: defaultLocationSettings.minimum_paid_order_cents * 0.01,
                });
                clearWarning();
              }}
            >
              <NumberInput
                id={"min-cart-total-cents"}
                addonBefore={"$"}
                controls={false}
                rules={[
                  {
                    type: "number",
                    min: 0.01,
                    message: "This must be positive.",
                  },
                ]}
                value={settingsState.minCartTotalCents * 0.01}
                name={"minCartTotalCents"}
                onChange={(newValue) => {
                  setSettingsState({
                    ...settingsState,
                    minCartTotalCents: newValue * 100,
                  });
                  updateLocationSettings({
                    minimum_paid_order_cents: newValue * 100,
                  });
                }}
              />
            </BulkEditableSetting>
          </div>

          <BulkEditableSetting
            className={"tabs-settings"}
            title={"Tabs"}
            description={
              "If enabled, the Name and Phone Number fields will be automatically added (if not already present) to Guest Checkout Information above."
            }
            inBulkEditMode={bulkEditMode}
            bulkEditButtonText={bulkEditButtonText}
            onBulkEditInitial={() => {
              setSettingsState({
                ...settingsState,
                allowTabs: false,
                tabsEnabledByDefault: false,
              });
              updateLocationSettings({
                allow_consumer_tabs: false,
                tabs_on_by_default: false,
              });
            }}
            onBulkEditCancel={() => {
              removeChanges(["tabs_on_by_default", "allow_consumer_tabs"]);
            }}
          >
            <hr />
            <div className={"settings-element"}>
              <div className={"d-flex justify-content-space-between"}>
                <span className={"margin-right-4"} data-test-id={"allow-tabs-heading"}>
                  Allow Tabs
                </span>
                <span>
                  <BbotToggle
                    data-test-id={"allow-tabs-toggle"}
                    defaultEnabled={settingsState.allowTabs}
                    onClick={(e) => {
                      // if toggling off Allow Tabs and Tabs Enabled By Default is currently ON, we want to switch tabs enabled by default to off.
                      if (!e.SWITCH_STATE.enabled && settingsState.tabsEnabledByDefault) {
                        setSettingsState({
                          ...settingsState,
                          allowTabs: e.SWITCH_STATE.enabled,
                          tabsEnabledByDefault: false,
                        });
                        updateLocationSettings({
                          allow_consumer_tabs: e.SWITCH_STATE.enabled,
                          tabs_on_by_default: false,
                        });

                        // Checking if the current setting matches the initial settings to determine if we should remove from the variable tracking updates.
                        if (
                          !bulkEditMode && // don't need do this for bulk edit
                          !defaultLocationSettings.allow_consumer_tabs
                        ) {
                          // if consumer tabs on by default is off originally
                          if (!defaultLocationSettings.tabs_on_by_default) {
                            removeChanges(["allow_consumer_tabs", "tabs_on_by_default"]);
                          } else {
                            removeChanges(["allow_consumer_tabs"]);
                          }
                        }
                      } else {
                        setSettingsState({
                          ...settingsState,
                          allowTabs: e.SWITCH_STATE.enabled,
                        });
                        updateLocationSettings({
                          allow_consumer_tabs: e.SWITCH_STATE.enabled,
                        });
                      }
                    }}
                  />
                </span>
              </div>
              <TabDescription>
                <i>
                  If enabled, guests can start a tab. We'll keep a running tabulation and the guest can either close at
                  the end of the meal or we'll close it within 8 hours.
                  <br />
                  Note: Tabs are a great way to increase your average check size. But you should note that only Visa and
                  MasterCard are supported when using tabs.
                </i>
              </TabDescription>
            </div>

            <div className={"settings-element"}>
              <div className={"d-flex justify-content-space-between"}>
                <span className={"margin-right-4"}>Tabs On By Default</span>
                <span>
                  <BbotToggle
                    defaultEnabled={settingsState.tabsEnabledByDefault}
                    onClick={(e) => {
                      // if setting Tabs Enabled By Default ON, also make sure Allow Tabs is on.
                      if (e.SWITCH_STATE.enabled) {
                        setSettingsState({
                          ...settingsState,
                          tabsEnabledByDefault: true,
                          allowTabs: true,
                        });

                        // if initial setting for Tabs Enabled By Default is ON, remove changes
                        if (!bulkEditMode && defaultLocationSettings.tabs_on_by_default) {
                          if (defaultLocationSettings.allow_consumer_tabs) {
                            removeChanges(["allow_consumer_tabs", "tabs_on_by_default"]);
                          } else {
                            removeChanges(["tabs_on_by_default"]);
                          }
                        } else {
                          // initial setting for Tabs Enabled was OFF, we need to update location settings state.
                          if (defaultLocationSettings.allow_consumer_tabs) {
                            updateLocationSettings({
                              tabs_on_by_default: true,
                            });
                          } else {
                            updateLocationSettings({
                              tabs_on_by_default: true,
                              allow_consumer_tabs: true,
                            });
                          }
                        }
                      } else {
                        setSettingsState({
                          ...settingsState,
                          tabsEnabledByDefault: false,
                        });
                        updateLocationSettings({
                          tabs_on_by_default: false,
                        });
                        if (!bulkEditMode && !defaultLocationSettings.tabsEnabledByDefault) {
                          removeChanges(["tabs_on_by_default"]);
                        }
                      }
                    }}
                  />
                </span>
              </div>
              <TabDescription>
                <i>
                  During the checkout process guests have the option to start a tab or just order and close out. If
                  enabled this will default the tabs checkbox to "on" for guests at this location. They will still have
                  the option to uncheck and just place a single order.
                </i>
              </TabDescription>
            </div>
          </BulkEditableSetting>
          <SettingsContainer className={"settings-element"}>
            <TitleRow className={"card-subheader"}>
              <span>Visa and Master Card Auth Strategy</span>
              <ToggleContainer>
                <Select
                  label={""}
                  options={[
                    { name: "Incremental Fallback Fixed", value: "incremental_fallback_fixed" },
                    { name: "Fixed", value: "fixed" },
                  ]}
                  onChange={(value) => {
                    setSettingsState({
                      ...settingsState,
                      patronTabAuthStrategy: value,
                    });
                    updateLocationSettings({ patron_tab_auth_strategy: value });
                  }}
                  value={settingsState.patronTabAuthStrategy}
                />
              </ToggleContainer>
            </TitleRow>
            <DescriptionContainer>
              <i>
                The recommended setting is "fixed" for the best guest experience. Allows choosing between progressively
                increasing a hold on a card every time a tab is added to if a tab is opened with Visa/MasterCard
                (Incremental Fallback Fixed) OR only charging a fixed amount up front and charging the guest once at the
                end when the tab is closed if it was opened with Visa/MasterCard (Fixed). All other types of credit card
                (Amex/Discover/etc) will charge a fixed amount up front and will only be settled once at the end with
                either setting.
              </i>
            </DescriptionContainer>
          </SettingsContainer>
          <SettingsContainer className={"settings-element"}>
            <TitleRow className={"card-subheader"}>
              <span>Auto Close Delay (in minutes)</span>
              <ToggleContainer>
                <NumberInput
                  id={"auto-close-delay"}
                  controls={false}
                  required={false}
                  rules={[
                    {
                      type: "number",
                      min: 1,
                      message: "This must be positive.",
                    },
                  ]}
                  value={settingsState.patronTabAutoCloseDelayMinutes}
                  name={"patronTabAutoCloseDelayMinutes"}
                  onChange={(newValue) => {
                    setSettingsState({
                      ...settingsState,
                      patronTabAutoCloseDelayMinutes: Math.round(newValue),
                    });
                    updateLocationSettings({
                      patron_tab_auto_close_delay_minutes: Math.round(newValue),
                    });
                  }}
                />
              </ToggleContainer>
            </TitleRow>
            <DescriptionContainer>
              <i>
                The global default of 180 minutes (3 hours) is the recommended setting. You should only set a custom
                number of minutes if your service model requires tabs to be closed at a set time at the end of service.
              </i>
            </DescriptionContainer>
          </SettingsContainer>
          <BulkEditableSetting
            className={"tabs-settings"}
            title={"Promotional Codes"}
            description={"This controls the promotional code label that is shown on the checkout page."}
            inBulkEditMode={bulkEditMode}
            bulkEditButtonText={bulkEditButtonText}
            onBulkEditInitial={() => {
              updateCustomMenuProperties({
                promo_code_text: settingsState.promoCodeLabel || "",
              });
            }}
            onBulkEditCancel={() => {
              removeCustomMenuChanges(["promo_code_text"]);
            }}
          >
            <BbotInput
              value={settingsState.promoCodeLabel}
              name={"promoCodeLabel"}
              onChange={(e) => {
                setSettingsState({
                  ...settingsState,
                  promoCodeLabel: e.target.value,
                });
                updateCustomMenuProperties({
                  promo_code_text: e.target.value,
                });
              }}
            />
          </BulkEditableSetting>
          {userIsBbotAdmin && (
            <BulkEditableSetting
              className={"tabs-settings"}
              title={"PII Legal Disclaimer (Admin Only)"}
              description={"This allows us to set a disclaimer about how we will use PII entered during checkout."}
              inBulkEditMode={bulkEditMode}
              bulkEditButtonText={bulkEditButtonText}
              onBulkEditInitial={() => {
                updateLocationSettings({
                  checkout_pii_disclaimer_text: settingsState.checkoutPIIDisclaimerText || "",
                });
              }}
              onBulkEditCancel={() => {
                removeCustomMenuChanges(["checkout_pii_disclaimer_text"]);
              }}
            >
              <BbotInput
                value={settingsState.checkoutPIIDisclaimerText}
                name={"checkoutPIIDisclaimerText"}
                onChange={(e) => {
                  setSettingsState({
                    ...settingsState,
                    checkoutPIIDisclaimerText: e.target.value,
                  });
                  updateLocationSettings({
                    checkout_pii_disclaimer_text: e.target.value,
                  });
                }}
              />
            </BulkEditableSetting>
          )}
        </Card>
      </div>

      <div className={"margin-bottom-2"}>
        <h4>Allowed Ordering Window Schedule</h4>
      </div>
      <Card className={"settings-section-card margin-bottom-4"}>
        <div>
          <p>
            Use this scheduler to control when guests can order at the selected locations. <br />
            You can type date ranges into the boxes directly under the weekday names, or you can click and drag on the
            scheduler itself. <br />
            To clear time blocks, click and drag starting on an already selected time block. You can also delete the
            time-input text at the top of each column.
          </p>
        </div>
        <div className={"margin-bottom-2"}>
          <DismissibleButton
            buttonText={"Edit for Selected Locations"}
            dismissedInitially={!bulkEditMode}
            onClickCallback={() => {
              updateLocationSettings({
                visible_schedule: defaultLocationSettings.visible_schedule,
              });
            }}
            onCancelCallback={() => {
              removeChanges(["visible_schedule"]);
            }}
          >
            <Scheduler
              initialSchedule={defaultLocationSettings?.visible_schedule}
              onChangeCallback={(timeRanges, intervals) => saveSchedule(timeRanges, intervals, "visible_schedule")}
            />
          </DismissibleButton>
        </div>

        <div className={"margin-bottom-1"}>
          <h5>Restrict ordering dates</h5>
        </div>
        <DismissibleButton
          buttonText={"Edit for Selected Locations"}
          dismissedInitially={!bulkEditMode}
          onClickCallback={() => {
            updateLocationSettings({
              visible_black_list_dates: defaultLocationSettings.visible_schedule_black_list_dates,
              visible_white_list_dates: defaultLocationSettings.visible_schedule_white_list_date,
            });
          }}
          onCancelCallback={() => {
            removeChanges(["visible_black_list_dates", "visible_white_list_dates"]);
          }}
        >
          <div className={"margin-y-2"}>
            <div className={"margin-bottom-1"}>
              <Checkbox
                checked={visibleWhiteListEnabled}
                onChange={(e) => {
                  setVisibleWhiteListEnabled(e.target.checked);
                  if (e.target.checked) {
                    // if checking black list box, clear array for white list
                    setVisibleBlackListEnabled(false);
                    setSettingsState({
                      ...settingsState,
                      visibleBlackListDates: [],
                    });
                    updateLocationSettings({ visible_black_list_dates: [] });
                  } else {
                    setSettingsState({
                      ...settingsState,
                      visibleWhiteListDates: [],
                    });
                    updateLocationSettings({ visible_white_list_dates: [] });
                  }
                }}
              >
                Restrict ordering to specific dates
              </Checkbox>
            </div>

            {visibleWhiteListEnabled && (
              <>
                <div>
                  {settingsState.visibleWhiteListDates.map((dateRange, i) => (
                    <DateRangeContainer key={i} className={"margin-bottom-1 padding-1"}>
                      <div>
                        <span className={"margin-right-4"}>
                          {`${DateTime.fromISO(dateRange?.start_date).toFormat("f")} - ${DateTime.fromISO(
                            dateRange?.end_date
                          ).toFormat("f")}`}
                        </span>
                        <i
                          className={"zmdi zmdi-edit clickable-icon margin-right-2"}
                          onClick={() => {
                            setShowAddEditDateRangeModal(true);
                            setEditDateRangeMode(true);
                            setSelectedDateRangeName({
                              frontendName: "visibleWhiteListDates",
                              backendName: "visible_white_list_dates",
                            });
                            setSelectedDateRange({
                              dateRange: dateRange,
                              index: i,
                            });
                          }}
                        />
                        <i
                          className={"zmdi zmdi-delete clickable-icon"}
                          onClick={() => {
                            let dateRanges = settingsState.visibleWhiteListDates;
                            dateRanges.splice(i, 1);
                            setSettingsState({
                              ...settingsState,
                              visibleWhiteListDates: dateRanges,
                            });

                            updateLocationSettings({
                              visible_white_list_dates: dateRanges,
                            });
                          }}
                        />
                      </div>
                      <div className={"notes"}>{dateRange?.notes}</div>
                    </DateRangeContainer>
                  ))}
                </div>
                <div>
                  <BbotButton
                    onClick={() => {
                      setShowAddEditDateRangeModal(true);
                      setEditDateRangeMode(false);
                      setSelectedDateRangeName({
                        frontendName: "visibleWhiteListDates",
                        backendName: "visible_white_list_dates",
                      });
                    }}
                  >
                    + Add date range
                  </BbotButton>
                </div>
              </>
            )}
          </div>

          <div className={"margin-y-2"}>
            <div className={"margin-bottom-1"}>
              <Checkbox
                checked={visibleBlackListEnabled}
                onChange={(e) => {
                  setVisibleBlackListEnabled(e.target.checked);
                  if (e.target.checked) {
                    // if checking black list box, clear array for white list
                    setVisibleWhiteListEnabled(false);
                    setSettingsState({
                      ...settingsState,
                      visibleWhiteListDates: [],
                    });
                    updateLocationSettings({ visible_white_list_dates: [] });
                  } else {
                    setSettingsState({
                      ...settingsState,
                      visibleBlackListDates: [],
                    });
                    updateLocationSettings({ visible_black_list_dates: [] });
                  }
                }}
              >
                Restrict ordering to exclude specific dates
              </Checkbox>
            </div>

            {visibleBlackListEnabled && (
              <>
                <div>
                  {settingsState.visibleBlackListDates.map((dateRange, i) => (
                    <DateRangeContainer key={i} className={"margin-bottom-1 padding-1"}>
                      <div>
                        <span className={"margin-right-4"}>
                          {`${DateTime.fromISO(dateRange.start_date).toFormat("f")} - ${DateTime.fromISO(
                            dateRange.end_date
                          ).toFormat("f")}`}
                        </span>
                        <i
                          className={"zmdi zmdi-edit clickable-icon margin-right-2"}
                          onClick={() => {
                            setShowAddEditDateRangeModal(true);
                            setEditDateRangeMode(true);
                            setSelectedDateRangeName({
                              frontendName: "visibleBlackListDates",
                              backendName: "visible_black_list_dates",
                            });
                            setSelectedDateRange({
                              dateRange: dateRange,
                              index: i,
                            });
                          }}
                        />
                        <i
                          className={"zmdi zmdi-delete clickable-icon"}
                          onClick={() => {
                            let dateRanges = settingsState.visibleBlackListDates;
                            dateRanges.splice(i, 1);
                            setSettingsState({
                              ...settingsState,
                              visibleBlackListDates: dateRanges,
                            });

                            updateLocationSettings({
                              visible_black_list_dates: dateRanges,
                            });
                          }}
                        />
                      </div>
                      <div className={"notes"}>{dateRange.notes}</div>
                    </DateRangeContainer>
                  ))}
                </div>
                <div>
                  <BbotButton
                    onClick={() => {
                      setShowAddEditDateRangeModal(true);
                      setEditDateRangeMode(false);
                      setSelectedDateRangeName({
                        frontendName: "visibleBlackListDates",
                        backendName: "visible_black_list_dates",
                      });
                    }}
                  >
                    + Add date range
                  </BbotButton>
                </div>
              </>
            )}
          </div>
        </DismissibleButton>
      </Card>

      <div className={"margin-bottom-2"}>
        <h4>Allowed Pickup/Delivery Schedule</h4>
      </div>
      <Card className={"settings-section-card"}>
        <div>
          <p>
            Use this scheduler to control the time blocks guests can pick from when they place in-advance orders. <br />
            You can type date ranges into the boxes directly under the weekday names, or you can click and drag on the
            scheduler itself. <br />
            To clear time blocks, click and drag starting on an already selected time block. You can also delete the
            time-input text at the top of each column.
          </p>
        </div>
        <div className={"margin-bottom-2"}>
          <DismissibleButton
            buttonText={"Edit for Selected Locations"}
            dismissedInitially={!bulkEditMode}
            onClickCallback={() => {
              updateLocationSettings({
                active_schedule: defaultLocationSettings.active_schedule,
              });
            }}
            onCancelCallback={() => {
              removeChanges(["active_schedule"]);
            }}
          >
            <Scheduler initialSchedule={defaultLocationSettings?.active_schedule} onChangeCallback={saveSchedule} />
          </DismissibleButton>
        </div>

        <div className={"margin-bottom-1"}>
          <h5>Restrict pickup/delivery dates</h5>
        </div>
        <DismissibleButton
          buttonText={"Edit for Selected Locations"}
          dismissedInitially={!bulkEditMode}
          onClickCallback={() => {
            updateLocationSettings({
              active_black_list_dates: defaultLocationSettings.active_schedule_black_list_dates,
              active_white_list_dates: defaultLocationSettings.active_schedule_white_list_dates,
            });
          }}
          onCancelCallback={() => {
            removeChanges(["active_black_list_dates", "active_white_list_dates"]);
          }}
        >
          <div className={"margin-bottom-2"}>
            <div className={"margin-bottom-1"}>
              <Checkbox
                checked={activeWhiteListEnabled}
                onChange={(e) => {
                  setActiveWhiteListEnabled(e.target.checked);
                  if (e.target.checked) {
                    // if checking black list box, clear array for white list
                    setActiveBlackListEnabled(false);
                    setSettingsState({
                      ...settingsState,
                      activeBlackListDates: [],
                    });
                    updateLocationSettings({ active_black_list_dates: [] });
                  } else {
                    setSettingsState({
                      ...settingsState,
                      activeWhiteListDates: [],
                    });
                    updateLocationSettings({ active_white_list_dates: [] });
                  }
                }}
              >
                Restrict delivery/pickup to specific dates
              </Checkbox>
            </div>
            {activeWhiteListEnabled && (
              <>
                <div>
                  {settingsState.activeWhiteListDates.map((dateRange, i) => (
                    <DateRangeContainer key={i} className={"margin-bottom-1 padding-1"}>
                      <div>
                        <span className={"margin-right-4"}>
                          {`${DateTime.fromISO(dateRange.start_date).toFormat("f")} - ${DateTime.fromISO(
                            dateRange.end_date
                          ).toFormat("f")}`}
                        </span>
                        <i
                          className={"zmdi zmdi-edit clickable-icon margin-right-2"}
                          onClick={() => {
                            setShowAddEditDateRangeModal(true);
                            setEditDateRangeMode(true);
                            setSelectedDateRangeName({
                              frontendName: "activeWhiteListDates",
                              backendName: "active_white_list_dates",
                            });
                            setSelectedDateRange({
                              dateRange: dateRange,
                              index: i,
                            });
                          }}
                        />
                        <i
                          className={"zmdi zmdi-delete clickable-icon"}
                          onClick={() => {
                            let dateRanges = settingsState.activeWhiteListDates;
                            dateRanges.splice(i, 1);
                            setSettingsState({
                              ...settingsState,
                              activeWhiteListDates: dateRanges,
                            });

                            updateLocationSettings({
                              active_white_list_dates: dateRanges,
                            });
                          }}
                        />
                      </div>
                      <div className={"notes"}>{dateRange.notes}</div>
                    </DateRangeContainer>
                  ))}
                </div>
                <div>
                  <BbotButton
                    variant={"secondary"}
                    onClick={() => {
                      setShowAddEditDateRangeModal(true);
                      setEditDateRangeMode(false);
                      setSelectedDateRangeName({
                        frontendName: "activeWhiteListDates",
                        backendName: "active_white_list_dates",
                      });
                    }}
                  >
                    + Add date range
                  </BbotButton>
                </div>
              </>
            )}
          </div>

          <div className={"margin-y-2"}>
            <div className={"margin-bottom-1"}>
              <Checkbox
                checked={activeBlackListEnabled}
                onChange={(e) => {
                  setActiveBlackListEnabled(e.target.checked);
                  if (e.target.checked) {
                    // if checking black list box, clear array for white list
                    setActiveWhiteListEnabled(false);
                    setSettingsState({
                      ...settingsState,
                      activeWhiteListDates: [],
                    });
                    updateLocationSettings({ active_white_list_dates: [] });
                  } else {
                    setSettingsState({
                      ...settingsState,
                      activeBlackListDates: [],
                    });
                    updateLocationSettings({ active_black_list_dates: [] });
                  }
                }}
              >
                Restrict delivery/pickup to exclude specific dates
              </Checkbox>
            </div>
            {activeBlackListEnabled && (
              <>
                <div>
                  {settingsState.activeBlackListDates.map((dateRange, i) => (
                    <DateRangeContainer key={i} className={"margin-bottom-1 padding-1"}>
                      <div>
                        <span className={"margin-right-4"}>
                          {`${DateTime.fromISO(dateRange.start_date).toFormat("f")} - ${DateTime.fromISO(
                            dateRange.end_date
                          ).toFormat("f")}`}
                        </span>
                        <i
                          className={"zmdi zmdi-edit clickable-icon margin-right-2"}
                          onClick={() => {
                            setShowAddEditDateRangeModal(true);
                            setEditDateRangeMode(true);
                            setSelectedDateRangeName({
                              frontendName: "activeBlackListDates",
                              backendName: "active_black_list_dates",
                            });
                            setSelectedDateRange({
                              dateRange: dateRange,
                              index: i,
                            });
                          }}
                        />
                        <i
                          className={"zmdi zmdi-delete clickable-icon"}
                          onClick={() => {
                            let dateRanges = settingsState.activeBlackListDates;
                            dateRanges.splice(i, 1);
                            setSettingsState({
                              ...settingsState,
                              activeBlackListDates: dateRanges,
                            });

                            updateLocationSettings({
                              active_black_list_dates: dateRanges,
                            });
                          }}
                        />
                      </div>
                      <div className={"notes"}>{dateRange.notes}</div>
                    </DateRangeContainer>
                  ))}
                </div>
                <div>
                  <BbotButton
                    onClick={() => {
                      setShowAddEditDateRangeModal(true);
                      setEditDateRangeMode(false);
                      setSelectedDateRangeName({
                        frontendName: "activeBlackListDates",
                        backendName: "active_black_list_dates",
                      });
                    }}
                  >
                    + Add date range
                  </BbotButton>
                </div>
              </>
            )}
          </div>
        </DismissibleButton>
      </Card>

      <AddEditDateRange
        show={showAddEditDateRangeModal}
        initialDateRange={selectedDateRange}
        onHideCallback={() => setShowAddEditDateRangeModal(false)}
        onSubmitCallback={(startDate, endDate, notes) => {
          setShowAddEditDateRangeModal(false);
          let dateRanges;
          if (editDateRangeMode) {
            // edit the date range at the right index
            dateRanges = settingsState[selectedDateRangeName.frontendName];
            const selectedIndex = selectedDateRange.index;
            dateRanges[selectedIndex] = {
              start_date: startDate,
              end_date: endDate,
              notes: notes,
            };
          } else {
            // add date range to the end of the
            dateRanges = settingsState[selectedDateRangeName.frontendName];
            dateRanges.push({
              start_date: startDate,
              end_date: endDate,
              notes: notes,
            });
          }
          setSettingsState({
            ...settingsState,
            [selectedDateRangeName.frontendName]: dateRanges,
          });

          updateLocationSettings({
            [selectedDateRangeName.backendName]: dateRanges,
          });
        }}
      />

      <AddEditRequiredCheckoutInfo
        show={showAddEditRCIModal}
        onHideCallback={() => {
          setShowAddEditRCIModal(false);
          setSelectedRCI(null);
        }}
        updatedLocationSettings={updatedLocationSettings}
        currentLocationSettings={currentLocationSettings}
        isDisabled={rciFieldDisabled(currentLocationSettings.allow_consumer_tabs, selectedRCI?.key || newRCIKey, [
          "name",
          "phone_number",
        ])}
        editMode={editRCIMode}
        onSubmitCallback={saveRequiredCheckoutInfo}
        removeRCI={removeRCI}
        requiredCheckoutInfo={selectedRCI}
        existingRCIKeys={Object.keys(requiredCheckoutInfo)}
        selectedLocationIds={selectedLocationIds}
        fulfillmentMethod={defaultLocationSettings.fulfillment_method}
        possibleFulfillmentMethods={defaultLocationSettings.possible_fulfillment_methods}
        newRCIKey={newRCIKey}
        existing
      />
    </div>
  );
};

const TabDescription = styled.div`
  max-width: 80%;
  font-weight: 300;
`;

const NewFieldSelect = styled(BbotSelect)`
  min-width: 140px;
`;

const DateRangeContainer = styled.div`
  border: 1px solid var(--color-neutral__line);
  .notes {
    color: var(--color-neutral__placeholder);
  }
`;

const StyledButton = styled.div`
  color: var(--color-neutral__label);
  background-color: var(--color-neutral__accent-background);
  border: 1px solid var(--color-neutral__line);

  outline: none;
  display: flex;
  width: 100%;
  padding: 15px 25px;
  align-items: center;
  transition-timing-function: ease-in-out;
  transition-property: background-color;
  transition-duration: 0.2s;

  > span {
    margin-left: 20px;
  }

  &:focus {
    outline: none;
  }
`;

export default CheckoutTab;
