import { Icon, Inset } from "@doordash/design-language";

const { Medium: insetFromEdge } = Inset.Sizes;
const { ExclamationCircleFill, CheckCircleFill } = Icon.Types;

const upsellsFailToast = (displayToast) =>
  displayToast({
    insetFromEdge,
    text: "Upsells not saved",
    icon: ExclamationCircleFill,
  });

const upsellsSuccessToast = (displayToast, text) =>
  displayToast({
    text,
    insetFromEdge,
    icon: CheckCircleFill,
  });

const toast = (displayToast, res, successMessage) => {
  if (res.data.success) {
    upsellsSuccessToast(displayToast, successMessage);
  } else {
    upsellsFailToast(displayToast);
  }
};

export const containsMenuHeadingId = (menuHeadings, selectedElement) =>
  Array.isArray(menuHeadings)
    ? menuHeadings.some(
        (e) => e.id === selectedElement || (typeof e === "object" && containsMenuHeadingId(e, selectedElement))
      )
    : menuHeadings && typeof menuHeadings === "object" && menuHeadings.id === selectedElement;

export const containsMenuItemId = (menus, targetId) => menus.some((menu) => menu.menuItemsIds.includes(targetId));

export const extractAllMenuItemsFromMenus = (menuArray) =>
  menuArray.flatMap((menu) =>
    menu.menuItemsIds.map((id, index) => ({ id, name: menu.menuItemsNames[index], selected: false }))
  );

export const updateSelected = (upsells, currentlySelectedUpsells) =>
  currentlySelectedUpsells.map((upsell) => (upsells.includes(upsell.id) ? { ...upsell, selected: true } : upsell));

export const toggleItem = (
  menuItem,
  currentlySelectedUpsells,
  setCurrentlySelectedUpsells,
  orderedUpsells,
  setOrderedUpsells
) => (
  setCurrentlySelectedUpsells(
    currentlySelectedUpsells.map((item) => (item.id === menuItem.id ? { ...item, selected: !item.selected } : item))
  ),
  orderedUpsells.some((item) => item.id === menuItem.id)
    ? setOrderedUpsells(orderedUpsells.filter((item) => item.id !== menuItem.id))
    : setOrderedUpsells([...orderedUpsells, currentlySelectedUpsells.find((item) => item.id === menuItem.id)])
);

export const getMenuHeadingsAndCustomerUpsells = async (menus, menuHeadings, axios, setmenuHeadings) => {
  if (menus?.length && menuHeadings?.length === 0) {
    const res = await axios.get("owner/menu_headings");
    setmenuHeadings(res.data.headings);
  }
};

export const getMenusAndItems = async (axios, setMenuItems, setMenus) => {
  const {
    data: { menus },
  } = await axios.get("owner/menus");
  setMenuItems(
    menus.map((menu) =>
      menu.menuItemsNames.map((name, index) => ({
        name,
        id: menu.menuItemsIds[index],
        imageUrl: menu.menuItemsImageUrls[index],
        upsells: menu && menu.upsells && menu.upsells.length > 0 ? menu.upsells[index] : [],
      }))
    )
  );
  setMenus(menus);
};

export const elementTypes = {
  menuHeading: "menuHeading",
  menuItem: "menuItem",
  customer: "customer",
};

const updatePathAndMessagePerElementType = (menuHeadings, menus, selectedElement) => ({
  [elementTypes.menuHeading]: {
    path: "api/editMenuHeading",
    successMessage: `Upsells for ${
      menuHeadings.find((heading) => heading.id === selectedElement)?.heading_name || "heading"
    } saved`,
  },
  [elementTypes.menuItem]: {
    path: "api/ownerUpdateItem",
    successMessage: `Upsells for ${
      extractAllMenuItemsFromMenus(menus).find((item) => item.id === selectedElement)?.name || "menu item"
    } saved`,
  },
  [elementTypes.customer]: {
    path: "admin/editOwnerProperties",
    successMessage: "Cart-level upsells for customer saved",
  },
});

export const updateUpsells = async (displayToast, axios, menuHeadings, menus, selectedElement, elementType, params) => {
  const { path, successMessage } = updatePathAndMessagePerElementType(menuHeadings, menus, selectedElement)[
    elementType
  ];
  const res = await axios.post(path, params);
  toast(displayToast, res, successMessage);
  setTimeout(() => {
    window.location.reload();
  }, 500);
};

export const setSelectedElementAndCurrentlySelectedUpsells = (
  id,
  setSelectedElement,
  setCurrentlySelectedUpsells,
  menus,
  setOrderedUpsells
) => {
  setSelectedElement(id);
  setCurrentlySelectedUpsells(extractAllMenuItemsFromMenus(menus));
  setOrderedUpsells([]);
};

export const findUpsells = (selectedElement, menus) =>
  menus.flatMap((menu) => menu.menuItemsUpsells).find((item) => item.id === selectedElement)?.upsells || null;

export const removeDashesFromUUIDS = (orderedUpsells) => orderedUpsells.map((item) => item.id.replace(/-/g, ""));
