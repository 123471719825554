import axios from "axios";
import { useEffect, useState } from "react";

// Components
import { Col, Container, Row } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import DashboardLink from "./DashboardLink";

// Styles
import "./dashboard.scss";

// Config
import BBOT_SERVER from "Config";
import IncompleteJourneyModal from "components/owner-app/modals/incomplete-journey-modal/IncompleteJourneyModal";
import { BbotAlert } from "top-component-library";
import { generalErrorAlert, isFeatureEnabledById } from "util/Utils";
import { DOVETAIL_ANNOUNCEMENTS } from "./dovetail";
import { removeDeprecatedLinks } from "./utils";

const OwnerAppDashboard = (props) => {
  const [journeys, setJourneys] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const linksToDisplay =
    "Upsells,Menus,Available Hours + Menu Settings,Handheld Devices,Presentation to Guests,Text Message Management,Payouts and Billing,Past Orders,Reports,Account Settings,Promos + Discounts,Service Charges,Tip Settings,Employees,Tables / Location Codes,Kiosk Configuration Settings,Printer Configuration,Support Text Alerts,Guest Notification Sets,Order Grouping,System Logs,Service Requests,BOS Settings,DoorDash App Store,Device Profiles,Stations,Customer Properties,Feature Management,PreBot,Admin Portal,Manage Partners,Sales Rep Commissions,Cut Agreements,Card Processing Rates,Owner Panel Announcements,PCB Management,Cut Agreements,Card Processing Rates";
  const {
    MENU_LINK_CONFIG,
    SALES_LINK_CONFIG,
    OWNER_LINK_CONFIG,
    INTEGRATION_LINK_CONFIG,
    ADMIN_LINK_CONFIG,
    SUPER_ADMIN_LINK_CONFIG,
    TOP_ADMIN_ONLY_LINKS,
  } = removeDeprecatedLinks(linksToDisplay);

  const {
    allowedCustomers,
    selectedCustomer,
    selectAllowedCustomer,
    userInfo,
    history,
    linksWhitelist,
    match: {
      params: { selectedCustomerId: customerIdFromUrl },
    },
    isSelectedCustomerFlaggedForDovetail,
  } = props;

  const userIsAdmin = userInfo?.role === "admin";
  const userIsBbotSuperAdmin = userInfo?.permissions?.includes("manage_admins") && userInfo?.teams?.includes("bbot");
  const userIsBbotAdmin = userInfo?.teams?.includes("bbot");

  /**
   * If there's a customer id in the url, try to select that customer
   */
  useEffect(() => {
    if (customerIdFromUrl && allowedCustomers.length) {
      selectAllowedCustomer(customerIdFromUrl);
    }
  }, [customerIdFromUrl, allowedCustomers]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Get the announcements and journeys when user changes selected customer
   */
  useEffect(() => {
    const getJourneys = async () => {
      try {
        const res = await axios.get("/api/journey/getJourneys");
        setJourneys(res.data.journeys);
      } catch (error) {
        console.error(error);
        generalErrorAlert(error, "Couldn't load account information.");
      }
    };

    const getAnnouncements = async () => {
      try {
        const res = await axios.get("/owner/getAnnouncements?customerId=" + selectedCustomer?.customer_id);
        const dovetailAnnouncements = isSelectedCustomerFlaggedForDovetail ? DOVETAIL_ANNOUNCEMENTS : [];
        setAnnouncements([...res.data.announcements, ...dovetailAnnouncements]);
      } catch (error) {
        generalErrorAlert(error, "Error Retrieving Announcements");
      }
    };

    getJourneys();

    if (selectedCustomer?.customer_id) {
      getAnnouncements();
    }
  }, [selectedCustomer]);

  /**
   * Redirect to journey page if user has access to 1 account and that account is not set up yet
   */
  useEffect(() => {
    const incompleteJourneys = journeys.filter((journey) => journey.tasks_completed < journey.total_tasks);

    if (userInfo && userInfo.role !== "admin" && allowedCustomers.length === 1 && incompleteJourneys.length > 0) {
      const path = incompleteJourneys[0];
      history.push(`/journey/${path.id}`);
    } else if (
      userInfo &&
      userInfo.role !== "admin" &&
      allowedCustomers.length > 1 && // If the user is multi vendor
      journeys.length && // Make sure journeys have actually been loaded, and it's not just initial state
      incompleteJourneys.length === journeys.length // If the user has NO accounts set up yet, so # of incomplete journeys === # of total journeys
    ) {
      history.push("/myjourneys");
    }
  }, [journeys, allowedCustomers, userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeAnnouncement = async (announcement_id) => {
    if (announcement_id) {
      const payload = {
        announcement: announcement_id,
      };

      try {
        await axios.post("/owner/closeAnnouncement?userId=" + userInfo.id, payload);
      } catch (error) {
        generalErrorAlert(error, "Error Closing Announcement");
      }
    }
  };

  const renderDashboardLinks = (links, isSuperAdmin = false) => {
    const redirectUrlAppendix = userIsAdmin ? BBOT_SERVER + "/superuser/console" : BBOT_SERVER + "/owner/console";
    const filteredLinks = links.filter(
      (link) =>
        !(link.featureId && !isFeatureEnabledById(link.featureId, selectedCustomer)) &&
        (!link.shouldCheckLinkWhitelist || (link.shouldCheckLinkWhitelist && linksWhitelist.includes(link.link)))
    );

    return filteredLinks.map((link, index) => {
      return (
        <DashboardLink
          link={link}
          index={index}
          redirectUrlAppendix={redirectUrlAppendix}
          isSuperAdmin={isSuperAdmin}
          isCurrentOwnerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
          selectedCustomer={selectedCustomer}
        />
      );
    });
  };

  const relevantAnnouncements =
    announcements?.sort((announcement_a, announcement_b) =>
      announcement_a.start_date < announcement_b.start_date
        ? 1
        : announcement_a.start_date > announcement_b.start_date
        ? -1
        : 0
    ) || [];

  return (
    <div>
      <Container className="dashboard-container">
        {relevantAnnouncements.map((announcement, index) => (
          <div key={"dashboard-alert-" + index} className="margin-bottom-3">
            <BbotAlert
              className="margin-bottom-1"
              message={
                <div
                  dangerouslySetInnerHTML={{
                    __html: announcement.header,
                  }}
                />
              }
              description={
                <div
                  dangerouslySetInnerHTML={{
                    __html: announcement.body,
                  }}
                />
              }
              type="info"
              showIcon
              closable
              onClose={() => closeAnnouncement(announcement["id"])}
            />
          </div>
        ))}
        <Row className={"margin-bottom-6"}>
          <Col>
            <h2>
              Welcome
              {selectedCustomer ? (
                <>
                  {" to "}
                  <span className={"color-primary__dark"}>{selectedCustomer.customer_name}</span>
                </>
              ) : (
                <></>
              )}
            </h2>
          </Col>
        </Row>
        <Row className="links-row">
          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined link-icon dashboard-link-icon"}>menu_book</span>
              <span className="dashboard-menu-title">Menu Management</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{renderDashboardLinks(MENU_LINK_CONFIG)}</div>
          </Col>
          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined link-icon dashboard-link-icon"}>savings</span>
              <span className="dashboard-menu-title">Financials</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{renderDashboardLinks(SALES_LINK_CONFIG)}</div>
          </Col>

          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined link-icon dashboard-link-icon"}>restaurant</span>
              <span className="dashboard-menu-title">Restaurant Setup</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>
              {renderDashboardLinks(OWNER_LINK_CONFIG)}
              {!userIsAdmin && journeys.length > 0 && (
                <div key={"dashboard-link-wizard"} className="dashboard-link">
                  <Link to={"/myjourneys"}>
                    <div className={"dashboard-link-container"}>
                      <span className={"material-icons-outlined link-icon"}>rocket_launch</span>
                      <span>PreBot</span>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </Col>

          <Col lg={4} sm={6} className="menu-column">
            <div className={"heading-container"}>
              <span className={"material-icons-outlined link-icon dashboard-link-icon"}>integration_instructions</span>
              <span className="dashboard-menu-title">Integrations</span>
            </div>

            <hr className={"bg-grey-12"} />

            <div className={"margin-left-1"}>{renderDashboardLinks(INTEGRATION_LINK_CONFIG)}</div>
          </Col>

          {userIsAdmin && (
            <Col lg={4} sm={6} className="menu-column">
              <div className={"heading-container"}>
                <span className={"material-icons-outlined link-icon dashboard-link-icon"}>admin_panel_settings</span>
                <span className="dashboard-menu-title">Admin</span>
              </div>

              <hr className={"bg-grey-12"} />

              <div className={"margin-left-1"}>
                {renderDashboardLinks(ADMIN_LINK_CONFIG)}
                {userIsBbotAdmin && !userIsBbotSuperAdmin && renderDashboardLinks(TOP_ADMIN_ONLY_LINKS)}
                {userIsBbotSuperAdmin && renderDashboardLinks(SUPER_ADMIN_LINK_CONFIG, userIsBbotSuperAdmin)}
              </div>
            </Col>
          )}
        </Row>
      </Container>
      <IncompleteJourneyModal
        allowedCustomers={allowedCustomers}
        selectedCustomer={selectedCustomer}
        selectAllowedCustomer={selectAllowedCustomer}
        journeys={journeys}
        userInfo={userInfo}
      />
    </div>
  );
};

export default withRouter(OwnerAppDashboard);
