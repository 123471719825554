import axios from "axios";

export const getDeviceProfiles = async (setDeviceProfiles) => {
  try {
    const res = await axios.get("/api/ownerconfigs");
    setDeviceProfiles(res.data.configs);
    return;
  } catch (error) {
    console.error(error, "Trouble getting handheld devices. Please refresh and try again.");
  }
};

export const editDeviceProfile = async ({ id, field, value, customer_id, setDeviceProfiles }) => {
  try {
    const payload = {
      id,
      customer_id,
      [field]: value,
    };
    await axios.post("/admin/editDeviceProfile", payload);
    getDeviceProfiles(setDeviceProfiles);
  } catch (error) {
    console.error(error, "Trouble editing device profile. Please refresh and try again.");
  }
};
