import * as React from "react";
import { Text, InlineChildren, Spacing, Colors } from "@doordash/design-language";
import { DashboardCell } from "./styles";
import Separator from "@doordash/component-separator";
import { dashboardCellNames } from "./constants";
import { generateUUIDv4 } from "util/Utils";

const Stats: React.FC = () => (
  <React.Fragment>
    <Text styles={Text.Styles.PageTitle2}>Restaurant Name</Text>
    <Text styles={Text.Styles.FieldMediumInput}>Restaurant Website</Text>
    <br />
    <InlineChildren>
      {dashboardCellNames.map((cell) => (
        <DashboardCell key={generateUUIDv4()}>
          <Text color={Colors.SystemWhite} styles={Text.Styles.NavBarCollapsedTitle}>
            {cell.name}
          </Text>
          <Text color={Colors.SystemWhite} styles={Text.Styles.Label1Emphasis}>
            {cell.value}
          </Text>
        </DashboardCell>
      ))}
    </InlineChildren>
    <br />
    <Separator type={Separator.Types.InsetBorder} inset={Spacing.Medium} />
  </React.Fragment>
);

export default Stats;
