import PropTypes from "prop-types";
import styled from "styled-components";
import { Checkbox, Form } from "top-component-library";
/*
Generic checkbox input
 */
const CheckboxInput = ({ id, label = "", name = "", defaultChecked = false, ...props }) => {
  return (
    <Form.Item name={name} valuePropName={"checked"}>
      <StyledCheckbox id={id} {...props}>
        {label}
      </StyledCheckbox>
    </Form.Item>
  );
};

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: 4px;
    border-color: var(--color-primary__regular);
  }
  &.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-primary__regular);
    &&:disabled {
      background-color: var(--color-neutral__placeholder);
      border-color: var(--color-neutral__placeholder);
    }
  }
`;

export default CheckboxInput;

CheckboxInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
};
