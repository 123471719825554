import axios from "axios";
import PcbSpecialCommandsCard from "components/owner-app/cards/pcb-special-commands-card/PcbSpecialCommandsCard";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { Accordion, Button, Card, Col, Container, Modal, Row, useAccordionToggle } from "react-bootstrap";
import { toast } from "react-toastify";
import { BbotButton } from "top-component-library";
import { generalErrorAlert } from "util/Utils";
import * as Yup from "yup";

function EditPCBModal({ showEditPCBModal, selectedPCB, onHideCallback, onSaveCallback, userInfo }) {
  const saveConnections = async (connections) => {
    try {
      const payload = {
        boxId: selectedPCB.id,
        newConnections: connections,
      };
      await axios.post("/printer-setup/save-wifi-credentials", payload);
      onSaveCallback();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  const saveWifi = async (values, index, newCreds = false) => {
    let { connections } = selectedPCB.wifi_credentials;

    if (newCreds) {
      connections.push({
        ssid: values.ssid,
        password: values.password,
        hidden: values.hidden,
      });
    } else {
      connections[index] = {
        ssid: values.ssid,
        password: values.password,
        hidden: values.hidden,
      };
    }
    await saveConnections(connections);
  };

  const deleteConnection = async (index) => {
    let { connections } = selectedPCB?.wifi_credentials;
    connections.splice(index, 1);
    await saveConnections(connections);
  };

  function DeleteToggle({ children, eventKey, index }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => deleteConnection(index));

    return (
      <i className={"zmdi zmdi-delete zmdi-hc-2x clickable-icon"} onClick={decoratedOnClick}>
        {children}
      </i>
    );
  }

  const disconnectPCB = async (printerControlBox) => {
    try {
      const payload = { action: "remove" };
      await axios.put("/printer-setup/control-box/" + printerControlBox.id, payload);
      onSaveCallback();
      onHideCallback();
      toast.success("Disconnected printer control box: " + printerControlBox.name);
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  const wifiAccordion = () => {
    return (
      <Accordion>
        {selectedPCB &&
          selectedPCB.wifi_credentials.connections.map((connection, index) => {
            return connection.ssid === "printer" || connection.ssid === "printers" ? (
              <div key={index}></div>
            ) : (
              <Card className={"margin-bottom-1"} key={index}>
                <Card.Header>
                  <span>{connection.ssid}</span>
                  <span className={"right-actions"}>
                    <Accordion.Toggle className={"margin-right-1"} as={Button} variant="link" eventKey={index}>
                      view/edit
                    </Accordion.Toggle>
                    <DeleteToggle index={index} />
                  </span>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body>
                    <Formik
                      initialValues={{
                        ssid: connection.ssid,
                        password: connection.password,
                        hidden: connection.hidden,
                      }}
                      validationSchema={Yup.object({
                        ssid: Yup.string(),
                        password: Yup.string(),
                        hidden: Yup.bool(),
                      })}
                      onSubmit={(values) => saveWifi(values, index)}
                    >
                      {({ values, errors, touched, isValidating }) => (
                        <Form className={"styled-form"}>
                          <div className="field-wrapper margin-bottom-2">
                            <div className={"field-label"}>Wifi name</div>
                            <Field id={"ssid-" + connection.ssid} name="ssid" type="text" />
                            {errors.ssid && <div className="error">{errors.ssid}</div>}
                          </div>
                          <div className="field-wrapper margin-bottom-2">
                            <div className={"field-label"}>Password</div>
                            <Field id={"password-" + connection.ssid} name="password" type="text" />
                            {errors.password && <div className="error">{errors.password}</div>}
                          </div>
                          <div className="field-wrapper margin-bottom-2">
                            <label className="d-flex align-items-center" key={index}>
                              <Field id={"hidden-" + index} name="hidden" type="checkbox" className={"margin-top-1"} />
                              <p className={"margin-top-1 margin-bottom-0"}>
                                Disable SSID broadcast (hide this connection from public)
                              </p>
                            </label>
                          </div>
                          <Button type={"submit"}>Save</Button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        <Card className={"margin-bottom-1"}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey={"new"}>
              Add A New Set Of Wifi Credentials
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={"new"}>
            <Card.Body>
              <Formik
                initialValues={{
                  ssid: "",
                  password: "",
                  hidden: "",
                }}
                validationSchema={Yup.object({
                  ssid: Yup.string(),
                  password: Yup.string(),
                  hidden: Yup.bool(),
                })}
                onSubmit={(values, actions) => {
                  saveWifi(values, 0, true);
                  actions.resetForm();
                  // COLLAPSE
                }}
              >
                {({ values, errors, actions, touched, isValidating, handleSubmit }) => (
                  <Form onSubmit={handleSubmit} className={"styled-form"}>
                    <div className="field-wrapper margin-bottom-2">
                      <div className={"field-label"}>Wifi name</div>
                      <Field id={"ssid"} name="ssid" type="text" />
                      {errors.ssid && <div className="error">{errors.ssid}</div>}
                    </div>
                    <div className="field-wrapper margin-bottom-2">
                      <div className={"field-label"}>Password</div>
                      <Field id={"password"} name="password" type="text" />
                      {errors.password && <div className="error">{errors.password}</div>}
                    </div>
                    <div className="field-wrapper margin-bottom-2">
                      <label className="d-flex align-items-center">
                        <Field id={"hidden"} name="hidden" type="checkbox" className={"margin-top-1"} />
                        <p className={"margin-top-1 margin-bottom-0"}>
                          Disable SSID broadcast (hide this connection from public)
                        </p>
                      </label>
                    </div>

                    <Accordion.Toggle as={Button} eventKey={"new"} type={"submit"}>
                      Save
                    </Accordion.Toggle>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  return (
    <Modal className={"modal modal-extra-large"} size={"xl"} show={showEditPCBModal} onHide={onHideCallback}>
      <Modal.Header closeButton>
        <Modal.Title>{selectedPCB?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={"styled-form"}>
          <Container className={"padding-x-0 margin-y-0"}>
            {userInfo?.role === "admin" && (
              <div style={{ marginBottom: 20 }}>
                <h5>Box ID: {selectedPCB?.id}</h5>
                <h5>Version: {selectedPCB?.current_software_version_id}</h5>
                <h5>SSH Username: {selectedPCB?.ssh_username}</h5>
                <h5>SSH Port: {selectedPCB?.ssh_port}</h5>
              </div>
            )}
            <Row className={"margin-bottom-2"}>
              <Col sm={12} className={"margin-bottom-3"}>
                <div>
                  <h4>Wifi Properties</h4>
                </div>
                <div>
                  <p>
                    Please note that the wifi settings only apply to printers that are connected via wifi. Most printers
                    are connected via ethernet, and changing the wifi credentials won't make any difference. Each box
                    comes default with "printer" and "printers" connections.
                    <b>These are hidden from this list.</b> All other currently set up wifi settings will be displayed
                    below.
                  </p>
                </div>
              </Col>
              <Col sm={12}>{wifiAccordion()}</Col>
              {userInfo?.role === "admin" && selectedPCB?.current_software_version_id >= 10 && (
                <PcbSpecialCommandsCard selectedPCB={selectedPCB} userInfo={userInfo} />
              )}
            </Row>
            <Row>
              <BbotButton
                style={{ marginLeft: 15 }}
                id={"disconnect-pcb-button"}
                type={"primary"}
                danger
                onClick={() => disconnectPCB(selectedPCB)}
              >
                Disconnect PCB
              </BbotButton>
            </Row>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

EditPCBModal.propTypes = {
  showEditPCBModal: PropTypes.bool.isRequired,
  onHideCallback: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  selectedPCB: PropTypes.object,
  userInfo: PropTypes.object,
};

export default EditPCBModal;
