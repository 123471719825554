export const dashboardCellNames = [
  { name: "Total Items Delivered", value: "0" },
  { name: "Items Delivered (last 7 days)", value: "0" },
  { name: "Sales (last 7 days)", value: "$0.00" },
  { name: "Tips (last 7 days)", value: "$0.00" },
];

export const menuNames = ["menus", "menu_headings", "menu_items", "modifier_groups", "modifiers", "tags"];

export type MenuKey = typeof menuNames[number];

export type ModalProps = {
  primaryActionProps: any;
  secondaryActionProps: any;
  fields: any;
  updateForm: any;
  updateFormField?: any;
  setOpenModal: (open: boolean) => void;
  onClose: () => void;
};

export enum MenusTableKeys {
  DESCRIPTION = "description",
  ENABLED = "enabled",
  HEADING = "heading",
  ICON = "icon",
  IN_STOCK = "in_stock",
  MENU = "menu",
  MENUS = "menus",
  MENU_HEADINGS = "menu_headings",
  MENU_HEADING_NAME = "menu_heading_name",
  MENU_ITEMS = "menu_items",
  MENU_NAME = "menu_name",
  MODIFIERS = "modifiers",
  MODIFIER_GROUP = "modifier_group",
  MODIFIER_GROUPS = "modifier_groups",
  MODIFIER_GROUP_NAME = "modifier_group_name",
  MODIFIER_NAME = "modifier_name",
  NAME_FOR_OWNER = "name_for_owner",
  PRE_SELECTED_ITEMS = "pre_selected_items",
  PRICE = "price",
  REPORT_CATEGORY = "report",
  SCHEDULE = "schedule",
  SELECTABLE_MODIFIERS = "selectable_modifiers",
  SORT_ITEMS = "sort_items",
  SORT_MODIFIERS = "sort_modifiers",
  TAGS = "tags",
  TAG_NAME = "tag_name",
}
