import styled from "styled-components";
import { Collapse } from "top-component-library";

const BbotAccordion = ({ ...props }) => {
  return (
    <StyledAccordion
      accordion
      bordered={false}
      expandIconPosition={"right"}
      expandIcon={(props) => {
        return props.isActive ? (
          <StyledIcon className="zmdi zmdi-long-arrow-down" />
        ) : (
          <StyledIcon className="zmdi zmdi-arrow-right" />
        );
      }}
      {...props}
    />
  );
};

const StyledAccordion = styled(Collapse)`
  background-color: #ffffff;
  .ant-collapse-header {
    font-weight: bold;
  }

  &&&.ant-collapse-borderless > .ant-collapse-item:first-child {
    border-top: 1px solid #d9d9d9;
  }
`;

const StyledIcon = styled.i`
  font-weight: bold;
`;

export default BbotAccordion;
