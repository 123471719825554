export enum DynamicValueNames {
  orderNowPayLater = "order-now-pay-later",
}

export const DYNAMIC_VALUE_DEFAULTS: Record<DynamicValueNames, any> = {
  [DynamicValueNames.orderNowPayLater]: false,
};

export enum NumberDynamicValueNames {
  CurieDimensionAnalysisMaxLimit = "curie_dimension_analysis_max_limit",
}

export const NUMBER_DYNAMIC_VALUE_DEFAULTS = {
  [NumberDynamicValueNames.CurieDimensionAnalysisMaxLimit]: 5,
};

export enum StringDynamicValueNames {
  MhubCarouselCampaignPlacementMigration = "mhub_carousel-campaign-placement-migration",
}

export const STRING_DYNAMIC_VALUE_DEFAULTS = {
  [StringDynamicValueNames.MhubCarouselCampaignPlacementMigration]: "control",
};
