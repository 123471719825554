import { Colors, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
import styled from "styled-components";

export const DashboardCell = styled(StackChildren)`
  padding: ${Spacing.XSmall}px ${Spacing.Large}px;
  margin-right: ${Spacing.Large}px;
  background-color: ${Colors.MapCurrentLocationBackgroundDefault};
  border-radius: ${Spacing.XxxxSmall}px;
  color: ${Colors.SystemWhite};
`;

export const MenuName = styled(Text).attrs({
  styles: Text.Styles.ButtonMediumTitle,
})`
  text-transform: uppercase;
  padding: ${Spacing.XxxSmall}px;
  color: ${(props: { selected: boolean }) =>
    props.selected ? Colors.MapCurrentLocationBackgroundDefault : Colors.SystemBlack};
`;

export const SubtitleWrapper = styled(InlineChildren)`
  display: flex;
  justify-content: space-between;
`;
