import { Card } from "antd";
import PropTypes from "prop-types";
import CheckboxGroupInput from "top-component-library/form-inputs/CheckboxGroupInput";
import { verticalOptions } from "util/Utils";
/*
Formatted checkbox input that displays options in vertical columns within a Card. Content passed in the body will be
displayed within the card above the checkboxes
 */
const FormattedCheckboxGroupInput = ({
  id,
  label = "",
  options,
  name,
  onChange,
  required = false,
  validationMessage,
  columns = 2,
  titleType = "inner",
  rules = [
    {
      required: required,
      message: validationMessage || "This is a required field.",
    },
  ],
  ...props
}) => {
  // Sort objects to be displayed in vertical columns
  const sortedOptions = verticalOptions(
    options.sort((ob1, ob2) => (ob1.label.toLowerCase() > ob2.label.toLowerCase() ? 1 : -1)),
    columns
  );
  // Render
  return (
    <Card title={label} type={titleType} {...props}>
      {props.children}
      <CheckboxGroupInput
        onChange={onChange}
        name={name}
        id={id}
        options={sortedOptions}
        numColumns={columns}
        required={required}
        rules={rules}
        {...props}
      />
    </Card>
  );
};

export default FormattedCheckboxGroupInput;

FormattedCheckboxGroupInput.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // An array of objects, each object should have a 'label' and 'value' key
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validationMessage: PropTypes.string,
  columns: PropTypes.number, // Number of columns in which to display the checkboxes
  rules: PropTypes.array, // Validation rules
};
