import axios from "axios";
import { TextInput } from "components/global/form";
import "components/owner-app/modals/printer-configuration/NetworkConfigModals.scss";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { generalErrorAlert } from "util/Utils";

function AddPCBModal(props) {
  const { showAddPCBModal, network, customerId, customerName, userInfo } = props;

  const [, setControlBoxIdInput] = useState("");
  const [controlBoxes, setControlBoxes] = useState([]);
  const [selectedControlBox, setSelectedControlBox] = useState(null);

  const saveNewPCB = async (controlBoxName) => {
    try {
      if (!selectedControlBox) {
        toast.error("There is no printer control box selected. Please complete step one.");
        return;
      } else if (!controlBoxName) {
        toast.error("Please provide a name for the new printer control box.");
        return;
      }

      const payload = {
        boxName: controlBoxName,
        network_id: network.id,
        customer_id: customerId,
        action: "assign",
      };
      await axios.put("/printer-setup/control-box/" + selectedControlBox.id, payload);
      toast.success("Connected to Printer Control Box: " + controlBoxName);
      props.onSaveCallback();
      setControlBoxIdInput("");
      setControlBoxes([]);
      setSelectedControlBox(null);
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  const searchControlBoxes = async (controlBoxIdSearch) => {
    try {
      if (userInfo?.role !== "admin" && controlBoxIdSearch.length < 6) {
        toast.error("Please input the first six characters of the control box's id.");
        return;
      }
      const res = await axios.get("/printer-setup/control-box/" + controlBoxIdSearch, {});
      setControlBoxes(res.data.control_boxes);
      if (res.data.control_boxes.length === 1) {
        setSelectedControlBox(res.data.control_boxes[0]);
      }
    } catch (error) {
      toast.error("Trouble connecting to the server. Please refresh the page.");
      console.error(error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        pcbIdSearch: "",
        pcbName: customerName + " box",
      }}
    >
      {({ values, errors, touched, isValidating, setFieldValue }) => (
        <Modal show={showAddPCBModal} onHide={props.onHideCallback}>
          <Modal.Header closeButton>
            <Modal.Title>Add a new Printer Control Box</Modal.Title>
          </Modal.Header>
          <div className={"modal-banner"}>
            <h6>To connect a new Printer Control Box, fill out the fields below and press Save.</h6>
          </div>
          <Modal.Body>
            <Form>
              <div className={"styled-form"}>
                <div className={"margin-bottom-2"}>
                  <div>
                    <h5>Step 1: Find your Printer Control Box within TOP</h5>
                  </div>
                  <Row>
                    <TextInput
                      label={
                        userInfo?.role === "admin"
                          ? "Search for the control box's id."
                          : "Enter first 6 characters of control box's id"
                      }
                      name={"pcbIdSearch"}
                      id={"pcb-id-search"}
                      placeholder={"Printer control box serial number"}
                      sm={12}
                    />
                  </Row>

                  <div>
                    <Button
                      variant={"secondary"}
                      className={"margin-y-1"}
                      onClick={() => searchControlBoxes(values.pcbIdSearch)}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    {controlBoxes.length === 1 && <p>Control box found: </p>}
                    {controlBoxes.length > 1 && <p>Select a control box from the options below:</p>}
                  </div>
                  {controlBoxes.map((controlBox, index) => {
                    const selectedClassName = controlBox.id === selectedControlBox?.id ? "selected" : "";
                    return (
                      <div
                        className={"control-box margin-bottom-1 margin-x-2 " + selectedClassName}
                        key={index}
                        onClick={() => setSelectedControlBox(controlBox)}
                      >
                        <span>{controlBox.name}</span>
                        <span>{controlBox.id}</span>
                      </div>
                    );
                  })}
                </div>
                <div className={"margin-bottom-2 field-wrapper"}>
                  <div>
                    <div>
                      <h5>Step 2: Name your Printer Control Box</h5>
                    </div>
                    <Row>
                      <TextInput label={"Control Box Name"} name={"pcbName"} id={"pcb-name"} placeholder={"Name"} />
                    </Row>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              onClick={() => {
                saveNewPCB(values.pcbName);
                setFieldValue("pcbIdSearch", "");
                setFieldValue("pcbName", customerName + " box");
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

AddPCBModal.propTypes = {
  onHideCallback: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  showAddPCBModal: PropTypes.bool.isRequired,
  customerId: PropTypes.string,
  customerName: PropTypes.string,
};
export default AddPCBModal;
