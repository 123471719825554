import styled from "styled-components";
import { Form, Switch } from "top-component-library";

const ToggleInput = ({ id, label = "", name = "", ...props }) => {
  return (
    <Form.Item id={id} label={label} name={name} valuePropName={"checked"}>
      <StyledSwitch {...props} />
    </Form.Item>
  );
};

const StyledSwitch = styled(Switch)`
  &&&.ant-switch-checked {
    background-color: var(--color-primary__regular);
  }
`;

export default ToggleInput;
