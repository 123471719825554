import {
  trackClickIntegrationCardDisconnect,
  trackClickIntegrationCardEdit,
} from "instrumentation/tracking/page-tracking-events";
import styled from "styled-components";
import { BbotButton, BbotTag, Card, Col, Row } from "top-component-library";

const AppStoreCard = ({
  id,
  integrationKey,
  title,
  description,
  logo,
  connected,
  connectButtonText,
  disconnectAction,
  connectAction,
  bbotAdminOnly = false,
}) => {
  const actionsList = connected
    ? [
        <BbotButton
          type={"default"}
          onClick={() => {
            trackClickIntegrationCardDisconnect({ id, title });
            disconnectAction();
          }}
          data-test-id={`disconnect-${integrationKey}`}
        >
          Disconnect
        </BbotButton>,
        <BbotButton
          type={"primary"}
          onClick={() => {
            trackClickIntegrationCardEdit({ id, title });
            connectAction();
          }}
          data-test-id={`edit-${integrationKey}`}
        >
          Edit
        </BbotButton>,
      ]
    : [
        <BbotButton
          type={"primary"}
          onClick={() => {
            trackClickIntegrationCardEdit({ id, title });
            connectAction();
          }}
          data-test-id={`connect-${integrationKey}`}
        >
          {connectButtonText}
        </BbotButton>,
      ];

  return (
    <StyledCard id={id} actions={actionsList} className={"margin-x-1"}>
      <Row>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={{ span: 16 }}>
          <h4 className={"margin-bottom-1"}>
            {title} {bbotAdminOnly && <BbotTag>TOP Admin Only</BbotTag>}
          </h4>
          <p>{description}</p>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }}>
          <img src={logo} alt={"Icon"} width={70} height={70} />
        </Col>
      </Row>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  &&&.ant-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-card-actions {
    background-color: var(--color-neutral__accent-background);
    width: 100%;
    li:first-child:nth-last-child(1) {
      text-align: center;
    }

    > li {
      border-right: none;
      text-align: right;
      padding-right: 10px;
    }

    > li:not(:last-child) {
      text-align: left;
      padding-left: 10px;
    }
  }
`;

export default AppStoreCard;
