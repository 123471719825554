import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import HandheldConfigsCard from "components/owner-app/cards/printer-configuration/HandheldConfigsCard";
import PrinterControlBoxesCard from "components/owner-app/cards/printer-configuration/PrinterControlBoxesCard";
import PrintersCard from "components/owner-app/cards/printer-configuration/PrintersCard";
import AddEditNetworkModal from "components/owner-app/modals/printer-configuration/AddEditNetworkModal";

import BbotCard from "components/owner-app/cards/BbotCard";
import styled from "styled-components";
import { BbotButton } from "top-component-library";

const NetworkCard = ({
  network,
  availableCustomerHandheldConfigs,
  stations,
  selectedCustomer,
  userInfo,
  driverProfiles,
  onSaveCallback,
}) => {
  const [showAddEditNetworkModal, setShowAddEditNetworkModal] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const onManagingCustomer = network.managing_customer_id === selectedCustomer.customer_id;

  const printers = onManagingCustomer
    ? network?.printers
    : network?.printers.filter((printer) => printer.customer.customer_id === selectedCustomer.customer_id);

  const filterFunction = (data) => {
    return onManagingCustomer ? data : data.filter((item) => item.customer_id === selectedCustomer.customer_id);
  };

  const printerControlBoxes = filterFunction(network?.control_boxes);
  const visibleNetworkHandheldConfigs = filterFunction(network?.handheld_configs);

  return (
    <BbotCard key={network.id + "-network-card"}>
      <StyledNetworkCardHeader>
        <StyledNetworkCardHeaderRow>
          <h4>
            <b>{network.name}</b>
          </h4>
          <BbotButton
            type={"primary"}
            onClick={() => {
              setSelectedNetwork(network);
              setShowAddEditNetworkModal(true);
            }}
          >
            Edit Network
          </BbotButton>
        </StyledNetworkCardHeaderRow>
      </StyledNetworkCardHeader>
      <StyledNetworkCardBody>
        <Row>
          <StyledPrinterCardCol>
            <PrintersCard
              printers={printers}
              stations={stations}
              selectedCustomer={selectedCustomer}
              driverProfiles={driverProfiles}
              onSaveCallback={onSaveCallback}
              network={network}
            />
          </StyledPrinterCardCol>
          <StyledControlBoxCardCol>
            <PrinterControlBoxesCard
              printerControlBoxes={printerControlBoxes}
              selectedCustomer={selectedCustomer}
              userInfo={userInfo}
              onSaveCallback={onSaveCallback}
              network={network}
            />
          </StyledControlBoxCardCol>
          <StyledConfigsCardCol>
            <HandheldConfigsCard
              visibleNetworkHandheldConfigs={visibleNetworkHandheldConfigs}
              availableCustomerHandheldConfigs={availableCustomerHandheldConfigs}
              selectedCustomer={selectedCustomer}
              network={network}
              onSaveCallback={onSaveCallback}
            />
          </StyledConfigsCardCol>
        </Row>
      </StyledNetworkCardBody>
      <AddEditNetworkModal
        selectedCustomer={selectedCustomer}
        onSaveCallback={() => {
          onSaveCallback();
          setShowAddEditNetworkModal(false);
        }}
        onHideCallback={() => setShowAddEditNetworkModal(false)}
        showAddEditNetworkModal={showAddEditNetworkModal}
        network={selectedNetwork}
        userInfo={userInfo}
      />
    </BbotCard>
  );
};

const StyledNetworkCardBody = styled(Card.Body)`
  height: 100%;
`;

const StyledNetworkCardHeader = styled(Card.Header)`
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const StyledNetworkCardHeaderRow = styled(Row)`
  justify-content: space-between;
  flex: 1;
`;

const StyledPrinterCardCol = styled(Col)`
  flex: 8;
`;

const StyledControlBoxCardCol = styled(Col)`
  flex: 7;
`;

const StyledConfigsCardCol = styled(Col)`
  flex: 6;
`;

export default NetworkCard;
