import axios from "axios";
import { setSelectedCustomer, setUserInfo } from "instrumentation/tracking/trackingCommonData";
import { Settings } from "luxon";
import moment from "moment-timezone";
import React from "react";
import { notification } from "top-component-library";

const MX_VERSIONS = {
  CLASSIC: "classic",
  DOORDASH: "door_dash",
};

// This function takes a page component...
function withCustomerInfo(PageComponent, selectData) {
  // ...and returns another component with customer info attached to it...
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedCustomerId: null, // used by lagging getAllowedCustomer
        selectedCustomer: null,
        userInfo: null,
        allowedCustomers: [],
        loadingData: true,
        linksWhitelist: [],
        isSelectedCustomerFlaggedForDovetail: false,
      };
    }

    componentDidMount = async () => {
      await this.getCustomerInfo();
    };

    clearCustomerInfo = () => {
      this.setState({
        selectedCustomer: null,
        userInfo: null,
        allowedCustomers: [],
      });
      setSelectedCustomer(null);
      setUserInfo(null);
    };

    getCustomerInfo = async () => {
      // get logged in user info
      await this.getLoggedInUserInfo();

      // get allowed customers
      await this.getAllowedCustomers();

      await this.getConfigLocations();
    };

    getConfigLocations = async () => {
      if (!this.state.selectedCustomerId) return;
      try {
        if (!this.state.selectedCustomerId) return;
        const res = await axios.get("api/ownerlocations");
        const locations = res.data.locations;
        const kioskEnabled = locations.some((location) => location.kiosk_enabled);

        this.setState({
          linksWhitelist: kioskEnabled ? ["/edit-kiosk-config"] : [],
        });
      } catch (error) {
        console.error(error);
        console.error("Could not get location data.");
      }
    };

    getAllowedCustomersById = (customers) => {
      let customersById = {};
      customers.forEach((customer) => {
        customersById[customer.customer_id] = customer;
      });
      return customersById;
    };

    getAllowedCustomers = async () => {
      const { selectedCustomerId } = this.state;
      if (!this.state.userInfo?.email) return; // Must be logged in to be able to get allowed customers

      try {
        this.setState({ loadingData: true });
        const res = await axios.get("/owner/getAllowedCustomersLight");
        this.setState({
          allowedCustomers: res.data.customers,
          allowedCustomersById: this.getAllowedCustomersById(res.data.customers),
        });

        if (selectedCustomerId) {
          this.setSelectedCustomer(selectedCustomerId);
        } else if (res.data.customers.length === 1) {
          await this.selectAllowedCustomer(res.data.customers[0].customer_id);
        }

        this.setState({ loadingData: false });
      } catch (error) {
        this.setState({ loadingData: false });
        console.error("User was not logged in. Redirect to login page.");
      }
    };

    setSelectedCustomer = (customerId) => {
      const selectedCustomer = this.state.allowedCustomers.find((customer) => customer.customer_id === customerId);
      this.setState({
        selectedCustomer: selectedCustomer,
        isSelectedCustomerFlaggedForDovetail: selectedCustomer.mx_version === MX_VERSIONS.DOORDASH, // If this mx has transitioned to doordash, then we want to toggle on dovetail changes
        // TODO: REMOVE THESE OVERWRITES AND UNCOMMENT LINES ABOVE THIS WHEN TESTING IS DONE
        // selectedCustomer: {
        //   ...selectedCustomer,
        //   dd_store_id: "26071630",
        // },
        // isSelectedCustomerFlaggedForDovetail: true,
      });
      setSelectedCustomer(selectedCustomer);
    };

    getLoggedInUserInfo = async () => {
      try {
        this.setState({ loadingData: true });
        const res = await axios.get("api/getLoggedInUserInfo");
        if (res.data.hasOwnProperty("user") && res.data.user["is_anonymous"]) {
          this.setState({
            userInfo: res.data.user,
          });
        } else {
          // if already logged in as a customer, get its properties.
          if (res.data.user_info.most_recent_customer_id) {
            this.setState({
              selectedCustomerId: res.data.user_info.most_recent_customer_id,
            });
          } else if (res.data.user_info.last_selected_customer_id) {
            this.setState({
              selectedCustomerId: res.data.user_info.last_selected_customer_id,
            });
          }
          this.setState({
            userInfo: res.data.user_info,
            loadingData: false,
          });
          setUserInfo(res.data.user_info);
        }
      } catch (error) {
        this.setState({ loadingData: false });

        notification.error({
          message: "Trouble connecting with the server. Please refresh the page.",
        });
        console.error(error);
      }
    };

    selectAllowedCustomer = async (customerId, showNotification = true) => {
      if (this.state.selectedCustomerId !== customerId) {
        try {
          await axios.post("/owner/selectCustomer", {
            customer_id: customerId,
          });
          const selectedCustomer = this.state.allowedCustomers.find((customer) => customer.customer_id === customerId);
          if (showNotification) {
            notification.success({
              message: "Switched to customer: " + selectedCustomer.customer_name,
            });
          }
          this.setState({
            selectedCustomer: selectedCustomer,
            selectedCustomerId: customerId,
          });
          Settings.defaultZoneName = selectedCustomer.timezone;
          moment.tz.setDefault(selectedCustomer.timezone);
          setSelectedCustomer(selectedCustomer);

          await this.getConfigLocations();
        } catch (error) {
          console.error("selectAllowedCustomer failed with err", error);
        }
      }
    };

    setUserInfo = (userInfo) => {
      this.setState({ userInfo: userInfo });
    };

    setLoading = (loading) => {
      this.setState({ loadingData: loading });
    };

    render() {
      const {
        allowedCustomers,
        allowedCustomersById,
        selectedCustomer,
        userInfo,
        loadingData,
        linksWhitelist,
        isSelectedCustomerFlaggedForDovetail,
      } = this.state;
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return (
        <PageComponent
          allowedCustomers={allowedCustomers}
          allowedCustomersById={allowedCustomersById}
          selectAllowedCustomer={this.selectAllowedCustomer}
          selectedCustomer={selectedCustomer}
          userInfo={userInfo}
          setUserInfo={this.setUserInfo}
          getCustomerInfo={this.getCustomerInfo}
          clearCustomerInfo={this.clearCustomerInfo}
          loadingData={loadingData}
          setLoadingState={this.setLoading}
          getAllowedCustomers={this.getAllowedCustomers}
          linksWhitelist={linksWhitelist}
          isSelectedCustomerFlaggedForDovetail={isSelectedCustomerFlaggedForDovetail}
          {...this.props}
        />
      );
    }
  };
}

export default withCustomerInfo;
