import React, { useRef, useState } from "react";
import { Colors, Text } from "@doordash/design-language";
// @ts-ignore
import ImageCropModal from "@doordash/component-image-crop";
import { client } from "filestack-react";

import FileUpload, {
  FileSizes,
  FileState,
  FsFile,
  FsFileMetadata,
  FsStoredUrl,
  getFileMetadata,
  getFileMetadataFromStoredUrl,
  getImageDimensions,
} from "components/owner-app/file-upload";
import { FILESTACK_API_KEY } from "Constants";

import { downloadFileFromUrl, generalErrorAlert } from "util/Utils";
import SavedAssetTile from "components/global/image-uploader/SavedAssetTile";

export type UploadAssetsOptions = {
  imageTitle: string;
  aspectRatio: number;
  minHeight: number;
  minWidth: number;
  maxWidth?: number;
  savedFile?: FsFileMetadata;
  onFileSaved: (file: FsFileMetadata, original?: FsFileMetadata) => void;
};

export type ImageUploaderAndCropperProps = {
  title: string;
  acceptedTypes: string[];
  acceptedTypesDescription: string;
  isAdmin: boolean;
  showPreviewOnSide?: boolean;
  directSave?: boolean;
  uploadAssetOptions: UploadAssetsOptions;
  savedOriginalFile?: FsFileMetadata;
  onDelete: () => void;
};

const ImageUploaderAndCropper: React.FC<Omit<ImageUploaderAndCropperProps, "description" | "isLoading">> = ({
  title,
  acceptedTypes,
  acceptedTypesDescription,
  isAdmin,
  uploadAssetOptions,
  savedOriginalFile,
  onDelete,
  showPreviewOnSide = false,
  directSave = false,
}) => {
  const [file, setFile] = useState(uploadAssetOptions.savedFile);

  const [originalFile, setOriginalFile] = useState(savedOriginalFile);
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const filestackClient = useRef(new client.Client(FILESTACK_API_KEY));

  const validateImage = async (file: File) => {
    const [height, width] = await getImageDimensions(file);

    if (height < minHeight) {
      return "Image height is too short";
    }

    if (width < minWidth) {
      return "Image width is too narrow";
    }

    return "";
  };

  /**
   * Called when file is initially uploaded, but before cropping happens.
   */
  const onInitialUpload = async (files: FsFileMetadata[]) => {
    setFile(files[0]);
    setOriginalFile(files[0]);

    if (directSave) {
      uploadAssetOptions.onFileSaved(files[0]);
    } else {
      setIsEditing(true);
    }
  };

  const onRemoveClicked = () => {
    onDelete();
    setFile(undefined);
    setIsEditing(false);
  };

  const onCroppingComplete = async (croppedFile: File) => {
    try {
      setIsUploading(true);
      const uploaded = await handleResizeAndFsUpload(croppedFile);
      await uploadAssetOptions.onFileSaved(uploaded, originalFile);

      setFile(uploaded);
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleResizeAndFsUpload = async (file: File) => {
    const { aspectRatio, maxWidth } = uploadAssetOptions;

    const result = (await filestackClient.current.upload(file)) as FsFile;

    if (result.status !== FileState.STORED) {
      throw new Error("Something went wrong. Unable to upload file.");
    }

    if (!maxWidth) {
      const uploaded = getFileMetadata(result, file);
      return uploaded;
    }

    const [height, width] = await getImageDimensions(file);

    const maxHeight = Math.ceil(maxWidth / aspectRatio);

    if (height <= maxHeight && width <= maxWidth) {
      const uploaded = getFileMetadata(result, file);
      return uploaded;
    }

    // one of these is greater than its respective maximum, so this will be < 1
    const minRatio = Math.min(maxHeight / height, maxWidth / width);

    const transformedUrl = filestackClient.current.transform(result.handle, {
      resize: {
        width: Math.ceil(width * minRatio),
        height: Math.ceil(height * minRatio),
      },
    });

    const storedUrl = (await filestackClient.current.storeURL(transformedUrl)) as FsStoredUrl;
    const uploaded = getFileMetadataFromStoredUrl(storedUrl);
    return uploaded;
  };

  const downloadFile = async () => {
    if (!file) {
      return;
    }

    try {
      const res = await filestackClient.current.download(file.handle);
      if (!res) {
        return;
      }

      const url = URL.createObjectURL(res.data);
      downloadFileFromUrl(url, file.filename);
      URL.revokeObjectURL(url);
    } catch (error) {
      generalErrorAlert("There was an error downloading the file.");
    }
  };

  const { aspectRatio, minWidth, minHeight } = uploadAssetOptions;

  return (
    <>
      {!file ? (
        <FileUpload
          onSave={onInitialUpload}
          acceptedTypes={acceptedTypes}
          maxFileSize={2 * FileSizes.MB}
          validateFile={validateImage}
        >
          <div className="margin-y-2">
            <Text styles={Text.Styles.Label2Emphasis} color={Colors.TextTertiary}>
              {acceptedTypesDescription}
            </Text>
          </div>
        </FileUpload>
      ) : (
        <SavedAssetTile
          fileOptions={[uploadAssetOptions]}
          files={[file]}
          file={originalFile}
          isAdmin={isAdmin}
          downloadFile={downloadFile}
          onDelete={onRemoveClicked}
          onEdit={() => setIsEditing(true)}
          showPreviewOnSide={showPreviewOnSide}
          title={title}
          showEditButton={!directSave}
        />
      )}

      {isEditing && (
        <ImageCropModal
          aspect={aspectRatio}
          imgSrc={originalFile?.url}
          uploadImageMinWidth={minWidth}
          uploadImageMinHeight={minHeight}
          uploadCallBack={onCroppingComplete}
          isSaving={isUploading}
          zoomStep={0.1}
          isCircleCrop={false}
          onClose={() => {
            setIsEditing(false);
            if (!uploadAssetOptions.savedFile) {
              setFile(undefined);
              setOriginalFile(undefined);
            }
          }}
          originalFilename={file?.filename}
        />
      )}
    </>
  );
};

export default ImageUploaderAndCropper;
