import * as React from "react";
import { Modal } from "@doordash/component-modal";
import { ModalProps } from "../constants";
import { Select, TextField } from "@doordash/component-fields";
import Toggle from "@doordash/component-toggle";

const preSelectedItems = [
  {
    name: "French Fries",
    value: "french_fries",
  },
  {
    name: "Salad",
    value: "salad",
  },
];

export const AddOrEditModifierGroupModal: React.FC<ModalProps> = ({
  primaryActionProps,
  secondaryActionProps,
  setOpenModal,
  fields,
  updateForm,
  onClose,
}) => (
  <Modal
    title={`${fields.modifier_group_name ? "Edit" : "New"} Modifier Group`}
    primaryAction={primaryActionProps}
    secondaryAction={secondaryActionProps}
    onClose={onClose}
    isFullScreenOnMobile
  >
    <TextField
      label={"Modifier Group Name"}
      autoComplete={"off"}
      onChange={(value: string) => updateForm({ ...fields, modifier_group_name: value })}
      value={fields.modifier_group_name || ""}
    />
    <TextField
      label={"Minimum Selectable Amount"}
      autoComplete={"off"}
      onChange={(value: string) => updateForm({ ...fields, minimum_selectable_amount: value })}
      value={fields.minimum_selectable_amount || ""}
    />
    <TextField
      label={"Maximum Selectable Amount"}
      autoComplete={"off"}
      onChange={(value: string) => updateForm({ ...fields, maximum_selectable_amount: value })}
      value={fields.maximum_selectable_amount || ""}
    />
    <Select
      label={"Pre-selected Items"}
      options={preSelectedItems}
      onChange={(value: string) => updateForm({ ...fields, pre_selected_items: value })}
      value={fields.pre_selected_items || ""}
    />
    <br />
    <Toggle
      label={"Show this heading on orders"}
      onChange={() => updateForm({ ...fields, show_this_heading_on_orders: !fields.show_this_heading_on_orders })}
      isSelected={fields.show_this_heading_on_orders || false}
    />
  </Modal>
);
