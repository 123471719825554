import React from "react";
import { BorderRadius, Colors, Text } from "@doordash/design-language";
import styled from "styled-components";

const UploadBOSAssetsPreviewHero: React.FC = () => (
  <PreviewContainer>
    <LandingPageLabel color={Colors.TextTertiary} styles={Text.Styles.Caption2}>
      Landing Page
    </LandingPageLabel>
    <PhoneWireframe>
      <DecorativeCircle1 />
      <DecorativeCircle2 />
      <DecorativeCircle3 />

      <div className="margin-bottom-8">
        <LogoContainer>
          <Text styles={Text.Styles.Caption2}>Logo</Text>
        </LogoContainer>
      </div>

      <div className="margin-bottom-6">
        <InputSearchSkeleton>
          <InputSearchButtonSkeleton />
        </InputSearchSkeleton>
        <ButtonSkeleton />
        <ButtonSkeleton />
      </div>

      <ForegroundLabel styles={Text.Styles.Caption2} textAlign="center">
        Landing Page Background
      </ForegroundLabel>

      <HomebarSkeleton />
    </PhoneWireframe>
    <MenuPageLabel color={Colors.TextTertiary} styles={Text.Styles.Caption2}>
      Menu Page
    </MenuPageLabel>
    <PhoneWireframe>
      <LogoContainer>
        <Text styles={Text.Styles.Caption2}>Logo</Text>
      </LogoContainer>

      <MenuBannerPreviewContainer>
        <DecorativeCircle4 />
        <DecorativeCircle5 />
        <ForegroundLabel textAlign="center" styles={Text.Styles.Caption2}>
          Menu Banner
        </ForegroundLabel>
      </MenuBannerPreviewContainer>

      <div className="margin-bottom-13">
        <LineSkeleton />
        <LineSkeleton />
      </div>
      <ButtonSkeleton />
      <HomebarSkeleton />
    </PhoneWireframe>
  </PreviewContainer>
);

export default UploadBOSAssetsPreviewHero;

const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: ${Colors.BackgroundTertiary};
  padding: 24px;
  border-radius: ${BorderRadius.Small}px;
`;

const PhoneWireframe = styled.div`
  width: 160px;
  height: 360px;
  border-radius: ${BorderRadius.Medium}px;
  background-color: ${Colors.SystemWhite};
  margin-right: 40px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  overflow: hidden;
`;

const LandingPageLabel = styled(Text)`
  transform: rotate(-90deg);
  position: relative;
  bottom: 24px;
  margin-right: -12px;
`;

const MenuPageLabel = styled(Text)`
  transform: rotate(-90deg);
  position: relative;
  bottom: 20px;
  margin-right: -8px;
`;

const LogoContainer = styled.div`
  margin-top: 12px;
  padding: 6px;
  background-color: ${Colors.BackgroundSecondary};
  display: inline-block;
  border-radius: ${BorderRadius.Medium}px;
`;

const InputSearchSkeleton = styled.div`
  border: 1px solid ${Colors.BackgroundTertiary};
  height: 24px;
  border-radius: ${BorderRadius.Small}px;
  background-color: ${Colors.SystemWhite};
  margin-bottom: 6px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
`;

const InputSearchButtonSkeleton = styled.div`
  width: 40%;
  height: 100%;
  float: right;
  background-color: ${Colors.BackgroundTertiary};
  border-top-right-radius: ${BorderRadius.Small}px;
  border-bottom-right-radius: ${BorderRadius.Small}px;
`;

const ButtonSkeleton = styled.div`
  background-color: ${Colors.BackgroundTertiary};
  height: 24px;
  border-radius: ${BorderRadius.Small}px;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
`;

const MenuBannerPreviewContainer = styled.div`
  height: 80px;
  background-color: ${Colors.BackgroundSecondary};
  border-radius: ${BorderRadius.Medium}px;
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const LineSkeleton = styled.div`
  height: 12px;
  border-radius: ${BorderRadius.Medium}px;
  background-color: ${Colors.BackgroundTertiary};
  margin-bottom: 6px;
`;

const HomebarSkeleton = styled.div`
  height: 4px;
  width: 64px;
  background-color: ${Colors.BackgroundTertiary};
  position: absolute;
  bottom: 8px;
  left: 50px;
  border-radius: ${BorderRadius.Medium}px;
`;

const ForegroundLabel = styled(Text)`
  position: relative;
  z-index: 2;
`;

const DecorativeCircle1 = styled.div`
  height: 125px;
  width: 125px;
  background-color: ${Colors.BackgroundSecondary};
  position: absolute;
  top: 50px;
  right: -40px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle2 = styled.div`
  height: 178px;
  width: 178px;
  background-color: ${Colors.SystemBlue10};
  position: absolute;
  bottom: -20px;
  left: -80px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle3 = styled.div`
  height: 125px;
  width: 125px;
  background-color: ${Colors.BackgroundSecondary};
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle4 = styled.div`
  height: 114px;
  width: 114px;
  background-color: ${Colors.SystemBlue10};
  position: absolute;
  bottom: -50px;
  left: -10px;
  border-radius: 100%;
  z-index: 0;
`;

const DecorativeCircle5 = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${Colors.BackgroundTertiary};
  position: absolute;
  bottom: -20px;
  right: -20px;
  border-radius: 100%;
  z-index: 0;
`;
