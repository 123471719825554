import Button from "@doordash/component-button";
import { Modal } from "@doordash/component-modal";
import { BorderRadius, Colors, Icon, InlineChildren, Spacing, StackChildren, Text } from "@doordash/design-language";
import FileUpload, {
  bytesToReadable,
  FilestackClient,
  FileThumbnail,
  FsClient,
  FsFileMetadata,
} from "components/owner-app/file-upload";
import { FILESTACK_API_KEY } from "Constants";
import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { DangerButtonWrapper } from "top-component-library/DangerButtonWrapper";
import { downloadFileFromUrl, generalErrorAlert } from "util/Utils";

type FloorplanManagerProps = {
  files: FsFileMetadata[];
  onSave: (files: FsFileMetadata[]) => void;
  removeFile: (file: FsFileMetadata) => void;
  isAdmin: boolean;
};

const FloorplanManager: React.FC<FloorplanManagerProps> = ({ files, onSave, removeFile, isAdmin }) => {
  const [showFileUpload, setShowFileUpload] = useState(!files.length);
  const filestackClient = useRef<FilestackClient>(new FsClient(FILESTACK_API_KEY));

  const handleDownload = useCallback(async (file: FsFileMetadata) => {
    try {
      const res = await filestackClient.current.download(file.handle);
      if (!res) {
        return;
      }

      const url = URL.createObjectURL(res.data);
      downloadFileFromUrl(url, file.filename);
      URL.revokeObjectURL(url);
    } catch (_) {
      generalErrorAlert(
        new Error(`There was an issue downloading ${file.filename}. Please try again or contact TOP support.`)
      );
    }
  }, []);

  const handleRemove = useCallback(
    (file: FsFileMetadata) => {
      removeFile(file);
      if (files.length === 1) {
        setShowFileUpload(true);
      }
    },
    [removeFile, files]
  );

  const showCancel = !!files.length;

  return (
    <>
      <FileList files={files} removeFile={handleRemove} downloadFile={handleDownload} isAdmin={isAdmin} />
      {showFileUpload || !files.length ? (
        <>
          <StackChildren size={StackChildren.Sizes.XxxSmall}>
            <Text tag="h3" styles={Text.Styles.Body1Emphasis}>
              Upload your Floorplan
            </Text>
            <Text styles={Text.Styles.Body2}>
              Don’t forget to include table numbers and any other origins of orders (bar seats, room numbers, etc.) you
              would like TOP to serve.
            </Text>
          </StackChildren>
          <FileUpload onSave={onSave}>
            <Text styles={Text.Styles.Label2Emphasis} color={Text.Colors.TextTertiary}>
              PDF, JPG, PNG, XLSX, or CSV
            </Text>
          </FileUpload>
          {showCancel && (
            <Button type={Button.Types.Tertiary} isInline onClick={() => setShowFileUpload(false)}>
              Cancel
            </Button>
          )}
        </>
      ) : (
        <Button leadingIcon={Icon.Types.Add} isInline onClick={() => setShowFileUpload(true)}>
          Add Floorplan
        </Button>
      )}
    </>
  );
};

type FileListProps = {
  files: FsFileMetadata[];
  removeFile: (file: FsFileMetadata) => void;
  downloadFile: (file: FsFileMetadata) => void;
  isAdmin: boolean;
};

const FileList: React.FC<FileListProps> = ({ files, removeFile, downloadFile, isAdmin }) => {
  const [fileToRemove, setFileToRemove] = useState<FsFileMetadata | null>(null);

  const onRemoveClicked = (file: FsFileMetadata) => {
    if (isAdmin) {
      setFileToRemove(file);
    } else {
      removeFile(file);
    }
  };

  return (
    <StackChildren size={StackChildren.Sizes.Large}>
      {files.map((file) => (
        <TileContainer key={file.handle}>
          <StackChildren>
            <InlineChildren>
              <FileThumbnail type={file.mimetype} />
              <StackChildren size={StackChildren.Sizes.XxxxSmall}>
                <Text styles={Text.Styles.Label1Emphasis}>{file.filename}</Text>
                <Text styles={Text.Styles.Label1Emphasis} color={Text.Colors.TextTertiary}>
                  {bytesToReadable(file.size)}
                </Text>
              </StackChildren>
            </InlineChildren>
            <InlineChildren>
              {isAdmin && (
                <DangerButtonWrapper>
                  <Button
                    type={Button.Types.Tertiary}
                    isInline
                    onClick={() => downloadFile(file)}
                    accessibilityLabel={`Download floorplan ${file.filename}`}
                  >
                    Download Attachment
                  </Button>
                </DangerButtonWrapper>
              )}

              <Button
                type={Button.Types.Tertiary}
                isInline
                onClick={() => onRemoveClicked(file)}
                accessibilityLabel={`Remove floorplan ${file.filename}`}
              >
                Remove
              </Button>
            </InlineChildren>
          </StackChildren>
        </TileContainer>
      ))}

      {fileToRemove && (
        <Modal
          title={`Remove ${fileToRemove.filename}`}
          onClose={() => setFileToRemove(null)}
          primaryAction={{
            text: "Confirm",
            onClick: () => {
              removeFile(fileToRemove);
              setFileToRemove(null);
            },
            buttonProps: {
              accessibilityLabel: `Click to confirm removing floorplan ${fileToRemove.filename}`,
            },
          }}
        >
          <Text>{`Are you sure you want to remove ${fileToRemove.filename}?`} </Text>
        </Modal>
      )}
    </StackChildren>
  );
};

const TileContainer = styled.div`
  border: 1px solid ${Colors.BorderSecondary};
  border-radius: ${BorderRadius.Medium}px;
  padding: ${Spacing.Large}px;
  width: 100%;
`;

export default FloorplanManager;
