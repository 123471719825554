import axios from "axios";
import { useState } from "react";
import { generalErrorAlert } from "util/Utils";

import styled from "styled-components";
import {
  Alert,
  BbotButton,
  BbotModal,
  BbotTag,
  Button,
  Col,
  Form,
  notification,
  Row,
  Select,
  SelectInput,
} from "top-component-library";
import CheckboxInput from "top-component-library/form-inputs/CheckboxInput";
import NumberInput from "top-component-library/form-inputs/NumberInput";
import TextInput from "top-component-library/form-inputs/TextInput";

// Keeps track of state of the wizard
const MODAL_STATE = {
  chooseLocationToCopyFrom: "choose_customer_to_copy_from",
  chooseLocationType: "choose_location_type", // normal or external
  enterExternalDetails: "enter_external_details", // external url form END
  enterLocationDetails: "enter_location_details", // location details form END
};

const AddLocationsModal = (props) => {
  const [locationIdToCopy, setLocationIdToCopy] = useState(null);
  const [showLocationsPreview, setShowLocationsPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [wizardState, setWizardState] = useState(MODAL_STATE.chooseLocationToCopyFrom);
  const [, setLocationFormValues] = useState({});

  const [newLocationsFormInstance] = Form.useForm();
  const [externalUrlFormInstance] = Form.useForm();

  const onHide = () => {
    setWizardState(MODAL_STATE.chooseLocationToCopyFrom);
    setLocationIdToCopy(null);
    onHideCallback();
    newLocationsFormInstance.resetFields();
  };

  const {
    show,
    onHideCallback,
    onSubmitCallback,
    locations,
    selectedCustomer,
    fulfillmentMethodPrettyNames,
    userIsAdmin,
  } = props;

  const generateNewLocations = (locationName, locationCode, addNumbers, start, end, usePadding, paddingLength) => {
    // NOTE: can't use !start || !end because they are numbers, and !0 is not true. We want to case on if there is a number here
    if (!addNumbers || typeof start !== "number" || typeof end !== "number") {
      return [
        {
          locationName: locationName,
          locationShortId: locationCode,
          externalUrl: null,
        },
      ];
    }

    let locations = [];
    for (let i = start; i <= end; i++) {
      const numberSuffix = usePadding ? i.toString().padStart(paddingLength, "0") : i.toString();
      locations.push({
        locationName: locationName + numberSuffix,
        locationShortId: locationCode + numberSuffix,
        externalUrl: null,
      });
    }

    return locations;
  };

  const saveNewLocations = async (values) => {
    if (!selectedCustomer) {
      notification.error({
        message: "Please use the dropdown in the header to select a customer.",
      });
      return;
    }

    const payload =
      wizardState === MODAL_STATE.enterExternalDetails
        ? {
            customer_id: selectedCustomer.customer_id,
            location_id_to_copy: locationIdToCopy,
            create_locations_from_scratch: !locationIdToCopy,
            fulfillment_method: values.fulfillmentMethod,
            new_locations: [
              {
                locationName: values.locationName,
                locationShortId: values.locationCode,
                externalUrl: values.externalUrl,
              },
            ],
          }
        : {
            customer_id: selectedCustomer.customer_id,
            location_id_to_copy: locationIdToCopy,
            create_locations_from_scratch: !locationIdToCopy,
            fulfillment_method: values.fulfillmentMethod,
            new_locations: generateNewLocations(
              values.locationName,
              values.locationCode,
              values.addNumbers,
              values.start,
              values.end,
              values.usePadding,
              values.paddingLength
            ),
          };

    if (payload.new_locations.some((newLocation) => newLocation.locationShortId === "")) {
      notification.error({
        message: "Cannot create a location with an empty Location Code. Please add a valid Location Code.",
      });
      return;
    }

    setIsSaving(true);

    try {
      await axios.put("/api/ownerlocations", payload);
      notification.success({ message: "Successfully added new locations." });
      onHide();
      onSubmitCallback();
    } catch (error) {
      generalErrorAlert(error, "Unexpected Server Error.", selectedCustomer?.customer_id);
    } finally {
      setIsSaving(false);
      setLocationIdToCopy(null);
    }
  };

  const getLocationsPreview = () => {
    const newLocationFormValues = newLocationsFormInstance.getFieldsValue(true);

    const newLocations = generateNewLocations(
      newLocationFormValues.locationName,
      newLocationFormValues.locationCode,
      newLocationFormValues.addNumbers,
      newLocationFormValues.start,
      newLocationFormValues.end,
      newLocationFormValues.usePadding,
      newLocationFormValues.paddingLength
    );
    return newLocations.map((location, index) => (
      <div className={"margin-bottom-1"} key={index}>
        <div>{location.locationName}</div>
        <div className={"supporting-text"}>{location.locationShortId}</div>
      </div>
    ));
  };

  const externalUrlForm = (
    <Form id={"external-url-form"} form={externalUrlFormInstance} layout={"vertical"} onFinish={saveNewLocations}>
      <TextInput
        name={"locationName"}
        id={"location-name"}
        required
        label={"Location Name (name of the location on receipts)"}
      />
      <TextInput name={"locationCode"} id={"location-code"} required label={"Location Code"} />
      <TextInput
        name={"externalUrl"}
        id={"external-url"}
        required
        label={<span className={"supporting-text"}>External URL</span>}
      />
      {!locationIdToCopy && (
        <SelectInput
          required
          name={"fulfillmentMethod"}
          id={"fulfillment-method"}
          label={"Fulfillment Method"}
          options={Object.entries(fulfillmentMethodPrettyNames).map(
            ([fulfillmentMethod, fulfillmentMethodPrettyName]) => ({
              label: fulfillmentMethodPrettyName,
              value: fulfillmentMethod,
            })
          )}
        />
      )}
    </Form>
  );

  const locationDetailsForm = (
    <Form
      id={"location-details-form"}
      form={newLocationsFormInstance}
      layout={"vertical"}
      onFinish={saveNewLocations}
      onValuesChange={(_, values) => setLocationFormValues(values)}
      initialValues={{
        locationName: "",
        locationCode: "",
        externalUrl: "",
        addNumbers: false,
        start: 1,
        end: 1,
        usePadding: false,
        paddingLength: 0,
      }}
    >
      {!locationIdToCopy && (
        <Alert
          className="margin-bottom-2"
          message={"Additional Configuration May Be Needed"}
          type="warning"
          showIcon
          description={
            <div>
              <p>
                All existing Menus, Stations, Service Charges, and Order Filters on this customer account will be
                automatically connected to the new locations. After creating the locations, please double check that
                they are set up properly.
              </p>
            </div>
          }
        />
      )}

      <TextInput
        name={"locationName"}
        id={"location-name"}
        required
        label={"Location Name (name of the location on receipts)"}
      />
      <TextInput
        name={"locationCode"}
        id={"location-code"}
        required={!newLocationsFormInstance.getFieldValue("addNumbers")} // Don't make this field required if using numbers
        label={"Location Code"}
      />

      {!locationIdToCopy && (
        <SelectInput
          required
          name={"fulfillmentMethod"}
          id={"fulfillment-method"}
          label={"Fulfillment Method"}
          options={Object.entries(fulfillmentMethodPrettyNames).map(
            ([fulfillmentMethod, fulfillmentMethodPrettyName]) => ({
              label: fulfillmentMethodPrettyName,
              value: fulfillmentMethod,
            })
          )}
        />
      )}

      <CheckboxInput name={"addNumbers"} id={"add-numbers"} label={"Add Numbers To Location Codes and Names"} />

      {newLocationsFormInstance.getFieldValue("addNumbers") && (
        <>
          <Row gutter={12}>
            <Col span={12}>
              <NumberInput
                name={"start"}
                id={"start"}
                label={"Start"}
                dependencies={["end"]}
                rules={[
                  {
                    type: "number",
                    min: 0,
                    message: "Cannot use negative numbers.",
                  },
                  {
                    type: "number",
                    max: newLocationsFormInstance.getFieldValue("end"),
                    message: "Start cannot be greater than end.",
                  },
                ]}
              />
            </Col>
            <Col span={12}>
              <NumberInput name={"end"} id={"end"} label={"End"} />
            </Col>
          </Row>
          <CheckboxInput name={"usePadding"} id={"use-padding"} label={"Pad Numbers In Location Codes and Names"} />
        </>
      )}

      {newLocationsFormInstance.getFieldValue("addNumbers") && newLocationsFormInstance.getFieldValue("usePadding") && (
        <NumberInput name={"paddingLength"} id={"padding-length"} label={"Padding Length"} />
      )}

      {newLocationsFormInstance.getFieldValue("addNumbers") && (
        <div>
          <div>
            <Button
              type={"link"}
              onClick={() => setShowLocationsPreview((showLocationsPreview) => !showLocationsPreview)}
            >
              {`${showLocationsPreview ? "Hide Locations Preview" : "Preview Locations"}`}
            </Button>
          </div>

          {showLocationsPreview && getLocationsPreview()}
        </div>
      )}
    </Form>
  );

  const locationChooser = (
    <div>
      <div className={"margin-bottom-1"}>What location would you like to copy existing settings from?</div>

      <LocationChooser
        onChange={(locationId) => {
          setLocationIdToCopy(locationId);
          setWizardState(MODAL_STATE.chooseLocationType);
        }}
      >
        {locations.map((location) => (
          <Select.Option key={location.locationId} value={location.locationId}>
            {location.locationName}
          </Select.Option>
        ))}
      </LocationChooser>

      {userIsAdmin && locations.length === 0 && (
        <BbotButton
          className="margin-top-2 full-width padding-y-2"
          onClick={() => setWizardState(MODAL_STATE.chooseLocationType)}
        >
          Create Locations From Scratch
          <BbotTag className="margin-x-2">Admin Only</BbotTag>
        </BbotButton>
      )}
    </div>
  );

  const locationTypeChooser = (
    <div>
      <div className={"margin-bottom-2"}>What type of location would you like to add?</div>
      <div className={"margin-bottom-1"}>
        <ChooserButton type={"primary"} onClick={() => setWizardState(MODAL_STATE.enterLocationDetails)}>
          Standard Location
        </ChooserButton>
      </div>
      <div className={"margin-bottom-1"}>
        <ChooserButton onClick={() => setWizardState(MODAL_STATE.enterExternalDetails)}>
          External URL Location
        </ChooserButton>
      </div>
    </div>
  );

  const modalBody = () => {
    switch (wizardState) {
      case MODAL_STATE.chooseLocationToCopyFrom:
        return locationChooser;
      case MODAL_STATE.chooseLocationType:
        return locationTypeChooser;
      case MODAL_STATE.enterExternalDetails:
        return externalUrlForm;
      case MODAL_STATE.enterLocationDetails:
        return locationDetailsForm;
      default:
        return null;
    }
  };

  const modalFooter = () => {
    switch (wizardState) {
      case MODAL_STATE.enterExternalDetails:
        return (
          <>
            <BbotButton onClick={onHide}>Cancel</BbotButton>
            <BbotButton type={"primary"} onClick={externalUrlFormInstance.submit} loading={isSaving}>
              Save
            </BbotButton>
          </>
        );
      case MODAL_STATE.enterLocationDetails:
        return (
          <>
            <BbotButton onClick={onHide}>Cancel</BbotButton>
            <BbotButton type={"primary"} onClick={newLocationsFormInstance.submit} loading={isSaving}>
              Save
            </BbotButton>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <BbotModal visible={show} onCancel={onHide} title={"Add New Locations"} footer={<>{modalFooter()}</>}>
      {modalBody()}
    </BbotModal>
  );
};

// ============== STYLES ==================

const LocationChooser = styled(Select)`
  width: 100%;
`;

const ChooserButton = styled(BbotButton)`
  width: 100%;
`;

export default AddLocationsModal;
