import * as React from "react";
import { Icon, InlineChildren, Text } from "@doordash/design-language";
import { capitalizeString, generateUUIDv4, splitSnakeCase } from "util/Utils";
import { MenuKey, menuNames } from "./constants";
import { MenuName, SubtitleWrapper } from "./styles";

type TablesProps = {
  selectedTable: string;
  setSelectedTable: (selectedTable: MenuKey) => void;
  setOpenModal: (open: boolean) => void;
};

const Tables: React.FC<TablesProps> = ({ setOpenModal, selectedTable, setSelectedTable }) => (
  <React.Fragment>
    <br />
    <InlineChildren>
      {menuNames.map((menuName) => (
        <MenuName
          key={generateUUIDv4()}
          selected={menuName === selectedTable}
          onClick={() => setSelectedTable(menuName)}
        >
          {splitSnakeCase(menuName)}
        </MenuName>
      ))}
    </InlineChildren>
    <br />
    <SubtitleWrapper>
      <Text styles={Text.Styles.PageTitle2}>Edit {capitalizeString(splitSnakeCase(selectedTable))}</Text>
      <Icon size={Icon.Sizes.XLarge} type={Icon.Types.AddCircleFill} onClick={() => setOpenModal(true)} />
    </SubtitleWrapper>
  </React.Fragment>
);

export default Tables;
