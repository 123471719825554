import { useState } from "react";

import EditHandheldsOnNetworkModal from "components/owner-app/modals/printer-configuration/EditHandheldsOnNetworkModal";
import { BbotTable } from "top-component-library";

const HandheldConfigsCard = ({
  visibleNetworkHandheldConfigs,
  network,
  availableCustomerHandheldConfigs,
  selectedCustomer,
  onSaveCallback,
}) => {
  const [showAddHandheldToNetworkModal, setShowAddHandheldToNetworkModal] = useState(false);

  const getSelectableConfigs = () => {
    let selectableConfigs = availableCustomerHandheldConfigs.concat(visibleNetworkHandheldConfigs);
    if (selectedCustomer?.customer_id !== network?.managing_customer_id) {
      selectableConfigs = selectableConfigs.filter((config) => config.customer_id === selectedCustomer?.customer_id);
    }
    return selectableConfigs;
  };

  const selectableConfigs = getSelectableConfigs();

  return (
    <>
      <BbotTable
        id={"printer-configuration-terminals-table"}
        title={"Terminals on Network"}
        manuallyFormattedColumns={[
          {
            title: "Device Profile",
            dataIndex: "config_name",
          },
        ]}
        data={visibleNetworkHandheldConfigs}
        addButtonText={"Edit Terminals on this Network"}
        onAdd={() => setShowAddHandheldToNetworkModal(true)}
        showCountByTitle={false}
      />
      <EditHandheldsOnNetworkModal
        showAddHandheldConfigModal={showAddHandheldToNetworkModal}
        network={network}
        selectableConfigs={selectableConfigs}
        visibleNetworkConfigs={visibleNetworkHandheldConfigs}
        selectedCustomer={selectedCustomer}
        onSaveCallback={onSaveCallback}
        onHideCallback={() => setShowAddHandheldToNetworkModal(false)}
      />
    </>
  );
};

export default HandheldConfigsCard;
