import axios from "axios";
import { SelectInput, TextInput } from "components/global/form";
import PinHelpModal from "components/owner-app/modals/employees/PinHelpModal";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Alert, Form as BtpForm, Button, OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import { capitalizeString, generalErrorAlert, generateRandomPin } from "util/Utils";
import * as Yup from "yup";

// Styles
import EditEmployeeModal from "components/owner-app/modals/employees/EditEmployeeModal";
import MapIntegrationEmployees from "components/owner-app/modals/employees/MapIntegrationEmployees";
import {
  trackAddEmployee,
  trackBulkRemoveEmployees,
  trackEditEmployee,
  trackRemoveSingleEmployee,
} from "instrumentation/tracking/page-tracking-events";
import { BbotContainer, Breadcrumbs } from "top-component-library";
import "./ManageEmployees.scss";

export const getDuplicatePinEmployees = (employees) => {
  // group employees by pin number
  let groupedEmployees = {};
  employees.forEach((employee) => {
    const pin = employee.pin_number;
    if (groupedEmployees.hasOwnProperty(pin)) {
      groupedEmployees[pin] = [...groupedEmployees[pin], employee];
    } else {
      groupedEmployees[pin] = [employee];
    }
  });

  // get the employees in groups larger than 1
  return Object.values(groupedEmployees)
    .filter((employeeGroup) => employeeGroup.length > 1)
    .flat();
};

const ManageEmployees = (props) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [pins, setPins] = useState(new Set());
  const [employeeToEdit, setEmployeeToEdit] = useState(null);
  const [showEmployeeMappingModal, setShowEmployeeMappingModal] = useState(false);
  const [isLoadingEmployees, setIsLoadingEmployees] = useState(false);

  // integration for table/employee mapping
  const [posIntegration, setPosIntegration] = useState(null);

  // modals
  const [showPinHelpModal, setShowPinHelpModal] = useState(false);

  const { selectedCustomer, allowedCustomersById } = props;

  useEffect(() => {
    getEmployees();
    getPosIntegration();
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getEmployees = async () => {
    if (!selectedCustomer) return;

    setIsLoadingEmployees(true);
    try {
      const res = await axios.get("api/allbartenders", {
        params: {
          format: "json",
          owner_id: selectedCustomer.customer_id,
        },
      });
      setEmployees(res.data);
      setPins(new Set(res.data.map((employee) => employee.pin_number)));
    } catch (error) {
      console.error(error);
    }
    setIsLoadingEmployees(false);
  };

  const getPosIntegration = async () => {
    if (!selectedCustomer) return;

    try {
      const res = await axios.get("/owner/getAllPartnersAndIntegrations", {
        params: {
          customerId: selectedCustomer?.customer_id,
        },
      });
      const integrations = res.data.customer_integrations;

      if (Object.keys(integrations).includes("toast")) {
        setPosIntegration("toast");
      } else if (Object.keys(integrations).includes("omnivore")) {
        setPosIntegration("omnivore");
      } else if (Object.keys(integrations).includes("omnivore_3")) {
        setPosIntegration("omnivore_3");
      } else if (Object.keys(integrations).includes("omnivore_direct")) {
        setPosIntegration("omnivore_direct");
      } else {
        setPosIntegration(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const duplicatePinsExist = pins.size < employees.length;

  /**
   * Called when a user selects a location in the table
   * Adds location id to the list if not in there already, removes it if it already was in there.
   * @param employeeId the id of the selected location
   */
  const rowSelected = (employeeId) => {
    if (selectedEmployees.includes(employeeId)) {
      // if row is already selected, un select it
      const newSelectedEmployees = selectedEmployees.filter((selectedEmployeeId) => selectedEmployeeId !== employeeId);
      setSelectedEmployees(newSelectedEmployees);
    } else {
      // row is not already selected, select it
      setSelectedEmployees([...selectedEmployees, employeeId]);
    }
  };

  const getRandomizedPinNumber = () => {
    let newPin = generateRandomPin();
    while (pins.has(newPin)) {
      newPin = generateRandomPin();
    }
    return newPin;
  };

  const saveNewEmployee = async (values, { resetForm }) => {
    setIsSaving(true);
    trackAddEmployee();

    try {
      const payload =
        values.level === "Manager"
          ? {
              customer_id: selectedCustomer?.customer_id,
              manager: {
                level: values.level,
                pin_number: values.pinNumber,
                user: {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  email: values.emailAddress,
                },
              },
            }
          : {
              customer_id: selectedCustomer?.customer_id,
              level: values.level,
              pin_number: values.pinNumber,
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.emailAddress,
              admin_note: "",
            };
      await axios.post("api/allbartenders", payload);
      await getEmployees();
      resetForm();
    } catch (error) {
      generalErrorAlert(error);
      if (error.response.status === 513) {
        await getEmployees();
      }
    }
    setIsSaving(false);
  };

  const deleteEmployee = async () => {
    trackBulkRemoveEmployees();

    try {
      const selectedEmployeesUsernames = employees
        .filter((employee) => selectedEmployees.includes(employee.user.id))
        .map((employee) => employee.user.username);
      const payload = {
        customer_id: selectedCustomer.customer_id,
        usernames: selectedEmployeesUsernames,
      };
      await axios.delete("api/allbartenders", { params: payload });
      await getEmployees();
      setSelectedEmployees([]);
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const deleteSingleEmployee = async (username) => {
    trackRemoveSingleEmployee();
    try {
      const payload = {
        customer_id: selectedCustomer.customer_id,
        username: username,
      };
      await axios.delete("api/allbartenders", { params: payload });
      await getEmployees();
    } catch (error) {
      generalErrorAlert(error);
    }
  };

  const employeeRowsSkeleton = () => {
    let skeletonRows = [];
    for (let i = 0; i < 3; i++) {
      skeletonRows.push(
        <tr key={i}>
          <td>
            <input type={"checkbox"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
          <td>
            <div className={"skeleton skeleton-div skeleton-div__sm"} />
          </td>
        </tr>
      );
    }
    return skeletonRows;
  };

  const duplicatePinEmployees = getDuplicatePinEmployees(employees);

  const employeesTable = () => {
    return (
      <div className={"card padding-2 employees-table-container"}>
        <div className={"margin-bottom-2 margin-x-1 employees-table-header"}>
          <span>
            <div style={{ display: "inline-block" }}>
              <h4>Employee List</h4>
            </div>
          </span>
          <span className={"table-action-row"}>
            {selectedEmployees.length > 0 && (
              <Button size={"sm"} variant={"danger"} onClick={deleteEmployee}>
                Delete
              </Button>
            )}
          </span>
        </div>
        <div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              emailAddress: "",
              pinNumber: "",
              level: "Bartender",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required("This field is required"),
              lastName: Yup.string().required("This field is required"),
              emailAddress: Yup.string()
                .email("Please enter a valid email")
                .test(
                  "unique-email",
                  "Email already taken",
                  (value, context) => !employees.map((employee) => employee.user.email).includes(value)
                )
                .required("This field is required"),
              pinNumber: Yup.string()
                .matches(/^[0-9]{4}$/, "Pin must be 4 digits")
                .test("unique-pin", "Pin not available", (value, context) => !pins.has(value))
                .required("This field is required"),
            })}
            onSubmit={saveNewEmployee}
          >
            {({ values, errors, touched, isValidating, setFieldValue }) => (
              <Form>
                <Table responsive className={"employees-table"}>
                  <thead>
                    <tr>
                      <th>
                        <input
                          type={"checkbox"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              // select all location codes
                              setSelectedEmployees(employees.map((employee) => employee.user.id));
                            } else {
                              // clear location codes
                              setSelectedEmployees([]);
                            }
                          }}
                        />
                      </th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Pin</th>
                      <th>
                        <span className={"margin-right-1"}>Level</span>
                        <OverlayTrigger
                          key={"placement"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={"tooltip-permission"}>
                              Note: Manager-level employees are allowed to log in to the TOP Owner Portal and the TOP
                              tablet app. Therefore, managers have more permission than bartenders or servers.
                            </Tooltip>
                          }
                        >
                          <div className="zmdi zmdi-info-outline dashboard-link-icon" />
                        </OverlayTrigger>
                      </th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>
                        <TextInput
                          testId={"first-name"}
                          name={"firstName"}
                          id={"first-name"}
                          className={"padding-x-0 margin-bottom-0"}
                          hidelabel={"true"}
                        />
                      </td>
                      <td>
                        <TextInput
                          testId={"last-name"}
                          name={"lastName"}
                          id={"last-name"}
                          className={"padding-x-0 margin-bottom-0"}
                          hidelabel={"true"}
                        />
                      </td>
                      <td>
                        <TextInput
                          testId={"email"}
                          name={"emailAddress"}
                          id={"email"}
                          className={"padding-x-0 margin-bottom-0"}
                          hidelabel={"true"}
                        />
                      </td>
                      <td className={"pin-cell"}>
                        <Field name="pinNumber">
                          {({ field, form: { touched, errors }, meta }) => (
                            <BtpForm.Group className={"padding-x-0 margin-bottom-0 pin-input-group"}>
                              <div
                                className={`pin-input-control form-control ${
                                  meta.touched && meta.error ? "invalid" : ""
                                }`}
                              >
                                <input {...field} data-test-id={"pin"} />
                                <span className={"icon-container"}>
                                  <i
                                    className={"zmdi zmdi-refresh-sync clickable-icon"}
                                    onClick={() => setFieldValue("pinNumber", getRandomizedPinNumber())}
                                  />
                                </span>
                              </div>
                              {meta.touched && meta.error && <div className={"pin-error"}>{meta.error}</div>}
                            </BtpForm.Group>
                          )}
                        </Field>
                      </td>
                      <td>
                        <SelectInput
                          testId={"level"}
                          name={"level"}
                          id={"level"}
                          className={"padding-x-0 margin-bottom-0"}
                          hidelabel={"true"}
                        >
                          <option value={"Manager"}>Manager</option>
                          <option value={"Owner"}>Owner</option>
                          <option value={"Server"}>Server</option>
                          <option value={"Bartender"}>Bartender</option>
                        </SelectInput>
                      </td>
                      <td>
                        <Button type={"submit"} disabled={isSaving} data-test-id={"submit"}>
                          {isSaving ? <Spinner animation={"border"} size={"sm"} /> : "Save"}
                        </Button>
                      </td>
                    </tr>
                    {isLoadingEmployees
                      ? employeeRowsSkeleton()
                      : employees.map((employee, index) => (
                          <tr key={index} data-test-id={"test-" + employee.user.email}>
                            <td>
                              <input
                                type={"checkbox"}
                                checked={selectedEmployees.includes(employee.user.id)}
                                onChange={(e) => {
                                  rowSelected(employee.user.id);
                                }}
                              />
                            </td>
                            <td>{employee.user.first_name}</td>
                            <td>{employee.user.last_name}</td>
                            <td>{employee.user.email}</td>
                            <td className={"pin-cell"}>{employee.pin_number}</td>
                            <td>{employee.level}</td>
                            <td>
                              <div
                                className={"d-flex justify-content-space-between padding-x-2"}
                                style={{
                                  marginTop: "5px",
                                }}
                              >
                                <i
                                  data-test-id={"test-edit-" + employee.user.email}
                                  className="zmdi zmdi-edit inline-icon clickable-icon"
                                  onClick={() => {
                                    trackEditEmployee();
                                    setEmployeeToEdit(employee);
                                  }}
                                />
                                <i
                                  className="zmdi zmdi-delete inline-icon clickable-icon"
                                  onClick={() => {
                                    deleteSingleEmployee(employee.user.username);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <BbotContainer>
      <Breadcrumbs name={"Edit Employees"} link={"/employees"} />

      <div className={"margin-bottom-2"}>
        <h2>Edit Employees</h2>
      </div>

      <div className={"margin-bottom-2"}>
        <p>
          Need a configuration setting to be added? Request by emailing bb-support@doordash.com. Click{" "}
          <a href={"https://central.bbot.menu/article/288-how-to-add-a-new-employee"}>here</a> from more info.
        </p>
      </div>

      {!!posIntegration && (
        <div className={"margin-bottom-3"}>
          <Button disabled={isLoadingEmployees} onClick={() => setShowEmployeeMappingModal(true)}>
            Link {capitalizeString(posIntegration.replaceAll("_", " "))} Employees To TOP
          </Button>
        </div>
      )}

      {duplicatePinsExist && (
        <Alert variant="warning" className={"margin-bottom-4 duplicate-pins-alert"}>
          <Alert.Heading className={"margin-bottom-2 alert-heading"}>
            <span>Setting Unique PINS</span>
            <span className={"alert-heading-button"}>
              <Button
                data-test-id={"set-unique-pins-need-help"}
                size={"sm"}
                variant={"warning"}
                onClick={() => setShowPinHelpModal(true)}
              >
                Need Help?
              </Button>
            </span>
          </Alert.Heading>
          <p>
            {`Some employee PINs are not unique. From your
              employee list, ${duplicatePinEmployees.length} out of ${employees.length} employees need to establish non-unique
              PINs. You can do this manually or by selecting the ‘Need Help?’
              button within this alert.`}
          </p>
        </Alert>
      )}

      {employeesTable()}

      {/*MODALS*/}

      <PinHelpModal
        show={showPinHelpModal}
        employees={employees}
        pins={pins}
        onHideCallback={() => setShowPinHelpModal(false)}
        onSubmitCallback={getEmployees}
        selectedCustomer={selectedCustomer}
      />

      <EditEmployeeModal
        show={!!employeeToEdit}
        onHideCallback={() => setEmployeeToEdit(null)}
        onSaveCallback={getEmployees}
        employee={employeeToEdit}
        employees={employees}
        selectedCustomer={selectedCustomer}
        allowedCustomersById={allowedCustomersById}
      />

      <MapIntegrationEmployees
        show={showEmployeeMappingModal}
        onHideCallback={() => setShowEmployeeMappingModal(false)}
        onSaveCallback={getEmployees}
        posIntegration={posIntegration}
        selectedCustomer={selectedCustomer}
        employees={employees}
        pins={pins}
      />
    </BbotContainer>
  );
};

export default ManageEmployees;
