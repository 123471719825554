import * as React from "react";
import { Modal } from "@doordash/component-modal";
import { ModalProps } from "../constants";

export const AddOrEditModifierModal: React.FC<ModalProps> = ({
  primaryActionProps,
  secondaryActionProps,
  setOpenModal,
  fields,
  updateForm,
  onClose,
}) => (
  <Modal
    title={"Edit Modifier"}
    // title={`${fields.real_property ? 'Edit' : 'New'} Modifier`}
    primaryAction={primaryActionProps}
    secondaryAction={secondaryActionProps}
    onClose={onClose}
    isFullScreenOnMobile
  >
    Add Or Edit Modifier Modal
  </Modal>
);
