import { notification } from "antd";
import Text from "antd/es/typography/Text";
import axios from "axios";
import { PRINTER_DRIVER_PRETTY_NAMES } from "Constants";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import {
  BbotButton,
  BbotModal,
  CheckboxInput,
  Col,
  Form,
  FormattedCheckboxGroupInput,
  NumberInput,
  Row,
  SelectInput,
  TextInput,
} from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const EditPrinterModal = ({
  showEditPrinterModal,
  selectedPrinter,
  onHideCallback,
  onSaveCallback,
  stations,
  allPrinters,
  driverProfiles,
  network,
  getLoadBalanceMasterChoices,
}) => {
  const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(false);
  const [editPrinterForm] = Form.useForm();
  const [, setPrinterFormValues] = useState({});
  const [testPrintForm] = Form.useForm();
  const [, setTestPrintFormValues] = useState({});
  const loadBalancingOptions = getLoadBalanceMasterChoices(allPrinters, selectedPrinter);
  const [printerInitialValues, setPrinterInitialValues] = useState({});

  const savePrinter = async (values) => {
    try {
      setSaveButtonDisabled(true);
      const payload = {
        printer_id: selectedPrinter.id,
        name: values.name,
        receipt_font_scale: values.receiptFontScale,
        num_tickets_to_print_at_checkout: values.checkoutNumTickets,
        ticket_font_scale: 2,
        extra_properties: { lineWidth: values.ticketLineWidth, order_number_at_bottom: values.order_number_at_bottom },
        local_ip: values.ipAddress,
        local_port: values.port,
        customer_id: values.customer,
        stations: values.isLoadBalanceWorker ? [] : values.stationsChecked,
        driver_profile: values.driverProfile,
        backup_printer: values.backupPrinter,
        prints_receipts: values.printsReceipts,
        loadbalance_master:
          values.isLoadBalanceWorker && values.loadBalanceMaster != null ? values.loadBalanceMaster : null,
      };
      await axios.put("printer-setup/printer-management/" + selectedPrinter.id, payload);
      notification.success({ message: "Printer " + selectedPrinter.name + " has been successfully updated." });
      setSaveButtonDisabled(false);
      onSaveCallback();
    } catch (error) {
      generalErrorAlert(error, "There was an error saving the printer info. Please refresh and try again.");
      setSaveButtonDisabled(false);
      console.error(error);
    }
  };

  const disconnectPrinter = async () => {
    try {
      await axios.delete("/printer-setup/printer-management/" + selectedPrinter.id);
      notification.success({ message: "Printer " + selectedPrinter.name + " was successfully disconnected" });
      onSaveCallback();
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  const sendTestPrint = async (values) => {
    try {
      if (values.printMethod === "custom" && !values.customMessage) {
        generalErrorAlert("Please Enter a Custom Message");
        return;
      }
      if (values.printMethod === "volumeTest" && !values.customQuantity) {
        generalErrorAlert("Please Enter a Custom Number of Tickets");
        return;
      }
      const payload = {
        printerId: selectedPrinter.id,
        printType: values.printMethod,
        printMessage: values.printMethod === "custom" ? values.customMessage : "",
        printQuantity: values.printMethod === "volumeTest" ? values.customQuantity : "",
      };
      await axios.post("/printer-setup/test-print", payload);
      notification.success({ message: "Successfully sent test print to printer: " + selectedPrinter.name });
    } catch (error) {
      generalErrorAlert(error, "Trouble connecting to the server. Please refresh the page and try again.");
      console.error(error);
    }
  };

  useEffect(() => {
    let printerInitialValues = {
      name: selectedPrinter?.name,
      ipAddress: selectedPrinter?.local_ip,
      port: selectedPrinter?.local_port,
      driverProfile: selectedPrinter?.driver_profile,
      stationsChecked: selectedPrinter?.stations
        .filter((station) => station.customer_id === selectedPrinter?.customer.customer_id)
        .map((station) => station.id),
      receiptFontScale: selectedPrinter?.receipt_font_scale,
      ticketFontScale: selectedPrinter?.ticket_font_scale,
      ticketLineWidth: selectedPrinter?.extra_properties.lineWidth,
      checkoutNumTickets: selectedPrinter?.num_tickets_to_print_at_checkout,
      printsReceipts: selectedPrinter?.prints_receipts,
      order_number_at_bottom: selectedPrinter?.extra_properties.order_number_at_bottom,
      backupPrinter: selectedPrinter?.backup_printer_id ? selectedPrinter.backup_printer_id : "",
      customer: selectedPrinter?.customer.customer_id,
      isLoadBalanceWorker: Boolean(selectedPrinter?.loadbalance_master),
      loadBalanceMaster: selectedPrinter?.loadbalance_master,
    };
    setPrinterInitialValues(printerInitialValues);
    editPrinterForm.setFieldsValue(printerInitialValues);
  }, [selectedPrinter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BbotModal
      title={selectedPrinter?.name}
      visible={showEditPrinterModal}
      onCancel={onHideCallback}
      cancelButtonProps={{
        danger: true,
        onClick: disconnectPrinter,
      }}
      cancelText={"Delete Printer"}
      onOk={editPrinterForm.submit}
      okText={"Submit"}
      okButtonProps={{
        loading: saveButtonDisabled,
      }}
    >
      <Form
        form={editPrinterForm}
        name="edit-printer-form"
        onFinish={savePrinter}
        layout={"vertical"}
        initialValues={printerInitialValues}
        onValuesChange={(_, values) => {
          setPrinterFormValues(values);
        }}
      >
        <div>
          <Row gutter={12}>
            <Col span={24}>
              <Text>Printer Type: </Text>
              <b>{selectedPrinter?.printer_type}</b>
            </Col>
          </Row>
          <LastHeaderRow gutter={12}>
            <Col span={24}>
              <Text>Last printed: </Text>
              <b>
                <Moment fromNow>{selectedPrinter?.last_print_time}</Moment>
              </b>
            </Col>
          </LastHeaderRow>
          <Row gutter={12}>
            <Col span={12}>
              <TextInput id={"name"} name={"name"} label={"Printer Name"} placeholder={""} sm={12} />
            </Col>
            <Col span={12}>
              <SelectInput
                id={"driver-profile"}
                name={"driverProfile"}
                label={"Driver Profile"}
                required={true}
                options={driverProfiles?.map((profile) => ({
                  label: PRINTER_DRIVER_PRETTY_NAMES.hasOwnProperty(profile)
                    ? PRINTER_DRIVER_PRETTY_NAMES[profile]
                    : profile,
                  value: profile,
                }))}
              />
            </Col>
          </Row>
          {selectedPrinter?.printer_type === "pi_ethernet" && (
            <Row gutter={12}>
              <Col span={12}>
                <TextInput
                  id="ip_address"
                  name="ipAddress"
                  label="Printer IP Address"
                  required={true}
                  regex={/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/}
                  regexValidationMessage={"Enter a valid IP Address"}
                />
              </Col>
              <Col span={12}>
                <NumberInput id="port" name="port" label="Printer Port (Default: 9100)" min={0} />
              </Col>
            </Row>
          )}
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                id={"backup-printer"}
                name={"backupPrinter"}
                label={"Backup Printer"}
                required={false}
                options={[{ label: "Any Available Printer", value: "" }].concat(
                  allPrinters?.map((printer, index) =>
                    printer.id !== selectedPrinter?.id
                      ? {
                          value: printer.id,
                          label: printer.name,
                        }
                      : {
                          value: printer.id,
                          label: "Disable Backup Printing",
                        }
                  )
                )}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={8}>
              <NumberInput name={"receiptFontScale"} id={"receipt-font-size"} label={"Printer Font Scale"} min={1} />
            </Col>
            <Col span={8}>
              <NumberInput
                name={"checkoutNumTickets"}
                id={"checkout-num-tickets"}
                label={"# Tickets to print"}
                min={1}
              />
            </Col>
            <Col span={8}>
              {/*Commented out until we fix fontscale bug so that the receipt fontScale only controls receipts and not both tickets and receipts*/}
              {/*<NumberInput name={"ticketFontScale"} id={"ticket-font-size"} label={"Ticket font scale"} md={12} />*/}
              <NumberInput name={"ticketLineWidth"} id={"ticket-line-width"} label={"Ticket line width"} min={1} />
            </Col>
          </Row>
          <Row gutter={12}>
            <CheckboxInput
              id={"print-receipts"}
              name={"printsReceipts"}
              label={"Check this box if you want this printer to print receipts."}
            />
          </Row>
          <Row gutter={12}>
            <CheckboxInput
              id={"order-number-at-bottom"}
              name={"order_number_at_bottom"}
              label={
                "Check this box if you want this printer to print order numbers at the bottom (in addition to the top)."
              }
            />
          </Row>
          <Row gutter={12}>
            <Col span={22}>
              <SelectInput
                id={"customer-select"}
                name={"customer"}
                label={"Which account owns this printer?"}
                typeAhead={true}
                options={Object.keys(stations || {})
                  .map((customer_id, index) => ({
                    value: customer_id,
                    label: stations[customer_id].customer_name,
                  }))
                  .sort((customer1, customer2) => customer1.label.localeCompare(customer2.label))}
                onChange={() => {
                  editPrinterForm.setFieldsValue("stationsChecked");
                }}
              />
            </Col>
          </Row>
          <Row gutter={12}>
            <CheckboxInput
              id="isLoadBalanceWorker"
              name="isLoadBalanceWorker"
              label="This printer only prints tickets for another printer (to balance the workload)"
            />
          </Row>
          {editPrinterForm.getFieldValue("isLoadBalanceWorker") && (
            <Row gutter={12}>
              <Col span={22}>
                <SelectInput
                  id={"loadBalanceMaster"}
                  name={"loadBalanceMaster"}
                  typeAhead={true}
                  options={loadBalancingOptions}
                />
                <p>
                  This printer will not be shown on the Menu Item editor. (And any menu items currently set to print at
                  it will no longer print at it.) Instead, some tickets sent to{" "}
                  {editPrinterForm.getFieldValue("loadBalanceMaster") != null
                    ? loadBalancingOptions?.find(
                        (option) => option.value === editPrinterForm.getFieldValue("loadBalanceMaster")
                      )?.label
                    : "the selected parent printer"}{" "}
                  will print at {editPrinterForm.getFieldValue("name") || "this printer"} , on a rotating basis, to
                  balance the workload.
                </p>
              </Col>
            </Row>
          )}
          {stations && !stations.isEmpty && !editPrinterForm.getFieldValue("isLoadBalanceWorker") && (
            <Row gutter={12}>
              <Col span={24}>
                <FormattedCheckbox
                  id={"stations-checked"}
                  name={"stationsChecked"}
                  label={"Stations this Printer Serves"}
                  columns={1}
                  style={{ marginBottom: 20 }}
                  required={false}
                  options={
                    stations[editPrinterForm.getFieldValue("customer")]?.stations?.map((station) => ({
                      label: station.station_name,
                      value: station.id,
                    })) ?? []
                  }
                />
              </Col>
            </Row>
          )}
        </div>
      </Form>
      <Form
        form={testPrintForm}
        name="edit-printer-form"
        onFinish={sendTestPrint}
        layout={"vertical"}
        initialValues={{
          printMethod: "test",
          customMessage: "",
          customQuantity: 1,
        }}
        onValuesChange={(_, values) => {
          setTestPrintFormValues(values);
        }}
      >
        <div>
          <Row gutter={12}>
            <Col span={24}>
              <SelectInput
                id="test-print-selection"
                label="To send a Test Print, select one of the test print options below then click Send Test Print"
                name="printMethod"
                required={false}
                options={[
                  { value: "test", label: "Print Test print from TOP to " + selectedPrinter?.local_ip },
                  { value: "latest", label: "Print a recent ticket" },
                  { value: "random", label: "Print a random receipt" },
                  { value: "recent", label: "Print recent orders" },
                  { value: "width", label: "Print line width chart" },
                  { value: "custom", label: "Custom print message" },
                  { value: "volumeTest", label: "Test High Volume Printing" },
                ]}
              />
            </Col>
          </Row>
          {testPrintForm.getFieldValue("printMethod") === "custom" && (
            <Row gutter={12}>
              <TextInput
                id={"customMessage"}
                name={"customMessage"}
                label={"Enter a Custom Print Message"}
                placeholder={""}
                sm={12}
              />
            </Row>
          )}
          {testPrintForm.getFieldValue("printMethod") === "volumeTest" && (
            <Row gutter={12}>
              <NumberInput
                id={"customQuantity"}
                name={"customQuantity"}
                label={"Enter a Number of Tickets to Print"}
                min={1}
                max={100}
                sm={12}
              />
            </Row>
          )}
          <BbotButton id={"send-test-print-button"} type={"primary"} onClick={testPrintForm.submit}>
            Send Test Print
          </BbotButton>
        </div>
      </Form>
    </BbotModal>
  );
};

const LastHeaderRow = styled(Row)`
  margin-bottom: 20px;
`;

const FormattedCheckbox = styled(FormattedCheckboxGroupInput)`
  margin-bottom: 20px;
`;

EditPrinterModal.propTypes = {
  showEditPrinterModal: PropTypes.bool.isRequired,
  onHideCallback: PropTypes.func.isRequired,
  onSaveCallback: PropTypes.func.isRequired,
  selectedPrinter: PropTypes.object,
  allPrinters: PropTypes.array.isRequired,
  driverProfiles: PropTypes.array,
};

export default EditPrinterModal;
