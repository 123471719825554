import { BbotButton, BbotModal, Col, Row } from "top-component-library";

const ResetWebhookModal = (props) => {
  const { show, onCancel, onConfirm } = props;
  return (
    <BbotModal
      title={"Confirm Webhook Credentials Reset"}
      visible={show}
      onCancel={onCancel}
      closable
      footer={[
        <BbotButton
          key={1}
          type={"default"}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </BbotButton>,
        <BbotButton
          key={2}
          type={"primary"}
          onClick={() => {
            onConfirm();
          }}
        >
          Confirm
        </BbotButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          <div className={"margin-bottom-2"}>
            By clicking "Confirm", you agree to reset your API Client Key and API Secret Key.
          </div>
          <div className={"margin-bottom-2"}>
            After clicking "Confirm", you will be shown your new API Client Key and API Secret Key exactly once. Make
            sure to copy and paste them into LastPass or another secure password management tool. You will not be able
            to view them again on this page.
          </div>
          <div className={"margin-bottom-2"}>If you wish to cancel this request, please press "Cancel".</div>
        </Col>
      </Row>
    </BbotModal>
  );
};

export default ResetWebhookModal;
