import React from "react";
import { DVSClientClass } from "@doordash/lib-dvs-client";
import { DVSClientContext, DVSClientProvider } from "@doordash/lib-dvs-client/lib/react";
import { Colors } from "@doordash/design-language";
import Loading from "@doordash/component-loading";

export const DVSClient = new DVSClientClass(
  process.env.APP_ENV === "staging"
    ? {
        dvsEdgeUrl: "https://dynamic-values-edge-service.doordash.com",
      }
    : {
        dvsEdgeUrl: "https://dynamic-values-edge-service.doordash.com",
      }
);

export const OwnerDVSClientProvider: React.FC = ({ children }) => {
  DVSClient.init({
    application: "bbot",
    appVersion: "1",
    os: "web",
    exposuresEnabled: false,
    namespaces: ["bbot"],
  });

  React.useEffect(() => {
    const user_id = "";
    const user_email = "Unknown";

    DVSClient.evaluate({
      user_id,
      user_email,
    });
  }, []);

  return <DVSClientProvider client={DVSClient}>{children}</DVSClientProvider>;
};

export const DVSLoadingWrapper: React.FC = ({ children }) => {
  const { state } = React.useContext(DVSClientContext);

  if (state !== "ready") {
    return <Loading color={Colors.SystemBlue70} />;
  }

  return <>{children}</>;
};

export default OwnerDVSClientProvider;
