/* eslint-disable no-unused-expressions */
import { withRouter } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import Table from "@doordash/component-table";
import { Inset, Text } from "@doordash/design-language";

const HandheldDevices = () => {
  const [handheldDevices, setHandheldDevices] = useState([]);

  const convertDatesToHumanReadable = (objects) =>
    objects.map((object) => {
      const humanReadableLastPollTime = moment(object.last_poll_time).format("ddd, MMM D, YYYY h:mm A");
      const humanReadableRegisteredOn = moment(object.registered_on).format("ddd, MMM D, YYYY h:mm A");

      object.last_poll_time = humanReadableLastPollTime;
      object.registered_on = humanReadableRegisteredOn;

      return object;
    });

  useEffect(() => {
    const getHandheldDevices = async () => {
      try {
        const res = await axios.get("/api/ownerdevices");
        setHandheldDevices(convertDatesToHumanReadable(res.data.devices));
        return;
      } catch (error) {
        console.error(error, "Trouble getting handheld devices. Please refresh and try again.");
      }
    };
    getHandheldDevices();
  }, [setHandheldDevices]);

  return handheldDevices?.length > 0 ? (
    <Inset size={Inset.Sizes.XLarge}>
      <Text style={Text.Styles.Bold32}>Available Devices</Text>
      <Table
        columns={[
          { name: "Name", accessor: "name" },
          { name: "Unique ID", accessor: "unique_id" },
          { name: "Config Name", accessor: "config_name" },
          { name: "Environment", accessor: "release_channel" },
          { name: "Version", accessor: "apk_version" },
          { name: "Platform", accessor: "platform" },
          { name: "Registered", accessor: "registered_on" },
          { name: "Device Manager", accessor: "mdm" },
          { name: "Last Connected", accessor: "last_poll_time" },
        ]}
        data={handheldDevices}
      ></Table>
    </Inset>
  ) : null;
};

export default withRouter(HandheldDevices);
