import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Spin } from "top-component-library";

const PageLoadingSpinner = ({ ...props }) => {
  return <Spin indicator={<StyledLoadingIcon />} {...props} />;
};

const StyledLoadingIcon = styled(LoadingOutlined)`
  color: var(--color-primary__regular);
  font-size: 32px;
`;

export default PageLoadingSpinner;
