/* eslint-disable react-hooks/exhaustive-deps */
import { Text } from "@doordash/design-language";
import { LinkText } from "./styles";
import { sortBy } from "lodash";

const { Medium18, Bold14, Bold12, Medium12 } = Text.Styles;

export const MenuHeadingUpsells = ({ menuHeadings, selectedElement, setUpsells }) => (
  <Text styles={Medium12}>
    <Text styles={Medium18}>Menu Heading Upsells</Text>
    {menuHeadings.length !== 0 &&
      sortBy(menuHeadings, "heading_name")?.map((heading) => (
        <LinkText
          styles={selectedElement === heading.id ? Bold12 : Medium12}
          selected={selectedElement === heading.id}
          onClick={() => setUpsells(heading.id)}
          key={heading.id}
        >
          {heading.heading_name}
        </LinkText>
      ))}
  </Text>
);

export const MenuItemUpsells = ({ menuItems, menus, selectedElement, setUpsells }) => (
  <>
    <Text styles={Medium18}>Menu Item Upsells</Text>
    {menus.length !== 0 &&
      menus?.map((menu, index) => (
        <Text styles={Medium12}>
          <Text styles={Bold14}>{menu.menuName}</Text>
          {sortBy(menuItems[index], "name").map((menuItem) => (
            <LinkText
              key={menuItem.id}
              styles={selectedElement === menuItem.id ? Bold12 : Medium12}
              selected={selectedElement === menuItem.id}
              onClick={() => setUpsells(menuItem.id)}
            >
              {menuItem.name}
            </LinkText>
          ))}
        </Text>
      ))}
  </>
);
