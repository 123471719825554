import axios from "axios";
import { useEffect } from "react";

import { useState } from "react";
import {
  BbotModal,
  CheckboxInput,
  Col,
  Form,
  notification,
  Row,
  SelectInput,
  TextInput,
  URLInput,
} from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const CreateEditConnectPartnerModal = (props) => {
  const { onClose, onSave, selectedPartner, mode, selectedInfo, show } = props;
  const [modalInputForm] = Form.useForm();
  const [buttonMode, setButtonMode] = useState(mode === "create" ? false : selectedInfo?.type === "button");
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    setButtonMode(mode === "create" ? false : selectedInfo?.type === "button");
    let newFormValues =
      mode === "create"
        ? {
            type: "text",
            label: "",
            description: "", // Will not be set for buttons
            url: "", // To be set for buttons only
            required: true,
          }
        : {
            type: selectedInfo?.type,
            label: selectedInfo?.label,
            description: selectedInfo?.description || "",
            url: selectedInfo?.url || "",
            required: selectedInfo?.required,
          };
    setInitialValues(newFormValues);
    modalInputForm.setFieldsValue(newFormValues);
  }, [selectedInfo, mode, modalInputForm]);

  const addConnectCardInfo = async (values) => {
    const connectPartnerInfo = selectedPartner.connect_partner_info;
    const newKey = values.label.toLowerCase().replace(/ /g, "_");

    // Delete old selectedInfo field if in edit mode
    if (mode === "edit") delete connectPartnerInfo[selectedInfo?.key];

    // Prevent identical keys in connect_partner_info when creating
    if (connectPartnerInfo[newKey] && mode === "create") {
      notification.error({
        message: "That label already exists. Please choose a different one.",
      });
      return;
    }
    const newInfo = {
      key: newKey,
      type: values.type,
      label: values.label,
      display_order: mode === "create" ? Object.keys(connectPartnerInfo).length + 1 : selectedInfo?.display_order,
      required: values.required,
    };
    if (values.type === "button") newInfo.url = values.url;
    else newInfo.description = values.description;

    connectPartnerInfo[newKey] = newInfo;

    const payload = {
      partnerId: selectedPartner.id,
      propertiesToEdit: { connect_partner_info: connectPartnerInfo },
    };

    try {
      await axios.post("/owner/editPartnerProperties", payload);
      notification.success({
        message: "Successfully updated sign up requirements.",
      });
      modalInputForm.resetFields();
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble updating sign up requirements.", true);
    }
  };

  return (
    <BbotModal
      title={"Add New Input for your App Connection Modal"}
      visible={show}
      onCancel={() => {
        modalInputForm.resetFields();
        onClose();
      }}
      closable
      okText={"Submit"}
      okButtonProps={{
        htmlType: "submit",
        form: "modalInputForm",
      }}
    >
      <Form
        name={"modalInputForm"}
        form={modalInputForm}
        onFinish={addConnectCardInfo}
        layout={"vertical"}
        initialValues={initialValues}
      >
        <Row>
          <Col span={24}>
            <SelectInput
              id={"type"}
              label={"Input Type"}
              name={"type"}
              options={[
                { value: "text", label: "Text" },
                { value: "url", label: "URL" },
                { value: "number", label: "Number" },
                { value: "password", label: "Password" },
                { value: "checkbox", label: "Checkbox" },
                { value: "button", label: "Button" },
              ]}
              onChange={(value) => {
                if (value === "button") setButtonMode(true);
                else setButtonMode(false);
              }}
              required={true}
              initialValue={mode === "create" ? "text" : selectedInfo?.type}
            />
            <TextInput id={"label"} label={"Input Label"} name={"label"} required={true} />
            {buttonMode ? (
              <URLInput id={"url"} label={"Redirect URL"} name={"url"} required={false} />
            ) : (
              <>
                <TextInput id={"description"} label={"Input Description"} name={"description"} required={false} />
                <CheckboxInput id={"required"} label={"Is this a required field?"} name={"required"} />
              </>
            )}
          </Col>
        </Row>
      </Form>
    </BbotModal>
  );
};

export default CreateEditConnectPartnerModal;
