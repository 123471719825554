import axios from "axios";
import CreateEditCardRateModal from "components/owner-app/modals/card-processing-rates/CreateEditCardRateModal";
import DeleteCardRateModal from "components/owner-app/modals/card-processing-rates/DeleteCardRateModal";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { centsToDollar, fractionToPercentage, generalErrorAlert } from "util/Utils";
import { BbotAlert, BbotTable, Breadcrumbs, Col, Row, TableEditDeleteIcons } from "../../top-component-library";

const CardProcessingRates = (props) => {
  const { selectedCustomer, userInfo } = props;
  const userIsBbotSuperAdmin = userInfo?.teams.includes("bbot") && userInfo?.permissions.includes("manage_admins");

  const [creditCardRates, setCreditCardRates] = useState([]);
  const [createEditCardRateModalMode, setCreateEditCardRateModalMode] = useState(null);
  const [showCreateEditCardRateModal, setShowCreateEditCardRateModal] = useState(false);
  const [showDeleteCardRateModal, setShowDeleteCardRateModal] = useState(false);
  const [selectedCardRate, setSelectedCardRate] = useState(null);

  useEffect(() => {
    if (selectedCustomer) {
      getCardProcessingRates();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCardProcessingRates = async () => {
    try {
      const res = await axios.get("/admin/getCardProcessingRates", {
        params: {
          customer_id: selectedCustomer?.customer_id,
        },
      });
      setCreditCardRates(res.data.card_rates);
    } catch (error) {
      generalErrorAlert(error, "Trouble saving card processing rate. Please refresh and try again.");
    }
  };

  return (
    <div>
      <div className={"margin-x-5"}>
        <Breadcrumbs name={"Card Processing Rates"} link={"/card-processing-rates"} />
        <Row>
          <Col>
            <div className="margin-bottom-4">
              <h2>
                Customizable Card Processing Rates For{" "}
                <span className="color-primary__dark">{selectedCustomer?.customer_name}</span>
              </h2>
            </div>
            <div className="margin-bottom-4">
              <p>
                If there are no customizable card processing rates for this customer, the default rates will be used. If
                there are multiple card processing rates for this customer, the rates with the latest start date will be
                used.
              </p>
              {!userIsBbotSuperAdmin && (
                <BbotAlert
                  message="Warning"
                  description="You are currently viewing this in read-only mode,
                    as you do not have permissions to create, edit, or delete
                    card processing rates."
                  type="warning"
                  showIcon
                  closable
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className={"margin-bottom-4"}>
          <Col span={24}>
            <BbotTable
              id={"announcements-table"}
              title={"Card Processing Rates"}
              columns={[
                "Start Date",
                "End Date",
                "Card Present",
                "Card Not Present",
                "Auth Cancelled",
                "Auth Increment",
                "Actions",
              ]}
              data={creditCardRates.map((creditCardRate, index) => ({
                key: index,
                start_date: DateTime.fromISO(creditCardRate.start_date).toLocaleString(),
                end_date: DateTime.fromISO(creditCardRate.end_date).toLocaleString(),
                card_present:
                  fractionToPercentage(creditCardRate.card_present_fraction, 3) +
                  " % + $" +
                  centsToDollar(creditCardRate.card_present_cents),
                card_not_present:
                  fractionToPercentage(creditCardRate.card_not_present_fraction, 3) +
                  " % + $" +
                  centsToDollar(creditCardRate.card_not_present_cents),
                auth_cancelled:
                  fractionToPercentage(creditCardRate.auth_canceled_fraction, 3) +
                  " % + $" +
                  centsToDollar(creditCardRate.auth_canceled_cents),
                auth_increment:
                  fractionToPercentage(creditCardRate.auth_increment_fraction, 3) +
                  " % + $" +
                  centsToDollar(creditCardRate.auth_increment_cents),
                actions: (
                  <div>
                    <TableEditDeleteIcons
                      onEdit={() => {
                        if (!userIsBbotSuperAdmin) return;
                        setCreateEditCardRateModalMode("edit");
                        setShowCreateEditCardRateModal(true);
                        setSelectedCardRate(creditCardRate);
                      }}
                      onDelete={() => {
                        if (!userIsBbotSuperAdmin) return;
                        setShowDeleteCardRateModal(true);
                        setSelectedCardRate(creditCardRate);
                      }}
                    />
                  </div>
                ),
              }))}
              onAdd={() => {
                setCreateEditCardRateModalMode("create");
                setShowCreateEditCardRateModal(true);
              }}
              addButtonText="New Card Processing Rate"
            />
          </Col>
        </Row>
      </div>
      <CreateEditCardRateModal
        show={showCreateEditCardRateModal}
        mode={createEditCardRateModalMode}
        selectedCustomer={selectedCustomer}
        selectedCardRate={selectedCardRate}
        onClose={() => setShowCreateEditCardRateModal(false)}
        onSave={getCardProcessingRates}
        userInfo={userInfo}
      />
      <DeleteCardRateModal
        show={showDeleteCardRateModal}
        selectedCardRate={selectedCardRate}
        onClose={() => setShowDeleteCardRateModal(false)}
        onSave={getCardProcessingRates}
      />
    </div>
  );
};

export default CardProcessingRates;
