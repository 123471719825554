import AddEditStaffAlert from "pages/support-alerts/AddEditStaffAlert";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { BbotModal, BbotTable, notification, TableEditDeleteIcons } from "top-component-library";
import { deleteSupportAlert } from "util/ApiCalls";
import { capitalizeString, splitSnakeCase } from "util/Utils";

const StaffAlerts = ({
  staffAlerts,
  possibleStations,
  isLoading,
  selectedCustomer,
  setTabHeaderVisibility,
  refreshData,
  ...props
}) => {
  const [showAddWizard, setShowAddWizard] = useState(false);
  const [alertToEdit, setAlertToEdit] = useState(null);
  const [alertToDelete, setAlertToDelete] = useState(null);

  useEffect(() => {
    setShowAddWizard(false);
    setAlertToEdit(null);
  }, [selectedCustomer]);

  const onSaveOrEditCallback = () => {
    setShowAddWizard(false);
    setAlertToEdit(null);
    setTabHeaderVisibility(false);
    refreshData();
  };

  const onDelete = async () => {
    await deleteSupportAlert(alertToDelete.id);
    setAlertToDelete(null);
    notification.success({ message: "Staff alert deleted successfully." });
    refreshData();
  };

  const formatAlerts = (staffAlerts) => {
    return staffAlerts.map((staffAlert, i) => (
      <p key={i}>{capitalizeString(splitSnakeCase(staffAlert.alert_class))}</p>
    ));
  };

  const formattedAlertRows = staffAlerts.map((row) => {
    return {
      key: row.id,
      recipient: row.name,
      alert_description: formatAlerts(row.support_alerts),
      alert_actions: (
        <TableEditDeleteIcons
          onEdit={() => {
            setAlertToEdit(staffAlerts.find((staffAlert) => staffAlert.id === row.id));
            setTabHeaderVisibility(true);
          }}
          onDelete={() => setAlertToDelete(staffAlerts.find((staffAlert) => staffAlert.id === row.id))}
        />
      ),
    };
  });

  return (
    <>
      <StyledInfoBox>
        Based on your venue's hours, these automatic messages from our server alert management if a printer or tablet
        goes offline causing DoorDash's orders to be unfulfillable, or if an order has not been accepted for a
        significant amount of time. <br />
      </StyledInfoBox>
      {showAddWizard || alertToEdit ? (
        <AddEditStaffAlert
          onCancel={() => {
            setShowAddWizard(false);
            setAlertToEdit(null);
            setTabHeaderVisibility(false);
          }}
          onSaveOrEditCallback={onSaveOrEditCallback}
          selectedCustomer={selectedCustomer}
          possibleStations={possibleStations}
          editMode={Boolean(alertToEdit)}
          alertToEdit={alertToEdit}
        />
      ) : (
        <BbotTable
          id={"staff-alerts"}
          data={formattedAlertRows}
          columns={["Recipient", "Alert Description", "Alert Actions"]}
          loading={isLoading}
          onAdd={() => setShowAddWizard(true)}
          interactive={false}
          pagination={staffAlerts.length > 10}
        />
      )}
      <BbotModal
        title="Are you sure you'd like to delete this alert?"
        visible={alertToDelete}
        onCancel={() => setAlertToDelete(null)}
        okText="Delete"
        okType="danger"
        onOk={onDelete}
      >
        You are about to delete the terminal alert from the device profile: <strong>{alertToDelete?.name}</strong>
      </BbotModal>
    </>
  );
};

const StyledInfoBox = styled.div`
  font-size: 14px;
  color: var(--color-neutral__placeholder);
  margin-bottom: 24px;
`;

export default StaffAlerts;
