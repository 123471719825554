// Prism Components
import { Icon } from "@doordash/design-language";
import Tooltip from "@doordash/component-tooltip";

// Styles
import "./ToolTip.scss";

// Utils
import {
  getDoveTailToolTipBodyText,
  getDovetailIconType,
  DEFAULT_TEXT_COLOR_HEX,
} from "../../pages/owner-app-dashboard/dovetail";
import { isMobile } from "../../pages/owner-app-dashboard/utils";

const ToolTip = ({ link, isToolTipVisible, setIsToolTipVisible, isOwnerFlagged }) => {
  const tooltipBodyText = getDoveTailToolTipBodyText(link.name, isOwnerFlagged);
  const toggleToolTip = (event, toggle) => {
    event.stopPropagation();
    event.preventDefault();
    setIsToolTipVisible(toggle);
  };

  return (
    <span>
      <Tooltip
        isVisible={isToolTipVisible}
        onClose={() => setIsToolTipVisible(false)}
        shouldCloseBeVisible={isMobile}
        position={"top"}
        content={<div className="tool-tip-text" dangerouslySetInnerHTML={{ __html: tooltipBodyText }}></div>}
      >
        {() => (
          <span onClick={(e) => toggleToolTip(e, !isToolTipVisible)}>
            <Icon
              type={getDovetailIconType(link.name)}
              color={DEFAULT_TEXT_COLOR_HEX}
              size={18}
              onMouseLeave={(e) => (isMobile ? null : toggleToolTip(e, false))}
              onMouseEnter={(e) => (isMobile ? null : toggleToolTip(e, true))}
            />
          </span>
        )}
      </Tooltip>
    </span>
  );
};
export default ToolTip;
