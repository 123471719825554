import axios from "axios";
import React from "react";

import { Badge, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BbotToggle from "top-component-library/BbotToggle";

import { toast } from "react-toastify";
import "./FeatureManagement.scss";

class FeatureManagement extends React.Component {
  state = {
    featuresEnabled: [],
    features: [],
  };

  componentDidMount() {
    this.getEnabledFeatures();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getEnabledFeatures();
    }
  }

  getEnabledFeatures = async () => {
    const { selectedCustomer } = this.props;
    if (!selectedCustomer) return;
    try {
      const res = await axios.get("/admin/getCustomerEnabledFeatures", {
        params: {
          customer_id: selectedCustomer.customer_id,
        },
      });
      this.setState({
        featuresEnabled: res.data.features_enabled,
        features: res.data.all_features,
      });
    } catch (error) {
      console.error(error);
    }
  };

  toggleHit = async (e, feature) => {
    const { selectedCustomer, getAllowedCustomers } = this.props;
    const enabled = e.SWITCH_STATE.enabled;
    const payload = {
      customer_id: selectedCustomer.customer_id,
      enabled: enabled,
      feature: feature?.feature_id,
    };
    try {
      await axios.post("/admin/setCustomerFeatureToggle", payload);

      toast.success(
        `Successfully ${enabled ? "enabled" : "disabled"} ${feature?.name} for ${selectedCustomer.name_for_admin}.`
      );

      this.getEnabledFeatures();
      getAllowedCustomers();
    } catch (error) {
      console.error(error);
    }
  };

  render = () => {
    const { features, featuresEnabled } = this.state;
    return (
      <Container>
        <Row>
          <Col>
            <div className={"breadcrumbs-row margin-bottom-4"}>
              <span>
                <Link to={"/"} className={"breadcrumb-back"}>
                  &#60; Back
                </Link>
              </span>
              <span className={"breadcrumbs"}>
                <span>
                  <Link to={"/"} className={"breadcrumb-link"}>
                    Owner Portal
                  </Link>
                </span>
                <span className={"breadcrumb-link"}>&#62;</span>
                <span>
                  <Link to={"/feature-management"} className={"breadcrumb-link"}>
                    Feature Management
                  </Link>
                </span>
              </span>
            </div>
          </Col>
        </Row>
        <Row className={"margin-bottom-6"}>
          <Col>
            <div className={"margin-bottom-4"}>
              <h2>Feature Management</h2>
            </div>
            <div className={"margin-bottom-4"}>
              {features.length > 0 ? (
                <p>
                  Use the toggles to switch features on and off. Toggling a switch will immediately affect the control
                  and access to the feature for the selected customer.
                </p>
              ) : (
                <p>
                  There aren't any betas/features configured yet. Once features are added to this page, you will be able
                  to control customer access to them by flipping their toggles on/off.
                </p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {features.map((feature, i) => {
            const enabled = featuresEnabled?.includes(feature.feature_id);

            return (
              <Col className={"padding-right-6 margin-bottom-8"} md={6} key={i}>
                <div className={"margin-bottom-2 feature-container"}>
                  <span className={"feature-header"}>
                    <h3 className={"margin-right-1"}>{feature.name}</h3>
                    {feature.beta && (
                      <Badge pill variant={"secondary"}>
                        BETA
                      </Badge>
                    )}
                  </span>
                  <span>
                    <BbotToggle defaultEnabled={enabled} onClick={(e) => this.toggleHit(e, feature)} />
                  </span>
                </div>
                <div className={"margin-bottom-3"}>
                  <p>{feature.description}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    );
  };
}

export default FeatureManagement;
