import axios from "axios";
import { assoc, assocPath } from "ramda";
import React, { useEffect } from "react";
import "./activity-feed.scss";

// Components
import { DateTime } from "luxon";
import styled from "styled-components";
import { BbotButton, Card, Checkbox, Col, List, Row } from "top-component-library";
import { generalErrorAlert } from "util/Utils";
import Detail from "./Detail";

const LEVELS = [
  {
    className: "zmdi zmdi-info-outline level-info",
    label: "Good To Know",
    levelType: "info",
    levelValue: 0,
  },
  {
    className: "zmdi zmdi-alert-triangle level-warn",
    label: "Potential Problem",
    levelType: "warn",
    levelValue: 1,
  },
  {
    className: "zmdi zmdi-alert-octagon level-error",
    label: "Problem",
    levelType: "error",
    levelValue: 2,
  },
];

const ViewOptions = ({ options, onOptionsChange, contentAtEnd }) => {
  const levels = options.levels;
  return (
    <div className={"d-flex justify-content-space-between"}>
      <div className={"d-flex"}>
        <div className={"margin-right-2"}>
          <div>Levels</div>
          {LEVELS.map((level) => (
            <div key={level.label}>
              <Checkbox
                checked={levels[level.levelType]}
                onChange={(e) => {
                  onOptionsChange({ ...options, levels: { ...options.levels, [level.levelType]: e.target.checked } });
                }}
              >
                <span className={"margin-right-1"}>
                  <i className={level.className} />
                </span>
                {level.label}
              </Checkbox>
            </div>
          ))}
        </div>

        {Object.keys(options.categories).length > 0 && (
          <div>
            <div>Categories</div>
            {options.categories ? (
              Object.entries(options.categories).map(([name, checked]) => (
                <Checkbox
                  checked={checked}
                  key={name}
                  onChange={() => onOptionsChange(assocPath(["categories", name], !checked, options))}
                >
                  {name}
                </Checkbox>
              ))
            ) : (
              <i>None</i>
            )}
          </div>
        )}
      </div>

      <div>{contentAtEnd}</div>
    </div>
  );
};

const ActivityFeed = ({ endpoint, endpointParameters, pollInterval }) => {
  const [entries, setEntries] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [viewOptions, setViewOptions] = React.useState({
    levels: { info: true, warn: true, error: true },
    categories: {},
  });

  const getFeeds = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(endpoint, {
        params: endpointParameters,
      });
      setEntries(res.data.entries);
    } catch (error) {
      generalErrorAlert(error, `Could not load logs from ${endpoint}.`, true);
    } finally {
      setIsLoading(false);
    }
  };

  const updatedFilteredEntries = (entries, viewOptions) => {
    const filterCategory = (category) => viewOptions.categories[category];
    const filterLevel = (levelIndex) => {
      return viewOptions.levels[LEVELS[levelIndex].levelType];
    };
    return entries.filter((entry) => filterCategory(entry.category) && filterLevel(entry.level));
  };

  const updatedViewOptions = (entries, viewOptions) => {
    const oldCategories = new Set(Object.keys(viewOptions.categories));
    const newCategories = entries.reduce((newCategories, entry) => {
      if (oldCategories.has(entry.category)) {
        // Preserve checked state for previously existing category
        newCategories[entry.category] = viewOptions.categories[entry.category];
      } else {
        newCategories[entry.category] = true;
      }
      return newCategories;
    }, {});
    return assoc("categories", newCategories, viewOptions);
  };

  useEffect(() => {
    if (pollInterval) {
      const id = setInterval(() => {
        getFeeds();
      }, pollInterval * 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [pollInterval]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get feed whenever selected customer changes
  useEffect(() => {
    if (endpointParameters) {
      getFeeds();
    }
  }, [endpoint, endpointParameters]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update view options with available categories whenever entries change
  useEffect(() => {
    setViewOptions(updatedViewOptions(entries, viewOptions));
  }, [entries]); // eslint-disable-line react-hooks/exhaustive-deps

  // if the filter box is empty, don't filter.  If entry.affected_objects is null, the filter command will err out, and those should be filtered out anyway if any filtering is happening
  const getSortedAndFilteredEntries = () => {
    const sortedEntries = entries.sort((entryA, entryB) =>
      DateTime.fromISO(entryA.timestamp) < DateTime.fromISO(entryB.timestamp) ? 1 : -1
    );

    return updatedFilteredEntries(sortedEntries, viewOptions);
  };

  const sortedAndFilteredEntries = getSortedAndFilteredEntries();

  return (
    <div className="activity-feed">
      <Detail entry={sortedAndFilteredEntries[selectedRow]} onHide={() => setSelectedRow(null)} />
      <Card className={"margin-y-4"}>
        <ViewOptions
          options={viewOptions}
          onOptionsChange={setViewOptions}
          contentAtEnd={
            <BbotButton
              loading={isLoading}
              type={"primary"}
              onClick={() => {
                getFeeds();
              }}
            >
              Refresh
            </BbotButton>
          }
        />
      </Card>

      <Card>
        <List
          loading={isLoading}
          dataSource={sortedAndFilteredEntries}
          renderItem={(event, index) => (
            <StyledEvent onClick={() => setSelectedRow(index)}>
              <List.Item.Meta
                description={
                  <Row gutter={8}>
                    <Col xs={24} md={6}>
                      <span className={"margin-right-1"}>
                        <i className={LEVELS[event.level].className} />
                      </span>
                      <span>{event.category}</span>
                    </Col>
                    <Col xs={24} md={12}>
                      <div>
                        <b>{event.title}</b>
                      </div>
                      <div>{event.description}</div>
                    </Col>
                    <Col xs={24} md={6}>
                      {DateTime.fromISO(event.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}
                    </Col>
                  </Row>
                }
              />
            </StyledEvent>
          )}
        />
      </Card>
    </div>
  );
};

const StyledEvent = styled(List.Item)`
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--color-neutral__page-background);
  }
`;

export default ActivityFeed;
