import * as React from "react";
import { Modal } from "@doordash/component-modal";
import { ModalProps } from "../constants";
import { TextField } from "@doordash/component-fields";
import Toggle from "@doordash/component-toggle";

export const AddOrEditTagModal: React.FC<ModalProps> = ({
  primaryActionProps,
  secondaryActionProps,
  setOpenModal,
  fields,
  updateForm,
  onClose,
}) => (
  <Modal
    title={`${fields.tag_name ? "Edit" : "New"} Tag`}
    primaryAction={primaryActionProps}
    secondaryAction={secondaryActionProps}
    onClose={onClose}
    isFullScreenOnMobile
  >
    <TextField
      label={"Tag Name"}
      autoComplete={"off"}
      onChange={(value: string) => updateForm({ ...fields, tag_name: value })}
      value={fields.tag_name || ""}
    />
    <br />
    <Toggle
      label={"Visible To Patrons?"}
      onChange={() => updateForm({ ...fields, visible_to_patrons: !fields.visible_to_patrons })}
      isSelected={fields.visible_to_patrons || false}
    />
    <br />
    <Toggle
      label={"Make Number-Valued?"}
      onChange={() => updateForm({ ...fields, make_number_valued: !fields.make_number_valued })}
      isSelected={fields.make_number_valued || false}
    />
  </Modal>
);
