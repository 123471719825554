import { Form } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

import {
  AdvancedSettingsContainer,
  BbotModal,
  CheckboxInput,
  FulfillmentAndLocationsInput,
  notification,
  NumberInput,
  PercentPlusAmountInput,
  TextInput,
} from "top-component-library";

import { trackClickSaveNewServiceCharge } from "instrumentation/tracking/page-tracking-events";
import {
  filterLocationIds,
  fulfillmentMethodsToIds,
  generalErrorAlert,
  locationsToIds,
  removePatronChoice,
} from "util/Utils";

/*
Modal to add a new service charge
  Required props:
    id: ID for testing
    locations: Expects a list of location objects (res.data.locations from api/getOwnerLocations). Uses
      .locationId, .locationName, and .fulfillmentMethod
    fulfillmentMethods: Expects an object of fulfillment methods (res.data.fulfillment_methods from
      api/getOwnerLocations). Restructures them into an array
    ownerProperties: Expects an owner properties object (res.data.owner_properties from api/getOwnerProperties). Uses
      .tax_rate
    isVisible: Controls whether the modal is visible
    hideModalFunction: Pass a function to be called when cancel is clicked in modal. Should set the isVisible to false
    selectedCustomer: Currently selected customer
    onSubmitCallback: Function called when the modal is submitted
*/

const AddServiceChargeModal = ({
  id,
  locations,
  fulfillmentMethods = {},
  ownerProperties,
  isVisible,
  hideModalFunction,
  selectedCustomer,
  onSubmitCallback,
  userInfo,
  onSaving,
  supportsDynamicDelivery,
  ...props
}) => {
  // This is just used to force a rerender... it's a weird antD thing https://github.com/ant-design/ant-design/issues/21829
  const [, setArbitrary] = useState(true);
  // State to store whether a service charge is saving
  const [isSaving, setIsSaving] = useState(false);
  // State to store the initial values for form fields
  const [formInitialValues, setFormInitialValues] = useState();

  // Antd form object
  const [newServiceChargeForm] = Form.useForm();

  // Set initial values for form fields
  useEffect(() => {
    const initialValues = {
      fee_name: "",
      fee_percent: 0,
      fee_amount: 0.0,
      guest_name_is_owner_name: true,
      use_default_tax_rate: true,
      tax_rate: ownerProperties.default_tax_fraction * 100,
      visible_to_owner: true,
      fulfillment_methods: fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods)),
      locations: locationsToIds(locations),
    };
    setFormInitialValues(initialValues);
    newServiceChargeForm.setFieldsValue(initialValues);
  }, [newServiceChargeForm, ownerProperties, fulfillmentMethods, locations]);

  // When modal is submitted parse form data and create a new service charge
  const addFunction = () => {
    newServiceChargeForm
      .validateFields()
      .then((values) => {
        // Filter the location codes with a selected fulfillment method. Necessary to filter again because of how the
        // form works. Not my favorite solution
        if (!values.locations) {
          values.locations = locationsToIds(locations);
        } else {
          values.locations = filterLocationIds(values.locations, locations, values.fulfillment_methods);
        }
        // Set fulfillment_methods to all if all are selected
        if (
          !values.fulfillment_methods ||
          values.fulfillment_methods.length === Object.keys(removePatronChoice(fulfillmentMethods)).length
        ) {
          values.fulfillment_methods = ["all"];
        }
        // Parse the other settings
        if (!values.name_for_guests || values.guest_name_is_owner_name) {
          values.name_for_guests = values.fee_name;
        }
        if (values.visible_to_owner !== false) {
          values.visible_to_owner = true;
        }
        if (values.use_delivery_fee) {
          values.fixed_cents = 0;
          values.fraction_of_sale = 0;
          values.automatically_matches_delivery_cost = true;
        } else {
          values.fixed_cents = values.fee_amount * 100;
          values.fraction_of_sale = values.fee_percent / 100;
          values.automatically_matches_delivery_cost = false;
        }
        values.tax_fraction = values.use_default_tax_rate ? null : parseFloat((values.tax_rate / 100).toFixed(5));
        // Reset fields and save the form values as a new service charge
        saveNewServiceCharge(values);
        hideModalFunction(); // This doesn't actually go through until after the form is reset so there's a flash of the reset form. Not really a problem but I'd prefer if it didn't
        newServiceChargeForm.resetFields();

        // track adding service charges to Segment
        trackClickSaveNewServiceCharge({ service_charge: values });
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
      });
  };

  const saveNewServiceCharge = async (values) => {
    if (!selectedCustomer) {
      generalErrorAlert(
        new Error("No customer selected"),
        "Please use the dropdown in the header to select a customer.",
        true
      );
      return;
    }
    setIsSaving(true);
    onSaving(true);
    const payload = {
      customer_id: selectedCustomer.customer_id,
      order_fee_config: {
        fixed_cents: values.fixed_cents,
        fraction_of_sale: values.fraction_of_sale,
        fulfillment_methods: values.fulfillment_methods,
        location_ids: values.locations,
        name_for_customer: values.name_for_guests,
        name_for_owner: values.fee_name,
        tax_fraction: values.tax_fraction,
        visible_to_owner: values.visible_to_owner,
        automatically_matches_delivery_cost: values.automatically_matches_delivery_cost,
      },
    };
    try {
      await axios.post("api/saveOrderFeeConfig", payload);
      notification.success({
        message: "Successfully added new service charge.",
      });
      onSubmitCallback();
    } catch (error) {
      generalErrorAlert(
        error,
        "Unexpected Server Error. If this keeps happening please contact DoorDash support.",
        true
      );
      console.error(error);
    }
    setIsSaving(false);
    onSaving(false);
  };

  // Render component
  return (
    <BbotModal
      id={id}
      visible={isVisible}
      onCancel={() => {
        newServiceChargeForm.resetFields();
        hideModalFunction();
      }}
      title="Add new Service Charge"
      destroyOnClose
      okText="Add"
      onOk={addFunction}
      okButtonProps={{ id: id + "-confirm" }}
      cancelButtonProps={{ id: id + "-cancel" }}
      confirmLoading={isSaving}
    >
      <Form
        id={id + "-form"}
        form={newServiceChargeForm}
        onChange={() => setArbitrary((arbitrary) => !arbitrary)}
        layout="vertical"
        initialValues={formInitialValues}
        scrollToFirstError // This doesn't seem to work and I'm not sure why. Not a huge problem but kind of annoying
      >
        {/* Charge Name */}
        <TextInput id={id + "-fee-name-input"} label="Charge Name" name="fee_name" placeholder="Charge name" />
        {/* Fee percent + amount */}
        {!newServiceChargeForm.getFieldValue("use_delivery_fee") && (
          <PercentPlusAmountInput
            id={id + "-fee-input"}
            label="Service Charge"
            required={!newServiceChargeForm.getFieldValue("use_delivery_fee")}
            name="fee"
            form={newServiceChargeForm}
          />
        )}
        {/* Advanced Settings - collapsed by default */}
        <AdvancedSettingsContainer id={id + "-advanced-settings"}>
          {/* Use a different name_for_guests */}
          <CheckboxInput
            name="guest_name_is_owner_name"
            label="Use the Charge Name for display on checkout and receipts"
            id={id + "-guest-name-checkbox"}
          />
          {!newServiceChargeForm.getFieldValue("guest_name_is_owner_name") && (
            <TextInput
              id={id + "-guest-name-input"}
              label="Charge name for guests"
              name="name_for_guests"
              required={false}
            />
          )}
          {/* Use a non-default tax rate */}
          <CheckboxInput
            label="Use the default tax rate for this fee"
            id={id + "-tax-rate-checkbox"}
            name="use_default_tax_rate"
          />
          {!newServiceChargeForm.getFieldValue("use_default_tax_rate") && (
            <NumberInput
              id={id + "-tax-rate-input"}
              label="Tax rate"
              name="tax_rate"
              addonAfter="%"
              min={0}
              max={100}
            />
          )}
          {supportsDynamicDelivery && (
            <CheckboxInput
              id={id + "-delivery-fee"}
              name="use_delivery_fee"
              label="Pass along the delivery fee (instead of using a set fee)"
            />
          )}
          {userInfo.role === "admin" && (
            <CheckboxInput label="Visible to owners" id={id + "-visible-to-owner-input"} name="visible_to_owner" />
          )}
          <FulfillmentAndLocationsInput
            locations={locations}
            fulfillmentMethods={fulfillmentMethods}
            id={id + "-fulfillmentAndLocationsInput"}
            form={newServiceChargeForm}
          />
        </AdvancedSettingsContainer>
      </Form>
    </BbotModal>
  );
};

export default AddServiceChargeModal;
