import axios from "axios";

import { BbotModal, Col, Form, Row, TextInput } from "top-component-library";
import { generalErrorAlert } from "util/Utils";

const CreateCredentialsModal = (props) => {
  const { show, onClose, onSave, selectedPartner, selectedCustomer, customerOwnedPartnerApp } = props;
  const [createCredentialsForm] = Form.useForm();

  const createNewCredentials = async (values) => {
    const payload = {
      partnerId: selectedPartner.id,
      displayName: values.displayName,
      customerId: selectedCustomer?.customer_id,
    };
    try {
      const res = await axios.post("/owner/createNewCredentialsForPartner", payload);
      createCredentialsForm.resetFields();
      onSave(res.data.credentials);
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble creating new credentials.", true);
    }
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onClose}
      title={customerOwnedPartnerApp ? "Create New App Credentials" : "Create New Partner Credentials"}
      closable
      okText={"Submit"}
      okButtonProps={{
        htmlType: "submit",
        form: "createCredentials",
      }}
    >
      <Form name={"createCredentials"} form={createCredentialsForm} onFinish={createNewCredentials} layout={"vertical"}>
        <Row>
          <Col span={24}>
            <div className={"margin-bottom-2"}>
              Please enter a Credential Name, then press "Enter" to create a new set of credentials.
            </div>
            <div className={"margin-bottom-2"}>
              After pressing enter, you will be given your username and password exactly once. Please save them into
              LastPass or another secure password management tool as you will not be able to view them again.
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TextInput
              id={"display-name"}
              label={"Credential Name"}
              name={"displayName"}
              required={true}
              maxLength={50}
            />
          </Col>
        </Row>
      </Form>
    </BbotModal>
  );
};

export default CreateCredentialsModal;
