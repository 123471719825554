import { client } from "filestack-react";
import MockClient from "./MockFilestackClient";

export type FilestackClient = InstanceType<typeof client.Client | typeof MockClient>;

export enum FileState {
  INIT = "Initialized",
  PROGRESS = "Progress",
  STORED = "Stored",
  INTRANSIT = "InTransit",
  FAILED = "Failed",
  INVALID = "Invalid",
}

export type FsFileMetadata = FsFile & {
  filename: string;
  mimetype: string;
  name: string;
  type: string;
};

export type FsFile = {
  status: FileState;
  size: number;
  url: string;
  handle: string;
  _file: {
    name: string;
    size: number;
    type: string;
  };
  container?: string;
  key?: string;
  workflows?: any[];
  uploadTags?: { [key: string]: string };
};

export type FsStoredUrl = {
  filename: string;
  handle: string;
  mimetype: string;
  size: number;
  type: string;
  url: string;
};
