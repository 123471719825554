import styled from "styled-components";
import { Alert } from "top-component-library";

const BbotAlert = ({ ...props }) => {
  return <StyledAlert {...props} />;
};

const StyledAlert = styled(Alert)`
  &&&.ant-alert {
    &-success {
      background-color: var(--color-success__light);
      border-color: var(--color-success__regular);
      .ant-alert-message {
        color: var(--color-success__dark);
      }
    }

    &-info {
      background-color: var(--color-primary__light);
      border-color: var(--color-primary__regular);
      .ant-alert-message {
        color: var(--color-primary__dark);
      }
    }

    &-warning {
      background-color: var(--color-warning__light);
      border-color: var(--color-warning__regular);
      .ant-alert-message {
        color: var(--color-warning__dark);
      }
    }

    &-error {
      background-color: var(--color-error__light);
      border-color: var(--color-error__regular);
      .ant-alert-message {
        color: var(--color-error__dark);
      }
    }
  }
`;

export default BbotAlert;
