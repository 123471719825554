import axios from "axios";
import React from "react";

import { Field, Formik } from "formik";
import { DateTime } from "luxon";
import { Alert, Button, Col, Form, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";

import Tag from "@doordash/component-tag";
import { CheckboxInput, DateInput, NumberInput } from "components/global/form";
import { CUT_AGREEMENT_RETRO_USERS } from "Constants";
import { toast } from "react-toastify";
import { fractionToPercentage, generalErrorAlert, isoToReadableDate } from "util/Utils";

class EditCutAgreementModal extends React.Component {
  state = {
    locations: [], // used by outbound
    locationIds: [], // used by outbound
    orderFeeConfigs: [], // used by outbound
    locationsById: {},

    externalVendorsIds: [],
    vendeesIds: [],

    startDateIsRecent: false, // true if cut agreements start date is less than 24 hours ago
    showEndDatePicker: false,

    isSaving: false,
  };

  componentDidMount() {
    this.getLocationsAndOrderConfigs();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.getLocationsAndOrderConfigs();
    }

    if (prevProps.inbound !== this.props.inbound) {
      this.getLocationsAndOrderConfigs();
    }

    if (prevProps.cutAgreement !== this.props.cutAgreement) {
      const now = DateTime.now();
      const cutAgreementStartDate = DateTime.fromISO(this.props.cutAgreement.start_date);
      const cutAgreementEndDate = DateTime.fromISO(this.props.cutAgreement.end_date);
      this.setState({
        startDateIsRecent: cutAgreementStartDate.plus({ hours: 24 }) > now,
        showEndDatePicker: cutAgreementEndDate.year < 2198,
      });
      this.getLocationsAndOrderConfigs();
    }
  }

  getLocationsAndOrderConfigs = async () => {
    const { cutAgreement } = this.props;
    if (cutAgreement?.from_customer?.customer_id) {
      await this.getVendorsVendees();
      await this.getLocations(cutAgreement.from_customer.customer_id);
      await this.getOrderFeeConfigs(cutAgreement.from_customer.customer_id);
    }
  };

  getVendorsVendees = async () => {
    const { selectedCustomer } = this.props;

    if (!selectedCustomer) return;

    try {
      const res = await axios.get("/admin/getVendorsVendees", {
        params: { customer_id: selectedCustomer.customer_id },
      });
      this.setState({
        externalVendorsIds: res.data.vendors,
        vendeesIds: res.data.vendees,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getLocations = async (customerId) => {
    const { inbound, cutAgreement } = this.props;
    const { externalVendorsIds, vendeesIds } = this.state;

    try {
      let params = { customer_id: customerId };

      if (
        (inbound && externalVendorsIds.includes(customerId)) ||
        (!inbound && vendeesIds.includes(cutAgreement.recipient_if_customer?.customer_id))
      ) {
        params.show_vendees_locations = true;
      }

      const res = await axios.get("/owner/locations/", { params: params });

      // Group locations by customer id then fulfillment method
      let locationsByCustomerAndFulfillmentMethod = {};
      res.data.locations.forEach((location) => {
        if (!(location.customer_id in locationsByCustomerAndFulfillmentMethod)) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id] = {};
        }
        if (!(location.fulfillment_method in locationsByCustomerAndFulfillmentMethod[location.customer_id])) {
          locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method] = [];
        }

        locationsByCustomerAndFulfillmentMethod[location.customer_id][location.fulfillment_method].push(location);
      });

      this.setState({
        locations: res.data.locations,
        locationIds: res.data.locations.map((location) => location.locationId),
        locationsById: locationsByCustomerAndFulfillmentMethod,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getOrderFeeConfigs = async (customerId) => {
    try {
      const res = await axios.get("/api/getAllOrderFeeConfigs", {
        params: {
          customer_id: customerId,
          include_vendor_fee_configs: true,
          include_ended_fee_configs: true,
        },
      });
      this.setState({ orderFeeConfigs: res.data.order_fee_configs });
    } catch (error) {
      console.error(error);
    }
  };

  saveCutAgreement = async (values) => {
    const { onClose, onSave, cutAgreement } = this.props;

    this.setState({ isSaving: true });
    const payload = {
      cutAgreementId: cutAgreement.id,
      just_specific_locations: !values.appliesToAllLocations,
      just_specific_feeconfigs: values.appliesToSpecificOrderFeeConfigs,
      specific_feeconfigs_ids: values.specific_feeconfigs_ids,
      specific_locations_ids: values.specific_locations_ids,
      fraction_of_tip: values.percentage_of_tip / 100,
      fraction_of_items_pretax: values.percentage_of_items_pretax / 100,
      fraction_of_items_tax: values.percentage_of_items_tax / 100,
      fixed_cents: values.fixed_cents,
      take_fixed_cents_from_free_orders: values.take_fixed_cents_from_free_orders,
      fraction_of_servicecharge_pretax: values.percentage_of_servicecharge_pretax / 100,
      fraction_of_servicecharge_tax: values.percentage_of_servicecharge_tax / 100,
      start_date: DateTime.fromJSDate(values.start_date).toUTC().set({ hour: 0, minute: 0, seconds: 0 }),
      end_date: DateTime.fromJSDate(values.end_date).toUTC().set({ hour: 0, minute: 0, seconds: 0 }),
    };
    try {
      if (window.confirm("Are you sure you would like to save these changes?")) {
        await axios.post("/admin/updateCutAgreement", payload);
        onSave();
        onClose();
        this.setState({
          showEndDatePicker: false,
        });
      }
    } catch (error) {
      generalErrorAlert(error, "Could not save this cut agreement.");
    } finally {
      this.setState({ isSaving: false });
    }
  };

  deleteCutAgreement = async () => {
    const { cutAgreement, onClose, onSave } = this.props;
    const payload = { cutAgreementId: cutAgreement.id };

    try {
      await axios.post("/admin/deleteCutAgreement", payload);
      onSave();
      onClose();
      this.setState({
        showEndDatePicker: false,
      });
    } catch (error) {
      console.error(error.response.data.message);
      toast.error("Unable to delete cut agreement. Please refresh and try again.");
    }
  };

  getModalTitle = () => {
    const { inbound, cutAgreement } = this.props;
    if (inbound) return "Edit Cut Agreement from " + cutAgreement.from_customer.name_for_admin;

    if (cutAgreement.recipient_is_bbot) return "Edit Cut Agreement To BBot";
    else return "Edit Cut Agreement To " + cutAgreement.recipient_if_customer.name_for_admin;
  };

  getCustomerName = (customerId) => {
    const { allowedCustomersById } = this.props;
    const customer = allowedCustomersById[customerId];
    return customer?.name_for_admin;
  };

  validate_start_date = (value) => {
    const { userInfo } = this.props;
    let error;

    // if field isn't disabled
    if (!document.getElementById("start_date").disabled) {
      const canEditRetroCuts = CUT_AGREEMENT_RETRO_USERS.includes(userInfo?.email);
      // if can edit retro
      if (
        !canEditRetroCuts &&
        value < DateTime.now().toUTC().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 })
      ) {
        error = "Start date be equal to or later than current date.";
      }
    }
    return error;
  };

  render() {
    const { cutAgreement, show, onClose, fulfillmentMethodsPrettyNames, userInfo } = this.props;

    const { locationsById, locationIds, orderFeeConfigs, startDateIsRecent, showEndDatePicker, isSaving } = this.state;

    const initialValues = {
      percentage_of_tip: fractionToPercentage(cutAgreement?.fraction_of_tip, 4),
      percentage_of_items_pretax: fractionToPercentage(cutAgreement?.fraction_of_items_pretax, 4),
      percentage_of_items_tax: fractionToPercentage(cutAgreement?.fraction_of_items_tax, 4),
      fixed_cents: cutAgreement?.fixed_cents,
      take_fixed_cents_from_free_orders: cutAgreement?.take_fixed_cents_from_free_orders,
      percentage_of_servicecharge_pretax: fractionToPercentage(cutAgreement?.fraction_of_servicecharge_pretax, 4),
      percentage_of_servicecharge_tax: fractionToPercentage(cutAgreement?.fraction_of_servicecharge_tax, 4),
      appliesToAllLocations: !cutAgreement?.just_specific_locations,
      appliesToSpecificOrderFeeConfigs: cutAgreement?.just_specific_feeconfigs,
      start_date: DateTime.fromISO(cutAgreement?.start_date).toUTC().set({ hour: 12 }).toJSDate(),
      end_date: DateTime.fromISO(cutAgreement?.end_date).toUTC().set({ hour: 12 }).toJSDate(),
      specific_feeconfigs_ids: cutAgreement?.specific_feeconfigs,
      specific_locations_ids: cutAgreement?.locations.filter((locationId) => locationIds.includes(locationId)),
    };

    const userCanEditRetro = CUT_AGREEMENT_RETRO_USERS.includes(userInfo?.email);

    const userCanEdit = userInfo?.permissions?.includes("manage_admins");

    const now = DateTime.now();

    return (
      <Modal
        show={show}
        onHide={() => {
          this.setState({ showEndDatePicker: false });
          onClose();
        }}
        size={"medium"}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object({
            percentage_of_tip: Yup.number().max(100).min(0).required(),
            percentage_of_items_pretax: Yup.number().max(100).min(0).required(),
            percentage_of_items_tax: Yup.number().max(100).min(0).required(),
            fixed_cents: Yup.number().min(0),
            percentage_of_servicecharge_pretax: Yup.number().max(100).min(0).required(),
            percentage_of_servicecharge_tax: Yup.number().max(100).min(0).required(),
            end_date: Yup.date()
              .required()
              .when(
                "start_date",
                (start_date, schema) =>
                  start_date &&
                  schema.min(
                    userCanEditRetro
                      ? start_date
                      : start_date.getTime() >
                        now
                          .toUTC()
                          .set({
                            hour: 0,
                            minute: 0,
                            seconds: 0,
                            milliseconds: 0,
                          })
                          .toMillis()
                      ? start_date
                      : now.toUTC().set({
                          hour: 0,
                          minute: 0,
                          seconds: 0,
                          milliseconds: 0,
                        })
                  ),
                "End date must be later than both the start date and today's date"
              ),
          })}
          onSubmit={this.saveCutAgreement}
        >
          {({ values, errors, touched, isValidating, setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={"styled-form"}>
              <Modal.Header closeButton>
                <Modal.Title>{this.getModalTitle()}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"padding-x-4"}>
                {!startDateIsRecent && (
                  <Alert variant={"danger"}>
                    To make a change to a Cut Agreement going forward, you should make this CutAgreement end today, and
                    create a new CutAgreement that starts today. To make any retroactive changes to a Cut Agreement,
                    please submit an Eng Fifo request through the workflow in the engineering-public slack channel. Be
                    sure to provide as much detail on the retroactive changes you'd like to make including dates, cuts,
                    affected locations, and affected service charges.
                  </Alert>
                )}
                <Form.Row>
                  <DateInput
                    md={12}
                    id={"start_date"}
                    name={"start_date"}
                    label={"Start Date"}
                    disabled={!userCanEditRetro && initialValues.start_date < now}
                    validate={this.validate_start_date}
                  />
                  {showEndDatePicker ? (
                    <DateInput
                      md={12}
                      id={"end_date"}
                      name={"end_date"}
                      label={"End Date"}
                      disabled={!userCanEditRetro && initialValues.end_date < now}
                    />
                  ) : (
                    <Col md={12}>
                      <div className={"margin-bottom-1"}></div>
                      <Button
                        size={"lg"}
                        variant={"outline-primary"}
                        onClick={() => {
                          setFieldValue(
                            "end_date",
                            DateTime.now().set({ hour: 12, minute: 0, seconds: 0 }).plus({ years: 1 }).toJSDate()
                          );
                          this.setState({ showEndDatePicker: true });
                        }}
                      >
                        Use Custom End Date
                      </Button>
                    </Col>
                  )}
                </Form.Row>
                <Form.Row>
                  <NumberInput
                    name="percentage_of_tip"
                    id="percentage-tip"
                    label={"Percentage Of Tip"}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                <Form.Row>
                  <NumberInput
                    name="percentage_of_items_pretax"
                    id="percentage-items-pretax"
                    label={"Percentage Of Items Pretax"}
                    sm={12}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                <Form.Row>
                  <NumberInput
                    name="percentage_of_items_tax"
                    id="percentage-items-tax"
                    label={"Percentage Of Items Tax"}
                    sm={12}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                {cutAgreement.recipient_is_bbot && (
                  <Form.Row>
                    <NumberInput
                      name="fixed_cents"
                      id="bbot-fixed-cents"
                      label={"TOP Fixed Cents"}
                      disabled={initialValues.start_date < now}
                    />
                  </Form.Row>
                )}
                {cutAgreement.recipient_is_bbot && (
                  <Form.Row>
                    <CheckboxInput
                      name="take_fixed_cents_from_free_orders"
                      id="take-fixed-cents-from-free-orders"
                      label={"Take Fixed Cents From Free Orders"}
                      disabled={false}
                    />
                  </Form.Row>
                )}
                <Form.Row>
                  <NumberInput
                    name="percentage_of_servicecharge_pretax"
                    id="percentage-servicecharge-pretax"
                    label={"Percentage Of Service Charge Pretax"}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                <Form.Row className={"margin-bottom-2"}>
                  <NumberInput
                    name="percentage_of_servicecharge_tax"
                    id="percentage-servicecharge-pretax"
                    label={"Percentage Of Service Charge Tax"}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                <Form.Row>
                  <CheckboxInput
                    md={12}
                    name="appliesToAllLocations"
                    id="applies-to-all-locations"
                    label={"This cut agreement applies to all locations"}
                    disabled={initialValues.start_date < now}
                  />
                </Form.Row>
                {/*select the relevant locations*/}
                {!values.appliesToAllLocations && (
                  <div className={"padding-x-1 locations-list"}>
                    {Object.keys(locationsById).map((customerId, index) => (
                      <div key={customerId}>
                        <div className={"customer-name"}>{this.getCustomerName(customerId)}</div>
                        <div className={"customer-locations-group"}>
                          {Object.keys(locationsById[customerId]).map((fulfillmentMethod) => (
                            <div key={fulfillmentMethod}>
                              <div className={"fulfillment-method"}>
                                {fulfillmentMethodsPrettyNames[fulfillmentMethod]}
                              </div>
                              <div className={"fulfillment-method-locations-group"}>
                                <Button
                                  variant={"link"}
                                  disabled={initialValues.start_date < now}
                                  onClick={() => {
                                    let locations = values.specific_locations_ids;
                                    const newLocations = locationsById[customerId][fulfillmentMethod];

                                    newLocations.forEach((location) => {
                                      if (!locations.includes(location.locationId)) {
                                        locations.push(location.locationId);
                                      }
                                    });

                                    setFieldValue("specific_locations_ids", locations);
                                  }}
                                >
                                  Select All {fulfillmentMethodsPrettyNames[fulfillmentMethod]}
                                </Button>
                                {locationsById[customerId][fulfillmentMethod].map((location) => (
                                  <div className={"field-wrapper"} key={location.locationId}>
                                    <label className={"d-flex align-items-center"}>
                                      <Field
                                        type={"checkbox"}
                                        name={"specific_locations_ids"}
                                        id={"location-" + location.locationId}
                                        value={location.locationId}
                                        disabled={initialValues.start_date < now}
                                      />
                                      <span>{location.locationName}</span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <hr />
                <div className={"field-wrapper margin-bottom-1"}>
                  <label className={"d-flex align-items-center"}>
                    <Field
                      type={"checkbox"}
                      name={"appliesToSpecificOrderFeeConfigs"}
                      id={"applies-to-specific-order-fee-configs"}
                      disabled={initialValues.start_date < now}
                    />
                    <span>This cut only applies to specific service charges</span>
                  </label>
                </div>
                {/*select the relevant order fee configs*/}
                {values.appliesToSpecificOrderFeeConfigs && (
                  <div className={"padding-x-1"}>
                    {orderFeeConfigs.map((orderFeeConfig, index) => (
                      <div className={"field-wrapper"} key={index}>
                        <label className={"d-flex"}>
                          <Field
                            type={"checkbox"}
                            name={"specific_feeconfigs_ids"}
                            id={"order-fee-config-" + orderFeeConfig.id}
                            value={orderFeeConfig.id}
                            disabled={initialValues.start_date < now}
                          />
                          <div>
                            <div>{`${orderFeeConfig.name_for_owner} (${orderFeeConfig.customer_name_for_admin})`}</div>
                            <div className="d-flex align-items-center">
                              <span className="margin-right-1">
                                {`${isoToReadableDate(orderFeeConfig.start_date)} - ${isoToReadableDate(
                                  orderFeeConfig.end_date
                                )}`}
                              </span>

                              {now > DateTime.fromISO(orderFeeConfig.end_date) && <Tag text="Ended" />}
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer className={"with-danger"}>
                <span>
                  {startDateIsRecent && (
                    <Button
                      size={"sm"}
                      variant={"danger"}
                      onClick={this.deleteCutAgreement}
                      disabled={initialValues.start_date < now}
                    >
                      Delete Cut Agreement
                    </Button>
                  )}
                </span>
                <span>
                  <Button
                    size={"sm"}
                    variant={"light"}
                    onClick={() => {
                      onClose();
                      this.setState({
                        showEndDatePicker: false,
                      });
                    }}
                    className={"margin-right-1"}
                    disabled={isSaving}
                  >
                    Cancel
                  </Button>
                  {userCanEdit && (
                    <Button
                      size={"sm"}
                      type={"submit"}
                      disabled={Object.keys(errors).length > 0 || initialValues.end_date < now || isSaving}
                    >
                      {isSaving ? <Spinner animation={"border"} size={"sm"} /> : "Save"}
                    </Button>
                  )}
                </span>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default EditCutAgreementModal;
