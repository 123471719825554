import { SearchOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { useRef, useState } from "react";
import Moment from "react-moment";
import { Button, Input } from "../../../../top-component-library";

const pillVariant = (secondsSincePoll) => {
  let statusIndicator = "error";
  if (secondsSincePoll && secondsSincePoll <= 120) {
    statusIndicator = "success";
  } else if (secondsSincePoll && secondsSincePoll <= 240) {
    statusIndicator = "warning";
  }
  return statusIndicator;
};

export const statusBadge = (time_since_heartbeat) => {
  return (
    <Tag color={pillVariant(time_since_heartbeat)}>
      {time_since_heartbeat ? (
        <>
          LIVE: <Moment fromNow>{Date.now() - time_since_heartbeat * 1000}</Moment>
        </>
      ) : (
        <>Offline</>
      )}
    </Tag>
  );
};

export const ControlBoxTableColumns = ({ controlBoxes }) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const commandedVersionFilter = () => {
    let currentVersions = [
      ...new Set(
        controlBoxes.map((box) => {
          return box.current_version;
        })
      ),
    ];
    return currentVersions.map((version) => {
      return { text: version || 7, value: version };
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, dataName) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  return [
    {
      title: "Name",
      dataIndex: "name_for_admin",
      ...getColumnSearchProps("name_for_admin", "PCB Name"),
    },
    {
      title: "ID",
      dataIndex: "id",
      ...getColumnSearchProps("id", "PCB ID"),
    },
    {
      title: "Last Poll",
      dataIndex: "last_poll",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.time_since_heartbeat - b.time_since_heartbeat,
    },
    {
      title: "Current Version",
      dataIndex: "current_version",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.current_version - b.current_version,
      filters: commandedVersionFilter(),
      onFilter: (version, controlBox) => {
        return controlBox.current_version === version;
      },
    },
    {
      title: "Commanded Version",
      dataIndex: "commanded_version",
    },
  ];
};
