import React, { useEffect, useState } from "react";
import ConnectPOSIntegrationModal from "components/global/modals/pos-integrations/ConnectPOSIntegrationModal";
import DisconnectPOSIntegrationModal from "components/global/modals/pos-integrations/DisconnectPOSIntegrationModal";
import EditIntegrationSettingsModal from "util/pos-integrations/EditIntegrationSettingsModal";
import {
  connectIntegration,
  disconnectIntegration,
  getIntegrationSettings,
} from "util/pos-integrations/IntegrationConnectModalUtils";
import RevelIntegrationModalInputs from "./RevelIntegrationModalInputs";
import RevelIntegrationApiConfigInputs from "./RevelIntegrationApiConfigInputs";
import RevelIntegrationEstablishmentInputs from "./RevelIntegrationEstablishmentInputs";

const RevelIntegrationModal = (props) => {
  const [revelSettings, setRevelSettings] = useState({});
  const [showConnectWarning, setShowConnectWarning] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  const { selectedCustomer, disconnectMode, show, onClose, onSave } = props;

  useEffect(() => {
    if (selectedCustomer) {
      getRevelSettings();
    }
  }, [selectedCustomer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getRevelSettings = async () => {
    setLoadingData(true);
    const getSettingsUrl = "/admin/getRevelConnectionStatus";
    await getIntegrationSettings(getSettingsUrl, "Revel", selectedCustomer, saveRevelSettings);
    setLoadingData(false);
  };

  const saveRevelSettings = async (revelSettings) => {
    setRevelSettings(revelSettings);
    setShowConnectWarning(!revelSettings.connected);
  };

  const connectRevel = async (values, deleteMenuItems, keepOpen) => {
    const payload = {
      customer_id: selectedCustomer?.customer_id,
      delete_menu_items: deleteMenuItems,
      inject_orders: values.injectOrders,
      revel_api_key: values.revelApiKey,
      revel_api_secret: values.revelApiSecret,
      revel_api_url: values.revelApiUrl,
      selected_establishment: values.selectedRevelEstablishment,
      multi_channel_menu_name: values.revelMultiChannelMenuName,
      display_fulfillment_on_ticket: values.displayFulfillmentOnTicket,
      display_location_on_ticket: values.displayLocationOnTicket,
      display_checkout_info_on_ticket: values.displayCheckoutInfoOnTicket,
      call_name_template_string: values.callNameTemplateString,
      inject_order_info_as_customer_data: values.injectOrderDataAsCustomerData,
      sync_refunds_from_revel: values.syncRefundsFromRevel,
      ignore_revel_inventory: values.ignoreRevelInventory,
      keep_quantity_limits: values.keepQuantityLimits,
      custom_takeout_dining_option: values.customTakeoutDiningOption,
      custom_delivery_dining_option: values.customDeliveryDiningOption,
      custom_dine_in_dining_option: values.customDineInDiningOption,
      unique_mods_per_item: values.uniqueModsPerItem,
      auto_inject_default_mods: values.autoInjectDefaultMods,
      customer_name_template_string: values.customerNameTemplateString,
    };

    if (keepOpen) {
      await connectIntegration("/admin/connectRevel", payload, "Revel", setLoadingData, onConnectSuccessKeepOpen);
    } else {
      await connectIntegration("/admin/connectRevel", payload, "Revel", setLoadingData, onConnectSuccess);
    }
  };

  const onConnectSuccessKeepOpen = async () => {
    setShowConnectWarning(false);
    onSave();
  };

  const onConnectSuccess = async () => {
    setShowConnectWarning(false);
    await getRevelSettings();
    onSave();
    onClose();
  };

  const disconnectRevel = async (deleteMenuItems) => {
    await disconnectIntegration(
      "/admin/disconnectRevel",
      "Revel",
      selectedCustomer,
      deleteMenuItems,
      setLoadingData,
      onDisconnectSuccess
    );
  };

  const onDisconnectSuccess = async () => {
    saveRevelSettings({});
    setShowConnectWarning(false);
    onClose();
    onSave();
    await closeModal();
  };

  const closeModal = async () => {
    await getRevelSettings();
    onClose();
  };

  const disconnectModal = () => {
    return (
      <DisconnectPOSIntegrationModal
        onHide={onClose}
        show={show}
        disconnectFunc={disconnectRevel}
        onClose={onClose}
        integrationSourceName={"Revel"}
      />
    );
  };

  const submitEditSettingsKeepOpen = async (values) => {
    await connectRevel(values, false, true);
    await getRevelSettings();
  };

  const submitEditSettings = async (values) => {
    await connectRevel(values, false, false);
    await getRevelSettings();
  };

  const getSettingsInitialValues = (revelSettings) => {
    return {
      revelApiKey: revelSettings.revel_api_key ?? "",
      revelApiSecret: revelSettings.revel_api_secret ?? "",
      revelApiUrl: revelSettings.revel_api_url ?? "",
      injectOrders: revelSettings.inject_orders ?? false,
      allRevelEstablishments: revelSettings.all_revel_establishments ?? [],
      showEstablishmentDropdown: revelSettings.show_establishment_dropdown ?? false,
      selectedRevelEstablishment: revelSettings.selected_revel_establishment ?? "",
      revelMultiChannelMenuName: revelSettings.revel_multi_channel_menu_name ?? "",
      displayFulfillmentOnTicket: revelSettings.display_fulfillment_on_ticket ?? true,
      displayLocationOnTicket: revelSettings.display_location_on_ticket ?? true,
      displayCheckoutInfoOnTicket: revelSettings.display_checkout_info_on_ticket ?? true,
      callNameTemplateString: revelSettings.call_name_template_string ?? "",
      injectOrderDataAsCustomerData: revelSettings.inject_order_info_as_customer_data ?? true,
      syncRefundsFromRevel: revelSettings.sync_refunds_from_revel ?? false,
      ignoreRevelInventory: revelSettings.ignore_revel_inventory ?? false,
      keepQuantityLimits: revelSettings.keep_quantity_limits ?? false,
      allRevelDiningOptions: revelSettings.dining_options ?? [],
      customDeliveryDiningOption: revelSettings.custom_delivery_dining_option ?? "",
      customDineInDiningOption: revelSettings.custom_dine_in_dining_option ?? "",
      customTakeoutDiningOption: revelSettings.custom_takeout_dining_option ?? "",
      uniqueModsPerItem: revelSettings.unique_mods_per_item ?? false,
      autoInjectDefaultMods: revelSettings.auto_inject_default_mods ?? false,
      customerNameTemplateString: revelSettings.customer_name_template_string ?? "",
    };
  };

  const addApiCredentialsModal = () => {
    let initialValues = getSettingsInitialValues(revelSettings);
    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={closeModal}
        onSubmit={submitEditSettingsKeepOpen}
        modalBody={RevelIntegrationApiConfigInputs}
        loadingData={loadingData}
        modalTitle={"Revel POS Settings"}
      />
    );
  };

  const editRevelEstablishmentModal = () => {
    let initialValues = getSettingsInitialValues(revelSettings);
    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={closeModal}
        onSubmit={submitEditSettingsKeepOpen}
        modalBody={RevelIntegrationEstablishmentInputs}
        loadingData={loadingData}
        modalTitle={"Revel POS Settings"}
      />
    );
  };

  const editSettingsModal = () => {
    let initialValues = getSettingsInitialValues(revelSettings);
    return (
      <EditIntegrationSettingsModal
        initialValues={initialValues}
        show={show}
        onHide={onClose}
        onCancel={closeModal}
        onSubmit={submitEditSettings}
        modalBody={RevelIntegrationModalInputs}
        loadingData={loadingData}
        modalTitle={"Revel POS Settings"}
      />
    );
  };

  const connectModal = () => {
    return (
      <ConnectPOSIntegrationModal
        integrationSourceName={"Revel"}
        onHide={onClose}
        onSubmit={() => setShowConnectWarning(false)}
        show={showConnectWarning && show}
        loadingData={loadingData}
      />
    );
  };

  if (disconnectMode) {
    return disconnectModal();
  }

  if (showConnectWarning) {
    return connectModal();
  }

  if (!revelSettings.connected) {
    return addApiCredentialsModal();
  }

  return revelSettings.show_establishment_dropdown ? editRevelEstablishmentModal() : editSettingsModal();
};

export default RevelIntegrationModal;
