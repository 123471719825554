/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Text, Inset } from "@doordash/design-language";
import Table from "@doordash/component-table";
import { menuHeadings, menuItems, menus, modifierGroups, modifiers, tags } from "./data";
import Tables from "./Tables";
import Stats from "./Stats";
import { formFieldsDictionary, modals, updateFieldsDictionary } from "./utils";
import { MenuKey } from "./constants";
import {
  menuHeadingsFields,
  menuItemsFields,
  menusFields,
  modifierGroupsFields,
  modifiersFields,
  tagsFields,
} from "./defaultFormState";

type MenusPageProps = {};

const MenusPage: React.FC<MenusPageProps> = ({}) => {
  const [selectedTable, setSelectedTable] = React.useState<MenuKey>("menus");
  const [openModal, setOpenModal] = React.useState(false);
  const [menuHeadingsForm, setMenuHeadingsForm] = React.useState<any>(menuHeadingsFields);
  const [menuItemsForm, setMenuItemsForm] = React.useState<any>(menuItemsFields);
  const [menusForm, setmenusForm] = React.useState<any>(menusFields);
  const [modifierGroupsForm, setModifierGroupsForm] = React.useState<any>(modifierGroupsFields);
  const [modifiersForm, setModifiersForm] = React.useState<any>(modifiersFields);
  const [tagsForm, setTagsForm] = React.useState<any>(tagsFields);

  const tableInfo: any = React.useMemo(
    () =>
      ({
        tags,
        modifiers,
        menus,
        modifier_groups: modifierGroups,
        menu_items: menuItems,
        menu_headings: menuHeadings,
      } as const),
    [menuHeadings, menuItems, menus, modifierGroups, modifiers, tags]
  );
  const primaryActionProps = React.useMemo(
    () => ({
      onClick: () => setOpenModal(false),
      text: "Save",
    }),
    [setOpenModal]
  );
  const secondaryActionProps = React.useMemo(
    () => ({
      onClick: () => setOpenModal(false),
      text: "Cancel",
    }),
    [setOpenModal]
  );
  const Modal = modals[selectedTable];
  const formFields = formFieldsDictionary(
    menuHeadingsForm,
    menuItemsForm,
    menusForm,
    modifierGroupsForm,
    modifiersForm,
    tagsForm
  )[selectedTable];
  const updateForm = updateFieldsDictionary(
    setMenuHeadingsForm,
    setMenuItemsForm,
    setmenusForm,
    setModifierGroupsForm,
    setModifiersForm,
    setTagsForm
  )[selectedTable];
  const updateFormField = (key: string, value: any) => updateForm({ ...formFields, [key]: value });
  const onClose = () => {
    updateForm({});
    setOpenModal(false);
  };

  return (
    <Inset size={Inset.Sizes.Large}>
      {openModal && (
        <Modal
          setOpenModal={setOpenModal}
          fields={formFields}
          updateForm={updateForm}
          updateFormField={updateFormField}
          onClose={onClose}
          primaryActionProps={primaryActionProps}
          secondaryActionProps={secondaryActionProps}
        />
      )}
      <Stats />
      <Tables setOpenModal={setOpenModal} setSelectedTable={setSelectedTable} selectedTable={selectedTable} />
      <Table
        {...tableInfo[selectedTable]}
        showDataLoading={false}
        isSortable={false}
        onRowClick={() => {
          updateForm(formFields);
          setOpenModal(true);
        }}
        onRowSelect={() => {}}
        rowsPerPage={25}
        showRowSelectionColumn
        headerTextProps={{ styles: Text.Styles.Bold14 }}
      />
    </Inset>
  );
};

export default MenusPage;
