import { MenusTableKeys } from "./constants";

export const menusColumns = [
  {
    name: "Menu Name",
    accessor: MenusTableKeys.MENU_NAME,
  },
  {
    name: "Menu Headings",
    accessor: MenusTableKeys.MENU_HEADINGS,
  },
  {
    name: "Enabled",
    accessor: MenusTableKeys.ENABLED,
  },
  {
    name: "Schedule",
    accessor: MenusTableKeys.SCHEDULE,
  },
];

export const menuHeadingsColumns = [
  {
    name: "Menu Heading Name",
    accessor: MenusTableKeys.MENU_HEADING_NAME,
  },
  {
    name: "Sort Items...",
    accessor: MenusTableKeys.SORT_ITEMS,
  },
];

export const menuItemsColumns = [
  {
    name: "Name For Owner",
    accessor: MenusTableKeys.NAME_FOR_OWNER,
  },
  {
    name: "Menu",
    accessor: MenusTableKeys.MENU,
  },
  {
    name: "Heading",
    accessor: MenusTableKeys.HEADING,
  },
  {
    name: "Enabled",
    accessor: MenusTableKeys.ENABLED,
  },
  {
    name: "In Stock",
    accessor: MenusTableKeys.IN_STOCK,
  },
  {
    name: "Price",
    accessor: MenusTableKeys.PRICE,
  },
  {
    name: "Description",
    accessor: MenusTableKeys.DESCRIPTION,
  },
  {
    name: "Report Category",
    accessor: MenusTableKeys.REPORT_CATEGORY,
  },
];

export const modifierGroupsColumns = [
  {
    name: "Modifier Group Name",
    accessor: MenusTableKeys.MODIFIER_GROUP_NAME,
  },
  {
    name: "Selectable Modifiers",
    accessor: MenusTableKeys.SELECTABLE_MODIFIERS,
  },
  {
    name: "Pre-selected Items",
    accessor: MenusTableKeys.PRE_SELECTED_ITEMS,
  },
  {
    name: "Sort Modifiers...",
    accessor: MenusTableKeys.SORT_MODIFIERS,
  },
];

export const modifiersColumns = [
  {
    name: "Modifier Name",
    accessor: MenusTableKeys.MODIFIER_NAME,
  },
  {
    name: "Modifier Group",
    accessor: MenusTableKeys.MODIFIER_GROUP,
  },
  {
    name: "Enabled",
    accessor: MenusTableKeys.ENABLED,
  },
  {
    name: "In Stock",
    accessor: MenusTableKeys.IN_STOCK,
  },
  {
    name: "Price",
    accessor: MenusTableKeys.PRICE,
  },
];

export const tagsColumns = [
  {
    name: "Icon",
    accessor: MenusTableKeys.ICON,
  },
  {
    name: "Tag Name",
    accessor: MenusTableKeys.TAG_NAME,
  },
];
