import BulkEditableSetting from "components/owner-app/BulkEditableSetting";
import { Card } from "top-component-library";

const StationsTab = (props) => {
  const { bulkEditMode, stations, updateLocationSettings, removeChanges, defaultLocationSettings, locations } = props;

  const getNumSyncedLocations = (stationId) => {
    return locations.filter((location) => location.stations.includes(stationId)).length;
  };

  return (
    <div>
      <div className={"margin-bottom-6"}>
        <div className={"margin-bottom-6"}>
          <div className={"margin-bottom-2"}>
            <h4>Linked Stations</h4>
            <p>
              When a guest orders at the chosen location(s), where will the order go?{" "}
              <a href={"https://central.bbot.menu/article/515-editing-locations-stations"}>More info here.</a>
            </p>
          </div>

          <Card>
            <div className={"margin-bottom-2"}>
              {Object.entries(stations).map((stationEntry) => (
                <BulkEditableSetting
                  key={`bulk-editable-setting-stations-${stationEntry[0]}`}
                  title={stationEntry[1]}
                  description={
                    <span>{getNumSyncedLocations(stationEntry[0])} locations currently have this station linked.</span>
                  }
                  inBulkEditMode={bulkEditMode}
                  bulkEditButtonText={"Edit For Selected Locations"}
                  onBulkEditInitial={(toggleChecked) => {
                    updateLocationSettings({
                      [stationEntry[0]]: toggleChecked ? "add" : "remove",
                    });
                  }}
                  onBulkEditCancel={() => {
                    removeChanges(stationEntry[0]);
                  }}
                  toggleConfig={{
                    defaultEnabled: defaultLocationSettings?.stations?.includes(stationEntry[0]) || false,

                    onClick: (e) => {
                      updateLocationSettings({
                        [stationEntry[0]]: e.SWITCH_STATE.enabled ? "add" : "remove",
                      });
                    },
                  }}
                />
              ))}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default StationsTab;
