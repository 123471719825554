import { useEffect, useState } from "react";

import axios from "axios";
import { CARD_PROCESSING_RATE_SUPERUSERS } from "Constants";
import { DateTime } from "luxon";
import moment from "moment";
import { BbotModal, Col, Form, notification, Row } from "top-component-library";
import DateTimePickerInput from "top-component-library/form-inputs/DateTimePickerInput";
import NumberInput from "top-component-library/form-inputs/NumberInput";
import { fractionToPercentage, generalErrorAlert } from "util/Utils";

const CreateEditCardRateModal = (props) => {
  const { show, onClose, mode, selectedCardRate, userInfo } = props;
  const [formValues, setFormValues] = useState({});
  const [editCardRateForm] = Form.useForm();

  useEffect(() => {
    setFormValues(initialValues);
    editCardRateForm.resetFields();
  }, [selectedCardRate, show]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveCardProcessingRate = async (values) => {
    const { onSave, onClose, selectedCardRate, selectedCustomer, mode } = props;
    const endpoint = mode === "create" ? "/admin/createCardProcessingRate" : "/admin/editCardProcessingRate";

    const rateProperties = {
      card_present_fraction: values.card_present_fraction / 100,
      card_present_cents: values.card_present_cents,
      card_not_present_fraction: values.card_not_present_fraction / 100,
      card_not_present_cents: values.card_not_present_cents,
      auth_canceled_fraction: values.auth_canceled_fraction / 100,
      auth_canceled_cents: values.auth_canceled_cents,
      auth_increment_fraction: values.auth_increment_fraction / 100,
      auth_increment_cents: values.auth_increment_cents,
      start_date: values.start_date,
      end_date: values.end_date,
    };

    const payload =
      mode === "create"
        ? {
            customerId: selectedCustomer?.customer_id,
            initialValues: rateProperties,
            authorizedSuperUser: values.userCanSetAnyValue,
          }
        : {
            customerId: selectedCustomer?.customer_id,
            cardRateId: selectedCardRate?.id,
            propertiesToEdit: rateProperties,
            authorizedSuperUser: values.userCanSetAnyValue,
          };
    try {
      await axios.post(endpoint, payload);
      notification.success({
        message: "Successfully " + (mode === "create" ? "created" : "edited") + " card processing rate.",
      });
      onSave();
      onClose();
    } catch (error) {
      generalErrorAlert(error, "Trouble saving card processing rate. Please refresh and try again.");
    }
  };

  const startDate = DateTime.now().toUTC().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 });
  const endDate = DateTime.now().toUTC().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).plus({ years: 1 });

  const initialValues =
    mode === "create"
      ? {
          start_date: moment(startDate),
          end_date: moment(endDate),
          card_present_fraction: 2.4,
          card_present_cents: 15,
          card_not_present_fraction: 2.79,
          card_not_present_cents: 30,
          auth_canceled_fraction: 0,
          auth_canceled_cents: 5,
          auth_increment_fraction: 0,
          auth_increment_cents: 15,
        }
      : {
          start_date: moment(selectedCardRate?.start_date),
          end_date: moment(selectedCardRate?.end_date),
          card_present_fraction: fractionToPercentage(selectedCardRate?.card_present_fraction, 3),
          card_present_cents: selectedCardRate?.card_present_cents,
          card_not_present_fraction: fractionToPercentage(selectedCardRate?.card_not_present_fraction, 3),
          card_not_present_cents: selectedCardRate?.card_not_present_cents,
          auth_canceled_fraction: fractionToPercentage(selectedCardRate?.auth_canceled_fraction, 3),
          auth_canceled_cents: selectedCardRate?.auth_canceled_cents,
          auth_increment_fraction: fractionToPercentage(selectedCardRate?.auth_increment_fraction, 3),
          auth_increment_cents: selectedCardRate?.auth_increment_cents,
        };

  const userCanSetAnyValue = CARD_PROCESSING_RATE_SUPERUSERS.includes(userInfo?.email);

  const validationSchema = {
    //comparing seconds for dates as antd doesnt play nice with them
    start_date: [
      {
        type: "date",
        transform(value) {
          return DateTime.fromISO(value?.format()).toSeconds();
        },
        min: DateTime.now().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).toSeconds(),
        message: "Start Date must be on or after Today",
      },
    ],
    end_date: [
      {
        type: "date",
        transform(value) {
          return DateTime.fromISO(value?.format()).toSeconds();
        },
        min: DateTime.now().set({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).toSeconds(),
        message: "End Date must be on or after Today",
      },
      {
        type: "date",
        transform(value) {
          return DateTime.fromISO(value?.format()).toSeconds();
        },
        min: DateTime.fromISO(editCardRateForm.getFieldValue("start_date")?.format()).toSeconds(),
        message: "End Date must be on or after Start Date",
      },
    ],
    card_present_fraction: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 1,
            max: 10,
            message: "Please input a value between 1 and 10",
          },
        ],
    card_present_cents: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            message: "Please input a value greater than or equal to 0",
          },
        ],
    card_not_present_fraction: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 1.5,
            max: 5,
            message: "Please input a value between 1.5 and 5",
          },
        ],
    card_not_present_cents: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            message: "Please input a value value greater than or equal to 0",
          },
        ],
    auth_canceled_fraction: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            max: 10,
            message: "Please input a value between 0 and 10",
          },
        ],
    auth_canceled_cents: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            message: "Please input a value greater than or equal to 0",
          },
        ],
    auth_increment_fraction: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            max: 10,
            message: "Please input a value between 0 and 10",
          },
        ],
    auth_increment_cents: userCanSetAnyValue
      ? []
      : [
          {
            type: "number",
            min: 0,
            message: "Please input a value greater than or equal to 0",
          },
        ],
  };

  return (
    <BbotModal
      visible={show}
      onCancel={onClose}
      title={(mode === "create" ? "Create New" : "Edit") + " Card Processing Rate"}
      closable
      okText={"Save"}
      onOk={editCardRateForm.submit}
      width={600}
    >
      <Form
        form={editCardRateForm}
        name={"edit-card-rate"}
        onFinish={(values) =>
          saveCardProcessingRate({
            ...values,
            userCanSetAnyValue: userCanSetAnyValue,
          })
        }
        layout={"vertical"}
        initialValues={initialValues}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <Row gutter={[24, 6]}>
          <Col span={12}>
            <DateTimePickerInput name="start_date" label="Start Date" rules={validationSchema.start_date} />
          </Col>
          <Col span={12}>
            <DateTimePickerInput name="end_date" label="End Date" rules={validationSchema.end_date} />
          </Col>

          <Col span={12}>
            <NumberInput
              name={"card_present_fraction"}
              id={"card_present_fraction"}
              label={"Card Present Percentage"}
              step="0.01"
              addonAfter="%"
              rules={validationSchema.card_present_fraction}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              name={"card_present_cents"}
              id={"card_present_cents"}
              label={"Card Present Cents"}
              rules={validationSchema.card_present_cents}
            />
          </Col>

          <Col span={12}>
            <NumberInput
              name={"card_not_present_fraction"}
              id={"card_not_present_fraction"}
              label={"Card Not Present Percentage"}
              step="0.01"
              addonAfter="%"
              rules={validationSchema.card_not_present_fraction}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              name={"card_not_present_cents"}
              id={"card_not_present_cents"}
              label={"Card Not Present Cents"}
              rules={validationSchema.card_not_present_cents}
            />
          </Col>

          <Col span={12}>
            <NumberInput
              name={"auth_canceled_fraction"}
              id={"auth_canceled_fraction"}
              label={"Auth Cancelled Percentage"}
              step="0.01"
              addonAfter="%"
              rules={validationSchema.auth_canceled_fraction}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              name={"auth_canceled_cents"}
              id={"auth_canceled_cents"}
              label={"Auth Cancelled Cents"}
              rules={validationSchema.auth_canceled_cents}
            />
          </Col>

          <Col span={12}>
            <NumberInput
              name={"auth_increment_fraction"}
              id={"auth_increment_fraction"}
              label={"Auth Increment Percentage"}
              step="0.01"
              addonAfter="%"
              rules={validationSchema.auth_increment_fraction}
            />
          </Col>
          <Col span={12}>
            <NumberInput
              name={"auth_increment_cents"}
              id={"auth_increment_cents"}
              label={"Auth Increment Cents"}
              rules={validationSchema.auth_increment_cents}
            />
          </Col>
        </Row>
        <Row>
          <div className={"supporting-text text-danger"}>
            IMPORTANT! Any change to card processing rate must have explicit approval from the DoorDash Deal Desk.
            Ensure there is a Slack thread approving this rate in the #deal-desk Slack channel before you set it.
          </div>
        </Row>
      </Form>
    </BbotModal>
  );
};

export default CreateEditCardRateModal;
