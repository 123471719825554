import {
  menuHeadingsColumns,
  menuItemsColumns,
  menusColumns,
  modifierGroupsColumns,
  modifiersColumns,
  tagsColumns,
} from "./columns";

import { MenusTableKeys } from "./constants";

export const menusData = [
  {
    [MenusTableKeys.MENU_NAME]: "hello table",
    [MenusTableKeys.MENU_HEADINGS]: "hi",
    [MenusTableKeys.ENABLED]: "yes",
    [MenusTableKeys.SCHEDULE]: "4/20",
  },
];

export const menuHeadingsData = [
  {
    [MenusTableKeys.MENU_HEADING_NAME]: "bla",
    [MenusTableKeys.SORT_ITEMS]: "sort items",
  },
];

export const menuItemsData = [
  {
    [MenusTableKeys.NAME_FOR_OWNER]: "name",
    [MenusTableKeys.MENU]: "bla",
    [MenusTableKeys.HEADING]: "bla",
    [MenusTableKeys.ENABLED]: "enabled",
    [MenusTableKeys.IN_STOCK]: "bla",
    [MenusTableKeys.PRICE]: "bla",
    [MenusTableKeys.DESCRIPTION]: "description",
    [MenusTableKeys.REPORT_CATEGORY]: "report category",
  },
];

export const modifierGroupsData = [
  {
    [MenusTableKeys.MODIFIER_GROUP_NAME]: "bla",
    [MenusTableKeys.SELECTABLE_MODIFIERS]: "selectable",
    [MenusTableKeys.PRE_SELECTED_ITEMS]: "bla",
    [MenusTableKeys.SORT_MODIFIERS]: "sorted",
  },
];

export const modifiersData = [
  {
    [MenusTableKeys.MODIFIER_NAME]: "hello modifier name",
    [MenusTableKeys.MODIFIER_GROUP]: "group me",
    [MenusTableKeys.ENABLED]: "bla",
    [MenusTableKeys.IN_STOCK]: "yes",
    [MenusTableKeys.PRICE]: "bla",
  },
];

export const tagsData = [
  {
    [MenusTableKeys.ICON]: "iconic",
    [MenusTableKeys.TAG_NAME]: "tag name",
  },
];

export const tags = {
  columns: tagsColumns,
  data: tagsData,
};

export const menus = {
  columns: menusColumns,
  data: menusData,
};

export const menuHeadings = {
  columns: menuHeadingsColumns,
  data: menuHeadingsData,
};

export const menuItems = {
  columns: menuItemsColumns,
  data: menuItemsData,
};

export const modifierGroups = {
  columns: modifierGroupsColumns,
  data: modifierGroupsData,
};

export const modifiers = {
  columns: modifiersColumns,
  data: modifiersData,
};
