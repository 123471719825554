import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Col, Row } from "top-component-library";

const Breadcrumbs = ({ name, link, refreshOnCurrentPageClick = false, ...props }) => {
  return (
    <Row>
      <Col span={24}>
        <BreadcrumbsText className={"margin-bottom-4"}>
          <span>
            <BreadcrumbBack to="/">
              <HomeOutlined />
            </BreadcrumbBack>
          </span>
          <BreadcrumbElement>&#62;</BreadcrumbElement>
          <BreadcrumbElement>
            <BreadcrumbLink to="/">Owner Portal</BreadcrumbLink>
          </BreadcrumbElement>
          <BreadcrumbElement>&#62;</BreadcrumbElement>
          <BreadcrumbElement>
            <BreadcrumbLinkActive
              to={link}
              onClick={refreshOnCurrentPageClick ? () => window.location.reload() : () => {}}
            >
              {name}
            </BreadcrumbLinkActive>
          </BreadcrumbElement>
        </BreadcrumbsText>
      </Col>
    </Row>
  );
};

const BreadcrumbsText = styled.div`
  display: flex;
  align-content: center;
`;

const BreadcrumbLink = styled(Link)`
  margin: 4px;
  color: grey;
  font-weight: normal;
`;

const BreadcrumbLinkActive = styled(Link)`
  background-color: var(--color-primary__light);
  border-radius: 10px;
  padding: 10px;
`;

const BreadcrumbBack = styled(Link)`
  margin: 4px;
  color: grey;
  font-weight: normal;
  font-size: 20px;
`;

const BreadcrumbElement = styled.span`
  margin-left: 10px;
  padding-top: 6px;
`;

export default Breadcrumbs;
