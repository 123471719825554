import styled from "styled-components";
import { Form, Input } from "top-component-library";
import { EMAIL_VALIDATION_REGEX } from "../../Constants";

const EmailInput = ({ id, label = "", name = "", required = true, validationMessage, rules = [], ...props }) => {
  return (
    <Form.Item
      id={id}
      label={label}
      name={name}
      rules={[
        {
          required: required,
          message: validationMessage || "This is a required field",
          transform: (value) => value?.trim(),
        },
        {
          pattern: new RegExp(EMAIL_VALIDATION_REGEX),
          message: "This must be a valid email.",
        },
      ].concat(rules)}
      validateTrigger={["onBlur", "onChange"]}
    >
      <StyledInput type={"text"} {...props} />
    </Form.Item>
  );
};

const StyledInput = styled(Input)`
  padding: 8px;
`;

export default EmailInput;
