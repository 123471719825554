import React from "react";
import { StackChildren, Text } from "@doordash/design-language";
import Loading from "@doordash/component-loading";
import ImageUploaderAndCropper, { ImageUploaderAndCropperProps } from "./ImageUploaderAndCropper";

type ImageUploaderAndCropperContainerProps = ImageUploaderAndCropperProps & {
  description?: string;
  isLoading?: boolean;
};

const ImageUploaderAndCropperContainer: React.FC<ImageUploaderAndCropperContainerProps> = ({
  description,
  isLoading = false,
  ...props
}) => (
  <StackChildren>
    <Text tag="h2" styles={!!description ? Text.Styles.Title1 : Text.Styles.Title2}>
      {props.title}
    </Text>

    {description && (
      <Text styles={Text.Styles.Body2} color={Text.Colors.Secondary}>
        {description}
      </Text>
    )}

    {isLoading ? (
      <div className="margin-y-6">
        <Loading />
      </div>
    ) : (
      <ImageUploaderAndCropper {...props} />
    )}
  </StackChildren>
);

export default ImageUploaderAndCropperContainer;
