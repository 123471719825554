import { AddOrEditTagModal } from "./modals/AddOrEditTagModal";
import { AddOrEditModifierModal } from "./modals/AddOrEditModifierModal";
import { AddOrEditMenuModal } from "./modals/AddMenuModal";
import { AddOrEditMenuHeadingsModal } from "./modals/AddOrEditMenuHeadingsModal";
import { AddOrEditModifierGroupModal } from "./modals/AddOrEditModifierGroupModal";
import { AddOrEditMenuItemModal } from "./modals/AddOrEditMenuItemModal";

export const modals: any = {
  tags: AddOrEditTagModal,
  modifiers: AddOrEditModifierModal,
  menus: AddOrEditMenuModal,
  modifier_groups: AddOrEditModifierGroupModal,
  menu_items: AddOrEditMenuItemModal,
  menu_headings: AddOrEditMenuHeadingsModal,
};

export const formFieldsDictionary: any = (
  menuHeadingsForm: any,
  menuItemsForm: any,
  menusForm: any,
  modifierGroupsForm: any,
  modifiersForm: any,
  tagsForm: any
) => ({
  menu_headings: menuHeadingsForm,
  menu_items: menuItemsForm,
  menus: menusForm,
  modifier_groups: modifierGroupsForm,
  modifiers: modifiersForm,
  tags: tagsForm,
});

export const updateFieldsDictionary: any = (
  setMenuHeadingsForm: any,
  setMenuItemsForm: any,
  setmenusForm: any,
  setModifierGroupsForm: any,
  setModifiersForm: any,
  setTagsForm: any
) => ({
  menu_headings: setMenuHeadingsForm,
  menu_items: setMenuItemsForm,
  menus: setmenusForm,
  modifier_groups: setModifierGroupsForm,
  modifiers: setModifiersForm,
  tags: setTagsForm,
});
