import { Form } from "antd";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { formatPhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import { BbotAlert } from "top-component-library";
import en from "world_countries_lists/data/countries/en/world.json";

const PhoneInput = ({
  id,
  label = "Phone Number",
  name = "phone",
  required = true,
  onChange = () => void 0,
  ...props
}) => {
  return (
    <ConfigProvider locale={en}>
      <StyledFormItem
        label={label}
        name={name}
        rules={[
          {
            required: required,
            message: "This is a required field",
          },
        ]}
        validateTrigger={["onBlur", "onChange"]}
      >
        <FormattedCountryPhoneInput id={id} onChange={(val) => onChange(val)} {...props} />
      </StyledFormItem>
    </ConfigProvider>
  );
};

const EMPTY_PHONE_OBJ = {
  phone: null,
  code: null,
  short: "US",
};

/*
This needs to be a separate component so that the value can be correctly transformed between a simple string and
a complex object. It gets passed to the main component as a string, then when it enters this component it's changed
into an object, and then changed back again when it's changed
 */
const FormattedCountryPhoneInput = ({ onChange, id, value = "", ...props }) => {
  // Initialize empty input value, defaulted to US

  const [phoneObject, setPhoneObject] = useState(EMPTY_PHONE_OBJ);
  // When a value is passed in from the main component parse it into an object and set the input value
  useEffect(() => {
    if (!value) setPhoneObject(EMPTY_PHONE_OBJ);
    else {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber)
        setPhoneObject({
          phone: formatPhoneNumber(value),
          code: parsedNumber.countryCallingCode,
          short: parsedNumber.metadata.country_calling_codes[parsedNumber.countryCallingCode][0],
        });
    }
  }, [value]);

  // When the input value changes, send the formatted version back to the main component
  // Triggering onChange causes the Form.Item to update to the given value
  useEffect(() => {
    if (phoneObject.phone) onChange(`+${phoneObject.code}${phoneObject.phone.replace(/\D/g, "")}`);
    else onChange("");
  }, [phoneObject]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <StyledPhoneInput id={id} value={phoneObject} onChange={(val) => setPhoneObject(val)} {...props} />
      {value && !isValidPhoneNumber(value) && (
        <BbotAlert type="warning" message="Warning: This is not a valid phone number" />
      )}
    </>
  );
};

const StyledFormItem = styled(Form.Item)`
  max-width: 400px;
  &&&.ant-form-item-has-error
    > .ant-form-item-control
    > .ant-form-item-control-input
    > .ant-form-item-control-input-content
    > .antd-country-phone-input {
    border-color: #ff4d4f !important; // Set the border color when there's a field error
    > .ant-input-prefix > span > .ant-select > .ant-select-selector {
      border-color: white !important; // Don't add a border around the country prefix
    }
  }
`;

const StyledPhoneInput = styled(CountryPhoneInput)`
  border-radius: 8px;
  border-color: var(--input__border-color);
  &&&.ant-input-affix-wrapper:hover {
    border-color: #cdd5e0; // Set the border to the correct color on focus. :hover worked and not :focus. Not sure why
  }
  &&&.ant-input-affix-wrapper > input.ant-input:focus {
    border: none; // Get rid of the border on the internal input
  }
`;

export default PhoneInput;

PhoneInput.propType = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};
