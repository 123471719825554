import { Icon } from "@doordash/design-language";
import axios from "axios";
import { ENV } from "Config";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { notification } from "../top-component-library";

export const envIsProd = () => {
  return ENV === "prod";
};

export const envIsLocalDev = () => {
  return ENV === "localdev";
};

export const envIsStaging = () => {
  return ENV === "staging";
};

export const envIsDev = () => {
  return ENV === "dev";
};

export const envIsDemo = () => {
  return ENV === "demo";
};

export const openLinkInNewTab = (url) => {
  const cleanedUrl = url.startsWith("http") ? url : `https://${url}`;
  window.open(cleanedUrl, "_blank").focus();
};

/**
 * If no fileExtension is provided, will download as a zip.
 * @param {*} data
 * @param {*} zipFileName
 * @param {*} fileExtension
 */
export const downloadDataFromBrowser = (data, zipFileName, fileExtension = "zip") => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const fileName = `${zipFileName}.${fileExtension}`;
  downloadFileFromUrl(downloadUrl, fileName);
};

export const downloadFileFromUrl = (url, filename) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

// =======================================================
// ==================== MONEY FUNCTIONS ==================
// =======================================================

export const centsToDollar = (cents) => (cents / 100).toFixed(2);

export const formatPrice = (price, currency = "USD", locale = "en-US", multiplier = 1) => {
  if (isNaN(price)) {
    return "";
  } else {
    return Intl.NumberFormat(locale, { style: "currency", currency }).format(price * multiplier);
  }
};

export const getCurrencySymbol = (currency = "USD", locale = "en-US") => {
  return (0)
    .toLocaleString(locale, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

export const getCreditCardIconType = (cardBrand) => {
  switch (cardBrand) {
    case "visa":
      return Icon.Types.CardVisaColor;
    case "mastercard":
      return Icon.Types.CardMastercardColor;
    case "amex":
      return Icon.Types.CardAmexColor;
    case "discover":
      return Icon.Types.CardDiscoverColor;
    default:
      return Icon.Types.CardLine;
  }
};

// =======================================================
// ============== STRING UTIL FUNCTIONS ==================
// =======================================================

/**
 * Converts ISO timestamp to human readable date: "2020-01-01T00:00:00Z" to "1/1/2020"
 * @param {*} isoDate
 * @returns Human readable date string
 */
export const isoToReadableDate = (isoDate) => DateTime.fromISO(isoDate).toUTC().set({ hour: 12 }).toLocaleString();

export const isNumber = (val) => {
  if (/[^\.0-9]/.test(val)) return false;
  if (val.length === 1) return true;
  return !Number.isNaN(+val);
};

/**
 * Takes a string, if it is less than numCharacters, replace the end with "..."
 * @param string: string to trim
 * @param numCharacters: number of characters to trim string to
 */
export const trimString = (string, numCharacters = 25) => {
  if (!string) return "";
  if (string.length < numCharacters) {
    return string;
  }

  return string.substring(0, numCharacters - 3) + "...";
};

/**
 * Splits a camelCase or PascalCase word into individual words separated by spaces.
 * @param {Object} word
 * @returns {String}
 */
export const splitCamelCase = (word) => {
  let output,
    i,
    l,
    capRe = /[A-Z]/;
  if (typeof word !== "string") {
    throw new Error('The "word" parameter must be a string.');
  }
  output = [];
  for (i = 0, l = word.length; i < l; i += 1) {
    if (i === 0) {
      output.push(word[i].toUpperCase());
    } else {
      if (i > 0 && capRe.test(word[i])) {
        output.push(" ");
      }
      output.push(word[i]);
    }
  }
  return output.join("");
};

/**
 * Replaces underscores with spaces to separate snake case strings into user readable sentences.
 * @param word
 * @returns {*}
 */
export const splitSnakeCase = (word) => {
  return word.replaceAll("_", " ");
};

/**
 * Takes a string, splits by spaces, and capitalizes each word
 * @param sentence
 * @returns {*}
 */
export const capitalizeString = (sentence) => {
  if (!sentence) return "";

  const words = sentence.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
};

export const timeISOToUserReadableDate = (timeString) => {
  return DateTime.fromISO(timeString).toLocaleString();
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const bytesToReadableSize = (bytes, dp = 1) => {
  const thresh = 1000;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
};

/**
 * Use this function with a filter to remove all duplicates.
 * Example: locations.filter(uniqueFilterFunc)
 * @param value
 * @param index
 * @param self
 * @returns {boolean}
 */
export const uniqueFilterFunc = (value, index, self) => {
  return self.indexOf(value) === index;
};

// customer sort function
export const customerSortAdmin = (customer1, customer2) => {
  // if customers are deactivated
  if (customer1.archived && !customer2.archived) {
    return 1;
  }
  if (customer2.archived && !customer1.archived) {
    return -1;
  }
  return customer1.name_for_admin.localeCompare(customer2.name_for_admin);
};

// customer sort function
export const customerSortOwner = (customer1, customer2) => {
  // if customers are deactivated
  if (customer1.archived && !customer2.archived) {
    return 1;
  }
  if (customer2.archived && !customer1.archived) {
    return -1;
  }
  return customer1.name_for_owner.localeCompare(customer2.name_for_owner);
};

export const fractionToPercentage = (num, digits = 2) =>
  Math.round((num + Number.EPSILON) * 10 ** (digits + 2)) / 10 ** digits;

// Takes an ordinal, aka # of days since 1/1/1, and returns a human readable date.
export const getFormattedDateFromOrdinal = (ordinal) => {
  const gregorianStart = DateTime.fromObject({ year: 1, month: 1, day: 1 });
  const dateTime = gregorianStart.plus({ days: ordinal - 1 });
  return dateTime.toLocaleString(DateTime.DATE_FULL);
};

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * Handle toast alert logic for TOP App Store Integrations
 * @param error usually error from catch block of API call
 * @param generalErrorMessage
 * @param useAntDesign
 * @param customer_id
 */
export function generalErrorAlert(
  error,
  generalErrorMessage = "Unknown Error.",
  useAntDesign = false,
  customer_id = null
) {
  console.error(error);
  if (error.errorCode) {
    error.userFacingError = error.errorCode;
  } else if (error.message) {
    error.userFacingError = error.message;
  } else if (error.response && error.response.data.errorCode) {
    error.userFacingError = error.response.data.errorCode;
  } else if (error.response && error.response.data.message) {
    error.userFacingError = error.response.data.message;
  } else if (error.response && error.response.data.error) {
    error.userFacingError = error.response.data.message;
  } else {
    error.userFacingError = generalErrorMessage + " Please try again or contact DoorDash at bb-support@doordash.com";
  }
  return useAntDesign ? notification.error({ message: error.userFacingError }) : toast.error(error.userFacingError);
  // axios.post('https://3oz00ipsfd.execute-api.us-east-1.amazonaws.com/Prod', {
  //     'user_facing_error': error.userFacingError,
  //     'customer_id': customer_id,
  //     'endpoint': error.response.config.url,
  //     'err': error},
  // );
}
// ==============================
// SCHEDULE AND TIME UTIL FUNCS
// ==============================

const timeStringToMilitaryTime = (time_string) => {
  let regex = /^(\d\d?):?(\d\d)?(AM|PM)$/g;
  try {
    let match = regex.exec(time_string);
    let [, hour_string, minute_string, meridian_string, ,] = match;
    if (!minute_string) {
      minute_string = "00";
    }
    let hour_int = parseInt(hour_string);
    if (hour_int === 12) {
      hour_int = 0;
    }
    return 1200 * (meridian_string === "PM") + hour_int * 100 + parseInt(minute_string);
  } catch (err) {
    throw new Error("Invalid time format");
  }
};

export const parseTimeRangeList = (time_range_list) => {
  try {
    // Filter our empty strings which parseTimeRange converts to "" before this function
    time_range_list = time_range_list.filter((time_range) => time_range?.length);

    // Return parsed time blocks
    return time_range_list
      .filter((timeRange) => timeRange && timeRange.length)
      .map((timeRange) => {
        // If there is a time range inputted and it is not an empty string
        let [t0, t1] = parseTimeRange(timeRange);
        if (t0 <= t1) {
          return [t0, t1];
        } else {
          return [t0, t1 + 2400];
        }
      });
  } catch (err) {
    throw err;
  }
};

// used by parseTimeRangeList
const parseTimeRange = (time_range_string) => {
  try {
    let [t0str, t1str] = time_range_string.split("-");
    const t0 = timeStringToMilitaryTime(t0str);
    let t1 = timeStringToMilitaryTime(t1str);
    if (t1 === 0) {
      t1 = 2400;
    }
    return [t0, t1];
  } catch (error) {
    throw error;
  }
};

export const cleanTextScheduleInput = (input) => {
  if (input) {
    return input
      .replace(/[\s.]/g, "")
      .toUpperCase()
      .replace("CLOSED", "")
      .replace("MIDNIGHT", "12AM")
      .replace("OPEN", "12AM-12AM");
  } else {
    return null;
  }
};

const timeRangesOverlap = (block1, block2) => {
  const [startA, endA] = block1;
  const [startB, endB] = block2;
  return (startB <= endA && endA <= endB) || (startA <= endB && endB <= endA);
};

const validateTimeRanges = (currentDayBlocks, nextDayBlocks) => {
  const numRangesCurrent = currentDayBlocks.length;
  const numRangesNextDay = nextDayBlocks.length;

  for (let i = 0; i < numRangesCurrent; i++) {
    let [t0, t1] = currentDayBlocks[i];

    if (t1 <= t0) t1 = t1 + 2400; // Accounts for overlaps into next day

    if (numRangesCurrent > 1) {
      // Loop through the current day blocks
      // Check if there is overlap internally for the time blocks
      for (let b = i + 1; b < numRangesCurrent; b++) {
        let [t2, t3] = currentDayBlocks[b];

        if (t3 <= t2) t3 = t3 + 2400; // Accounts for overlaps into next day

        const overlaps = timeRangesOverlap([t0, t1], [t2, t3]);
        if (overlaps) {
          return "Overlapping time ranges.";
        }
      }
    }

    // Loop through the next day blocks
    // ensure that the overlap into next day doesnt conflict with any time blocks for the next day
    if (t1 > 2400 && numRangesNextDay) {
      // Create Overlap Time Block for comparisons
      const [ovt0, ovt1] = [0, t1 - 2400];

      for (let c = 0; c < numRangesNextDay; c++) {
        let [t0, t1] = nextDayBlocks[c];

        if (t1 <= t0) t1 = t1 + 2400; // Accounts for overlaps into next day

        const overlaps = timeRangesOverlap([ovt0, ovt1], [t0, t1]);
        if (overlaps) {
          return "Overlapping time ranges with time ranges for next day.";
        }
      }
    }
  }

  // Return no errors.
  return null;
};

export const validateScheduleInputs = (textSchedule, nextDayTextSchedule) => {
  const textScheduleClean = cleanTextScheduleInput(textSchedule);
  const nextDayTextScheduleClean = cleanTextScheduleInput(nextDayTextSchedule);

  try {
    return validateTimeRanges(
      textScheduleClean ? parseTimeRangeList(textScheduleClean.split(",")) : [],
      nextDayTextScheduleClean ? parseTimeRangeList(nextDayTextScheduleClean.split(",")) : []
    );
  } catch (err) {
    return err.message;
  }
};

/**
 * Converts an integer military time to human time. e.g. 1335 -> 1:35PM
 * @param militaryTime
 */
export const militaryTimeToTimeString = (militaryTime) => {
  if (militaryTime === 2400) return "12AM";

  const hour = Math.floor(militaryTime / 100) % 12;
  const formattedHour = hour === 0 ? 12 : hour;
  const minutes = militaryTime % 100;
  const meridian = militaryTime >= 1200 ? "PM" : "AM";

  if (minutes === 0) return formattedHour + meridian;
  if (minutes < 10) return formattedHour + ":0" + minutes + meridian;
  return formattedHour + ":" + minutes + meridian;
};

export const removeKeys = (obj, deleteKeys) => {
  let clone = Object.assign({}, obj);
  deleteKeys.forEach((deleteKey) => {
    delete clone[deleteKey];
  });
  return clone;
};

/**
 * Returns whether or not a feature is enabled given a customer object.
 * @param feature_id
 * @param customer Note: customer object, not customer id
 * @returns {false}
 */
export const isFeatureEnabledById = (feature_id, customer) => {
  return !!customer && customer.enabled_features.includes(feature_id);
};

/**
 * Generates a random pin with the specified number of digits.
 * @param numDigits Number of digits to be in the pin. Defaults to 4.
 * @returns {string}
 */
export const generateRandomPin = (numDigits = 4) => {
  const max = Math.pow(10, numDigits);
  const rand = Math.floor(Math.random() * max);
  const randString = rand.toString();
  if (randString.length === numDigits) return randString;
  return rand.toString().padStart(numDigits, "0");
};

/**
 * Makes a GET request with our standard error handling.
 * @param url Request URL
 * @param onSuccess Function to be called if the GET request is successful.
 * @param defaultUserFacingErrorMessage Error message shown to user if request fails
 */
export const makeGETRequestWithErrorHandling = async (url, onSuccess, defaultUserFacingErrorMessage) => {
  try {
    const res = await axios.get(url);
    if (!!onSuccess) {
      onSuccess(res.data);
    }
  } catch (error) {
    generalErrorAlert(error, defaultUserFacingErrorMessage);
  }
};

/**
 * Makes a POST request with our standard error handling.
 * @param url Request URL
 * @param payload Request body
 * @param onSuccess Function to be called if the POST request is successful
 * @param userFacingSuccessMessage Message shown to user if request is successful
 * @param defaultUserFacingErrorMessage Error message shown to user if request fails
 */
export const makePOSTRequestWithErrorHandling = async (
  url,
  payload,
  onSuccess,
  userFacingSuccessMessage,
  defaultUserFacingErrorMessage
) => {
  try {
    let res = await axios.post(url, payload);
    if (!!userFacingSuccessMessage) {
      toast.success(userFacingSuccessMessage);
    }
    if (!!onSuccess) {
      onSuccess(res.data);
    }
  } catch (error) {
    generalErrorAlert(error, defaultUserFacingErrorMessage);
  }
};

/**
 * Takes a list of objects and calculates which value appears most frequently for each key
 * @param objects List of objects to calculate
 * @returns {{}}
 */
export const calculateDefaultObjectSettings = (objects) => {
  if (objects.length === 0) return;

  const properties = Object.keys(objects[0]);
  let defaultSettings = {};
  properties.forEach((property) => {
    const objectProperties = objects.map((object) => object[property]);

    // annotate properties with counters
    const countedProperties = objectProperties
      .reduce((counter, property) => {
        const counterProperty = counter.find((counterEntry) => counterEntry.property === property);
        if (counterProperty) {
          // if property in counter, increase counter
          counterProperty.count += 1;
        } else {
          counter.push({ property: property, count: 1 });
        }
        return counter;
      }, [])
      .sort((propertyA, propertyB) => (propertyA.count < propertyB.count ? 1 : -1));
    defaultSettings[property] = countedProperties[0].property;
  });

  return defaultSettings;
};

/**
 * Takes two inputs and returns if they are unique (works with arrays)
 * @param first First item to compare
 * @param second Second item to compare
 * @returns {boolean}
 */
export const isUnique = (first, second) => {
  if (Array.isArray(first) && Array.isArray(second)) {
    let firstCopy = [...first];
    let secondCopy = [...second];
    firstCopy.sort();
    secondCopy.sort();
    return JSON.stringify(firstCopy) !== JSON.stringify(secondCopy);
  } else return first !== second;
};

/**
 * Takes a fulfillment_method object (as returned by api/ownerlocations) and returns a list of their keys
 * @param fulfillmentMethods fulfillment_method object (as returned by api/ownerlocations)
 * @returns {string[]}
 */
export const fulfillmentMethodsToIds = (fulfillmentMethods) => {
  if (!fulfillmentMethods) return [];
  return Object.keys(fulfillmentMethods);
};

export const fulfillmentKeysToPrettyNames = (fulfillmentMethodKeys, fulfillmentMethods) => {
  try {
    if (fulfillmentMethodKeys === "all") return ["All"];
    return fulfillmentMethodKeys.map((key) => fulfillmentMethods[key]);
  } catch {
    return fulfillmentMethodKeys;
  }
};

/**
 * Removes 'patron_choice' from a fulfillment_methods object (as returned by api/ownerlocations)
 * @param fulfillmentMethods fulfillment_methods object (as returned by api/ownerlocations)
 * @returns {Pick<{patron_choice}|*, Exclude<keyof {patron_choice}|*, "patron_choice">>|*}
 */
export const removePatronChoice = (fulfillmentMethods) => {
  if (!fulfillmentMethods || !fulfillmentMethods.patron_choice) return fulfillmentMethods;
  const { patron_choice, ...rest } = fulfillmentMethods;
  return rest;
};

/**
 * Takes a list of fulfillment method keys and adds patron choice if pickup and/or delivery are present. Used for
 * filtering location codes by available fulfillment type
 * @param fulfillmentMethodKeys List of fulfillment method keys
 * @returns {*}
 */
export const addPatronChoice = (fulfillmentMethodKeys) => {
  return fulfillmentMethodKeys.includes("patron_pickup") ||
    fulfillmentMethodKeys.includes("driver_delivery") ||
    fulfillmentMethodKeys.includes("patron_pickup_with_expo") ||
    fulfillmentMethodKeys.includes("driver_delivery_with_expo")
    ? fulfillmentMethodKeys.concat(["patron_choice"])
    : fulfillmentMethodKeys;
};

/**
 * Takes a list, and if the list has a single item "all" it returns a list of the keys of all the fulfillment methods
 * passed to it, minus patron_choice, otherwise it returns the input. Used to unpack the value returned by
 * api/getAllOrderFeeConfigs
 * @param fulfillmentMethodKeys List of fulfillment method keys (as returned by api/getAllOrderFeeConfigs)
 * @param fulfillmentMethods fulfillment_method object (as returned by api/ownerlocations)
 * @returns {string[]|*}
 */
export const unfoldFulfillmentMethods = (fulfillmentMethodKeys, fulfillmentMethods) => {
  return fulfillmentMethodKeys === "all" // I feel like ===["all"] should work here but for some reason it doesn't
    ? fulfillmentMethodsToIds(removePatronChoice(fulfillmentMethods))
    : fulfillmentMethodKeys;
};

/**
 * Takes a list of location codes, and if it's the same length as all location codes, returns ["all"], otherwise returns
 * the input. Used to pack a selection of fulfillment_methods before sending it to api/serviceCharges or
 * api/saveOrderFeeConfig
 * @param fulfillmentMethodKeys List of fulfillment method keys (as strings)
 * @param fulfillmentMethods fulfillment_methods object (as returned by api/ownerlocations)
 * @returns {string[]|*}
 */
export const foldFulfillmentMethods = (fulfillmentMethodKeys, fulfillmentMethods) => {
  return fulfillmentMethodKeys?.length === unfoldFulfillmentMethods("all", fulfillmentMethods)?.length
    ? ["all"]
    : fulfillmentMethodKeys;
};

/**
 * Format a fulfillment method object (as returned from api/ownerlocations) into an array of objects, to be used in a checkbox
 * group
 * @param fulfillmentMethods fulfillment_methods object (as returned by api/ownerlocations)
 * @returns {*[]}
 */
export const fulfillmentMethodsToArray = (fulfillmentMethods) => {
  return Object.entries(fulfillmentMethods).map(([key, val]) => {
    return {
      value: key,
      label: val,
    };
  });
};

/**
 * Takes a list of location objects (as returned by api/ownerlocatioins) and returns a list of their IDs
 * @param locations Location objects (as returned by api/ownerlocations)
 * @returns {*[]}
 */
export const locationsToIds = (locations) => {
  if (!locations) return [];
  return locations.map((location) => location.locationId);
};

/**
 * Takes a list of location IDs and a list of location objects (as returned by api/ownerlocations) and returns the names
 * of the given location IDs
 * @param locationIds List of location IDs
 * @param locations List of location objects (as returned by api/ownerlocations)
 * @returns {*[]}
 */
export const locationIdsToNames = (locationIds, locations) => {
  return locationIds.map(
    (id) =>
      locations.find((location) => {
        return location.locationId === id;
      })?.locationName
  );
};

/**
 * Format an array of locations (as returned from api/ownerlocations) into an array of objects to be used in a checkbox
 * group
 * @param locations Array of locations objects (as returned from api/ownerlocations)
 * @returns {*}
 */
export const locationsToArray = (locations) => {
  return locations.map((location) => {
    return { value: location.locationId, label: location.locationName };
  });
};

/**
 * Takes a list of location IDs, a list of location objects (as returned by api/ownerlocations) and a list of
 * fulfillment method keys and returns a list of all location IDs that use one of the given fulfillment methods
 * @param locationIds List of location IDs to filter
 * @param locations List of location objects (as returned by api/ownerLocations)
 * @param fulfillmentMethodKeys List of fulfillment method keys to use as a filter
 * @returns {*}
 */
export const filterLocationIds = (locationIds, locations, fulfillmentMethodKeys) => {
  const updatedFulfillmentMethodKeys = addPatronChoice(fulfillmentMethodKeys);
  return locationIds?.filter((locationId) => {
    const location = locations.find((location) => location.locationId === locationId);
    return updatedFulfillmentMethodKeys.includes(location?.fulfillment_method);
  });
};

/**
 * Takes a list of location objects (as returned by api/ownerlocations) and a list of fulfillment method keys and
 * returns a list of all location objects taht use one of the given fulfillment methods
 * @param locations List of location objects (as returned by api/ownerLocations) to filter
 * @param fulfillmentKeys List of fulfillment method keys to use as a filter
 * @returns {*}
 */
export const filterLocations = (locations, fulfillmentKeys) => {
  return locations?.filter((location) => {
    return addPatronChoice(fulfillmentKeys).includes(location.fulfillment_method);
  });
};

/**
 * Converts snake case into title case. Shamelessly stolen from
 * https://www.codegrepper.com/code-examples/javascript/convert+snake+case+to+title+case+javascript
 * @param str Snake case string
 * @returns {*} Title case string
 */
export const snakeToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Converst camel case into title case. Shamelessly stolen from
 * https://stackoverflow.com/questions/7225407/convert-camelcasetext-to-sentence-case-text
 * @param str
 * @returns {string}
 */
export const camelToTitleCase = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 * Sort a list of options so that they will be listed correctly in X number of columns
 * @param options Items to be rearranged
 * @param numColumns Number of columns to arrange them in
 * @returns {*[]}
 */
export const verticalOptions = (options, numColumns = 2) => {
  let arr = [];
  for (let index = 0; index < Math.ceil(options.length / numColumns); index++) {
    for (let index2 = 0; index2 < numColumns; index2++) {
      const elementPosition = index + Math.ceil(options.length / numColumns) * index2;
      if (elementPosition < options.length) {
        arr.push(options[elementPosition]);
      }
    }
  }
  return arr;
};

export const dateFormat = new Intl.DateTimeFormat("en-US", {
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export const fixPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith("+")) return phoneNumber;
  else return "+1" + phoneNumber.replace(/\D/g, "");
};

/**
 * Taken from commonservices.js
 * @returns {string}
 */
export const generateUUIDv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const centsEqual = (cents1, cents2) => {
  return Math.abs(cents1 - cents2) < 0.00000000001;
};

/**
 *
 * @param countryCode
 * @param number
 * @returns {`+${string}${string}`}
 */
export const phoneNumberToString = (countryCode, number) => {
  return `+${countryCode}${number}`;
};
