export const menuHeadingsFields = {
  menu_heading_name: "",
};

export const menuItemsFields = {
  add_menu_tags: "",
  allow_modifiers: true,
  customize: [],
  description: "",
  display_in_menu_heading: "",
  include_on_menus: "",
  individual_drink_choice: [],
  inventory_management: "",
  item_display_name: "Mixed Hoagie Tray",
  item_type: "",
  more_info: true,
  most_loved: false,
  name_displayed_on_tablet: "",
  name_for_reports: "Mixed Hoagie Tray",
  preparation_choice: [],
  price: "0.00",
  protein_addition: [],
  protein_choice: [],
  report_category: "Food",
  salad_choice: [],
  show_image_on_front_page_of_menu: false,
  show_image_on_menu_item_page: false,
  side_choice: [],
  size_choice: [],
  skip_ahead_in_line: "",
  sku: "",
  special_instructions: false,
  tax_percent: "6",
  uses_the_same_inventory_as_item: false,
  which_station_fulfills_this_item: "",
};

export const menusFields = {
  menu_name: "yeet",
};

export const modifierGroupsFields = {
  maximum_selectable_amount: "",
  minimum_selectable_amount: "",
  modifier_group_name: "",
  pre_selected_items: "",
  show_this_heading_on_orders: false,
};

export const modifiersFields = {
  allow_duplicates: false,
  allow_modifiers: false,
  enabled: true,
  guests_may_type_instructions: false,
  inventory_management: "",
  item_display_name: "",
  item_type: "",
  name_displayed_on_tablet: "",
  name_for_reports: "",
  price: "",
  select_a_modifier_group: "",
  sku: "",
  tax_percent: "",
  uses_the_same_inventory_as_item: "",
};

export const tagsFields = {
  make_number_valued: false,
  tag_name: "",
  visible_to_patrons: false,
};
