import styled from "styled-components";
import { Tag } from "top-component-library";

const BbotTag = ({ color = "default", ...props }) => {
  return <StyledTag color={color} {...props} />;
};

const StyledTag = styled(Tag)`
  &&&.ant-tag {
    border-radius: 10px;
    border: none;
    display: inline-flex;
    align-items: center;

    &.ant-tag-default {
      color: var(--color-primary__regular);
      background-color: var(--color-primary__light);
    }
  }
`;
export default BbotTag;
