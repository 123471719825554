import React, { Component } from "react";

import { Table, Button } from "react-bootstrap";
import "./FulfillmentTable.scss";

class FulfillmentTable extends Component {
  renderTableRows = (columns, data) => {
    // If customer is flagged for dovetail, do not allow stations to be configured
    const editButtonText = this.props.isSelectedCustomerFlaggedForDovetail
      ? "Change Locations"
      : "Change Locations/Stations";
    let rows = [];

    data.forEach((d, rIndex) => {
      rows.push(
        <tr
          className={"tablet-row " + (this.props.selected.includes(d) ? "selected" : "")}
          key={"table-row-" + rIndex}
          onClick={() => this.props.handleSelectRow(d)}
        >
          {columns.map((c, cIndex) => {
            let colVal = d[c.name];
            if (Array.isArray(colVal)) {
              return (
                <td key={"table-td-" + cIndex}>
                  {colVal.map((i, vIndex) => {
                    return i[c.innerName] + (vIndex !== colVal.length - 1 ? ", " : "");
                  })}
                </td>
              );
            } else {
              if (c.name === "name") {
                return (
                  <td key={"table-td-" + cIndex}>
                    <a href={"/fulfillment-notices/" + d.id}>{colVal}</a>
                  </td>
                );
              } else if (c.name === "edit") {
                return (
                  <td key={"table-td-" + cIndex}>
                    <Button
                      variant={"link"}
                      onClick={() => this.props.editNotificationSet(d.id)}
                      disabled={d[columns[0].name] === "Default Guest Notification Set"}
                    >
                      {editButtonText}
                    </Button>
                  </td>
                );
              } else {
                return <td key={"table-td-" + cIndex}>{colVal}</td>;
              }
            }
          })}
        </tr>
      );
    });

    return rows;
  };

  render() {
    return (
      <div>
        <div className={"table-container fulfillment-table"}>
          <div className={"header bg-blue-6"}>
            <span className={"table-title color-white"}>{this.props.title}</span>
            <div className={"actions"}>
              {this.props.selected && this.props.selected.length > 0 && this.props.userInfo?.role === "admin" && (
                <Button
                  size={"sm"}
                  variant={"outline-danger"}
                  onClick={() => this.props.handleDelete(this.props.selected)}
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
          <div className={"table-scroll-container"}>
            <Table hover size="sm" className={"margin-bottom-0"}>
              <thead>
                <tr>
                  {this.props.columns.map((col, hIndex) => (
                    <th key={"fulfillment-notice-col-" + hIndex}>{col.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {this.props.columns && this.props.data && this.renderTableRows(this.props.columns, this.props.data)}
              </tbody>
            </Table>
          </div>
          {this.props.userInfo?.role === "admin" && (
            <button className="add-item" onClick={this.props.addRow}>
              <div className={"icon-circle"}>+</div>
              <span>{this.props.addItemText ? this.props.addItemText : "New Guest Notification Set"}</span>
            </button>
          )}
          {/*<div className="footer">page 1</div>*/}
        </div>
      </div>
    );
  }
}

export default FulfillmentTable;
