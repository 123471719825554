import Banner from "@doordash/component-banner";
import Button from "@doordash/component-button";
import { TextField } from "@doordash/component-fields";
import { useToast } from "@doordash/component-toast";
import { BorderRadius, Colors, Icon, InlineChildren, Inset, StackChildren, Text } from "@doordash/design-language";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import BBOT_SERVER from "Config";
import { useState } from "react";
import styled from "styled-components";

const CardSetupForm = ({
  intentClientSecrent,
  customerId,
  task_id,
  setSubmitting,
  getDataForPaymentTask,
  onSuccessCallback,
  onCancel,
  billpayerExists,
}) => {
  //Mostly copied from Stripe docs
  const stripe = useStripe();
  const elements = useElements();
  const [isSavingCard, setIsSavingCard] = useState(false);
  const { displayToast } = useToast();
  const [error, setError] = useState("");

  const [zipCode, setZipCode] = useState("");

  const [cardNumberError, setCardNumberError] = useState("");
  const [cardExpirationError, setCardExpirationError] = useState("");
  const [cardSecurityError, setCardSecurityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  const handleSubmit = async (event) => {
    // If no billpayer, show toast, return early.
    if (!billpayerExists) {
      displayToast({
        icon: Icon.Types.ErrorLine,
        text: "Please save a valid billing email first.",
        insetFromEdge: Inset.Sizes.Medium,
      });
      return;
    }

    if (!zipCode.length) {
      setZipCodeError("Please enter a valid postal code.");
      return;
    }

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setSubmitting(true);
    setIsSavingCard(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("stripe isn't loaded");
      return;
    }

    const result = await stripe.confirmCardSetup(intentClientSecrent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            postal_code: zipCode,
          },
          name: "DoorDash",
        },
      },
    });

    if (result.error) {
      console.error(result.error);
      // Display result.error.message in your UI.
      const {
        error: { code, message },
      } = result;

      let tempCardNumberError,
        tempCardExpError,
        tempCardSecurityError,
        bannerError = "";

      if (code === "incomplete_number") {
        tempCardNumberError = message;
      } else if (code === "invalid_expiry_year_past") {
        tempCardExpError = "Card is expired";
      } else if (code === "incomplete_expiry") {
        tempCardExpError = "Expiration date is incomplete";
      } else if (code === "incomplete_cvc") {
        tempCardSecurityError = "Security code is incomplete";
      } else {
        bannerError = "Error saving card: " + result.error.message;
      }

      setCardNumberError(tempCardNumberError);
      setCardExpirationError(tempCardExpError);
      setCardSecurityError(tempCardSecurityError);

      setError(bannerError);

      setSubmitting(false);
      setIsSavingCard(false);
      getDataForPaymentTask(true); // reset the intent
      return;
    }

    // No errors, hide field errors
    setCardNumberError("");
    setCardExpirationError("");
    setCardSecurityError("");
    setZipCodeError("");

    // The setup has succeeded. Display a success message and send
    // result.setupIntent.payment_method to your server to save the
    // card to a Customer
    try {
      await axios.post(BBOT_SERVER + "/api/journey/addCreditCard", {
        customer_id: customerId,
        payment_method: result.setupIntent.payment_method,
        task_id: task_id,
      });

      setError("");
      if (onSuccessCallback) {
        await onSuccessCallback(); // call the callback func if exists
      }
    } catch (error) {
      if (error?.response?.data?.errorCode) {
        setError(error.response.data.errorCode);
      } else {
        setError("Unexpected error authorizing card");
      }
    } finally {
      setSubmitting(false);
      setIsSavingCard(false);
    }
  };

  const styleOptions = {
    style: {
      base: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        "::placeholder": {
          color: "#767676",
          fontWeight: "300",
        },
      },
    },
  };

  const onCardNumberChange = (e) => {
    if (e.complete) {
      elements?.getElement(CardExpiryElement)?.focus();
    }
  };

  const onExpiryChange = (e) => {
    if (e.complete) elements?.getElement(CardCvcElement)?.focus();
  };

  return (
    <StripeForm size={StackChildren.Sizes.XLarge}>
      <InlineChildren className="credit-card-form">
        <CardContainer className="card-container" size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.FieldMediumLabel}>Credit Card</Text>
          <CardNumberElement className="cc-number-input" options={styleOptions} onChange={onCardNumberChange} />
          {cardNumberError && (
            <Text styles={Text.Styles.FieldMediumError} color={Colors.TextError}>
              {cardNumberError}
            </Text>
          )}
        </CardContainer>
      </InlineChildren>
      <InlineChildren className="credit-card-form">
        <ExpirationContainer size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.FieldMediumLabel}>Expiration</Text>
          <CardExpiryElement className="expiration-date-input" options={styleOptions} onChange={onExpiryChange} />
          {cardExpirationError && (
            <Text styles={Text.Styles.FieldMediumError} color={Colors.TextError}>
              {cardExpirationError}
            </Text>
          )}
        </ExpirationContainer>
        <CVVContainer size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.FieldMediumLabel}>CVV</Text>
          <CardCvcElement className="cvc-input" options={styleOptions} />
          {cardSecurityError && (
            <Text styles={Text.Styles.FieldMediumError} color={Colors.TextError}>
              {cardSecurityError}
            </Text>
          )}
        </CVVContainer>
        <ZipContainer>
          <Text styles={Text.Styles.FieldMediumLabel}>Postal Code</Text>
          <TextField value={zipCode} placeholder={"00000"} onChange={setZipCode} />
          {zipCodeError && (
            <Text styles={Text.Styles.FieldMediumError} color={Colors.TextError}>
              {zipCodeError}
            </Text>
          )}
        </ZipContainer>
      </InlineChildren>

      {error && !isSavingCard && <Banner label={error} variant={Banner.Variants.Negative} />}

      <InlineChildren>
        <Button isInline onClick={handleSubmit} state={isSavingCard ? Button.States.Loading : Button.States.Default}>
          Save Billing Method
        </Button>
        <Button isInline type={Button.Types.Tertiary} onClick={onCancel}>
          Cancel
        </Button>
      </InlineChildren>
    </StripeForm>
  );
};

export default CardSetupForm;

const StripeForm = styled(StackChildren)`
  .credit-card-form {
    .cc-number-input,
    .expiration-date-input,
    .cvc-input,
    .zipcode-input {
      border: 1px solid ${Colors.BorderSecondary};
      border-radius: ${BorderRadius.Medium}px;
      box-shadow: none;
      height: 2.5rem;
      margin-bottom: 0;
      margin-top: 16px !important;
      padding: 0.75rem;
      max-width: 100%;
    }
  }
`;

const CardContainer = styled(StackChildren)`
  width: 100%;
  min-width: 200px;
`;

const ExpirationContainer = styled(StackChildren)`
  width: 33%;
  min-width: 100px;
`;

const CVVContainer = styled(StackChildren)`
  width: 33%;
  min-width: 100px;
`;

const ZipContainer = styled(StackChildren)`
  width: 33%;
  min-width: 100px;
  input {
    height: 2.5rem;
    border-top: 1px solid ${Colors.BorderSecondary};
    border-bottom: 1px solid ${Colors.BorderSecondary};
    font-size: 14px;
    font-weight: 500;
    :focus {
      border-top: 2px solid #91a5f9;
      border-bottom: 2px solid #91a5f9;
    }
  }
`;
