import React, { useState, useEffect } from "react";
import { BorderRadius, Colors, StackChildren, Text } from "@doordash/design-language";

import { FsFileMetadata } from "components/owner-app/file-upload";
import { Widget } from "./UploadMenuAssetsWidget";
import axios, { AxiosError } from "axios";
import { getWidgetData, updateWidgetData } from "./Widget";
import { generalErrorAlert } from "util/Utils";
import ImageUploaderAndCropperContainer from "components/global/image-uploader/ImageUploaderAndCropperContainer";
import styled from "styled-components";
import { UpdateTaskStatus } from "./OrderingPageWidget";
import UploadBOSAssetsPreviewHero from "./UploadBOSAssetsPreviewHero";

type UploadBOSAssetsWidgetProps = {
  widget: Widget;
  updateTaskStatus: UpdateTaskStatus;
  userInfo: any;
  customerId: string;
};

type UploadedAsset = {
  original: FsFileMetadata;
  cropped: FsFileMetadata;
};

type UploadedAssets = {
  logo?: UploadedAsset;
  mobile_banner?: UploadedAsset;
  desktop_banner?: UploadedAsset;
  mobile_background?: UploadedAsset;
  desktop_background?: UploadedAsset;
};

const UploadBOSAssetsWidget: React.FC<UploadBOSAssetsWidgetProps> = ({
  widget,
  updateTaskStatus,
  userInfo,
  customerId,
}) => {
  const [uploadedAssets, setUploadedAssets] = useState<UploadedAssets>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (widget) {
      setIsLoading(true);
      getWidgetData(widget.id)
        .then((res: any) => {
          if (res.data.uploaded_assets) {
            setUploadedAssets(res.data.uploaded_assets);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [widget]);

  const isAdmin = userInfo?.role === "admin";

  const updateUploadedAssetData = async (assetType: string, cropped: FsFileMetadata, original?: FsFileMetadata) => {
    const updatedAssets = {
      ...uploadedAssets,
      [assetType]: {
        cropped,
        original: original ?? cropped,
      },
    };

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          uploaded_assets: updatedAssets,
        },
      });
      setUploadedAssets(updatedAssets);
      const allAssetsUploaded =
        !!updatedAssets.logo?.cropped &&
        !!updatedAssets.mobile_background?.cropped &&
        !!updatedAssets.desktop_background?.cropped &&
        !!updatedAssets.mobile_banner?.cropped &&
        !!updatedAssets.desktop_banner?.cropped;

      updateTaskStatus(allAssetsUploaded ? "completed" : "in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not save image. Please let us know if this keeps happening.",
        true
      );
    }
  };

  const removeAssetData = async (assetType: string) => {
    const updatedAssets = {
      ...uploadedAssets,
      [assetType]: null,
    };

    try {
      await updateWidgetData({
        widgetId: widget.id,
        widgetData: {
          uploaded_assets: updatedAssets,
        },
      });
      setUploadedAssets(updatedAssets);
      // Removing asset should un-complete the task
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error: unknown) {
      generalErrorAlert(
        error as Error | AxiosError,
        "Could not remove image. Please let us know if this keeps happening.",
        true
      );
    }
  };

  const saveAsset = async (
    assetName: keyof UploadedAssets,
    cropped: FsFileMetadata,
    original?: FsFileMetadata,
    url?: string
  ) => {
    try {
      if (url) {
        await axios.post(url, [{ filesUploaded: [cropped] }]);
      }
      updateUploadedAssetData(assetName, cropped, original);
    } catch (error) {
      generalErrorAlert(error, "Could not save image. Please let us know if this keeps happening.");
    }
  };

  /**
   *
   * @param assetName Name of the asset in this local component
   * @param assetType Name of the asset as stored in the backend
   */
  const removeAsset = async (assetName: keyof UploadedAssets, assetType: string) => {
    try {
      removeAssetData(assetName);
      await axios.post("/api/deleteCustomerAsset", {
        customerId,
        assetType: assetType,
      });
      // If removing asset, we should boot task to incomplete
      updateTaskStatus("in_progress", { widgetId: widget.id, goToNext: false });
    } catch (error) {
      generalErrorAlert(error, "Sorry, could not remove this asset. Please let us know if this keeps happening.", true);
    }
  };

  const onLogoSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("logo", cropped, original, "/api/storeLogo");

  const onDesktopBannerSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("desktop_banner", cropped, original, "/api/storeDesktopBanner");

  const onMobileBannerSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("mobile_banner", cropped, original, "/api/storeMobileBanner");

  const onDesktopBackgroundSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("desktop_background", cropped, original);

  const onMobileBackgroundSaved = async (cropped: FsFileMetadata, original?: FsFileMetadata) =>
    saveAsset("mobile_background", cropped, original);

  return (
    <StackChildren size={StackChildren.Sizes.XLarge}>
      <UploadBOSAssetsPreviewHero />

      <WarningContainer>
        <Text styles={Text.Styles.Body1}>
          Logo and Menu Banner images will automatically populate on ordering sites and corresponding Owner Portal input
          fields. Landing Page Background and Logo will not automatically populate on the landing page; use the "Upload
          Assets" button in the BOS Builder tool to import these images when configuring the BOS.
        </Text>
      </WarningContainer>

      <ImageUploaderAndCropperContainer
        showPreviewOnSide
        title="Logo"
        description="Your logo will display on your landing page as well as all of your menu pages."
        acceptedTypes={[".png", ".svg"]}
        acceptedTypesDescription="PNG or SVG format, minimum height of 200px (aspect ratio 1:1 - 2:1)"
        savedOriginalFile={uploadedAssets.logo?.original}
        isAdmin={isAdmin}
        isLoading={isLoading}
        onDelete={() => removeAsset("logo", "storeLogo")}
        directSave
        uploadAssetOptions={{
          imageTitle: "Logo",
          aspectRatio: 1,
          minHeight: 200,
          minWidth: 200,
          onFileSaved: onLogoSaved,
          savedFile: uploadedAssets.logo?.cropped,
        }}
      />

      <hr />

      <StackChildren size={StackChildren.Sizes.XLarge}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Title1}>Landing Page Background</Text>
          <Text styles={Text.Styles.Body2}>This image will display on your landing page only.</Text>
        </StackChildren>

        <ImageUploaderAndCropperContainer
          title="Mobile Background"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 9:16 aspect ratio"
          savedOriginalFile={uploadedAssets.mobile_background?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAssetData("mobile_background")}
          uploadAssetOptions={{
            imageTitle: "Mobile Background",
            aspectRatio: 9 / 16,
            minHeight: 250,
            minWidth: 250,
            maxWidth: 1000,
            onFileSaved: onMobileBackgroundSaved,
            savedFile: uploadedAssets.mobile_background?.cropped,
          }}
        />

        <ImageUploaderAndCropperContainer
          title="Desktop Background"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 16:9 aspect ratio"
          savedOriginalFile={uploadedAssets.desktop_background?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAssetData("desktop_background")}
          uploadAssetOptions={{
            imageTitle: "Desktop Background",
            aspectRatio: 16 / 9,
            minHeight: 250,
            minWidth: 250,
            maxWidth: 2500,
            onFileSaved: onDesktopBackgroundSaved,
            savedFile: uploadedAssets.desktop_background?.cropped,
          }}
        />
      </StackChildren>

      <hr />
      <StackChildren size={StackChildren.Sizes.XLarge}>
        <StackChildren size={StackChildren.Sizes.XxSmall}>
          <Text styles={Text.Styles.Title1}>Menu Banner</Text>
          <Text styles={Text.Styles.Body2}>This image will display on your menu page only.</Text>
        </StackChildren>

        <ImageUploaderAndCropperContainer
          title="Mobile Banner"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 3:2 aspect ratio"
          savedOriginalFile={uploadedAssets.mobile_banner?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAsset("mobile_banner", "mobileBanner")}
          uploadAssetOptions={{
            imageTitle: "Menu Banner",
            aspectRatio: 1.5,
            minHeight: 250,
            minWidth: 250,
            maxWidth: 1000,
            onFileSaved: onMobileBannerSaved,
            savedFile: uploadedAssets.mobile_banner?.cropped,
          }}
        />

        <ImageUploaderAndCropperContainer
          title="Desktop Banner"
          acceptedTypes={[".jpg", ".jpeg"]}
          acceptedTypesDescription="JPG or JPEG format, 4:1 aspect ratio"
          savedOriginalFile={uploadedAssets.desktop_banner?.original}
          isAdmin={isAdmin}
          isLoading={isLoading}
          onDelete={() => removeAsset("desktop_banner", "desktopBanner")}
          uploadAssetOptions={{
            imageTitle: "Menu Banner",
            aspectRatio: 4,
            minHeight: 250,
            minWidth: 250,
            maxWidth: 1500,
            onFileSaved: onDesktopBannerSaved,
            savedFile: uploadedAssets.desktop_banner?.cropped,
          }}
        />
      </StackChildren>
    </StackChildren>
  );
};

export default UploadBOSAssetsWidget;

const WarningContainer = styled.div`
  border-radius: ${BorderRadius.Medium}px;
  border: 1px solid ${Colors.TextError};
  padding: 20px;
`;
