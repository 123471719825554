import * as React from "react";
import { Modal } from "@doordash/component-modal";
import { ModalProps } from "../constants";
import { TextField } from "@doordash/component-fields";

export const AddOrEditMenuModal: React.FC<ModalProps> = ({
  primaryActionProps,
  secondaryActionProps,
  setOpenModal,
  fields,
  updateForm,
  onClose,
}) => (
  <Modal
    title={`${fields.menu_name ? "Edit" : "New"} Menu`}
    primaryAction={primaryActionProps}
    secondaryAction={secondaryActionProps}
    onClose={onClose}
    isFullScreenOnMobile
  >
    <TextField
      label={"Menu Name"}
      autoComplete={"off"}
      onChange={(value: string) => updateForm({ menu_name: value })}
      value={fields.menu_name || ""}
    />
  </Modal>
);
