import Toggle from "@doordash/component-toggle";

export const FieldToggle = ({ editDeviceProfile, id, field, value, isSelected, customer_id, setDeviceProfiles }) => (
  <Toggle
    onClick={() =>
      editDeviceProfile({
        id,
        field,
        customer_id,
        setDeviceProfiles,
        value: !value,
      })
    }
    isSelected={isSelected}
  />
);
