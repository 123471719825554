import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Table from "@doordash/component-table";
import { Inset, Text } from "@doordash/design-language";
import { getDeviceProfiles, editDeviceProfile } from "./utils";
import { Fields } from "./consts";
import { FieldToggle } from "./FieldToggle";

const { Bold32, Medium12 } = Text;

const DeviceProfiles = (props) => {
  const [deviceProfiles, setDeviceProfiles] = useState([]);

  useEffect(() => getDeviceProfiles(setDeviceProfiles), [setDeviceProfiles]);

  const {
    ConfigName,
    Description,
    ShowCreateOrder,
    ShowKDSView,
    ShowLocationsOverview,
    ShowMenuEditor,
    ShowOrderControl,
    ShowStationsOverview,
    ShowTipReport,
    UseStripeChipReader,
  } = Fields;

  const sharedProps = (row) => ({
    editDeviceProfile,
    setDeviceProfiles,
    id: row?.original?.id,
    customer_id: props?.selectedCustomer?.customer_id,
  });

  return deviceProfiles?.length > 0 ? (
    <Inset size={Inset.Sizes.XLarge}>
      <Text styles={Bold32}>Available Devices</Text>
      <Table
        columns={[
          {
            name: "Name",
            accessor: ConfigName,
            Cell: ({ row }) => <Text styles={Medium12}>{row.values[ConfigName]}</Text>,
          },
          {
            name: "Description",
            accessor: Description,
            Cell: ({ row }) => <Text styles={Medium12}>{row.values[Description]}</Text>,
          },
          {
            name: "Locations Overview",
            accessor: ShowLocationsOverview,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowLocationsOverview}
                value={row?.values?.[ShowLocationsOverview]}
                isSelected={row?.values?.[ShowLocationsOverview]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Create Order",
            accessor: ShowCreateOrder,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowCreateOrder}
                value={row?.values?.[ShowCreateOrder]}
                isSelected={row?.values?.[ShowCreateOrder]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Has Chipper",
            accessor: UseStripeChipReader,
            Cell: ({ row }) => (
              <FieldToggle
                field={UseStripeChipReader}
                value={row?.values?.[UseStripeChipReader]}
                isSelected={row?.values?.[UseStripeChipReader]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Stations Overview",
            accessor: ShowStationsOverview,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowStationsOverview}
                value={row?.values?.[ShowStationsOverview]}
                isSelected={row?.values?.[ShowStationsOverview]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "KDS View",
            accessor: ShowKDSView,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowKDSView}
                value={row?.values?.[ShowKDSView]}
                isSelected={row?.values?.[ShowKDSView]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Menu Management",
            accessor: ShowMenuEditor,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowMenuEditor}
                value={row?.values?.[ShowMenuEditor]}
                isSelected={row?.values?.[ShowMenuEditor]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Order Control",
            accessor: ShowOrderControl,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowOrderControl}
                value={row?.values?.[ShowOrderControl]}
                isSelected={row?.values?.[ShowOrderControl]}
                {...sharedProps(row)}
              />
            ),
          },
          {
            name: "Tip Report",
            accessor: ShowTipReport,
            Cell: ({ row }) => (
              <FieldToggle
                field={ShowTipReport}
                value={row?.values?.[ShowTipReport]}
                isSelected={row?.values?.[ShowTipReport]}
                {...sharedProps(row)}
              />
            ),
          },
        ]}
        data={deviceProfiles}
      ></Table>
    </Inset>
  ) : null;
};

export default withRouter(DeviceProfiles);
