import { Button } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BbotAlert, BbotButton, BbotTag } from "top-component-library";

const BulkEditHeader = (props) => {
  const {
    header,
    defaultTabBarProps,
    DefaultTabBar,
    editObjectNames,
    onLinkButtonClick,
    showSave,
    onSave,
    errorMessage,
  } = props;

  return (
    <Header className={"sticky"}>
      <div className={"d-flex justify-content-space-between margin-bottom-2"}>
        <span className={"d-flex align-items-center"}>
          <span className={"margin-right-2"}>
            <h4 className={"header-title"}>{header}</h4>
          </span>
          <span>
            {editObjectNames.slice(0, 5).map((tagLabel, index) => (
              <BbotTag key={index}>{tagLabel}</BbotTag>
            ))}

            {editObjectNames.length > 5 && <BbotTag>{`+ ${editObjectNames.length - 5} more`}</BbotTag>}
          </span>
          <Button type={"link"} onClick={onLinkButtonClick}>
            Click to go back
          </Button>
        </span>
        {showSave && onSave && (
          <SaveButton type={"primary"} onClick={onSave}>
            Save
          </SaveButton>
        )}
      </div>

      {errorMessage && (
        <div className={"margin-bottom-1"}>
          <BbotAlert type={"error"} message={errorMessage} />
        </div>
      )}

      <div>
        <DefaultTabBar {...defaultTabBarProps} />
      </div>
    </Header>
  );
};

const Header = styled.div`
  border-radius: 8px;
  border: 1px solid var(--color-neutral__line);
  padding: 16px 30px;
  position: sticky;
  top: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  background-color: white;
  margin-bottom: 36px;

  .header-title {
    display: inline;
  }
`;

const SaveButton = styled(BbotButton)`
  float: right;
`;

BulkEditHeader.propTypes = {
  header: PropTypes.string,
  defaultTabBarProps: PropTypes.object,
  DefaultTabBar: PropTypes.object,
  alertMessage: PropTypes.string,
  showSave: PropTypes.bool,
  onSave: PropTypes.func,
};

export default BulkEditHeader;
