import { track } from "instrumentation/tracking/track";

export const trackClickAddNewLocation = (fields) => track("track_add_new_location", fields);

export const trackClickOrderingEnabled = (fields) => track("track_add_new_location", fields);

export const trackClickEditLocation = (fields) => track("track_click_edit_location", fields);

export const trackClickDeleteLocation = (fields) => track("track_click_delete_location", fields);

export const trackClickGoBackLocationEditor = (fields) => track("track_click_location_editor_go_back", fields);

export const trackClickSaveLocationSettings = (fields) => track("track_click_save_location_settings", fields);

export const trackClickConfirmSaveLocationSettings = (fields) =>
  track("track_click_confirm_save_location_settings", fields);

export const trackClickBulkEditableSettingToggle = (fields) =>
  track("track_click_bulk_editable_setting_toggle", fields);

export const trackClickBulkEditSetting = (fields) => track("track_click_bulk_edit_setting", fields);
