import { Col, Form, Row } from "antd";
import PropTypes from "prop-types";
import { Checkbox } from "top-component-library";
/*
Generic input for a group of checkboxes
 */
const CheckboxGroupInput = ({ id, name, onChange, options, numColumns, ...props }) => {
  return (
    <Form.Item id={id} {...props} name={name}>
      <Checkbox.Group onChange={onChange}>
        <Row>
          {options.map((option) => {
            return (
              <Col span={24 / numColumns} key={option.value}>
                <Checkbox value={option.value}>{option.label}</Checkbox>
              </Col>
            );
          })}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
};

export default CheckboxGroupInput;

CheckboxGroupInput.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // List of objects, each object should have a 'label' and 'value'
  name: PropTypes.string,
  onChange: PropTypes.func,
  numColumns: PropTypes.number, // The number of columns of checkboxes
};
