import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";

import { Field, Form, Formik } from "formik";

import { BbotAlert } from "top-component-library";
import { getDoveTailToolTipBodyText } from "../../../../pages/owner-app-dashboard/dovetail";

import "./CreateFulfillmentNoticesModal.scss";

class CreateNewFulfillmentNoticesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedOptions: false,
    };
  }

  validateText = (value) => {
    var error;
    if (value.length === 0 || (value.length > 0 && value.trim().length === 0)) {
      error = "Input a valid name.";
    }
    return error;
  };

  render() {
    const { showAdvancedOptions } = this.state;
    const { show, onHide, stations, locations, isSelectedCustomerFlaggedForDovetail } = this.props;
    const doveTailToolTopBodyText = getDoveTailToolTipBodyText(
      "Guest Notification Sets",
      isSelectedCustomerFlaggedForDovetail
    );
    return (
      <Modal size="lg" show={show} onHide={onHide} dialogClassName={"create-new-fulfillment-notice-modal"}>
        <Formik
          initialValues={{
            name: "",
            configuredFor: null,
            selectedStations: stations.map((station) => station.id),
            selectedLocations: locations.map((location) => location.locationId),
          }}
          onSubmit={async (values) => {
            this.props.saveCallback(values);
          }}
        >
          {({ values, errors, touched, isValidating }) => (
            <Form className={"styled-form"}>
              <Modal.Header className={"padding-top-3"} closeButton>
                <Col sm={12}>
                  <h3 className="color-grey-2">Create New Notification Configuration</h3>
                  {isSelectedCustomerFlaggedForDovetail && (
                    <BbotAlert className="margin-top-1" message={doveTailToolTopBodyText} type="warning" showIcon />
                  )}
                </Col>
              </Modal.Header>
              <Modal.Body className={"padding-y-3"}>
                <Col sm={12}>
                  <Row>
                    <Col sm={12} className="margin-bottom-3">
                      <div className="field-wrapper">
                        <p>Configuration Name*</p>
                        <Field id="name" name="name" type="text" validate={this.validateText} />
                        {errors.name && <div className="error">{errors.name}</div>}
                      </div>
                    </Col>
                    <Col sm={12}>
                      <p>Will this configuration apply to stations or locations?</p>
                      <div role="group" aria-labelledby="my-radio-group">
                        <Row>
                          <Col sm={6} className="margin-bottom-3">
                            <div className="field-wrapper">
                              <label
                                input-type="radio"
                                className={`station-location-card padding-2 ${
                                  values.configuredFor === "All Locations" ? "selected" : ""
                                }`}
                              >
                                <Field type="radio" name="configuredFor" value="All Locations" />
                                All
                                <h4>Locations</h4>
                              </label>
                            </div>
                          </Col>
                          {/* If customer is flagged for dovetail, do not allow stations to be configured (also change how button looks here) */}
                          <Col
                            sm={6}
                            className="margin-bottom-3"
                            style={isSelectedCustomerFlaggedForDovetail ? { opacity: 0.4 } : {}}
                          >
                            <div className="field-wrapper">
                              <label
                                input-type="radio"
                                className={`station-location-card padding-2 ${
                                  values.configuredFor === "All Stations" ? "selected" : ""
                                }`}
                              >
                                <Field
                                  type="radio"
                                  name="configuredFor"
                                  value="All Stations"
                                  disabled={isSelectedCustomerFlaggedForDovetail}
                                />
                                All
                                <h4>Stations</h4>
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col sm={12}>
                      {showAdvancedOptions && (
                        <>
                          <p
                            className="color-grey-5"
                            onClick={() =>
                              this.setState({
                                showAdvancedOptions: !this.state.showAdvancedOptions,
                              })
                            }
                          >
                            Advanced Options
                          </p>

                          <div className="margin-bottom-2">
                            {values.configuredFor === "All Stations" && (
                              <div className="field-wrapper" role="group" aria-labelledby="checkbox-group">
                                {stations.map((station, index) => (
                                  <label className="d-flex align-items-center" key={"station-" + index}>
                                    <Field type="checkbox" name="selectedStations" value={station.id} />
                                    {station.station_name}
                                  </label>
                                ))}
                              </div>
                            )}
                            {values.configuredFor === "All Locations" && (
                              <div className="field-wrapper" role="group" aria-labelledby="checkbox-group">
                                {locations.map((location, index) => (
                                  <label className="d-flex align-items-center" key={"location-" + index}>
                                    <Field type="checkbox" name="selectedLocations" value={location.locationId} />
                                    {location.locationName}
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                          <p className="supporting-text color-grey-5 weight-400">
                            *Warning: By selecting a subset of locations/stations the app can behave in complex ways.
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Modal.Body>
              <Modal.Footer>
                <div className="modal-footer-content">
                  <div>
                    {values.configuredFor && (
                      <Button
                        className="color-grey-5 padding-x-0"
                        variant="link"
                        size="sm"
                        onClick={() =>
                          this.setState({
                            showAdvancedOptions: !this.state.showAdvancedOptions,
                          })
                        }
                      >
                        {this.state.showAdvancedOptions ? "Hide Options" : "Advanced Options"}
                      </Button>
                    )}
                  </div>
                  <div className="actions">
                    <Button variant="outline-primary" onClick={onHide}>
                      Cancel
                    </Button>
                    <Button
                      className="margin-left-2"
                      variant="primary"
                      type="submit"
                      disabled={errors === {} || values.name.length === 0 || !values.configuredFor}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

export default CreateNewFulfillmentNoticesModal;
