import Journey from "components/customer-journey/Journey";
import Page from "components/global/page-layout/Page-Layout";
import IntegrationsActivityFeed from "pages/activity-feed/IntegrationsActivityFeed";
import PrinterActivityFeed from "pages/activity-feed/PrinterActivityFeed";
import ApproveZapier from "pages/app-store/ApproveZapier";
import AppStore from "pages/app-store/AppStore";
import SquareOAuthModal from "pages/app-store/SquareOAuthModal";
import CardProcessingRates from "pages/card-processing-rates/CardProcessingRates";
import ConnectPayment from "pages/connect-payment/ConnectPayment";
import CutAgreements from "pages/cut-agreements/CutAgreements";
import DevTools from "pages/devTools-page/DevTools";
import FeatureManagement from "pages/feature-management/FeatureManagment";
import FulfillmentNotice from "pages/fulfillment-notices/FulfillmentNotice";
import FulfillmentNoticeList from "pages/fulfillment-notices/FulfillmentNoticeList";
import HandheldDevices from "pages/handheld-devices";
import KioskEditor from "pages/kiosk-editor/KioskEditor";
import LocationEditor from "pages/locations/LocationEditor";
import ManageEmployees from "pages/manage-employees/ManageEmployees";
import ManagePartners from "pages/manage-partners/ManagePartners";
import OrderGroupingRules from "pages/manage-printer-rules/OrderGroupingRules";
import MenusPage from "pages/menus/MenusPage";
import Upsells from "pages/menus/Upsells";
import OwnerAnnouncements from "pages/owner-announcements/OwnerAnnouncements";
import OwnerAppDashboard from "pages/owner-app-dashboard/OwnerAppDashboard";
import PcbUpdater from "pages/pcb-updater/PcbUpdater";
import PrinterConfiguration from "pages/printer-configuration/PrinterConfiguration";
import Promotions from "pages/promotions/Promotions";
import RestaurantDetails from "pages/restaurant-details/RestaurantDetails";
import SalesCommission from "pages/sales-commission/SalesCommission";
import SearchPastOrdersPage from "pages/search-past-orders/SearchPastOrdersPage";
import ServiceCharges from "pages/service-charges/ServiceCharges";
import ServiceRequests from "pages/service-requests/ServiceRequests";
import Stations from "pages/stations/Stations";
import SupportAlerts from "pages/support-alerts/SupportAlerts";
import SystemLogs from "pages/user-action-log/SystemLogs";
import UserActionLog from "pages/user-action-log/UserActionLog";
import { Redirect, Route, Switch } from "react-router-dom";
import JourneyChooser from "../components/customer-journey/JourneyChooser";

import { HISTORY_SOURCE_BBOT_OWNER_PORTAL } from "Constants";
import DeviceProfiles from "./device-profiles";
import TippingConfiguration from "./tip-settings/TippingConfiguration";

export const ProtectedRoute = ({
  component: Component,
  path,
  userInfo,
  adminOnly,
  bbotAdminOnly,
  bbotSuperAdminOnly,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userInfo) {
          return <></>;
        } else if (userInfo.is_anonymous) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from_bbot_page: HISTORY_SOURCE_BBOT_OWNER_PORTAL,
                  from: props.location,
                },
              }}
            />
          );
        } else if (
          // permissions check: if doesn't pass conditionals, redirect to dashboard.
          (bbotSuperAdminOnly &&
            !(userInfo?.permissions?.includes("manage_admins") && userInfo?.teams?.includes("bbot"))) ||
          (adminOnly && userInfo.role !== "admin") ||
          (bbotAdminOnly && !userInfo?.teams?.includes("bbot"))
        ) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return <Component {...rest} {...props} userInfo={userInfo} />;
        }
      }}
    />
  );
};

export const LoggedInRoutes = (props) => {
  // If customer is flagged for dovetail, do not allow these pages to be accessible via routes
  const { isSelectedCustomerFlaggedForDovetail } = props;

  return (
    <Page {...props}>
      <Switch>
        {/*SUPER ADMIN ONLY ROUTES*/}
        {!isSelectedCustomerFlaggedForDovetail && (
          <ProtectedRoute exact bbotAdminOnly path={`/cut-agreements`} component={CutAgreements} {...props} />
        )}

        <ProtectedRoute exact bbotSuperAdminOnly path={`/sales-commission`} component={SalesCommission} {...props} />
        <ProtectedRoute
          exact
          bbotAdminOnly
          path={`/card-processing-rates`}
          component={CardProcessingRates}
          {...props}
        />

        {/*ADMIN ONLY ROUTES*/}
        <ProtectedRoute exact path={`/admin/dev-tools`} component={DevTools} adminOnly={true} {...props} />

        <ProtectedRoute exact path={`/feature-management`} component={FeatureManagement} adminOnly={true} {...props} />

        <ProtectedRoute exact adminOnly path={`/pcb-updater`} component={PcbUpdater} {...props} />

        <ProtectedRoute exact adminOnly path={`/owner-announcements`} component={OwnerAnnouncements} {...props} />
        {!isSelectedCustomerFlaggedForDovetail && (
          <ProtectedRoute exact adminOnly={true} path="/stations" component={Stations} {...props} />
        )}
        <ProtectedRoute exact adminOnly={true} path="/devices" component={HandheldDevices} {...props} />
        <ProtectedRoute exact adminOnly={true} path="/profiles" component={DeviceProfiles} {...props} />

        {/* PROTECTED ROUTES */}
        <ProtectedRoute exact path={`/myjourneys`} component={JourneyChooser} {...props} />
        <ProtectedRoute exact path={`/employees`} component={ManageEmployees} {...props} />
        <ProtectedRoute exact path={`/payouts-and-billing`} component={ConnectPayment} {...props} />
        <ProtectedRoute exact path={`/upsells`} component={Upsells} {...props} />
        <ProtectedRoute exact path={`/manage-partners`} component={ManagePartners} {...props} />
        <ProtectedRoute exact path={`/approve-zapier`} component={ApproveZapier} {...props} />
        <ProtectedRoute exact path={`/square-oauth`} component={SquareOAuthModal} {...props} />
        <ProtectedRoute exact path="/integrations-activity-feed" component={IntegrationsActivityFeed} {...props} />
        {!isSelectedCustomerFlaggedForDovetail && (
          <ProtectedRoute exact path="/printer-configuration" component={PrinterConfiguration} {...props} />
        )}
        <ProtectedRoute exact path="/printer-activity-feed" component={PrinterActivityFeed} {...props} />
        <ProtectedRoute exact path="/user-action-log" component={UserActionLog} {...props} />
        <ProtectedRoute exact path="/system-logs" component={SystemLogs} {...props} />
        <ProtectedRoute exact path="/journey/:journeyId" component={Journey} {...props} />
        <ProtectedRoute exact path="/fulfillment-notices" component={FulfillmentNoticeList} {...props} />
        <ProtectedRoute
          exact
          path="/fulfillment-notices/:fulfillmentNoticeId"
          component={FulfillmentNotice}
          {...props}
        />
        {!isSelectedCustomerFlaggedForDovetail && (
          <ProtectedRoute exact path="/support-alerts" component={SupportAlerts} {...props} />
        )}
        <ProtectedRoute exact path="/manage-print-rules" component={OrderGroupingRules} {...props} />
        <ProtectedRoute exact path="/service-requests" component={ServiceRequests} {...props} />
        <ProtectedRoute exact path="/bbot-app-store" component={AppStore} {...props} />
        <ProtectedRoute exact path="/edit-locations" component={LocationEditor} {...props} />
        <ProtectedRoute exact path="/promotions" component={Promotions} {...props} />
        <ProtectedRoute exact path="/restaurant-details" component={RestaurantDetails} {...props} />
        <ProtectedRoute exact path="/service-charges" component={ServiceCharges} {...props} />
        <ProtectedRoute exact path="/edit-kiosk-config" component={KioskEditor} {...props} />
        <ProtectedRoute exact path="/orders" component={SearchPastOrdersPage} {...props} />
        <ProtectedRoute exact path="/menus" component={MenusPage} {...props} />
        <ProtectedRoute exact path="/tip-settings" component={TippingConfiguration} {...props} />
        <ProtectedRoute exact path="/" component={OwnerAppDashboard} {...props} />
        <ProtectedRoute exact path="/:selectedCustomerId" component={OwnerAppDashboard} {...props} />
      </Switch>
    </Page>
  );
};
