import axios from "axios";
import { useEffect, useState } from "react";

// Components
import { notification, Row, Tag } from "antd";
import AddNewPCBSoftwareModal from "components/owner-app/modals/pcb-updater/AddNewPCBSoftwareModal";
import PcbVersionSummaryTable from "components/owner-app/tables/pcb-updater-tables/PcbVersionSummaryTable";
import PrinterControlBoxTable from "components/owner-app/tables/pcb-updater-tables/PrinterControlBoxTable";
import styled from "styled-components";
import { BbotButton, BbotContainer, BbotTable, Breadcrumbs } from "top-component-library";

const PcbUpdater = () => {
  const [showUploader, setShowUploader] = useState(false);
  const [releases, setReleases] = useState([]);

  const [pcbs, setPcbs] = useState([]);

  const [awaitingProvisioningStatus, setAwaitingProvisioningStatus] = useState(true);
  const [currentProvisioningStatus, setCurrentProvisioningStatus] = useState(false);

  const getSoftwareReleases = async () => {
    try {
      const res = await axios.get("/printer-setup/controlbox-software-releases");
      const releases = res.data.control_boxes;
      setReleases(releases);
    } catch (error) {
      console.error(error);
    }
  };

  const getControlboxAndVersion = async () => {
    try {
      const res = await axios.get("printer-setup/controlbox-versions");

      const pcbs = res.data.printer_control_boxes;
      setPcbs(pcbs);
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrentPcbProvisioningStatus = async () => {
    try {
      const res = await axios.get("printer-setup/provisioning-status");
      setCurrentProvisioningStatus(res.data.provisioning_status);
      setAwaitingProvisioningStatus(false);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleProvisioningStatus = async (enableProvisioning) => {
    try {
      setAwaitingProvisioningStatus(true);
      let payload = { enable_provisioning: enableProvisioning };
      const res = await axios.post("printer-setup/provisioning-status", payload);
      if (res.data.success) {
        notification.success({ message: res.data.message });
        setCurrentProvisioningStatus(res.data.provisioning_status);
      }
    } catch (error) {
      console.error(error);
      notification.error({ message: "Error setting PCB Provisioning" });
    } finally {
      setAwaitingProvisioningStatus(false);
    }
  };

  useEffect(() => {
    getSoftwareReleases();
    getControlboxAndVersion();
    getCurrentPcbProvisioningStatus();
  }, []);

  return (
    <BbotContainer>
      <Breadcrumbs name={"PCB Updater"} link={"/pcb-updater"} />
      <div className={"margin-bottom-2"}>
        <h2>Manage Software Updates for Printer Control Boxes</h2>
      </div>
      <div className={"margin-bottom-4"}>
        <p>
          Here, you can manage the Software Releases for Printer Control Boxes. You can add a new software release, or
          update PCB's to an existing release by selecting them in the table and clicking the appropriate release in the{" "}
          <b>Update Selected PCB</b> dropdown.
        </p>
      </div>
      <div className={"margin-bottom-3"}>
        <ProvisioningRow>
          <ProvisioningTitle>PCB Provisioning Status:</ProvisioningTitle>
          <ProvisioningTag color={currentProvisioningStatus ? "success" : "error"}>
            {currentProvisioningStatus === true ? <>Enabled</> : <>Disabled</>}
          </ProvisioningTag>
        </ProvisioningRow>
        {currentProvisioningStatus !== true ? (
          <BbotButton
            id={"enable-pcb-provisioning-button"}
            type={"primary"}
            onClick={() => toggleProvisioningStatus(true)}
            loading={awaitingProvisioningStatus}
          >
            Enable Provisioning
          </BbotButton>
        ) : (
          <BbotButton
            id={"disable-pcb-provisioning-button"}
            type={"primary"}
            danger
            onClick={() => toggleProvisioningStatus(false)}
            loading={awaitingProvisioningStatus}
          >
            Disable Provisioning
          </BbotButton>
        )}
      </div>

      <TableRow>
        <PcbVersionSummaryTable printerControlBoxes={pcbs} />
      </TableRow>

      <TableRow>
        <PrinterControlBoxTable
          printerControlBoxes={pcbs}
          onUpdateCallback={getControlboxAndVersion}
          softwareVersions={releases}
        />
      </TableRow>

      <TableRow>
        <BbotTable
          id={"pcb-software-release-table"}
          title={"Software Releases"}
          columns={["Version", "URL", "Description"]}
          onAdd={() => setShowUploader(true)}
          addButtonText="Add a New Software Release"
          data={releases
            ?.map((release) => {
              return { ...release, key: `version-${release.version}` };
            })
            .sort((a, b) => b.version - a.version)}
          interactive={false}
          bordered
        />
      </TableRow>

      <AddNewPCBSoftwareModal
        show={showUploader}
        releases={releases}
        handleClose={() => setShowUploader(false)}
        onSubmitCallback={getSoftwareReleases}
      />
    </BbotContainer>
  );
};

const TableRow = styled.div`
  margin-bottom: 30px;
`;

const ProvisioningRow = styled(Row)`
  align-items: center;
`;

const ProvisioningTitle = styled.h4`
  font-size: 18px;
`;

const ProvisioningTag = styled(Tag)`
  margin-left: 10px;
  justify-text: center;
`;

export default PcbUpdater;
