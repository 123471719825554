import axios from "axios";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BbotSelect, BbotTable } from "../../../../top-component-library";

const UpdatePCBModal = ({ show, onClose, onUpdateCallback, softwareVersions, selectedPcbs }) => {
  const [version, setVersion] = useState(null);
  const [updating, setUpdating] = useState(false);

  const handleClose = () => {
    setVersion(null);
    onClose();
  };

  const handleUpdate = async () => {
    setUpdating(true);
    try {
      const payload = {
        version_number: version,
        pcb_ids: selectedPcbs.map((pcb) => pcb.id),
      };
      await axios.put("printer-setup/controlbox-versions", payload);
      toast.success("Successfully updated pcbs to version " + version);
      onUpdateCallback();
      onClose();
    } catch (error) {
      console.error(error);
    }
    setVersion(null);
    setUpdating(false);
  };

  const updateSelector = (
    <StyledSelect
      placeholder={"Target Version"}
      options={softwareVersions
        .sort((a, b) => b.version - a.version)
        .map((release) => {
          return { label: release.version, value: release.version };
        })}
      onChange={(value) => setVersion(value)}
    />
  );

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Selected PCBs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BbotTable
          id={"pcb-update-table"}
          title={"Control Boxes to Update"}
          titleRight={updateSelector}
          columns={["Name", "ID", "Current Version"]}
          data={selectedPcbs.map((box) => {
            return { name: box.name_for_admin, id: box.id, current_version: box.current_version };
          })}
          interactive={false}
          showCountByTitle={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate} disabled={updating || !version}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePCBModal;

const StyledSelect = styled(BbotSelect)`
  width: 120px;
`;
