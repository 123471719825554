export const MENU_LINKS = [
  {
    testId: "menus-navigation-link",
    name: "Menus",
    link: "/#!/home",
    iconClass: "zmdi zmdi-home dashboard-link-icon",
    googleIcon: "menu_book",
  },
  {
    name: "Available Hours + Menu Settings",
    link: "/#!/edit/schedule",
    iconClass: "zmdi zmdi-calendar-alt dashboard-link-icon",
    googleIcon: "today",
  },
  {
    name: "Presentation to Guests",
    link: "/#!/presentation",
    iconClass: "zmdi zmdi-collection-image dashboard-link-icon",
    googleIcon: "image",
  },

  {
    name: "Text Message Management",
    link: "/#!/messaging",
    iconClass: "zmdi zmdi-comment-outline dashboard-link-icon",
    googleIcon: "textsms",
  },
  {
    name: "Upsells",
    link: "/upsells",
    reactLink: true,
    iconClass: "zmdi zmdi-email-open dashboard-link-icon",
    googleIcon: "add_shopping_cart",
  },
];

export const SALES_LINKS = [
  {
    name: "Payouts and Billing",
    link: "/payouts-and-billing",
    reactLink: true,
    iconClass: "zmdi zmdi-money-off dashboard-link-icon",
    googleIcon: "payments",
  },
  {
    name: "Past Orders",
    link: "/orders",
    reactLink: true,
    iconClass: "zmdi zmdi-email-open dashboard-link-icon",
    googleIcon: "manage_search",
  },
  {
    name: "Reports",
    link: "/#!/account",
    iconClass: "zmdi zmdi-money dashboard-link-icon",
    id: "reports-link",
    googleIcon: "summarize",
  },
];

export const OWNER_LINKS = [
  {
    name: "Account Settings",
    link: "/#!/settings",
    iconClass: "zmdi zmdi-settings dashboard-link-icon",
    id: "settings-link",
    googleIcon: "settings",
  },
  {
    name: "Promos + Discounts",
    link: "/promotions",
    reactLink: true,
    iconClass: "zmdi zmdi-money-off dashboard-link-icon",
    id: "promotions-link",
    googleIcon: "local_offer",
  },
  {
    name: "Service Charges",
    link: "/service-charges",
    reactLink: true,
    id: "service-charges-link",
    googleIcon: "request_quote",
  },
  {
    name: "Tip Settings",
    link: "/tip-settings",
    reactLink: true,
    id: "tipping-settings-link",
    googleIcon: "paid",
  },
  {
    name: "Employees",
    link: "/employees",
    reactLink: true,
    iconClass: "zmdi zmdi-face dashboard-link-icon",
    id: "employees-link",
    googleIcon: "people",
  },
  {
    name: "Tables / Location Codes",
    link: "/edit-locations",
    reactLink: true,
    iconClass: "zmdi zmdi-my-location dashboard-link-icon",
    id: "edit-locations-link",
    googleIcon: "qr_code_scanner",
  },
  // {
  //   name: "Kiosk Configuration Settings",
  //   link: "/edit-kiosk-config",
  //   reactLink: true,
  //   shouldCheckLinkWhitelist: true,
  //   id: "edit-kiosk-config",
  //   googleIcon: "smartphone",
  // },
  {
    name: "Printer Configuration",
    link: "/printer-configuration",
    reactLink: true,
    iconClass: "zmdi zmdi-print dashboard-link-icon",
    googleIcon: "print",
  },
  {
    name: "Support Text Alerts",
    link: "/#!/supportAlerts",
    iconClass: "zmdi zmdi-comment-alert dashboard-link-icon",
    googleIcon: "textsms",
  },
  {
    name: "Guest Notification Sets",
    link: "/fulfillment-notices",
    reactLink: true,
    iconClass: "zmdi zmdi-collection-text dashboard-link-icon",
    googleIcon: "notifications",
  },
  {
    name: "Order Grouping",
    link: "/manage-print-rules",
    reactLink: true,
    iconClass: "zmdi zmdi-group dashboard-link-icon",
    googleIcon: "table_view",
  },
  {
    name: "System Logs",
    link: "/system-logs",
    reactLink: true,
    iconClass: "zmdi zmdi-collection-text dashboard-link-icon",
    googleIcon: "list_alt",
  },
  {
    name: "Service Requests",
    link: "/service-requests",
    reactLink: true,
    featureId: "service_requests",
    iconClass: "zmdi zmdi-run dashboard-link-icon",
    googleIcon: "directions_run",
  },
  {
    name: "BOS Settings",
    link: "/#!/bosSettings",
    iconClass: "zmdi zmdi-group dashboard-link-icon",
    id: "bos-settings-link",
    googleIcon: "dashboard_customize",
  },
];

export const INTEGRATION_LINKS = [
  {
    name: "DoorDash App Store",
    link: "/bbot-app-store",
    reactLink: true,
    iconClass: "zmdi zmdi-store dashboard-link-icon",
    id: "app-store-link",
    googleIcon: "apps",
  },
];

export const ADMIN_LINKS = [
  {
    name: "Handheld Devices",
    link: "/#!/edit/devices",
    iconClass: "zmdi zmdi-devices dashboard-link-icon",
    googleIcon: "devices",
  },
  {
    name: "Device Profiles",
    link: "/#!/edit/handheldConfigs",
    iconClass: "zmdi zmdi-smartphone-setting dashboard-link-icon",
    googleIcon: "tablet_mac",
  },
  {
    name: "Stations",
    link: "/stations",
    reactLink: true,
    googleIcon: "computer",
  },
  {
    name: "Customer Properties",
    link: "/#!/customerProperties",
    iconClass: "zmdi zmdi-info dashboard-link-icon",
    id: "customer-properties-link",
    googleIcon: "settings",
  },
  {
    name: "Feature Management",
    link: "/feature-management",
    iconClass: "zmdi zmdi-flag dashboard-link-icon",
    id: "feature-flags-link",
    reactLink: true,
    googleIcon: "flag",
  },
  {
    name: "PreBot",
    link: "/myjourneys",
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    reactLink: true,
    googleIcon: "rocket_launch",
  },
  {
    name: "Admin Portal",
    link: "/admin/portal",
    isAdminPortalLink: true,
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    googleIcon: "admin_panel_settings",
  },
  {
    name: "Manage Partners",
    link: "/manage-partners",
    reactLink: true,
    iconClass: "zmdi zmdi-accounts-list-alt dashboard-link-icon",
    googleIcon: "api",
  },
];

export const SUPER_ADMIN_LINKS = [
  {
    name: "Sales Rep Commissions",
    link: "/sales-commission",
    reactLink: true,
    iconClass: "zmdi zmdi-balance-wallet dashboard-link-icon",
    googleIcon: "groups",
  },
  {
    name: "Cut Agreements",
    link: "/cut-agreements",
    reactLink: true,
    iconClass: "zmdi zmdi-cutlery dashboard-link-icon",
    id: "cut-agreements",
    googleIcon: "vertical_split",
  },
  {
    name: "Card Processing Rates",
    link: "/card-processing-rates",
    reactLink: true,
    iconClass: "zmdi zmdi-card dashboard-link-icon",
    googleIcon: "credit_card",
  },
  {
    name: "Owner Panel Announcements",
    link: "/owner-announcements",
    iconClass: "zmdi zmdi-accounts-list dashboard-link-icon",
    id: "customer-journeys-link",
    reactLink: true,
    googleIcon: "textsms",
  },
  {
    name: "PCB Management",
    link: "/pcb-updater",
    reactLink: true,
    iconClass: "zmdi zmdi-accounts-list-alt dashboard-link-icon",
    googleIcon: "device_hub",
  },
];

export const TOP_ADMIN_ONLY_URLS = [
  {
    name: "Cut Agreements",
    link: "/cut-agreements",
    reactLink: true,
    iconClass: "zmdi zmdi-cutlery dashboard-link-icon",
    id: "cut-agreements",
    googleIcon: "vertical_split",
  },
  {
    name: "Card Processing Rates",
    link: "/card-processing-rates",
    reactLink: true,
    iconClass: "zmdi zmdi-card dashboard-link-icon",
    googleIcon: "credit_card",
  },
];
