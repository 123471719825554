import { Colors, InlineChildren, Spacing, Text } from "@doordash/design-language";
import styled from "styled-components";

export const LinkText = styled(Text)`
  padding: 4px 8px;
  color: ${(props) => (props.selected ? "#1167e8" : Colors.SystemGrey60)};
  background-color: ${(props) => (props.selected ? "#eaf2fe" : Colors.White)};
  border-radius: 4px;
`;

export const LinkSectionText = styled(LinkText)`
  padding: 4px 2px;
  color: ${(props) => (props.selected ? "#1167e8" : Colors.SystemBlack)};
`;

export const UpsellsListSectionTitle = styled(InlineChildren)`
  margin-bottom: ${Spacing.Small}px;
`;
