import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import CheckboxInput from "../../../../../src/components/global/form/CheckboxInput";
import TemplateStringInputField from "../../../../../src/components/global/form/pos-integrations/TemplateStringInputField";
import { SearchSelectInput, TextInput } from "../../../global/form";
import DisplayExtraCheckoutInfoOnTicketsFields from "../../../global/form/pos-integrations/DisplayExtraCheckoutInfoOnTicketsFields";

const RevelIntegrationModalInputs = (props) => {
  const { values } = props;

  const getDefaultDiningOption = (selectedValue) => {
    let matchingDiningOption = values?.allRevelDiningOptions?.find((e) => e.dining_option_id === selectedValue);

    if (matchingDiningOption) {
      return {
        value: selectedValue,
        label: matchingDiningOption.name,
      };
    }
    return null;
  };

  return (
    <>
      <div className={"margin-bottom-1"}>
        <p>Enable order injection from TOP to Revel. These settings can be changed at any time.</p>
      </div>
      <Form.Row className={"margin-bottom-1"}>
        <CheckboxInput sm={12} label="Inject orders into Revel" name="injectOrders" id="inject-orders" />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <TemplateStringInputField
          label={"Ticket Call Name Template String"}
          id={"call-name-template-string"}
          name={"callNameTemplateString"}
          subText={"Use custom formatting for the ticket call name that is injected into Revel."}
        />
      </Form.Row>
      <Form.Row className={"margin-bottom-1"}>
        <TextInput
          id={"revel-multi-channel-name-string"}
          name={"revelMultiChannelMenuName"}
          label={[
            "Multi Channel Menu Name",
            "  ",
            <OverlayTrigger
              key={"placement"}
              placement={"right"}
              overlay={
                <Tooltip id={"revel-multi-channel-tooltip"}>
                  Use this to sync menu items from the multi-channel menu instead of the online menu for Revel.
                </Tooltip>
              }
            >
              <div className="zmdi zmdi-info-outline dashboard-link-icon" />
            </OverlayTrigger>,
          ]}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          sm={12}
          label={"Inject TOP order data as customer data"}
          name={"injectOrderDataAsCustomerData"}
          id={"injectOrderDataAsCustomerData"}
        />
      </Form.Row>
      {values.injectOrderDataAsCustomerData && (
        <Form.Row className="margin-bottom-1">
          <TemplateStringInputField
            label="Customer Name Template String"
            id="customer-name-template-string"
            name="customerNameTemplateString"
            subText="Use custom formatting for the customer name that is injected into Revel to control what is displayed on the KDS."
          />
        </Form.Row>
      )}
      <Form.Row>
        {values.injectOrders && (
          <DisplayExtraCheckoutInfoOnTicketsFields injectOrders={values.injectOrders} posName={"Revel"} />
        )}
      </Form.Row>
      <Form.Row>
        <CheckboxInput label={"Sync refunds from Revel"} name={"syncRefundsFromRevel"} id={"syncRefundsFromRevel"} />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={"Keep TOP quantity limits instead of Revel quantity limits"}
          name={"keepQuantityLimits"}
          id={"keepQuantityLimits"}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={"Ignore Revel inventory for item availability"}
          name={"ignoreRevelInventory"}
          id={"ignoreRevelInventory"}
        />
      </Form.Row>
      <Form.Row>
        <SearchSelectInput
          isSearchable
          id={"customDineInDiningOption"}
          md={12}
          name={"customDineInDiningOption"}
          label={"Custom Dine In Dining Option"}
          placeholder={"Optional - Select a custom Dine-In Dining Option"}
          defaultValue={getDefaultDiningOption(values.customDineInDiningOption)}
          value={values.customDineInDiningOption}
          options={values.allRevelDiningOptions.map((diningOption) => ({
            label: diningOption.name,
            value: diningOption.dining_option_id,
          }))}
        />
      </Form.Row>
      <Form.Row>
        <SearchSelectInput
          isSearchable
          id={"customTakeoutDiningOption"}
          md={12}
          name={"customTakeoutDiningOption"}
          label={"Custom To Go Dining Option"}
          placeholder={"Optional - Select a custom To-Go Dining Option"}
          defaultValue={getDefaultDiningOption(values.customTakeoutDiningOption)}
          value={values.customTakeoutDiningOption}
          options={values.allRevelDiningOptions.map((diningOption) => ({
            label: diningOption.name,
            value: diningOption.dining_option_id,
          }))}
        />
      </Form.Row>
      <Form.Row>
        <SearchSelectInput
          isSearchable
          id={"customDeliveryDiningOption"}
          md={12}
          name={"customDeliveryDiningOption"}
          label={"Custom Delivery Dining Option"}
          placeholder={"Optional - Select a custom Delivery Dining Option"}
          defaultValue={getDefaultDiningOption(values.customDeliveryDiningOption)}
          value={values.customDeliveryDiningOption}
          options={values.allRevelDiningOptions.map((diningOption) => ({
            label: diningOption.name,
            value: diningOption.dining_option_id,
          }))}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={"Import unique modifiers per menu item"}
          name={"uniqueModsPerItem"}
          id={"uniqueModsPerItem"}
        />
      </Form.Row>
      <Form.Row>
        <CheckboxInput
          label={[
            "Automatically inject default modifiers ",
            <OverlayTrigger
              key="overlay-default-mods"
              placement="right"
              overlay={
                <Tooltip id="tooltip-default-mods">
                  Modifier groups that have one available modifier that is selected by default will inject into Revel
                  automatically.
                </Tooltip>
              }
            >
              <div className="zmdi zmdi-info-outline dashboard-link-icon" />
            </OverlayTrigger>,
          ]}
          name="autoInjectDefaultMods"
          id="autoInjectDefaultMods"
        />
      </Form.Row>
    </>
  );
};

export default RevelIntegrationModalInputs;
