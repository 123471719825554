import styled from "styled-components";
import { Input } from "top-component-library";

const BbotInput = ({ ...props }) => {
  return <InputStyled {...props} />;
};

const InputStyled = styled(Input)`
  width: 100%;
  padding: 8px 14px;
  border-radius: 8px;
`;

export default BbotInput;
