import axios from "axios";
import React from "react";
import { Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";

class SquareOAuthModal extends React.Component {
  makeSquareOAuthRequest = async () => {
    const { selectedCustomer } = this.props;
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const state = urlParams.get("state");
      const res = await axios.get(
        "/owner/start_square_oauth?customer_id=" + selectedCustomer.customer_id + "&state=" + state
      );
      window.open(res.data.url, "_self");
    } catch (error) {
      toast.error("Encountered an unknown error while attempting to connect with Square");
      console.error(error);
    }
  };

  render() {
    return (
      <Container>
        <div className={"margin-bottom-4"}>
          <h2>Approve Square</h2>
        </div>
        <div className={"margin-bottom-4"}>
          <p>Clicking approve will grant TOP read-only access to your Square account's data.</p>
        </div>
        <div>
          <Button onClick={() => this.makeSquareOAuthRequest()}>Approve</Button>
        </div>
      </Container>
    );
  }
}

export default SquareOAuthModal;
