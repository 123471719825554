import { useEffect, useState } from "react";

import DismissibleButton from "components/owner-app/dismissable-button/DismissibleButton";

import axios from "axios";
import { trackClickSaveEditServiceCharge } from "instrumentation/tracking/page-tracking-events";
import {
  BbotButton,
  BbotModal,
  Card,
  CheckboxInput,
  Col,
  Form,
  FulfillmentAndLocationsInput,
  List,
  notification,
  NumberInput,
  PercentPlusAmountInput,
  Row,
  TextInput,
} from "top-component-library";
import EditSettingsContainer from "top-component-library/EditSettingsContainer";
import {
  calculateDefaultObjectSettings,
  filterLocationIds,
  foldFulfillmentMethods,
  fulfillmentKeysToPrettyNames,
  generalErrorAlert,
  isUnique,
  locationIdsToNames,
  removeKeys,
  unfoldFulfillmentMethods,
} from "util/Utils";
/*
Page for editing and bulk editing service charges
  required props:
    selectedServiceChargeKeys: A list of the IDs of service charges to edit
    service charges: A list of service charge objects, retrieved from api/getOrderFeeConfig.data
    id: ID for testing
    hideEditFunction: The function to be called when editing is canceled
    userInfo: The userInfo passed through above component. Used to hide admin only settings
    locations: List of location objects from api/getLocations.data.locations
    fulfillmentMethods: List of fulfillment method objects from api/getLocations.data.fulfillment_methods
    onSubmitCallback: Function called when changes are submitted
    selectedCustomer: Object passed through parent
 */
const EditServiceCharges = ({
  selectedServiceChargeKeys,
  serviceCharges,
  id = "edit-service-charges",
  hideEditFunction,
  userInfo,
  locations,
  fulfillmentMethods,
  onSubmitCallback,
  supportsDynamicDelivery,
  ownerProperties,
}) => {
  // State to hold unsaved changes
  const [settingsState, setSettingsState] = useState({});
  // State to hold initial values for fields
  const [formInitialValues, setFormInitialValues] = useState();
  // States to show/hide modals
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showInvalidEntryModal, setShowInvalidEntryModal] = useState(false);
  // State to hide/show fields
  const [, setArbitrary] = useState(false);
  const [isFeeHidden, setIsFeeHidden] = useState(false);
  // Form object
  const [editServiceChargesForm] = Form.useForm();

  // Construct a list of the charge objects from the selected IDs (for ease of use)
  const selectedServiceCharges = serviceCharges.filter((charge) => selectedServiceChargeKeys.includes(charge.id));

  const bulkEditMode = selectedServiceChargeKeys?.length > 1;
  let defaultValues = bulkEditMode
    ? calculateDefaultObjectSettings(selectedServiceCharges)
    : { ...selectedServiceCharges[0] };
  // If single edit determine whether the name for customers field should populate unchecked. If bulk edit always hide
  if (!bulkEditMode) {
    defaultValues.guest_name_is_owner_name = defaultValues.name_for_owner === defaultValues.name_for_customer;
  } else {
    defaultValues.guest_name_is_owner_name = true;
  }

  // Set default values
  useEffect(() => {
    const initialValues = {
      name_for_owner: defaultValues?.name_for_owner,
      fee_percent: defaultValues?.fraction_of_sale * 100,
      fee_amount: defaultValues?.fixed_cents / 100,
      name_for_guests: defaultValues?.name_for_customer,
      default_tax: defaultValues?.uses_default_tax, // REMINDER: Need to add logic here
      tax_rate: (defaultValues?.tax_fraction * 100).toFixed(3),
      visible_to_owner: defaultValues?.visible_to_owner,
      fulfillment_methods: unfoldFulfillmentMethods(defaultValues?.fulfillment_methods, fulfillmentMethods),
      locations: defaultValues?.location_ids,
      guest_name_is_owner_name: defaultValues?.guest_name_is_owner_name,
      use_delivery_fee: defaultValues?.automatically_matches_delivery_cost,
    };
    setFormInitialValues(initialValues);
    editServiceChargesForm.setFieldsValue(initialValues);
    setIsFeeHidden(initialValues.use_delivery_fee);
  }, [selectedServiceChargeKeys, locations, fulfillmentMethods, serviceCharges]); // eslint-disable-line react-hooks/exhaustive-deps

  // Small helper to add a new unsaved change
  const handleChanges = (changes) => {
    let fieldsToRemove = [];
    if (!bulkEditMode) {
      for (const field in changes) {
        if (!isUnique(changes[field], defaultValues[field])) fieldsToRemove.push(field);
      }
    }
    setSettingsState(
      removeKeys(
        {
          ...settingsState,
          ...changes,
        },
        fieldsToRemove
      )
    );
  };

  // General settings tab
  const generalSettings = (
    <Card title="Basic Charge Settings" className="settings-section-card">
      {/* If single edit, show the charge name field */}
      {!bulkEditMode && (
        <div className="settings-element">
          <TextInput
            id={id + "-fee-name-input"}
            label="Service charge name"
            name="name_for_owner"
            placeholder="Service charge"
            onChange={(e) =>
              handleChanges(
                editServiceChargesForm.getFieldValue("guest_name_is_owner_name")
                  ? {
                      name_for_owner: e.target.value,
                      name_for_customer: e.target.value,
                    }
                  : { name_for_owner: e.target.value }
              )
            }
          />
          <p>
            <i>This is the name of the charge as it appears in the Owner Panel and on reports.</i>
          </p>
        </div>
      )}
      {
        <div className="settings-element">
          {/* Fee percent and amount */}
          {bulkEditMode && <span>Service fee </span>}
          <DismissibleButton
            name={id + "-fee-dismissible"}
            id={id + "-show-fee"}
            buttonText="Edit for selected service charges"
            dismissedInitially={!bulkEditMode}
            onClickCallback={() =>
              handleChanges({
                fraction_of_sale: editServiceChargesForm.getFieldValue("fee_percent") / 100,
                fixed_cents: editServiceChargesForm.getFieldValue("fee_amount") * 100,
                automatically_matches_delivery_cost: editServiceChargesForm.getFieldValue("use_delivery_fee"),
              })
            }
            onCancelCallback={() =>
              setSettingsState(
                removeKeys(settingsState, ["fraction_of_sale", "fixed_cents", "automatically_matches_delivery_cost"])
              )
            }
          >
            {!isFeeHidden && (
              <Row>
                <Col xxl={{ span: 11 }}>
                  <PercentPlusAmountInput
                    id={id + "-fee-input"}
                    label={bulkEditMode ? "" : "Service Fee"}
                    required={!isFeeHidden}
                    name="fee"
                    form={editServiceChargesForm}
                    onChange={(value) => {
                      handleChanges({
                        fraction_of_sale: value.percent / 100,
                        fixed_cents: value.amount * 100,
                      });
                    }}
                  />
                </Col>
              </Row>
            )}
            {(supportsDynamicDelivery || defaultValues.automatically_matches_delivery_cost) && (
              <CheckboxInput
                id="use_delivery_fee"
                name="use_delivery_fee"
                label="Pass along the delivery fee (instead of using a set fee)"
                onChange={(e) => {
                  setIsFeeHidden(e.target.checked);
                  handleChanges({
                    automatically_matches_delivery_cost: e.target.checked,
                    fixed_cents: defaultValues.fixed_cents,
                    fraction_of_sale: defaultValues.fraction_of_sale,
                  });
                  editServiceChargesForm.setFieldsValue({
                    fee_percent: formInitialValues.fee_percent,
                    fee_amount: formInitialValues.fee_amount,
                  });
                }}
              />
            )}
          </DismissibleButton>
          <p>
            <i>
              The service fee is calculated as a percent of the order plus a fixed dollar amount. If you choose to pass
              a delivery fee instead guests will be charged whatever fee your delivery provider charges.
            </i>
          </p>
        </div>
      }
    </Card>
  );

  // Advanced settings tab
  const advancedSettings = (
    <>
      <Card title="Advanced Settings" className="settings-section-card margin-bottom-4">
        {/* Checkbox controls whether the name for guests is the same as name for owners or whether a field to enter
        a unique name is displayed */}
        <div className="settings-element">
          <span>Displayed charge name </span>
          <DismissibleButton
            id={id + "-show-guest-name"}
            buttonText="Edit for selected service charges"
            dismissedInitially={!bulkEditMode}
            onClickCallback={() =>
              handleChanges({
                name_for_customer: editServiceChargesForm.getFieldValue("name_for_guests"),
              })
            }
            onCancelCallback={() => setSettingsState(removeKeys(settingsState, ["name_for_customer"]))}
          >
            {!bulkEditMode && (
              <CheckboxInput
                name="guest_name_is_owner_name"
                label="Use the Charge Name for display on checkout and receipts"
                id={id + "-guest-name-checkbox"}
                onChange={(e) => {
                  // If enabled set settingsState to name for owner
                  if (e.target.checked) {
                    handleChanges({
                      name_for_customer: editServiceChargesForm.getFieldValue("name_for_owner"),
                    });
                  } else if (defaultValues.name_for_owner === defaultValues.name_for_customer) {
                    // If disabled and previously enabled, use the current value of name for owner
                    handleChanges({
                      name_for_customer: editServiceChargesForm.getFieldValue("name_for_owner"),
                    });
                    editServiceChargesForm.setFieldsValue({
                      name_for_guests: editServiceChargesForm.getFieldValue("name_for_owner"),
                    });
                    // If disabled and previously disabled use the previous value
                  } else {
                    handleChanges({
                      name_for_customer: defaultValues?.name_for_customer,
                    });
                    editServiceChargesForm.setFieldsValue({
                      name_for_guests: defaultValues?.name_for_customer,
                    });
                  }
                }}
              />
            )}
            {(!editServiceChargesForm.getFieldValue("guest_name_is_owner_name") || bulkEditMode) && (
              <TextInput
                id={id + "-guest-name-input"} // REMINDEr: Checking and unchecking this counts as a change
                label="Charge name for guests"
                name="name_for_guests"
                required={true}
                onChange={(e) => handleChanges({ name_for_customer: e.target.value })}
              />
            )}
          </DismissibleButton>
          <p>
            <i>
              If this option is enabled the 'Charge name' will be used on all guest-facing displays. Disabling this
              option allows for using a different guest-facing name.
            </i>
          </p>
        </div>
        {/* Use a non-default tax rate */}
        <div className="settings-element">
          <span>Tax Rate </span>
          <DismissibleButton
            name={id + "-tax-dismissible"}
            id={id + "-show-tax"}
            buttonText="Edit for selected service charges"
            dismissedInitially={!bulkEditMode}
            onClickCallback={() =>
              handleChanges({
                tax_fraction: editServiceChargesForm.getFieldValue("tax_rate") / 100,
              })
            }
            onCancelCallback={() => setSettingsState(removeKeys(settingsState, ["tax_fraction"]))}
          >
            <CheckboxInput
              id={id + "-default-tax"}
              name="default_tax"
              label="Use default tax rate"
              onChange={(e) => {
                // If single editing a service charge that was previously default, do some special behavior
                if (!bulkEditMode && defaultValues?.uses_default_tax) {
                  if (e.target.checked) {
                    setSettingsState(removeKeys(settingsState, ["tax_fraction"]));
                  } else {
                    setSettingsState({
                      ...settingsState,
                      tax_fraction: defaultValues?.tax_fraction,
                    });
                    editServiceChargesForm.setFieldsValue({
                      tax_rate: defaultValues?.tax_fraction * 100,
                    });
                  }
                }
                // If enabled set the value to null
                else if (e.target.checked) {
                  handleChanges({ tax_fraction: null });
                }
                // If disabled set the field and the value to the current tax fraction
                else {
                  handleChanges({ tax_fraction: defaultValues?.tax_fraction });
                  editServiceChargesForm.setFieldsValue({
                    tax_rate: defaultValues?.tax_fraction * 100,
                  });
                }
              }}
            />
            {!editServiceChargesForm.getFieldValue("default_tax") && (
              <NumberInput
                id={id + "-tax-rate-input"}
                name="tax_rate"
                addonAfter="%"
                min={0}
                max={100}
                onChange={(value) => {
                  // If single editing and use default tax was previously true skip the uniqueness check
                  if (!bulkEditMode && defaultValues?.uses_default_tax) {
                    setSettingsState({
                      ...settingsState,
                      tax_fraction: value / 100,
                    });
                  } else handleChanges({ tax_fraction: value / 100 });
                }}
                step="0.001"
              />
            )}
          </DismissibleButton>
          <p>
            <i>All service charges will be taxed at your default tax rate unless a custom tax rate is entered.</i>
          </p>
        </div>
        {/* Visible to owners checkbox - only shown if user is an admin */}
        {userInfo.role === "admin" && (
          <div className="settings-element">
            <span>Visible by non-Admins </span>
            <DismissibleButton
              id={id + "-show-visible-to-owner"}
              buttonText="Edit for selected service charges"
              dismissedInitially={!bulkEditMode}
              onClickCallback={() =>
                handleChanges({
                  visible_to_owner: editServiceChargesForm.getFieldValue("visible_to_owner"),
                })
              }
              onCancelCallback={() => setSettingsState(removeKeys(settingsState, ["visible_to_owner"]))}
            >
              <CheckboxInput
                label="Visible to owners"
                id={id + "-visible-to-owner-input"}
                name="visible_to_owner"
                onChange={(e) => handleChanges({ visible_to_owner: e.target.checked })}
              />
            </DismissibleButton>
            <p>
              <i>
                If this option is disabled Owners/Managers will not be able to see or edit this service charge from the
                Owner Portal. It will still be visible on the checkout screen and on receipts.
              </i>
            </p>
          </div>
        )}
      </Card>
      {/* Fulfillment and location selection*/}
      <Card title="Fulfillment Methods and Location Codes" className="settings-section-card">
        <div className="settings-element">
          <DismissibleButton
            id={id + "-show-fulfillment-and-locations"}
            buttonText="Edit for selected service charges"
            dismissedInitially={!bulkEditMode}
            onClickCallback={() =>
              handleChanges({
                fulfillment_methods: foldFulfillmentMethods(
                  editServiceChargesForm.getFieldValue("fulfillment_methods"),
                  fulfillmentMethods
                ),
                location_ids: editServiceChargesForm.getFieldValue("locations"),
              })
            }
            onCancelCallback={() =>
              setSettingsState(removeKeys(settingsState, ["fulfillment_methods", "location_ids"]))
            }
          >
            <FulfillmentAndLocationsInput
              id={id + "-fulfillmentAndLocationInput"}
              form={editServiceChargesForm}
              fulfillmentMethods={fulfillmentMethods}
              locations={locations}
              initialFulfillments={unfoldFulfillmentMethods(defaultValues.fulfillment_methods, fulfillmentMethods)}
              onChange={(value) => {
                handleChanges({
                  fulfillment_methods: foldFulfillmentMethods(value.fulfillment_methods, fulfillmentMethods),
                  location_ids: filterLocationIds(value.location_ids, locations, value.fulfillment_methods),
                });
              }}
            />
          </DismissibleButton>
          <p>
            <i>
              By default service charges will apply to orders of all fulfillment types and originating from every
              location code. Use this setting to limit where this service charge will apply.
            </i>
          </p>
        </div>
      </Card>
    </>
  );

  // Formats a list of unsaved changes into user-friendly display
  const formatChanges = (changes) => {
    const arr = [];
    if (!bulkEditMode) {
      for (const change in changes) {
        if (
          change === "tax_fraction" &&
          defaultValues?.uses_default_tax &&
          !editServiceChargesForm.getFieldValue("default_tax")
        ) {
          arr.push({ name: change, value: changes[change] });
        } else {
          arr.push({ name: change, value: changes[change] });
        }
      }
    } else {
      for (const change in changes) {
        arr.push({ name: change, value: changes[change] });
      }
    }
    return (
      <List>
        {arr.map((change, i) => {
          let label = "";
          let value = "";
          switch (change.name) {
            case "name_for_owner":
              label = "Charge Name";
              value = change.value;
              break;
            case "fraction_of_sale":
              label = "Fee Percent";
              value = change.value * 100 + "%";
              break;
            case "fixed_cents":
              label = "Fee Amount";
              value = "$" + (change.value / 100).toFixed(2);
              break;
            case "automatically_matches_delivery_cost":
              label = "Fee type";
              value = change.value ? "Passes delivery fee" : "Set fee";
              break;
            case "name_for_customer":
              label = "Displayed Charge Name";
              value = change.value;
              break;
            case "tax_fraction":
              label = "Tax Rate";
              value = change.value !== null ? (change.value * 100).toFixed(3) + "%" : "Use default tax rate";
              break;
            case "visible_to_owner":
              label = "Visible to non-Admins";
              value = change.value ? "Visible" : "Not visible";
              break;
            case "fulfillment_methods":
              label = "Fulfillment Methods";
              value = fulfillmentKeysToPrettyNames(change.value, fulfillmentMethods).join(", ");
              break;
            case "location_ids":
              label = "Location Codes";
              value = locationIdsToNames(change.value, locations).join(", ");
              break;
            case "guest_name_is_owner_name":
              label = "Guest display name";
              value = change.value ? "Use the charge name" : "Use a different display name";
              break;
            default:
              label = "unrecognized input: " + change.name;
          }
          return (
            <List.Item key={i}>
              <Row>
                <Col span={24}>
                  <strong>{label}</strong>
                </Col>
                <Col span={24}>{value}</Col>
              </Row>
            </List.Item>
          );
        })}
      </List>
    );
  };

  // Save changes on a successful edit
  const saveChanges = async () => {
    const payloadProperties = { ...settingsState };
    // If the user is single editing, has entered a new name for owner, and hasn't specified a unique display name,
    // use the new owner name as the new display name
    if (
      !bulkEditMode &&
      editServiceChargesForm.getFieldValue("guest_name_is_owner_name") &&
      payloadProperties.name_for_owner
    ) {
      payloadProperties.name_for_customer = payloadProperties.name_for_owner;
    }
    if (payloadProperties.tax_fraction) {
      payloadProperties.tax_fraction = parseFloat(payloadProperties.tax_fraction.toFixed(5));
    }
    if (payloadProperties.automatically_matches_delivery_cost) {
      payloadProperties.fraction_of_sale = 0;
      payloadProperties.fixed_cents = 0;
    }

    // send tracking event to Segment
    trackClickSaveEditServiceCharge({
      service_charge_ids: selectedServiceChargeKeys,
      service_charge_properties: payloadProperties,
    });

    // Save changes, hide the confirmation modal, reset form fields, reset unsaved changes
    try {
      await axios.post("api/serviceCharges", {
        serviceChargeIds: selectedServiceChargeKeys,
        properties: payloadProperties,
      });
      notification.success({
        message: "Successfully saved service charge settings.",
      });
      setShowConfirmationModal(false);
      editServiceChargesForm.resetFields();
      onSubmitCallback();
      setSettingsState({});
    } catch (error) {
      generalErrorAlert(error, "Unexpected Server Error.", true);
      console.error(error);
    }
  };

  // Function called when the save button is clicked (shows a modal)
  const onSave = () => {
    validateChanges(settingsState)?.length === 0 ? setShowConfirmationModal(true) : setShowInvalidEntryModal(true);
  };

  // Check that all changes are valid, and return a list of issues if not
  const validateChanges = (changes) => {
    let res = [];
    for (const key in changes) {
      if (key === "name_for_owner" && changes.name_for_owner === "") res.push("Charge name cannot be blank");
      if (
        key === "fraction_of_sale" &&
        changes.fraction_of_sale === 0 &&
        !isFeeHidden &&
        (changes.fixed_cents === 0 || (!changes.fixed_cents && defaultValues.fixed_cents === 0))
      )
        res.push("Must enter a percent or an amount");
      if (
        key === "fixed_cents" &&
        changes.fixed_cents === 0 &&
        !isFeeHidden &&
        (changes.fraction_of_sale === 0 || (!changes.fraction_of_sale && defaultValues.fraction_of_sale === 0))
      )
        res.push("Must enter a percent or an amount");
      if (
        key === "automatically_matches_delivery_cost" &&
        !changes.automatically_matches_delivery_cost &&
        !(changes.fraction_of_sale > 0 || changes.fixed_cents > 0)
      )
        res.push("Must enter a percent or an amount");
      if (key === "name_for_customer" && changes.name_for_customer === "") res.push("Fee display name cannot be blank");
      if (key === "fulfillment_methods" && changes.fulfillment_methods?.length === 0)
        res.push("Must select at least one fulfillment method");
      if (key === "location_ids" && changes.location_ids?.length === 0)
        res.push("Must select at least one location code");
    }
    res = [...new Set(res)]; // Cleans up an edge case where the fee error shows twice
    return res.map((input) => <List.Item key={input}>{input}</List.Item>);
  };

  // Only count fee once and don't double count the names if they're the same
  const adjustedCount = (changes) => {
    const keys = Object.keys(changes);
    let count = keys.length;
    if (keys.includes("fraction_of_sale") && keys.includes("fixed_cents")) count--;
    if (
      keys.includes("name_for_owner") &&
      keys.includes("name_for_customer") &&
      changes.name_for_owner === changes.name_for_customer
    )
      count--;
    return count;
  };

  // Render
  return (
    <div>
      <Form
        id={id + "-form"}
        form={editServiceChargesForm}
        layout="vertical"
        initialValues={formInitialValues}
        scrollToFirstError
        onChange={() => setArbitrary((arbitrary) => !arbitrary)}
      >
        <EditSettingsContainer
          id={id + "-edit-settings"}
          selectedNames={selectedServiceCharges.map((charge) => charge.name_for_owner)}
          objectToEditName="service charge"
          showSave={adjustedCount(settingsState) > 0}
          onSave={onSave}
          hideEditFunction={hideEditFunction}
          tabs={[
            {
              name: "General",
              content: generalSettings,
            },
            {
              name: "Advanced",
              content: advancedSettings,
            },
          ]}
        />
      </Form>

      {/*==================== MODALS ===================*/}

      <BbotModal
        id={id + "-confirmationModal"}
        onCancel={() => setShowConfirmationModal(false)}
        visible={showConfirmationModal}
        title="Are you sure you want to make these changes?"
        okText="Confirm"
        onOk={saveChanges}
        okButtonProps={{ id: id + "-confirmationModal-confirm" }}
        cancelButtonProps={{ id: id + "-confirmationModal-cancel" }}
      >
        {formatChanges(settingsState)}
      </BbotModal>
      {/* Modal to list invalid changes */}
      <BbotModal
        title="Invalid changes"
        id={id + "-invalidEntryModal"}
        visible={showInvalidEntryModal}
        onCancel={() => setShowInvalidEntryModal(false)}
        footer={
          <BbotButton danger id={id + "-invalidEntryModal-cancel"} onClick={() => setShowInvalidEntryModal(false)}>
            Cancel
          </BbotButton>
        }
      >
        Please fix the following issues:
        <List>
          <strong>{validateChanges(settingsState)}</strong>
        </List>
      </BbotModal>
    </div>
  );
};

export default EditServiceCharges;
