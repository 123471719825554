import { FileState, FsFile, FsFileMetadata, FsStoredUrl } from "./types";

const MAX_LENGTH = 40;

export const truncateName = (name: string, length = MAX_LENGTH) =>
  name.slice(0, length) + (name.length > length ? "..." : "");

export enum FileSizes {
  KB = 1_000,
  MB = 1_000_000,
  GB = 1_000_000_000,
  TB = 1_000_000_000_000,
  PB = 1_000_000_000_000_000,
}

export const bytesToReadable = (bytes: number, decimalPlaces: number = 0) => {
  const [unit, size] = getGreatestSmallerFileSize(bytes);

  return `${parseFloat((bytes / size).toFixed(decimalPlaces))} ${unit}`;
};

const getGreatestSmallerFileSize = (bytes: number) => {
  const minFileSize = ["KB", FileSizes.KB];
  const greatestSmaller = Object.entries(FileSizes).reduce(
    (greatestSmaller, [type, size]) => (size <= bytes ? [type, size] : greatestSmaller),
    minFileSize
  ) as [keyof typeof FileSizes, FileSizes];

  return greatestSmaller;
};

/**
 * We want file uploads to be compatible with old uploads, so add some properties.
 */
export const getFileMetadata = (file: FsFile, originalFile: File): FsFileMetadata => {
  const { name, size, type } = file._file;
  return {
    ...file,
    name,
    size,
    type,
    filename: name,
    mimetype: originalFile.type,
  };
};

/**
 * If we have to resize an image, Filestack doesn't return metadata in the same format
 * as when we upload, so we must massage.
 */
export const getFileMetadataFromStoredUrl = (storedFile: FsStoredUrl): FsFileMetadata => ({
  filename: storedFile.filename,
  mimetype: storedFile.mimetype,
  name: storedFile.filename,
  type: storedFile.type,
  status: FileState.STORED,
  size: storedFile.size,
  url: storedFile.url,
  handle: storedFile.handle,
  _file: {
    name: storedFile.filename,
    size: storedFile.size,
    type: storedFile.type,
  },
});

export const getImageDimensions = (file: File): Promise<[number, number]> =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const { height, width } = image;
      resolve([height, width]);
    };
  });
