import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import "./styles/global/global-styles.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/global/components/notifications.scss";
import Routes from "./pages/routes.js";
import { PrismWebGlobalStyles, Themes, Theming } from "@doordash/design-language";
import LayerManager from "@doordash/component-layer-manager";
import { ToastProvider } from "@doordash/component-toast";
import { OwnerDVSClientProvider } from "DynamicValues";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Theming overrides={Themes.Merchant}>
        <OwnerDVSClientProvider>
          <ToastContainer position="top-center" autoClose={4000} />
          <LayerManager>
            <PrismWebGlobalStyles />
            <ToastProvider>
              <Routes />
            </ToastProvider>
          </LayerManager>
        </OwnerDVSClientProvider>
      </Theming>
    );
  }
}

export default App;
